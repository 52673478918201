<template>
  <div class="StockItem">
    <div class="title-box">
      <div class="name">{{data.name}}</div>
    </div>
    <div class="lines">
      <template v-for="(item, index) of getLines">
        <div v-if="!item.isHide" :key="index" class="line">
          <div class="name">{{ item.name }}</div>
          <div class="value">
            <template v-if="item.value === 'rule'">
              <p v-if="data.followedNextTime" style="margin-bottom: 0">{{ `${$t('【待跟进】线索下次跟进时间：')}${data.followedNextTime}` }}</p>
              <p v-if="data.defeatNextTime" style="margin-bottom: 0">{{ `${$t('【战败】线索回访时间：')}${data.defeatNextTime}` }}</p>
              <p v-if="data.invalidNextTime" style="margin-bottom: 0">{{ `${$t('【无效】线索回访时间：')}${data.invalidNextTime}` }}</p>
            </template>
            <template v-else>
              {{ item.value || '--' }}
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="btns">
      <span @click.stop="handleClick('progress')">查看任务完成情况</span>
      <span v-if="data.assignStatus === 0 && data.type === 1" @click.stop="handleClick('allocation')">分配销售顾问</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StockItem1',
  props:{
    data:{
      type: Object,
      default:()=>({})
    },
  },
  // data() {
  //   return {

  //   }
  // },
  computed: {
    getLines() {
      const { createByName, dealerName, createTime, assignCount, totalCount, execCount, type, followedNextTime, defeatNextTime, invalidNextTime } = this.data
      return [
        {
          name: '销售门店',
          value: dealerName,
          isHide: type == 1
        },
        {
          name: '任务创建人',
          value: createByName
        },
        {
          name: '任务创建时间',
          value: createTime
        },
        {
          name: '任务分配顾问进度',
          value: `${assignCount}/${totalCount}`,
          isHide: type == 2
        },
        {
          name: '任务完成进度',
          value: `${execCount}/${totalCount}`
        },
        {
          name: '任务执行规则',
          value:'rule'
        },
      ]
    },
  },
  methods:{
    handleClick(type){
      this.$emit('go', type)
    },
  }
}
</script>

<style lang="less" scoped>
.StockItem {
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 16px 12px 8px 12px;
  font-size: 13px;
  //margin: 10px 16px;

  .title-box {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 6px;

    .type-name {
      font-size: 13px;
    }
  }

  .lines {
    .line {
      display: flex;
      // align-items: center;
      line-height: 24px;
      .name {
        color: rgba(13,23,26,0.45);
        &::after {
          content: '：';
        }
      }
    }
  }
  .btns{
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    border-top: 1px solid rgba(13,23,26,0.05);
    span{
      flex:1;
      font-size: 16px;
      // padding: 8px 8px 0;
      font-family: HYQiHei-60S, HYQiHei;
      font-weight: normal;
      color: #B9921A;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
