<template>
  <div class="clue-defeat">
    <div class="totalCount">{{$t('共')}} {{ totalCount }} {{$t('条数据')}}</div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <!-- <Item :item-data="item" :checked="commonFilterParams.queryType === 0" @change="onCheckChanged" @click="handlerTo(item)" /> -->
        <Item :item-data="item" :checked="false" :refundReasonConfig="refundReasonConfig" @change="onCheckChanged" @click="handlerTo(item)" />
      </template>
    </List>
  
    <!-- <div v-if="commonFilterParams.queryType === 0" class="btns-bar">
      <van-checkbox :value="isAll" @click="isAllChange">{{$t('全选')}}</van-checkbox>
      <van-button square color="#EED484" @click="handlerAudit">{{$t('批量审核')}} {{ checkedItems.length > 0 ? `(${checkedItems.length})` :
        ''
      }}</van-button>
    </div> -->
  
    <van-dialog v-model="visible" show-cancel-button :before-close="handlerConfirm" confirmButtonColor="#B9921A"
      :cancel-button-text="$t('取消')" 
      :confirm-button-text="$t('确认')">
      <DefeatFrom v-if="visible" ref="defeatFromRef" :applyUserRole="checkedItems[0].applyUserRole"></DefeatFrom>
    </van-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DefeatFrom from './defeatFrom.vue'
import List from '@/components/baseList/list.vue'
import Item from './item.vue'
import loading from '@/utils/loading'
import { getOrderPage, orderUpdateAp } from '@/services/approveManage'
import { judgeMobile } from '@/utils/index'
import mixins from '../mixins.js'
import { replace } from 'lodash'

export default {
  name: 'leadChangeInto',
  components: { List, Item, DefeatFrom },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'clueDefeat')
    })
  },
  mixins: [mixins],
  data() {
    return {
      // 列表数据源集合
      list: [],
      // 审核选中项集合
      checkedItems: [],
      visible: false,
      totalCount: 0
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    judgeMobile,
    isAll() {
      return this.checkedItems.length > 0 && this.checkedItems.length === this.list.filter(item => item.applyUserRole === this.checkedItems[0].applyUserRole).length
    },
    refundReasonConfig() {
      const obj = {}
      this.dictHash[1038].forEach(({ code, name })=>{
        obj[code] = name
      })
      return obj
    },
  },
  activated() {
    this.onRefresh()
  },
  methods: {
    handlerConfirm(action, done) {
      if (action === 'confirm') {
        this.$refs.defeatFromRef.validate().then(async () => {
          await this.save()
          done()
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    async save() {
      const params = this.$refs.defeatFromRef.getData()
      params.orderIds = this.checkedItems.map(item => item.orderId)
      const result = await orderUpdateAp(params)
      this.$toast(this.$t('审核成功'))
      this.onRefresh()
      this.$emit('refresh')
    },
    // 审核
    handlerAudit() {
      if (!this.checkedItems.length) {
        this.$toast(this.$t('请选择待审核线索'))
        return false
      }
      this.visible = true
    },
    // 全选/取消全选
    isAllChange() {
      const selectList = []
      const flag = !this.isAll
      this.$refs.list.list.map((item) => {
        const flagObj = this.checkedItems.length ? this.checkedItems[0].applyUserRole : this.$refs.list.list[0].applyUserRole
        // 全选时，只能选择相同角色类型的数据
        if (flagObj === item.applyUserRole) {
          item.checked = flag
          if (item.checked) {
            selectList.push(item)
          }
        }
      })
      this.checkedItems = selectList
    },
    handlerTo(item) {
      // 区分是订单修改 还是退单
      const { type, orderId } = item
      if (type === 1) {
        let query = {
          id: orderId,
          toExamine: true,
        }
        if (this.commonFilterParams.queryType === 1) {
          query.noExamineBtn = true
        }
        this.$router.push({
          path: '/order-edit',
          query,
        })
      } else if (type === 3) {
        this.$router.push({
          path:'/refundOrder',
          query: {
            id: orderId,
          }
        })
      }
    },
    onCheckChanged(checked, theClue) {
      if (this.checkedItems.length && this.checkedItems[0].applyUserRole !== theClue.applyUserRole) {
        this.$toast(this.$t('请选择相同类型角色申请的线索'))
        return
      }
      const selectList = []
      this.$refs.list.list.forEach(item => {
        if (item.id === theClue.id) {
          item.checked = checked
        }
        if (item.checked) {
          selectList.push(item)
        }
      })
      this.checkedItems = selectList
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const {
        reviewTimeStart = '',
        reviewTimeEnd = '',
        applyTimeStart = '',
        applyTimeEnd =' ',
        reviewStatuses = [],
        salesUserIds = [],
        queryType,
        ...o
      } = this.commonFilterParams
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        applyTime: {
          start: applyTimeStart,
          end: applyTimeEnd,
        },
        examineTime: {
          start: reviewTimeStart,
          end: reviewTimeEnd,
        },
        applyUserIds: salesUserIds,
        reviewStatus: queryType == 0 ? 1 : 2,
        ...o
      }
      if (this.userInfo.type === 1) {
        params.type = 1
      }
      if (reviewStatuses.length) {
        params.applyStatusList = reviewStatuses
      }
      loading.showLoading()
      return getOrderPage(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        res.dataList.forEach((item) => {
          item.checked = false
        })
        if (obj.currentPage === 1) {
          this.list = res.dataList
          this.checkedItems = []
        } else {
          this.list = this.$refs.list.list.concat(res.dataList)
        }
        return res
      })
    },
    // 刷新
    onRefresh() {
      this.$refs.list.onRefresh()
    },
  
  }
}
</script>
<style lang="less" scoped>
  .btns-bar {
    width: 100%;
    position: absolute;
    justify-content: space-between;
    bottom: 0px;
    display: flex;
    background: #fff;
    padding-left: 16px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  
    /deep/.van-button {
      color: #0D171A !important;
      font-weight: 500;
      // width: 92px;
    }
  }
  
  .totalCount {
    color: @black;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }
</style>
  