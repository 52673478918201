<template>
<div class="search-item">
  <div class="top">
    <div class="row">
      <shortConsumerInfo :data="dataSource" :showLevel="false" :showCallType="true"/>
      <div  :class="['status', `status_${dataSource.state}`]">{{ dataSource.state | codeFormat }} </div>
    </div>
    <div class="info">
        <span class="label">{{$t('来源渠道：')}}</span>
        <span :style="{ color:dataSource.online===0?'#0040C5':'#B9921A' }">
            {{ dataSource.onlineName }}
        </span>
        <span v-if="dataSource.channelOne || dataSource.channelTwo" class="channel-name">{{ dataSource | sourceText }}</span>
    </div>
    <div class="info">
        <span class="label">{{$t('意向信息：')}}</span>
        <span class="val"> {{ dataSource.seriesName }}  {{ dataSource.modelName  }}{{ !dataSource.seriesName&&!dataSource.modelName?'--':'' }}</span>
    </div>
    <div class="info">
        <span class="label">{{$t('跟进人：')}}</span>
        <span class="val"> {{ dataSource.followUserName || '--'  }} </span>
    </div>
    <img v-if="dataSource.isRecycle===1" src="../../../images/public-clue.png" class="public-clue">
  </div>
  <div v-if="isCanOperation" class="bottom">
    <span @click="$router.push(`/clue-remark?id=${ dataSource.id }&followResult=1011041`)">{{$t('快速填写跟进')}}</span>
    <span v-if="isCanReceive" @click="handleClick('receive')">{{$t('领取')}}</span>
    <span v-else-if="isCanDetails" @click="handleClick('details')">{{$t('查看详情')}}</span>
    <!-- 必须是要有跟进人的才能申请 -->
    <span v-else-if="dataSource.followUserId" @click="handleClick('authorize')">{{$t('申请填写更多')}}</span>
  </div>
</div>  
</template>
<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
let vm
export default {
  components:{
    shortConsumerInfo
  },
  filters:{
    sourceText(data) {
      let arr = []
      const { channelOne, channelTwo, channelThree } = data
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      return arr.length > 0 ? arr.join('/') : vm.$t('暂无')
    },
  },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    userInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isCanReceive(){
      const { isRecycle,state,isAppointment } = this.dataSource
      const { roleLogos } = this.userInfo
      // 公共线索池的，就只能同角色领取
      const flag = isRecycle===1&&this.isSameRole
      
      let flag2 = false
      if (state==='2000000'&&isAppointment===1&&roleLogos.includes('1014002')){
        // 待分配的，如果关联了预约单则只有销售顾问可以领取
        flag2=true
      } else if (state==='2000000'&&isAppointment===0&&roleLogos.includes('1014001')){
        // 待分配的，如果没有关联预约单则只有DCC可以领取
        flag2=true
      }
      return flag||flag2
    },
    // 是否可以查看详情，【被授权人，跟进人】可以查看详情
    isCanDetails(){
      const { followUserId,authorizedEmployeeId } = this.dataSource
      const { id,authorizeIds } = this.userInfo
      return authorizedEmployeeId===id||authorizeIds.includes(followUserId)
    },
    // 是否同角色
    isSameRole(){
      const { roleLogos } = this.userInfo
      const { followUserRoleCode } = this.dataSource
      return roleLogos.includes(followUserRoleCode)
    },
    // 在公共线索池，并且无同角色，则不能做任何操作
    isCanOperation(){
      return !(!this.isSameRole&&this.dataSource.isRecycle===1)
    }
  },
  mounted() {
    vm = this
  },
  methods:{
    handleClick(type){
      this.$emit(type)
    }
  }
}
</script>
<style lang="less" scoped>
@checkbox-color:#0D171A;

.search-item{
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 16px 12px 12px 12px;
  .top{
      position: relative;
      flex:1;
      color: @checkbox-color;
      font-size: 13px;
      line-height: 16px;
      .info{
          line-height: 16px;
          margin-bottom: 8px;
          .label{
              color: rgba(13,23,26,0.45);
          }
          .channel-name{
              position: relative;
              padding-left: 8px;
          }
          .channel-name::before{
              position: absolute;
              top:50%;
              transform: translateY(-50%);
              left: 0;
              width: 1px;
              height: 14px;
              content: '';
              background: #D9D9D9;
          }
      }
      .row{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        
        .status{
          width: 70px;
          text-align: right;
          // &.status_2000005{
          //   color: #0040C5
          // }
          // &.status_2000010{
          //   color: #FFD100
          // }
          // &.status_2000040,&.status_2000015{
          //   color: #E4002C
          // }
          // &.status_2000045,&.status_2000020{
          //   color: rgba(13,23,26,0.45) !important
          // }

        }
      }

      .public-clue{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 76px;
        height: 76px;
      }
  }
  .bottom{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #B9921A;
    padding-top: 9px ;
    border-top: 1px solid rgba(13,23,26,0.05);
    span{
      cursor: pointer;
    }
  }
}
</style>
