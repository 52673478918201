<template>
  <div class="Stock">
    <div class="stock-top">
      <search-bar-new
        :placeholder-text="$t('请输入完整VIN号或后8位查询')"
        :showScreen="true"
        :active="true"
        :maxlength="17"
        @input="(val)=>{vin = val}"
        @search="onSearch"
        @screening="onScreening"
      >
      </search-bar-new>
      <!-- Tabs -->
      <van-tabs v-model="active" class="stock-tabs" @change="changeTab">
        <van-tab v-for="(tab, index) of tabs" :key="index" :title="tab.name" :name="tab.value"></van-tab>
      </van-tabs>
    </div>
    <FilterPanel
      ref="filterPanel"
      v-model="filterActive"
      :options="filterOptions"
      @submit="onFilter"
      @reset="controlModelSales"
      @beforeReset="beforeReset"
    />
    
    <!-- List -->
    <div class="totality">{{$t('共')}} {{ countNum }} {{$t('条数据')}}</div>
    <List v-if="userInfo.id" ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <ItemView v-if="active === '2'" :data="item"></ItemView>
        <StockItem v-else :data="item" :type="active"></StockItem>
      </template>
    </List>

  </div>
</template>

<script>
// Tools
import { mapGetters } from 'vuex'
import formatParams from '@/utils/formatParams'
import { arrayFormat, debounce, isMobilePhone } from '@/utils/index.js'
import { v4 as uuidv4 } from 'uuid'

// Components
import List from '@/components/baseList/list.vue'
import StockItem from './components/StockItem.vue'
import FilterPanel from '@/components/filter-panel'
import ItemView from './components/item.vue'

// Images
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'

// APIs
// import baseDataServices from '@/services/baseDataServices'
import { getWarehouseList } from '@/services/store.js'
import CarManageServices from '@/services/carManageService.js'
import { modelSalesQuery } from '@/services/store.js'
import SearchBarNew from '@/components/search-bar-new'
import salesAssistantService from '@/services/salesAssistant'

export default {
  name: 'stock',
  components: { List, StockItem, FilterPanel, ItemView, SearchBarNew },
  data() {
    return {
      filterIcon,
      filterActiveIcon,
      filterActive: false,
      active: '0',
      tabs: [
        {
          name: '商品车',
          value: '0'
        },
        {
          name: '已退役展车',
          value: '1'
        },
        {
          name: '已退役试驾车',
          value: '2'
        },
      ],
      list: [],
      filterParams: {
        isAbnormalVehicle:0,
        deliveryStoreCodes:[], // 交付门店
        seriesCodes:[], // 车系
        modelCodes:[], // 车型
        colourCodes:[], // 外饰
        interiorCodes:[], // 内饰
        optionsCodes:[], // 选装
        parentCode: '',
        childCode: [],
        dealerProvinceCode: '',
        dealerCityCode: '',
        dealerIds: []
      },
      defaultValue: {
        parentCode: '',
        childCode: [],
        dealerProvinceCode: '',
        dealerCityCode: '',
        dealerIds: []
      },
      vin: '',
      dealerList: [],
      seriesList:[],
      modelList:[],
      outsideColours:[],
      innerColours:[],
      saleOptions:[],
      countNum:0,
      parentList: [],
      provinceList: [],
      dealerResult: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    // 交付门店
    deliveryStoreList() {
      let arr = []
      try {
        arr = arrayFormat(this.userInfo.dealers[0].relations,{
          value: 'dealerCode',
          name:'dealerName',
          cb:(item)=>({ id:item.dealerCode })
        })
      } catch (error) {
            
      }
      return arr
    },
    filterOptions() {
      let arr = [{
        id: uuidv4(),
        type: 'options',
        label: '车系',
        multiple: true,
        field: 'seriesCodes',
        options: this.seriesList,
        isCache: true,        
        onChange:(arr, params={})=>{
          Object.assign(params,{
            modelCodes:[],
            colourCodes:[],
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(2,formatParams(params, false))
        }
      },
      {
        id: uuidv4(),
        type: 'options',
        label: '车型',
        multiple: true,
        field: 'modelCodes',
        options: this.modelList,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            colourCodes:[],
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(3,formatParams(params, false))
        }
      },
      {
        id: uuidv4(),
        type: 'options',
        label: '外饰',
        multiple: true,
        field: 'colourCodes',
        options:this.outsideColours,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(4,formatParams(params, false))
        }
      },
      {
        id: uuidv4(),
        type: 'options',
        label: '内饰',
        multiple: true,
        field: 'interiorCodes',
        options:this.innerColours,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            optionsCodes:[]
          })
          this.controlModelSales(5,formatParams(params, false))
        }
      },
      {
        id: uuidv4(),
        type: 'options',
        label: '选装',
        multiple: true,
        field: 'optionsCodes',
        options:this.saleOptions,
        isCache: true,
      }]
      const isAbnormalVehicles = [
        { id: 0, label: '否', value: 0 },
        { id: 1, label: '是', value: 1 }
      ]
      if (this.active !== '2') {
        arr.push(
          {
            id: uuidv4(),
            type: 'options',
            label: '是否异常车辆',
            field: 'isAbnormalVehicle',
            required: true,
            defaultValue:[0,1].includes(this.filterParams.isAbnormalVehicle) ?isAbnormalVehicles[this.filterParams.isAbnormalVehicle]:'',
            isCache: true,
            options:isAbnormalVehicles
          },{
            id: uuidv4(),
            type: 'options',
            label: '车辆节点',
            multiple: true,
            field: 'vehicleNodes',
            isCache: true,
            options:this.$store.getters.getDictHash(3003) || []
          }
        )
      }
      if (this.userInfo.type === 1) {
        if (this.active !== '2') {
          arr.unshift(
            {
              id: uuidv4(),
              type: 'options',
              label: '交付门店',
              multiple: true,
              field: 'deliveryStoreCodes',
              options: this.deliveryStoreList,
              defaultValue: this.filterParams.deliveryStoreCodes && this.deliveryStoreList.filter(({ value })=>this.filterParams.deliveryStoreCodes.includes(value)) || [] ,
              isCache: true,
            }
          )
        }
      } else {
        const { parentCode= '', childCode=[], dealerProvinceCode= '', dealerCityCode='', dealerIds=[] } = this.defaultValue
        arr.unshift(
          // 大区
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('大区'),
            field: 'parentCode',
            defaultValue: parentCode,
            options: this.parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
          },
          // 城市区域
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('城市区域'),
            multiple: true,
            field: 'childCode',
            defaultValue: childCode.join(','),
            options: [],
          },
          // 省份
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('省份'),
            field: 'dealerProvinceCode',
            defaultValue: dealerProvinceCode,
            options: this.provinceList.map(({ code, name }) => ({ id: code, code, name })),
          },
          // 城市
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('城市'),
            field: 'dealerCityCode',
            defaultValue: dealerCityCode,
            options: [],
          },
          // 门店
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.active === '2' ? this.$t('销售门店') : this.$t('交付门店'),
            multiple: true,
            field: 'dealerIds',
            defaultValue: dealerIds.join(','),
            dealerType: this.active === '2' ? 1 : 2,
            options: this.dealerResult.dataList?.map((item) => { return { code: item.id, name: item.name } })
          }
        )
      }
      return arr
    }
  },
  watch: {
    deliveryStoreList:{
      handler(val){
        this.setDeliveryStoreCodes(val)
      },
      immediate: true
    },
    active: {
      handler() {
        this.getDealer()
      },
      immediate: true
    },
    userInfo: {
      handler(val) {
        if (val.type === 0) {
          // 总部获取
          this.getRegionAndAddress()
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.controlModelSales()
  },
  methods: {
    onSearch() {
      if (!this.vin || this.vin.length == 8 || this.vin.length == 17) {
        this.onRefresh()
      } else {
        this.$toast(this.$t('请输入完整VIN号或后8位查询'))
      }
    },
    beforeReset(options) {
      if (this.active !== '2'){
        for (let i in options) {
          const item = options[i]
          if (item.field === 'deliveryStoreCodes' && this.deliveryStoreList && this.deliveryStoreList.length){
            item.defaultValue = [this.deliveryStoreList[0]]
          } else if (item.field === 'isAbnormalVehicle'){
            item.defaultValue = { id: 0, label: '否', value: 0 }
          }
        }
      }
    },
    onScreening() {      
      this.filterActive = true
    },
    setDeliveryStoreCodes(val) {
      if (val && val.length){
        this.filterParams.deliveryStoreCodes=[val[0].value]
      }
    },
    // 切换tab
    changeTab(val) {
      this.countNum = 0
      this.filterParams = this.$options.data().filterParams
      if (val !== '2') {
        this.setDeliveryStoreCodes(this.deliveryStoreList)
      }
      this.$refs.list.list = []
      try {
        this.$refs.filterPanel.reset()
      } catch (error) {
        
      }
      this.onRefresh()
    },
    onRefresh:debounce(function() {            
      this.$refs.list.onRefresh()
    },500),
    fetchApi(obj = {}) {
      let vinStr = ''
      if ([8, 17].includes(this.vin.length)) vinStr = this.vin
      const page = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
      }
      if (this.active === '2'){
        return CarManageServices.getBackendCarPage({ ...page,
          query:{ ...this.filterParams,isRetire:'11041001',optionCodes: this.filterParams.optionsCodes, vinCode: vinStr } }).then(res=>{
          console.log('res-----------', res)
          this.countNum = res?.page?.totalCount || 0
          return res
        })
      }
      const { deliveryStoreCodes=[],...query } = this.filterParams
      return getWarehouseList({
        ...page,
        ...query,
        vin: vinStr,
        deliveryStoreCodes : deliveryStoreCodes.length ? deliveryStoreCodes: this.deliveryStoreList.map(({ value })=>value),
        type: this.active
      }).then(res=>{
        console.log('res-----------', res)
        this.countNum = res?.page?.totalCount || 0
        return res
      })
    },
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      const { parentCode='', childCode=[], dealerProvinceCode='', dealerCityCode='', dealerIds=[] } = this.filterParams
      this.defaultValue = { parentCode,childCode,dealerProvinceCode,dealerCityCode,dealerIds }
      this.onRefresh()
      this.filterActive= false
    },
    controlModelSales(queryType = 1, params = {}) {
      const { parentCode='', childCode=[], dealerProvinceCode='', dealerCityCode='', dealerIds=[] } = params
      this.defaultValue = { parentCode,childCode,dealerProvinceCode,dealerCityCode,dealerIds }
      switch (queryType) {
        case 1:// 车系
          this.modelSalesQuery(1,params) 
          this.modelSalesQuery(2,params)
          this.modelSalesQuery(3,params)
          this.modelSalesQuery(4,params)
          this.modelSalesQuery(5,params)
          break      
        case 2: // 车型
          this.modelSalesQuery(2,params)
          this.modelSalesQuery(3,params)
          this.modelSalesQuery(4,params)
          this.modelSalesQuery(5,params)
          break
        case 3:// 外饰
          this.modelSalesQuery(3,params)
          this.modelSalesQuery(4,params)
          this.modelSalesQuery(5,params)
          break
        case 4:// 内饰
          this.modelSalesQuery(4,params)
          this.modelSalesQuery(5,params)
          break
        case 5:// 选装
          this.modelSalesQuery(5,params)
          break
      }
    },
    async modelSalesQuery(queryType=1,params={}) {
      const { seriesCodes=[], modelCodes=[], colourCodes=[], interiorCodes=[], optionsCodes=[] } = params
      const res = await modelSalesQuery({ queryType,seriesCodes,modelCodes,colourCodes,interiorCodes,optionsCodes }).then(res=>arrayFormat(res,{
        value: 'code',
        cb:(item)=>({ id:item.code })
      }))
      switch (queryType) {
        case 1:// 车系
          this.seriesList = res
          break      
        case 2: // 车型
          this.modelList = res
          break
        case 3:// 外饰
          this.outsideColours = res
          break
        case 4:// 内饰
          this.innerColours = res
          break
        case 5:// 选装
          this.saleOptions = res
          break
      }
    },
    // 获取大区 省份城市 交付门店
    async getRegionAndAddress() {
      this.parentList = await salesAssistantService.getRegionTreeApi()
      this.provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
    },
    async getDealer() {
      this.dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '', dealerType: this.active === '2' ? 1 : 2 })
    }
  }
}
</script>

<style lang="less" scoped>
.Stock {
  .stock-top{
    position: sticky;
    top: 0;
    z-index: 20;
  }
  .filter-icon {
    width: 20px;
    height: 20px;
    // position: fixed;
    // right: 12px;
    // top: 12px;
  }
  .totality{
    padding: 10px 16px 0;
    font-size: 12px;
  }
}
</style>
