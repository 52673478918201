<template>
  <div class="car-item" @click="handleClick('detail')">
    <div class="row">
      <span class="vin">{{ itemData.vinCode }}</span>
      <span v-if="itemData.carNumber" class="car-number">{{ itemData.carNumber }}</span>
    </div>
    <div class="main">
      <div class="info">
        <span class="label">{{ $t('车系') }}：</span>
        <span class="val">{{ itemData.seriesName }}</span>
      </div>
      <div class="info">
        <span class="label">{{ $t('车型') }}：</span>
        <span class="val">{{ itemData.modelName }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('外饰颜色')}}：</span>
        <span class="val">{{ itemData.exteriorColor }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('内饰颜色')}}：</span>
        <span class="val">{{ itemData.interiorColor }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('销售门店')}}：</span>
        <span class="val">{{ itemData.dealerName }}</span>
      </div>
      <template v-if="!type">
        <div class="info">
        <span class="label">{{$t('占用原因')}}：</span>
        <span class="val">{{ handleResult(itemData.processResult) }}{{ itemData.processResult&&itemData.processRemark? ' : ': '' }}{{ itemData.processRemark }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('占用时间')}}：</span>
        <span class="val">{{ itemData.processStartTime }} - {{ itemData.processEndTime }}</span>
      </div>
      </template>
    </div>
    <!-- <div class="info">
      <span class="label">{{$t('录入日期：')}}：</span>
      <span class="val">{{ itemData.entryTime }}</span>
    </div> -->
    <div v-if="userInfo.type === 1" class="btns">
      <span @click.stop="handleClick('schedule')">{{$t('查看排程')}}</span>
      <span v-if="itemData.status" @click.stop="handleClick('operate')">{{$t('车辆占用')}}</span>
      <span v-else @click.stop="review">{{$t('恢复可用')}}</span>
    </div>
  </div>
  </template>
<script>
import CarManageServices from '@/services/carManageService.js'
import { mapGetters } from 'vuex'
export default {
  props:{
    itemData: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type:Number,
      default: 0,
    }
  },
  computed:{
    ...mapGetters(['dictHash', 'userInfo']),
    resultList(){
      return this.dictHash[2007]
    }
  },
  methods:{
    handleResult(result){
      if (!result) return '-'
      const list = result.split(',')
      return list
        .map(code => this.resultList.find(e => e.code === code))
        .filter(target => target !== undefined)
        .map(target => target.name)
        .join(',')
    },
    handleClick(type){
      this.$emit('go',type)
    },
    review() {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('车辆状态恢复后，客户可进行预约试驾操作，请确认是否恢复！'),
        confirmButtonColor: '#B9921A',
      }).then( () => {
        this.carOperatePost()
      })  
    },
    carOperatePost() {
      CarManageServices.carOperatePost({ id: this.itemData.id, status: 1 }).then(res=>{
        this.$toast(this.$t('恢复可用成功'))
        this.$emit('upDate')
      })
    }
  }
}
</script>
    <style lang="less" scoped>
    .car-item{
      background: #FFFFFF;
      padding: 12px;
      box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
    }
    .row{
      display: flex;
      margin-bottom: 8px;
    
      .vin{
        height: 20px;
        font-size: 16px;
        font-family: HYQiHei-60S, HYQiHei;
        font-weight: normal;
        color: #0D171A;
        line-height: 20px;
        // margin-right: 8px;
        flex: 1;
      }
      .car-number{
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        font-size: 12px;
        background: #3476FE;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        color: #fff;
      }
    }
    .main{
      display:flex;
      flex-wrap: wrap;
    }
    .info {
      margin-bottom: 8px;
      display: flex;
      min-width: 50%;
      .label {
        color: rgba(13, 23, 26, 0.45);
        font-size: 13px;
      }
    
      .val {
        white-space: normal;
        flex: 1;
        min-width: 0;
        overflow-wrap: break-word;
        font-size: 13px;
    
      }
    
    }
    .btns{
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      border-top: 1px solid rgba(13,23,26,0.05);
      span{
        flex:1;
        height: 22px;
        font-size: 16px;
        font-family: HYQiHei-60S, HYQiHei;
        font-weight: normal;
        color: #B9921A;
        line-height: 22px;
        text-align: center;
        cursor: pointer;
      }
    }
    </style>
