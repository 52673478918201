<template>
<div v-if="queryMark" :class="['orderDetail', $store.getters.userInfo.type == 1 ? 'orderDetail-pab' : '']">
  <div class="top orderItem">
    <OrderTitle :orderDetail="orderDetail"></OrderTitle>
    <VehicleInfo :orderDetail="orderDetail"></VehicleInfo>
    <div :class="[ 'stepsBox', isEn ? 'stepComponent-en' : stepList.length > 3 ? 'stepComponent-zh' : 'stepComponent-zh-least']">
      <van-steps :active="activeStep" active-color="#029640" >
        <van-step v-for="(item, index) in stepList" :key="index">
          <template #default>
            <div class="stepTitle">
              <div>{{item.text}}</div>
              <div class="changeTime" >
                 <p>{{ dateFormat(item.changeTime,'YYYY-MM-DD') }}</p>
                 <p>{{ dateFormat(item.changeTime,'HH:mm:ss') }}</p>
              </div>
            </div>
          </template>
        </van-step>
      </van-steps>
    </div>
    <div v-if="['ORDER_LOCKED', 'BE_CONTRACT'].includes(orderDetail.orderStatus)" style="color: #e4002c;">
      <van-icon name="warning-o" color="#e4002c" />
      {{ $t('客户选择线下签署合同时，请在"更多"下载待签署合同，签署合同前请确认合同内容与订单信息一致') }}
    </div>
    <div v-if="showOrderUpdateInfo" class="info_tips">
      <template v-if="orderDetail.abnormalType === 1">
        <div class="info-i-t">{{$t('订单修改申请：')}}</div>
        <div class="info-i-c">
          {{ orderDetail.orderUpdateStatus | codeFormat }}
        </div>
      </template>
      <template v-if="orderDetail.abnormalType === 3">
        <div class="info-i-t">{{$t('退单申请：')}}</div>
        <div class="info-i-c" @click="moreOperate({type: 'viewReturnOrder'})">
          {{ headerTips }}
        </div>
      </template>
    </div>
    <div v-if="orderDetail?.reviewVo?.status && [1,3].includes(orderDetail?.reviewVo?.status)" class="info_tips" style="margin-top: 10px;" >
      <span>{{$t('线下合同申请：')}}</span>
      <span>{{ getStatusName }}</span>
    </div>
  </div>
  <div class="middle orderItem">
    <CustomerInfo :orderDetail="orderDetail"></CustomerInfo>
  </div>
  <div class="bankInfo orderItem">
    <div class="common-t">
      <div class="common-t-l">{{$t('收款账户')}}</div>
      <div class="common-t-r" @click="onCopy">{{$t('复制信息')}}
      </div>
    </div>
    <van-cell :title="$t('户名')" :value=orderDetail.extend?.acctName />
    <van-cell :title="$t('开户行')" :value=orderDetail.extend?.acctBankName />
    <van-cell :title="$t('收款账号')" :value=orderDetail.extend?.acctBankNo />
  </div>
  <div class="bottom orderItem">
    <van-tabs v-model="activeName">
      <van-tab
        v-for="(item, index) in tabList"
        :key="index"
        :title="item.title"
        :name="item.componentName"
      >
        <component
          :is="item.componentName"
          class="scrollBlock"
          :orderDetail="orderDetail"
          @refresh="getDetail"
        >
        </component>
      </van-tab>
    </van-tabs>
  </div>
  <div v-if="$store.getters.userInfo.type == 1" class="common-footer">
    <!-- 后续迭代开发 -->
    <van-popover
      v-if="showMore"
      v-model="showPopover"
      theme="dark"
      trigger="click"
      placement="top-start"
      :actions="actions"
      class="popoverBox"
      @select="moreOperate">
      <div class="van-popover__content">
        <template v-for="(item, index) in actions">
          <div
            :key="index"
            class="van-popover__action"
            @click.stop="moreOperate(item)"
          >
            <div class="van-popover__action-text van-hairline--bottom">
              {{ item.text }}
            </div>
          </div>
        </template>
      </div>
      <template #reference>
        <span class="more">{{$t('更多')}}</span>
      </template>
    </van-popover>
    <qwIcon v-if="orderDetail.customerWeChat" :leadId="orderDetail.leadId" :externalUserIds="orderDetail.customerWeChat" class="icon"/>
    <phoneIcon
      v-if="orderDetail.customerMobile || orderDetail.customerViMobile "
      :phone="orderDetail.customerMobile"
      :clueId="orderDetail.leadId"
      :oneself="orderDetail.salesUserId === userInfo.id"
      class="icon"
    />
    <div
      v-if="
        (showContractOperate && orderDetail.salesUserId !== '0') ||
          (showPushContract && orderDetail.salesUserId !== '0' ) || showDistribute || isOrderLocked
      "
      class="btns">
      <!--订单修改不可操作上传合同；orderUpdate = true表示修改中 -->
      <van-button v-if="isOrderLocked" class="btn submit" @click="uploadContract">
        <span v-if="orderDetail?.reviewVo">{{ $t('查看线下合同') }}</span>
        <span v-else>{{ $t('上传线下合同') }}</span>
      </van-button>
      <!-- 只要分配了销售顾问  就展示订单跟进 -->
      <van-button
        v-if="!isOrderLocked && showContractOperate && orderDetail.salesUserId !== '0'"
        class="btn submit"
        :style="{
          width: orderDetail.orderPlanFollowTime ? '190px' : '100px',
        }"
        @click="onFollow">
        <div>{{$t('填写跟进')}}</div>
        <div v-if="orderDetail.orderPlanFollowTime" class="time">
          {{ orderDetail.orderPlanFollowTime | datetimeText }}
        </div>
      </van-button>
      <!--订单修改不可操作推送合同；orderUpdate = true表示修改中 -->
      <van-button
        v-if="showPushContract && orderDetail.salesUserId !== '0' && !orderDetail.orderUpdate"
        round
        class="btn submit"
        @click="contractConfirm"
        >{{$t('推送线上合同')}}</van-button>
      <!-- <van-button
        v-if="showPushContractAgain && orderDetail.salesUserId !== '0' && !orderDetail.orderUpdate"
        round
        class="btn submit"
        @click="contractConfirm"
        >{{$t('重新推送线上合同')}}</van-button> -->
      <van-button
        v-if="showDistribute"
        round
        class="btn submit"
        @click="distribute"
        >{{$t('分配')}}</van-button>
    </div>
  </div>
  <!-- 员工列表面板 -->
  <BusinessFilters
    ref="filter"
    v-model="showDistribution"
    :options="distributionOptions"
    :defaultActiveKey="['saIdList']"
    :buttonNameReset="$t('取消')"
    @reset="showDistribution = false"
    @submit="distributionFilter"
  >
  </BusinessFilters>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import OrderTitle from './component/orderTitle'
import VehicleInfo from './component/vehicleInfo'
import OrderTrack from './component/orderTrack'
import OrderInfo from './component/orderInfo'
import Finance from './component/finance'
import VehicleLogistics from './component/vehicleLogistics'
import externalUserIcon from '@/components/card-view/external-user-icon'
import CustomerInfo from './component/customerInfo'
import phoneIcon from '@/components/card-view/phone-icon'
import qwIcon from '@/components/card-view/qw-icon'
import wxIcon from '@/components/card-view/wx-icon'
import loading from '@/utils/loading'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import { ROLES } from '@/constants/constants'
import baseDataServices from '@/services/baseDataServices'
import orderServices from '@/services/orderServices'
import { i18n } from '@/main.js'
import { copyText, saveFile } from '@/utils/index'
import { dateFormat } from '@/utils'

export default {
  name: 'orderDetail',
  components: {
    OrderTitle,
    OrderTrack,
    OrderInfo,
    Finance,
    CustomerInfo,
    VehicleInfo,
    VehicleLogistics,
    externalUserIcon,
    phoneIcon,
    qwIcon,
    wxIcon,
    BusinessFilters,
  },
  data() {
    return {
      dateFormat,
      optionsList: [
        { des: this.$t('这是第一条选装信息') },
        { des: this.$t('这是第二条选装信，可以换行展示更多的数据') },
      ],
      orderId: '',
      orderDetail: {},
      activeName: 'OrderInfo',
      tabList: [
        { componentName: 'OrderInfo', title: this.$t('订单详情') },
        { componentName: 'OrderTrack', title: this.$t('订单轨迹') },
        // { componentName: 'VehicleLogistics', title: '车辆物流' },
        // { componentName: 'Finance', title: '5810a7' },
      ],
      stepList: [],
      activeStep: -1,
      queryMark: false,
      showPopover: false,
      showDistribution: false,
      isEn: i18n.locale === 'en',
      fieldList3: [
        {
          label: this.$t('户名'),
          key: 'acctName',
        },
        {
          label: this.$t('开户行'),
          key: 'acctBankName',
        },
        {
          label: this.$t('收款账号'),
          key: 'acctBankNo',
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    distributionOptions() {
      return [
        {
          name: '',
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds: '',
          height: '80vh',
          roleCodes: ROLES.SALES,
        },
      ]
    },
    // 分配按钮 店长角色且没有顾问id
    showDistribute() {
      const { salesUserId, refund } = this.orderDetail
      const { roleLogos = [] } = this.userInfo
      return roleLogos?.includes('1014003') && salesUserId == '0' && !refund
    },
    // 合同按钮 店长、订单所属的销售顾问及其master可见
    showContractOperate() {
      const { roleLogos, authorizeIds } = this.userInfo
      return (
        roleLogos.includes('1014003') ||
        (authorizeIds.includes(this.orderDetail.salesUserId) &&
          roleLogos.includes('1014002'))
      )
    },
    // 是否展示
    showViewContract() {
      return (
        this.showContractOperate &&
        [
          'BE_DELIVERY_BALANCE_BE_PAY',
          'BE_DELIVERY_BALANCE_PAID',
          'CONTRACTED',
          'ORDER_COMPLETED',
        ].includes(this.orderDetail.orderStatus) && this.orderDetail?.contractUrl
      )
    },
    // 是否展示207b7f
    showPreviewContract() {
      return (
        this.showContractOperate && this.orderDetail.orderStatus === 'ORDER_LOCKED'
      )
    },
    // 展示微信相关
    showWxIcon() {
      return !this.orderDetail.customerWeChat
    },
    // 订单编辑相关
    orderEdit() {
      // 合同未签署之前(已锁单 已推合同) && 顾问
      const { roleLogos = [] } = this.userInfo
      return (
        ['ORDER_LOCKED', 'BE_CONTRACT'].includes( this.orderDetail.orderStatus ) &&
        (roleLogos?.includes('1014002') || roleLogos?.includes('1014003'))
      )
    },
    // 申请退单相关的角色
    refundOrderRole() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos?.includes('1014002') || roleLogos?.includes('1014003')
    },
    // 是否展示更多操作按钮
    showMore() {
      return true
    },
    // 是否展示推送合同
    showPushContract() {
      return (
        this.showContractOperate &&
        ['ORDER_LOCKED'].includes(this.orderDetail.orderStatus) && !this.orderDetail.refund
      )
    },
    // 是否展示重新推送线上合同
    showPushContractAgain() {
      return (
        this.showContractOperate &&
        ['BE_CONTRACT'].includes(this.orderDetail.orderStatus) && !this.orderDetail.refund
      )
    },
    // 更多展示按钮合集
    actions() {
      const { roleLogos = [] } = this.userInfo
      // abnormalType 1修改订单标识 3 申请退单标识
      let arr = []
      const { orderStatus, orderUpdateStatus,salesUserId, orderUpdate, abnormalType, applyRefund, refund } = this.orderDetail
      if (!refund) {
        arr.push({ text: this.$t('转交付备注'),type:'remark' })
      }
      if (['ORDER_LOCKED', 'BE_CONTRACT'].includes(orderStatus) && this.userInfo.isManager && !refund) {
        arr.push({ text: this.$t('备注特殊资源需求'), type: 'noteSpecial' })
      }
      if (this.orderEdit && !refund) {
        if ( ['3001001', '3001003', '3001005', '3001010'].includes( orderUpdateStatus ) && abnormalType === 1) {
          arr.push({ text: this.$t('查看修改申请'), type: 'viewApply' })
        }
        if (['3001020', '3001025', '3001030'].includes(orderUpdateStatus) && abnormalType === 1) {
          arr.push({ text: this.$t('取消修改订单'), type: 'cancelApply' })
          arr.push({ text: this.$t('重新编辑订单'), type: 'reEdit' })
        }
        // 订单修改取消或者完成才能再次申请；orderUpdate = true表示修改中
        // 已上传线下合同且待审时不能操作
        if (
          (!orderUpdateStatus || ['3001000', '3001035'].includes(orderUpdateStatus))
          && salesUserId !== '0' && !orderUpdate
          && !(this.orderDetail?.reviewVo?.status && [1,3].includes(this.orderDetail?.reviewVo?.status))
        ) {
          arr.push({ text: this.$t('申请修改订单'), type: 'editOrder' })
        }
      }
      // if (this.showDistribute) {
      //   arr.push({ text: this.$t('订单分配'), type: 'distribute' })
      // }
      // 3.订单修改、退单---“更多” 操作中，不可预览合同 （不显示）
      if (this.showPreviewContract && salesUserId !== '0' && !orderUpdate && !refund) {
        arr.push({ text: this.$t('预览合同'), type: 'previewContract' })
      }
      if (this.showViewContract && !refund) {
        arr.push({ text: this.$t('购车合同查看'), type: 'viewContract' })
      }
      // 申请退单 店长
      if (applyRefund && roleLogos.includes('1014003') && !(this.orderDetail?.reviewVo?.status && [1,3].includes(this.orderDetail?.reviewVo?.status))) {
        arr.push({ text: this.$t('申请退单'), type: 'returnOrder' })
      }
      if (refund && this.refundOrderRole) {
        arr.push({ text: this.$t('查看退单申请'), type: 'viewReturnOrder' })
      }
      if (['ORDER_LOCKED', 'BE_CONTRACT'].includes(orderStatus) && !orderUpdate && !refund) {
        arr.push({ text: this.$t('下载待签署合同'), type: 'downloadContract' })
      }
      if (this.showWxIcon && !refund) {
        arr.push({ text: this.$t('添加客户微信'), type: 'addWechat' })
        arr.push({ text: this.$t('绑定客户微信'), type: 'bindWechat' })
      }
      return arr
    },
    // 展示订单修改信息
    showOrderUpdateInfo() {
      const { orderUpdateStatus } = this.orderDetail
      return (
        orderUpdateStatus && !['3001000', '3001035'].includes(orderUpdateStatus)
      )
    },
    // 是否显示上传线下合同
    isOrderLocked() {
      // const { type , status } = this.orderDetail.reviewVo || {}
      // return (this.orderDetail.orderStatus === 'ORDER_LOCKED' || this.orderDetail.orderStatus === 'BE_CONTRACT') && this.orderDetail.orderProtocolUrl && ( !this.orderDetail.reviewVo || (type === 2 && status === 10)) && this.orderDetail.salesUserId !== '0'
      const { orderStatus } = this.orderDetail
      return ['ORDER_LOCKED', 'BE_CONTRACT'].includes(orderStatus) && !this.orderDetail.refund && !this.orderDetail.orderUpdate
    },
    headerTips() {
      const arr = this.dictHash[3001]
      const { orderUpdateStatus, refund={} } = this.orderDetail
      const { refuseReason='', applyReason, applyStatus } = refund
      // 有驳回原因 或者撤回原因需要展示
      const statusName = arr.find(({ code }) => { return code === orderUpdateStatus })?.name
      const showReason = (applyStatus === '3001002' && applyReason) || (['3001025','3001026','3001027','3001028'].includes(applyStatus) && refuseReason)
      return showReason ? `${statusName} | ${showReason} >` : `${statusName} >`
    },
    judgePc() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    },
    getStatusName() {
      const { type, status } = this.orderDetail?.reviewVo
      const obj = {
        1: type === 1 ? this.$t('待审核') : this.$t('店长/Master审核中'),
        3: this.$t('城市经理审核中'),
        5: this.$t('已审核'),
        10: this.$t('已驳回')
      }
      return status && obj[status] || ''
    },
  },
  watch:{
    activeStep:{
      handler(val){
        // 滑动到高亮节点
        this.$nextTick(()=>{
          const scrollContainer = document.querySelector('.van-steps')
          if (val>=2){
            scrollContainer.scrollLeft = val * 100
          }
        })
      }
    }
  },
  mounted() {
    const { id } = this.$route.query
    this.orderId = id
    this.getDetail()
  },
  methods: {
    getValue(fieldKey){
      return this.orderDetail[fieldKey] || (this.orderDetail?.extend || {})[fieldKey]
    },
    onCopy(){
      if (wx?.setClipboardData){
        const copyText = this.fieldList3.map(item=>this.getValue(item.key) || '--')
        wx.setClipboardData({
          data: copyText.join(' '), // 需要复制的数据
          success: function(res) {
          }
        })
      }
    },
    // 获取订单详情
    async getDetail() {
      loading.showLoading()
      const res = await orderServices.getOrderDetail({ id: this.orderId })
      loading.hideLoading()
      this.queryMark = true
      this.orderDetail = res
      if (this.orderDetail.orderFinance && this.orderDetail.orderFinance.id) {
        this.tabList.push({
          componentName: 'Finance',
          title: this.$t('金融详情'),
        })
      }
      // 获取订单 step 信息
      this.getStepList()
    },
    async getStepList() {
      const orderStatusList = this.dictHash[3000]
      const res = await this.getHistory()
      const result = res.map(item => {
        return {
          ...item,
          text: orderStatusList.find((o) => o.code === item.orderStatus).name,
          code: item.orderStatus
        }
      })
      this.stepList = result
      this.activeStep = result.findIndex(({ isLight }) => { return isLight })
    },
    // 获取订单历史状态
    getHistory() {
      const vm = this
      return new Promise(function(resolve, reject) {
        orderServices
          .getHistoryState({
            orderId: vm.orderId,
            queryType: 1, // 1 订单 2 交车 (不传为全部)
          })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    selectUser(params = {}) {
      const { externalUserid, name } = params
      this.$set(this.orderDetail, 'customerName', name)
      this.$set(this.orderDetail, 'customerWeChat', externalUserid)
    },
    improveUser() {
      this.$router.push({
        name: 'editClue',
        query: {
          id: this.orderDetail.leadId,
          operate: 'edit',
        },
      })
    },
    // 订单跟进
    onFollow() {
      this.$router.push({
        name: 'orderFollow',
        query: {
          orderId: this.orderId,
        },
      })
    },
    // 预览合同
    async contractGet() {
      loading.showLoading()

      try {
        // 查询合同预览url
        const params = {
          orderId: this.orderId,
          contractType: 1, // 1 购车 2交车
          backUrl: `${window.location.href}&token=${sessionStorage.getItem(
            'Token'
          )}`,
        }
        const { contractViewUrl } = await orderServices.orderContract(params)
        this.contractViewUrl = contractViewUrl
        loading.hideLoading()
        // pc端需要打开新的窗体
        if (!this.$isMobile) {
          window.open(this.contractViewUrl, 'myWindow')
        } else {
          window.location.href = this.contractViewUrl
        }
        this.getDetail()
      } catch (error) {
        loading.hideLoading()
      }
    },
    // 推送线上合同
    async contractConfirm() {
      this.$dialog.confirm({
        title: '',
        message: this.$t('确认推送合同至客户签署 ？'),
        beforeClose: async (action, done) => {
          done()
          if (action === 'confirm') {
            loading.showLoading()
            await orderServices.orderContractConfirm(this.orderId, 1)
            loading.hideLoading()
            this.$toast.success(this.$t('推送合同成功'))
            setTimeout(() => {
              this.getDetail()
            }, 500)
          }
        },
      })
    },
    // 购车合同查看
    async contractView() {
      let url = this.orderDetail?.contractUrl
      if (this.$isMobile) {
        window.location.href = url
      } else {
        window.open(url, 'myWindow')
      }
    },
    // 分配订单
    distribute() {
      this.showDistribution = true
    },
    // 确认分配
    async distributionFilter(data) {
      const { saIdList = [] } = data
      if (!saIdList.length) {
        this.$toast(this.$t('请选择分配人员！'))
        return false
      }
      const params = {
        orderIds: [this.orderDetail.id],
        salesUserId: saIdList[0]?.id,
        salesUserName: saIdList[0]?.name,
      }
      loading.showLoading()
      const res = await orderServices.orderAssign(params)
      this.$toast.success(this.$t('分配成功'))
      setTimeout(() => {
        this.getDetail()
      }, 1000)
    },
    // 更多操作按钮
    async moreOperate({ type = '' }) {
      this.showPopover = false
      // 取消修改订单
      if (type === 'cancelApply') {
        this.$dialog
          .confirm({
            title: this.$t('提示'),
            message:
              this.$t('取消修改后，系统会删除修改的内容，保持原订单不变。确认取消 ？'),
            confirmButtonColor: '#B9921A',
          })
          .then(async () => {
            loading.showLoading()
            const res = await orderServices.orderUpdateCancel({
              orderId: this.$route.query.id,
            })
            const { code, msg, message } = res
            if (code === 0) {
              this.$toast.success(this.$t('操作成功！'))
              setTimeout(() => {
                loading.hideLoading()
                if (window.history.length>1){
                  this.$router.go(-1)
                } else {
                  this.getDetail()
                }
              }, 1500)
            } else {
              loading.hideLoading()
              this.$toast(msg || message)
            }
          })
          .finally(() => {})
        return
      }
      // 查看订单修改申请 订单重新编辑 修改订单
      if (['viewApply', 'reEdit', 'editOrder'].includes(type)) {
        let query = {
          id: this.$route.query.id,
        }
        if (type === 'viewApply') {
          // 查看申请
          query.toExamine = true
        }
        if (type === 'reEdit') {
          // 重新编辑
          query.reEdit = true
        }
        this.$router.push({
          path: '/order-edit',
          query,
        })
        return
      }
      // // 分配订单
      // if (type === 'distribute') {
      //   this.distribute()
      //   return
      // }
      // 预览合同
      if (type === 'previewContract') {
        this.contractGet()
        return
      }
      // 查看合同
      if (type === 'viewContract') {
        this.contractView()
        return
      }
      // 添加客户微信
      if (type === 'addWechat') {
        // 添加好友页面
        copyText(this.orderDetail.customerMobile, this.$t('号码复制成功'))
        setTimeout(() => {
          window.wx.invoke('navigateToAddCustomer', {}, function(res) {
            console.log('navigateToAddCustomer res:', res)
          })
        }, 2000)
      }
      // 绑定客户微信
      if (type === 'bindWechat') {
        const getExternalDetail = function(externalUserId) {
          return baseDataServices.getExternalDetail({ externalUserId })
        }
        const setWechat = function(params) {
          return baseDataServices.setWechat(params)
        }
        window.wx.invoke(
          'selectExternalContact',
          {
            filterType: 0,
          },
          async (res) => {
            if (res.err_msg != 'selectExternalContact:ok') return
            const { userIds } = res
            if (!userIds || userIds.length !== 1) {
              return this.$toast(this.$t('请选择一个外部联系人进行绑定'))
            }
            const [userUuid] = userIds
            const customer = await getExternalDetail(userUuid)
            if (this.orderDetail.leadId) {
              await setWechat({
                id: this.orderDetail.leadId,
                userAvatar: customer.avatar,
                userWeChat: customer.externalUserid,
                userWeChatName: customer.name,
              })
            }
            this.selectUser(customer)
          }
        )
      }
      // 转交付备注
      if (type==='remark'){
        this.$router.push({
          name:'orderDeliverRemark',
          query: {
            id: this.orderDetail.id,
            escrowRemark: this.orderDetail.escrowRemark
          }
        })
      }
      // 备注特殊资源需求
      if (type === 'noteSpecial') {
        const { id } = this.orderDetail
        this.$router.push({
          path:'/order-noteSpecial',
          query: {
            id
          }
        })
      }
      if (type === 'onFollow'){
        this.onFollow()
      }
      // 申请退单
      if (['returnOrder', 'viewReturnOrder'].includes(type)) {
        const { id } = this.orderDetail
        const query = {
          id,
        }
        this.$router.push({
          path:'/refundOrder',
          query
        })
      }
      // 下载待签署合同
      if (type === 'downloadContract') {
        if (this.judgePc) {
          this.$toast(this.$t('请登录SCRM系统PC端，或企业微信电脑版，进入订单详情页面后下载合同'))
          return false
        }
        loading.showLoading()
        const result = await orderServices.downContractTem({ id: this.orderId })
        loading.hideLoading()
        saveFile(result, { fileType: 'application/pdf' })
      }
    },
    // 上传线下合同
    uploadContract() {
      const query = {}
      if (this.orderDetail.reviewVo?.id ){
        query.id = this.orderDetail.reviewVo?.id,
        query.titleType = 'viewContract'
      } else {
        query.orderId= this.orderId,
        query.titleType = 'uploadContract'
      }
      query.enterPage = 'order-detail'
      if (this.$route.query.offlineOrderId) {
        query.id = this.$route.query.offlineOrderId
      }
      this.$router.replace({
        path: '/offlineOrder/detail',
        query
      }) 
    }
  },
}
</script>
<style scoped lang="less">
/deep/ .van-popover__action {
  min-width: 128px;
  width: auto;
}

.stepsBox{
  /deep/ .stepTitle{
    position: relative;
    .changeTime{
      position: absolute;
      left: 0;
      top: 32px;
      width: max-content;
    }
  }
}

.stepComponent-zh-least{
  /deep/.van-steps{
    overflow-x: auto;
    .van-steps__items{
      width: 100%;
    }

  }
}
.stepComponent-zh {
  /deep/.van-steps{
    overflow-x: auto;
    .van-steps__items{
      width: 100%;
      min-width: 720px;
    }

  }
}
.stepComponent-en {
  /deep/.van-steps{
    overflow-x: auto;
    .van-steps__items{
      min-width: 1200px;
    }
  }
}
/deep/ .van-steps--horizontal {
  padding: 10px 0 0 0;
  & .van-steps__items{
    padding-bottom: 46px !important;
  }
}

/deep/ .van-cell:not(.no-value){
  &:not(.van-collapse-item__title) {
    .van-cell__title {
      width: 120px !important;
      flex: inherit;
    }
  }
}

.orderDetail {
  padding: 16px;
  display: flex;
  flex-direction: column;
  color: #0d171a;
  font-size: 13px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  &.orderDetail-pab {
    padding-bottom: 96px;

    .bottom {
      // height: calc(100vh - 96px);
      height: calc(100vh - 128px);
      .scrollBlock {
        height: calc(100vh - 196px);
        overflow-y: scroll;
      }
    }
  }

  .orderItem {
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    width: 100%;
    box-sizing: border-box;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  .top {
    padding: 12px;
    .info_tips{
      display: flex;
      color: #E4002C;
      .info-i-t{
        max-width: 94px;
        min-width: 65px;
        flex-wrap: wrap;
      }
    }
  }

  .middle {
    padding: 0 12px 12px;

    /deep/ .van-cell {
      padding: 10px 0;

      &::after {
        width: 100%;
        left: 0;
      }
    }
  }
  .bankInfo{
    padding: 0 12px 12px;

    /deep/ .van-cell {
      padding: 10px 0;

      &::after {
        width: 100%;
        left: 0;
      }
    }
  }

  .bottom {
    padding: 12px;
    height: calc(100vh - 16px - env(safe-area-inset-bottom));

    /deep/ .van-tabs__wrap {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
    }

    .scrollBlock {
      height: calc(100vh - 80px - env(safe-area-inset-bottom));
      overflow-y: scroll;
    }
  }
  /deep/ .popoverBox {
    margin-right: 14px;
    width: 34px;
  }

  .btns {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .order-follow {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      align-items: center;
      margin-left: 10px;
    }

    /deep/ .van-button__text {
      font-size: 14px;
    }

    /deep/ .van-button--normal {
      padding: 0 !important;
    }

    .time {
      font-size: 12px;
    }

    .order-contract {
      margin-left: 10px;
    }
  }

  .icon {
    /deep/ img {
      width: 32px;
      height: 32px;
    }
    &:not(:last-of-type) {
      margin-right: 14px;
    }
  }
}
</style>
