import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

// 查询批量任务列表
export const getTaskBatchList = (params={}) =>request.post(`${SERVER.LEAD_SERVICE}/api/v1/taskBatch/page`,params).then(thenResolve)

// 查询批量任务列表Count
export const getTaskBatchCount = (params={}) =>request.post(`${SERVER.LEAD_SERVICE}/api/v1/taskBatch/pageCount`,params).then(thenResolve)

// 门店分配任务
export const assignTaskBatch = (params={}) =>request.post(`${SERVER.LEAD_SERVICE}/api/v1/taskBatch/assign`,params).then(thenResolve)
