var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"approveManage"},[_c('div',{staticClass:"approveManage-l"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,class:{ current: _vm.type === item.componentName },on:{"click":function($event){return _vm.changeTab(item)}}},[_c('p',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(`(${_vm.countInfo[item.countKey] || 0})`))])])}),0),_c('div',{staticClass:"approveManage-r"},[_c('div',{staticClass:"search-wrap"},[_c('search-bar-new',{attrs:{"placeholder-text":_vm.type == 'specialCarOrder' ? '请输入配置单号查询' : _vm.$t('请输入客户姓名/电话查询'),"showScreen":true,"active":_vm.searchActive},on:{"input":(val) => {
            _vm.searchStr = val;
          },"search":_vm.onSearch,"screening":function($event){_vm.filterPanelVisible = true}}}),_c('FilterPanel',{ref:"filterPanel",attrs:{"options":_vm.filterOptions},on:{"submit":_vm.onFilter},model:{value:(_vm.filterPanelVisible),callback:function ($$v) {_vm.filterPanelVisible=$$v},expression:"filterPanelVisible"}}),(_vm.type)?_c('van-tabs',{on:{"change":_vm.onChangeQueryType},model:{value:(_vm.commonFilterParams.queryType),callback:function ($$v) {_vm.$set(_vm.commonFilterParams, "queryType", $$v)},expression:"commonFilterParams.queryType"}},[_c('van-tab',{attrs:{"title":"待审批"}}),_c('van-tab',{attrs:{"title":"已审批"}})],1):_vm._e()],1),(
        _vm.$store.getters.userInfo.type == 0 &&
          ['leadChangeInto', 'orderEdit'].includes(_vm.type)
      )?_c('div',[_c('OptionsBox',{staticStyle:{"padding-bottom":"0px"},attrs:{"optionsList":[
          {
            text: _vm.type == 'leadChangeInto' ? '转入方审批' : '同区审批',
            value: 1,
          },
          {
            text: _vm.type == 'leadChangeInto' ? '转出方审批' : '跨区审批',
            value: 2,
          },
        ],"showCount":false,"subType":_vm.commonFilterParams.transferType},on:{"change":_vm.subTabChange}})],1):_vm._e(),(_vm.type)?_c(_vm.type,{ref:"listRef",tag:"component",staticClass:"list-wrap",attrs:{"commonFilterParams":_vm.commonFilterParams},on:{"refresh":_vm.reviewCenterCount}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }