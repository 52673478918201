<template>
<div class="orderInfo">
  <van-cell :title="$t('特殊资源备注')">
    <template #default>
      <div class="specialRemarkUrl">
        <p>{{ orderDetail.specialRemark }}</p>
        <van-uploader
          v-model="specialRemarkUrlList"
          class="upload"
          disabled
          :deletable="false"
          accept="image/*"
          :preview-options="{closeable: true}"/>
      </div>
    </template>
  </van-cell>
  <van-cell :title="$t('订单来源')" :value="orderDetail.orderSource"></van-cell>
  <van-cell :title="$t('客户类型')" :value="orderDetail.customerType == 1 ? $t('个人') : $t('企业')"></van-cell>
  <van-cell :title="$t('订单总金额')" :value="`¥${formatRMB(orderDetail.totalAmount)}`">
    <span content="¥"></span>{{ formatRMB(orderDetail.totalAmount) }}
  </van-cell>
  <van-cell :title="$t('意向金')">
    <span v-if="formatRMB(orderDetail.intentionAmount)" content="¥"></span>{{ formatRMB(orderDetail.intentionAmount) }}
  </van-cell>
  <van-cell :title="$t('定金')">
    <span v-if="formatRMB(orderDetail.depositAmount)" content="¥"></span>{{ formatRMB(orderDetail.depositAmount) }}
  </van-cell>
  <van-cell :title="$t('尾款')">
    <span v-if="formatRMB(orderDetail.balanceAmount)" content="¥"></span>{{ formatRMB(orderDetail.balanceAmount) }}
  </van-cell>
  <van-cell :title="$t('折扣金额')">
    <span v-if="formatRMB(orderDetail.discountAmount)" content="¥"></span>{{ formatRMB(orderDetail.discountAmount) }}
  </van-cell>
  <van-cell :title="$t('实际金额')">
    <span v-if="formatRMB(orderDetail.actualAmount)" content="¥"></span>{{ formatRMB(orderDetail.actualAmount) }}
  </van-cell>
  <van-collapse v-model="activeNames">
    <van-collapse-item :name="1" :title="$t('优惠券折扣明细')">
      <template v-if="carOrderDiscountList.length > 0">
        <div v-for="(item, index) in carOrderDiscountList" :key="index">
          <div class="content-t">{{ item.discountReason}}</div>
          <van-field rows="1" autosize type="textarea" :value="item.discountRemark" :placeholder="$t('折扣备注说明')" class="content-c"/>
          <div class="content-p">
            <span content="¥"></span>{{`${formatRMB(item.discountAmount) || '--'}`}}</div>
        </div>
      </template>
      <template v-else>
        <div class="noDiscount">{{$t('无折扣原因！')}}</div>
      </template>
    </van-collapse-item>
    <!-- <van-collapse-item :name="2" title="金融信息">
      <van-cell title="金融状态" :value="orderDetail.financeStatus"></van-cell>
      <van-cell title="金融方案" :value="''"></van-cell>
      <van-cell title="贷款金额" :value="''"></van-cell>
    </van-collapse-item> -->
    <van-collapse-item v-if="orderDetail.policyConfigList && orderDetail.policyConfigList.length" :name="$t('权益明细')" :title="$t('权益明细')">
      <!-- <div class="common-t">
        <div class="common-t-l">{{$t('权益明细')}}</div>
      </div> -->
      <div style="background-color: #F5F4F5;padding-top: 12px;overflow: hidden;">
        <van-cell-group v-for="(item, index) in orderDetail.policyConfigList" :key="index" style="margin: 0 12px 12px;">
          <van-cell :title="item.policyName || '-'" class="no-value custom-cell"></van-cell>
          <van-cell :title="$t('权益类型')" class="custom-cell">
            <div>{{ item.policyType }}</div>
          </van-cell>
          <van-cell :title="$t('权益价值')" class="custom-cell">
            <div><span content="¥"></span>{{ item.policyValue }}</div>
          </van-cell>
          <van-cell :title="$t('权益数量')" class="custom-cell">
            <div>{{ item.policyCount }}</div>
          </van-cell>
          <van-cell :title="$t('权益Code')" class="custom-cell">
            <div>{{ item.policyCode }}</div>
          </van-cell>
          <van-cell :title="$t('权益说明')" class="custom-cell">
            <div v-html="item.policyRemark"></div>
          </van-cell>
        </van-cell-group>
      </div>
    </van-collapse-item>
    <van-collapse-item v-if="orderDetail.couponItems && orderDetail.couponItems.length" :name="$t('大定应用卡券明细')" :title="$t('大定应用卡券明细')">
      <!-- <div class="common-t">
        <div class="common-t-l">{{$t('大定应用卡券明细')}}</div>
      </div> -->
      <div style="background-color: #F5F4F5;padding-top: 12px;overflow: hidden;">
        <van-cell-group v-for="(item, index) in orderDetail.couponItems" :key="index" style="margin: 0 12px 12px;" inset>
          <van-cell :title="item.couponName || '-'" class="no-value custom-cell"></van-cell>
          <van-cell :title="$t('卡券Code')" class="custom-cell">
            <div>{{ item.couponCode }}</div>
          </van-cell>
          <van-cell :title="$t('折扣金额')" class="custom-cell">
            <div><span content="¥"></span>{{ item.couponDiscount }}</div>
          </van-cell>
          <van-cell :title="$t('一级类目')" class="custom-cell">
            <div>{{ item.firstCategory }}</div>
          </van-cell>
          <van-cell :title="$t('一级类目名称')" class="custom-cell">
            <div>{{ item.firstCategoryName }}</div>
          </van-cell>
          <van-cell :title="$t('二级类目')" class="custom-cell">
            <div>{{ item.secondCategory }}</div>
          </van-cell>
          <van-cell :title="$t('二级类目名称')" class="custom-cell">
            <div>{{ item.secondCategoryName }}</div>
          </van-cell>
        </van-cell-group>
      </div>
    </van-collapse-item>
  </van-collapse>
</div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'orderInfo',
  props: {
    orderDetail: {
      type: Object,
      default:() => ({})
    }
  },
  data() {
    return {
      activeNames: [1, 2, '权益明细', '大定应用卡券明细'],
    }
  },
  computed: {
    carOrderDiscountList() {
      return this.orderDetail.carOrderDiscountList || []
    },
    specialRemarkUrlList() {
      let arr = []
      let list = this.orderDetail.specialRemarkUrl?.split(',') || []
      list.forEach(item => {
        arr.push(
          {
            uuid: uuidv4(),
            cosUrl: item,
            url: item,
            status: 'done'
          }
        )
      })
      return arr
    }
  },
}
</script>
<style lang="less" scoped>
.custom-cell {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.orderInfo{
  .specialRemarkUrl{
    display: flex;
    flex-direction: column;
    p{
      margin-bottom: 10px;
    }
    /deep/ .van-uploader__wrapper--disabled{
      opacity: 1;
    }
    /deep/ .van-uploader__upload{
      display: none;
    }
  }
  /deep/ .van-cell{
    padding: 10px 0;
    &::after{
      width: 100%;
      left: 0;
    }
  }
  /deep/ .van-collapse{
    &::after{
      display: none;
    }
    .van-collapse-item--border::after{
      display: none;
    }
    .van-collapse-item{
      .van-collapse-item__title{
        .van-cell__title{
          padding-left: 8px;
          font-size: 16px;
          position: relative;
          &::before{
            content: ' ';
            left: 0;
            position: absolute;
            top: calc(50% - 6px);
            width: 2px;
            height: 12px;
            background: #0040C5;
            border-radius: 0px 0px 0px 0px;
          }
        }
      }
      .van-collapse-item__content{
        padding: 0;
        .content-t{
          color: #0D171A;
          font-size: 14px;
          height: 40px;
          line-height: 40px;
          
        }
        .content-c{
          padding: 0;
          &.van-cell::after{
            display: none;
          }
          .van-field__body{
            .van-field__control{
              color: rgba(13,23,26,0.45)
            }
            
          }
        }
        .content-p{
          width: 100%;
          min-height: 20px;
          line-height: 20px;
          text-align: right;
          color: rgba(13,23,26,0.45);
          padding-bottom: 16px;
          position: relative;
          &::after{
            width: 100%;
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            right: 16px;
            bottom: 0;
            left: 0px;
            border-bottom: 1px solid #ebedf0;
            -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
          }
        }
        .noDiscount{
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
