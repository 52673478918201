const appName = '库存查询'
const routerPath = 'batchClueTask'

const routes = [
  {
    path: '/batch-clue-task',
    name: 'batchClueTask',
    component: () => import('@/modules/batchClueTask/index.vue'),
    meta: {
      title: '批量线索任务',
      appName,
      isAppIndex: true,
      routerPath
    }
  }
]

export default routes
