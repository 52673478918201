<template>
	<div class="create-wechat-clue-page">
		<!-- <SelectShop></SelectShop> -->
		<van-form
			ref="createForm"
			scroll-to-error
			style="border-radius: 8px;"
			input-align="right"
			error-message-align="right"
			@submit="onSubmit"
		>    
    <van-field
        v-model="formData.activeShopName"
        input-align="right"
        :label="$t('创建的线索所属门店')"
        right-icon="arrow"
        required
        readonly
				:rules="[{ required: true, trigger: 'onBlur', message: '请选择线索所属门店' }]"
        @click="selectField('activeShop', 'activeShopName', false)">
    </van-field>
		<!-- 客户姓名 -->
			<van-field
				v-model.trim="formData.userName"
				required
				:label="$t('微信客户')"
				readonly
			/>
		  <!-- 手机号码 -->
			<van-field
				v-model.trim="formData.userMobile"
				:label="$t('联系电话')"
				type="number"
				:placeholder="!formData.activeShopName ? $t('请选择线索所属门店') : $t('请输入联系电话')"
        :rules="[{ validator: isPhone, message: '请输入正确的11位手机号' }]"
				:formatter="(value)=> value.replace(/\s+/g, '')"
        :readonly="!formData.activeShopName"
			/>
			 <!-- 来源渠道 -->
			 <van-field
				:label="$t('来源渠道')"
				required
				:placeholder="$t('请选择来源渠道')"
				:value="formData.customerSource.displayName"
				right-icon="arrow"
				type="textarea"
				autosize
				rows="1"
				readonly
				:rules="[{ required: true, trigger: 'onBlur' }]"
				@click="onClueSourceSelect"
			/>

			<div class="actions">
				<van-button @click.prevent="$router.back()">
					{{ $t('取消') }}
				</van-button>
				<van-button type="info" native-type="submit" color="#EED484">
					{{ $t('保存') }}
				</van-button>
			</div>
		</van-form>
		<!-- 绑定微信 -->
		<van-dialog 
			v-model="isShowDialog"
			:title="dialog.title" 
			show-cancel-button 
			:cancel-button-text="$t('取消')" 
			:confirm-button-text="dialog.confirmButtonText"
			:confirmButtonColor="dialog.confirmButtonColor"
			:close-on-click-overlay="true"
			:before-close="weChatBindClue">
			<div style="padding: 10px">{{ dialog.message }}</div>
			<van-checkbox v-model="checked" style="padding: 10px" shape="square">勾选则关联已有线索，不勾选不会关联</van-checkbox>
		</van-dialog>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import clueServices from '@/services/clueServices'
import baseDataServices from '@/services/baseDataServices'
import loading from '@/utils/loading.js'
import { validate } from '@/utils/validate'
import selectClueSource from '@/components/select-clue-source'
import SelectShop from '@/components/SelectShop/index.vue'
import commonSelector from '@/components/common-selector'
import dayjs from 'dayjs'

export default {
  name: 'CreateWechatClue',
  components: {
    SelectShop
  },
  data() {
    return {
      validate,
      formData: {
        activeShop: '',
        activeShopName: '',
        userName: '',
        userMobile: '',
        userWechat: '', // 微信信息,
        customerSource: {
          data: [{
            id: '1753208491511615490',
            text: this.$t('自然进店'),
          },{
            id: '1753210199448981506',
            text: this.$t('门店新建')
          }],
          displayName: `${this.$t('自然进店')} ${this.$t('门店新建')}`
        }, // 来源渠道
      },
      judgeExists: false,
      isShowDialog: false,
      checked: true, // 默认关联线索
      existClueInfo: {},
      dialog: {
        type: '',
        title: '',
        message: '',
        confirmButtonText: '',
        confirmButtonColor: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'selectedShopId'
    ]),
    shopList() {
      let { dealers = [] } = this.userInfo
      dealers = dealers.filter(({ roleLogos=[] }) => { return roleLogos.length > 0 })
      return dealers.map(({ name, id, ...querys }) => { return {
        id,
        name,
        dictCode: id,
        dictName: name,
        ...querys
      } })
    }
  },
  watch: {
    'formData.userMobile'(value) {
      if (/^1[3456789]\d{9}$/.test(value)) {
        this.baseClueInfoFilled('tel')
      }
    },
    // 监听到店铺信息发生变化 
    selectedShopId: {
      handler(val) {
        // 重新校验线索是否存在
        this.getClueDraft()
        if (this.formData.userMobile) this.baseClueInfoFilled('tel')
      },
      immediate: true,
      deep: true
    },
    shopList: {
      handler(val) {
        const dealerId = this.$store.getters.selectedShopId || sessionStorage.getItem('selectedShopId')
        // this.formData.activeShop = val[0].id
        // this.formData.activeShopName = val[0].name
        this.formData.activeShop = val.filter(item => item.id == dealerId)[0]?.id
        this.formData.activeShopName = val.filter(item => item.id == dealerId)[0]?.name
      },
      immediate: true,
      deep: true
    },
  },
  mounted() {
    const { customerName, customerWechatId } = this.$route.query
    this.formData.userName = customerName
    this.formData.userWechat = customerWechatId
    if (!customerName) {
      this.getWeChatInfo()
    }
  },
  methods: {
    changeShop (value) {
      if (value) this.$store.commit('app/setSelectedShopId', value)
    },
    selectField(field, dictType, multiple = false) {
      const _obj = {
        field,
        dictType,
        multiple,
        lastSelect: this.formData[field],
        cellOptions: this.shopList
      }
      commonSelector(_obj).then(res => {
        this.formData[field] = res.dictCode
        this.formData[dictType] = res.dictName
      })
    },
    // 获取外部联系人详情
    async getWeChatInfo() {
      const res = await baseDataServices.getExternalDetail({
        externalUserId: this.formData.userWechat,
      })
      this.formData.userName = res.name
    },
    // 查询暂存
    getClueDraft() {
      clueServices.getClueDraft({}).then((res) => {
        const {
          channelOne = '',
          channelOneId = '',
          channelTwo = '',
          channelTwoId = '',
          channelThree = '',
          channelThreeId = '',
          channelFour = '',
          channelFourId = '',
          channelFiveId = '',
          channelFive = '',
          ...query
        } = res
        const customerSource = []
        addItem(customerSource, channelOneId, channelOne)
        addItem(customerSource, channelTwoId, channelTwo)
        addItem(customerSource, channelThreeId, channelThree)
        addItem(customerSource, channelFourId, channelFour)
        addItem(customerSource, channelFiveId, channelFive)
        const displayName = customerSource
          .map(({ text }) => text)
          .filter((val) => val)
          .join(' ')
        Object.assign(
          this.formData,
          query,
          displayName
            ? { customerSource: { data: customerSource, displayName } }
            : {}, // 来源
        )
      })
      // 拼接来源
      function addItem(arr, id = '', text = '') {
        id && arr.push({ id, text })
      }
    },
    // 通过电话号码判重
    async baseClueInfoFilled(type) {
      if (this.judgeExists) return false
      this.judgeExists = true
      try {
        loading.showLoading(this.$t('线索验证中'))
        const {
          userWechat,
          userMobile,
        } = this.formData
        const record = await clueServices.clueExist({
          userWechat,
          userMobile,
          dealerId: this.selectedShopId || this.userInfo.dealers[0]?.id,
        })
        this.judgeExists = false
        loading.hideLoading()
        this.handleLink(record, type)
      } catch (error) {
        loading.hideLoading()
        this.judgeExists = false
        this.$toast.fail(error.message)
      }
    },
    // 校验结果处理
    handleLink(data, type) {
      const {
        followUserId,
        followUserName,
        id,
        isRecycle,
        followUserRoleCode,
        state
      } = data
      this.existClueInfo = data
      if (!id) return false
      if (isRecycle == 0) { // 未被回收的逻辑
        // 无跟进人 或者战败 或者无效
        const messageObj = {
          '2000020': this.$t('该留资客户已存在本店并无效，请直接认领跟进'),
          '2000045': this.$t('该留资客户已在本店战败，请直接认领跟进')
        }
        if (!followUserId || (['2000020', '2000045'].includes(state) && !followUserId)) {
          this.isShowDialog = true
          this.dialog = {
            type: 'receiveClue',
            title: this.$t('提示'),
            message: ['2000020', '2000045'].includes(state) ? messageObj[state] : this.$t('该线索还未分配跟进人，是否领取 ？'),
            confirmButtonColor: '#B9921A',
            confirmButtonText: ['2000020', '2000045'].includes(state) ? this.$t('立即认领') : this.$t('领取'),
          }
        }
        // 跟进人是自己
        else if (followUserId === this.userInfo.id) {
          this.isShowDialog = true
          this.dialog = {
            type: 'follow',
            title: this.$t('提示'),
            message: this.$t('您正在跟进该客户，无需重复创建，是否去填写跟进记录？'),
            confirmButtonColor: '#B9921A',
            confirmButtonText: this.$t('去填写'),
          }
        }
        // 跟进人是他人
        else if (followUserId && followUserId !== this.userInfo.id) {
          this.$dialog
            .confirm({
              title: this.$t('提示'),
              message: `【${followUserName}${this.$t(
                '】正在跟进该客户，不允许重复创建，您是否去填写跟进记录？'
              )}`,
              confirmButtonColor: '#B9921A',
              confirmButtonText: this.$t('去填写'),
            })
            .then(() => {
              // this.goFollow(data.id)
              this.$router.push({
                path: '/clue-remark',
                query: {
                  id,
                },
              })
            })
            .finally(() => {
              type === 'tel'
                ? (this.formData.userMobile = '')
                : (this.formData.wechatInfo = {})
            })
        }
      } else if (isRecycle == 1) {
        // 回收的逻辑
        if (followUserId === this.userInfo.id) {
          this.$dialog
            .confirm({
              title: this.$t('提示'),
              message: this.$t('由于您未及时跟进，该线索已被回收至门店公共池，不允许重复创建'),
              showCancelButton: false,
              confirmButtonColor: '#B9921A',
              confirmButtonText: this.$t('确认'),
            })
            .then(() => {})
            .finally(() => {
              type === 'tel'
                ? (this.formData.userMobile = '')
                : (this.formData.wechatInfo = {})
            })
        }
        if (followUserId && followUserId !== this.userInfo.id) {
          const roleLogos = this.userInfo.roleLogos || []
          if (roleLogos.includes(followUserRoleCode)) {
            this.isShowDialog = true
            this.dialog = {
              type: 'receiveRecycle',
              title: this.$t('提示'),
              message: this.$t('店内公共池已存在同号码线索，不允许重复创建，是否直接领取？'),
              confirmButtonColor: '#B9921A',
              confirmButtonText: this.$t('领取'),
            }
          } else {
            this.$dialog
              .confirm({
                title: this.$t('提示'),
                message: `${this.$t(
                  '店内公共池已存在同号码线索，不允许重复创建；由于您无原跟进同角色权限（'
                )}${this.getDictNameByCode(1014, followUserRoleCode)}${this.$t(
                  '），无法直接领取'
                )}`,
                showCancelButton: false,
                confirmButtonColor: '#B9921A',
                confirmButtonText: this.$t(
                  '确认'
                ),
              })
              .then(() => {})
              .finally(() => {
                type === 'tel'
                  ? (this.formData.userMobile = '')
                  : (this.formData.wechatInfo = {})
              })
          }
        }
      }
    },
    // 选择来源渠道
    onClueSourceSelect() {
      const dealerVisible = this.$store.getters.userInfo.type === 1 ? 1 : null
      selectClueSource({ noStrictly: false, dealerVisible })
        .then((data) => {
          const res = data.value
          this.formData.customerSource.data = res
          this.$set(
            this.formData.customerSource,
            'displayName',
            res
              .map(({ text }) => text)
              .filter((val) => val)
              .join(' ')
          )
        }).catch((err) => console.log(err))
    },
    // 新建线索
    async onSubmit() {
      let validationRes = true
      validationRes = await this.$refs.createForm
        .validate()
        .then((res) => true)
        .catch((err) => {
          this.$toast(this.$t('请完成必填字段的填写后再提交'))
        })
      if (!validationRes) return
      loading.showLoading()
      const {
        userMobile,
        userName,
        customerSource: {
          // 来源渠道
          data: permissionList,
        },
      } = this.formData
      let permission = {}
      permissionList &&
        permissionList.forEach((item, index) => {
          switch (index) {
            case 0:
              permission['channelOneId'] = item.id
              permission['channelOne'] = item.text
              break
            case 1:
              permission['channelTwoId'] = item.id
              permission['channelTwo'] = item.text
              break
            case 2:
              permission['channelThreeId'] = item.id
              permission['channelThree'] = item.text
              break
            case 3:
              permission['channelFourId'] = item.id
              permission['channelFour'] = item.text
              break
            case 4:
              permission['channelFiveId'] = item.id
              permission['channelFive'] = item.text
              break
          }
        })
      const params = {
        userMobile,
        userName,
        userWeChat: this.$route.query.customerWechatId,
        userWeChatName: this.formData.userName,
        ...permission, // 渠道来源
        planTime: dayjs().add(1, 'days').format('YYYY-MM-DD 23:59:59'), // 下次跟进时间 默认次日
      }
      console.log('this.formData', params)
      await clueServices.creatClue(params) .then((res) => {
        this.$toast.success(this.$t('创建成功'))
        setTimeout(() => {
          loading.hideLoading()
          this.$router.push({
            path: '/clue-details',
            query: {
              id: res.id,
            },
          })				
        }, 1500)
      })
    },
    // 微信绑定线索
    weChatBindClue(action, done){
      if (action === 'confirm') {
        if (this.checked) {
          if (this.dialog.type !== 'receiveClue') {    
            baseDataServices.setWechat({
              id: this.existClueInfo.id,
              userWeChat: this.formData.userWechat,
              userWeChatName: this.formData.userName,
            }).then(() => {
              this.save()
            }).finally(() => {
              this.formData.userMobile = ''
            })
          } else {
            this.receiveClue()
          }
        } else {
          this.save()
        }
        done()
      } else {
        this.formData.userMobile = ''
        done()
      }
    },
    save() {
      if (this.dialog.type === 'receiveClue') {
        this.receiveClue()
      } else if (this.dialog.type === 'follow') {
        this.goFollow()
      } else if (this.dialog.type === 'receiveRecycle') {
        this.receiveRecycle()
      }
    },
    // 认领线索--先认领后绑定微信
    async receiveClue() {
      loading.showLoading()
      const result = await clueServices.receiveClue({ id: this.existClueInfo.id })
      loading.hideLoading()
      if (result) {
        this.$toast(this.$t('领取成功'))
        if (this.checked) {
          baseDataServices.setWechat({
            id: this.existClueInfo.id,
            userWeChat: this.formData.userWechat,
            userWeChatName: this.formData.userName,
          }).then(() => {
            setTimeout(() => {
              ['2000020', '2000045'].includes(this.existClueInfo.state) ? this.goDetail() : this.goFollow()
            }, 1000)
          }).finally(() => {
            this.formData.userMobile = ''
          })
        }
      }
    },
    async receiveRecycle() {
      loading.showLoading()
      const result = await clueServices.receiveRecycle({ id: this.existClueInfo.id })
      loading.hideLoading()
      if (result) {
        this.$toast(this.$t('领取成功'))
        setTimeout(() => {
          this.goFollow()
        }, 1000)
      }
    },
    // 去跟进
    goFollow() {
      this.$router.replace({
        path: '/clue-follow',
        query: {
          id: this.existClueInfo.id,
          replace: true
        },
      })
    },
    // 去详情
    goDetail() {
      this.$router.replace({
        path: '/clue-details',
        query: {
          id: this.existClueInfo.id,
          replace: true
        },
      })
    },
    isPhone(val) {
      if (!val) return ''
      // 正则表达式验证是否为正整数
      return /^1[3456789]\d{9}$/.test(val)
    }
  },
}
</script>
<style lang="less" scoped>
.create-wechat-clue-page {
	padding: 10px 16px;
	.actions {
		background: white;
		position: sticky;
		bottom: 0;
		padding: 16px 8px;
		display: flex;
		justify-content: space-around;
		button {
			color: #0d171a !important;
			border-radius: 4px;
			flex: 1;
			margin: 0 4px;
		}
	}
}
.selectShopBox{
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
  /deep/ .van-dropdown-menu {
    height: 48px;
    .van-dropdown-menu__bar{
      .van-dropdown-menu__item{
        justify-content: center;
      }
      .van-dropdown-menu__title{
        padding-left: 16px;
      }
      .van-dropdown-menu__title::after{
        border-color: transparent transparent @black @black;
      } 
    }
  }
}
</style>
