<template>
  <div class="specialCarOrderConfig"> 
    <div class="special-order-top">
      <search-bar-new
        placeholder-text="请输入销售顾问/ 配置单号"
        :showScreen="true"
        :active="Object.values(filterParams).length > 0"
        :maxlength="17"
        @input="(val)=>{searchStr = val}"
        @search="onRefresh"
        @screening="onScreening"
      >
      </search-bar-new>
    </div>
    <FilterPanel
      ref="filterPanel"
      v-model="filterActive"
      :options="filterOptions"
      @submit="onFilter"
      @reset="controlModelSales"
      @beforeReset="beforeReset"
      @changeOptions="changeSelectOptions"
    />
    
    <!-- List -->
    <div class="totality">
      <div>{{$t('共')}} {{ countNum }} {{$t('条数据')}}</div>
    </div>
    <List v-if="userInfo.id" ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <Item :data="item" @go="(type)=>handleDetail(item)"></Item>
      </template>
    </List>

  </div>
</template>

<script>
// Tools
import { mapGetters } from 'vuex'
import formatParams from '@/utils/formatParams'
import { arrayFormat, debounce, isMobilePhone } from '@/utils/index.js'
import { v4 as uuidv4 } from 'uuid'

// Components
import List from '@/components/baseList/list.vue'
import Item from './components/Item.vue'
import FilterPanel from '@/components/filter-panel'
// APIs
import { getOrderList } from '@/services/specialCar.js'
import SearchBarNew from '@/components/search-bar-new'
import mixin from '../specialCarStock/mixin'
import salesAssistantService from '@/services/salesAssistant'

export default {
  name: 'specialCarOrderConfig',
  components: { List, Item, FilterPanel, SearchBarNew },
  mixins: [mixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'specialCarOrderConfig')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/salesAssistant', '/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.filterParams = {}
      this.$refs.filterPanel.reset()
      this.$store.commit('app/deleteKeepAlive', 'specialCarOrderConfig')
    }
    next()
  },
  data() {
    return {
      filterActive: false,
      list: [],
      filterParams: {
      },
      searchStr: '',
      dealerList: [],
      seriesList:[],
      modelList:[],
      outsideColours:[],
      innerColours:[],
      saleOptions:[],
      countNum:0,
      parentList: [],
      provinceList: [],
      dealerResult: {},
      defaultValue: {
        parentCode: '',
        childCode: [],
        dealerProvinceCode: '',
        dealerCityCode: '',
        dealerIds: []
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    // 交付门店
    deliveryStoreList() {
      let arr = []
      try {
        arr = arrayFormat(this.userInfo.dealers[0].relations,{
          value: 'dealerCode',
          name:'dealerName',
          cb:(item)=>({ id:item.dealerCode })
        })
      } catch (error) {
            
      }
      return arr
    },
    filterOptions() {
      let arr = [{
        id: uuidv4(),
        type: 'dataRange',
        dateType: '',
        isCache: true,
        label: this.$t('创建时间'),
        start: {
          field: 'start',
          value: this.filterParams.start,
        },
        end: {
          field: 'end',
          value: this.filterParams.end,
        },
      },{
        id: uuidv4(),
        type: 'options',
        label: '配置单类型',
        multiple: false,
        isCache: true,
        field: 'configType',
        options: [
          { id: 1, label: '按配置下单', value: 1 },
          { id: 2, label: '按VIN下单', value: 2 },
        ],
      },{
        id: uuidv4(),
        type: 'options',
        label: '配置单状态',
        multiple: false,
        isCache: true,
        field: 'status',
        options: this.statusList,
      },{
        id: uuidv4(),
        type: 'options',
        label: '车辆类型',
        field: 'specialVehicleType',
        multiple: true,
        isCache: true,
        options: this.specialVehicles
      },{
        id: uuidv4(),
        type: 'options',
        label: '车系',
        multiple: true,
        field: 'seriesCodes',
        options: this.seriesList,
        isCache: true,        
        onChange:(arr, params={})=>{
          Object.assign(params,{
            modelCodes:[],
            colourCodes:[],
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(2,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: '车型',
        multiple: true,
        field: 'modelCodes',
        options: this.modelList,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            colourCodes:[],
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(3,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: '外饰',
        multiple: true,
        field: 'colourCodes',
        options:this.outsideColours,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(4,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: '内饰',
        multiple: true,
        field: 'interiorCodes',
        options:this.innerColours,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            optionsCodes:[]
          })
          this.controlModelSales(5,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('销售顾问'),
        field: 'salesIds', 
        multiple: true,
        isCache: true,
        options: this.dccStaffList,
      }]
      if (this.userInfo.type === 0) {
        const { parentCode= '', childCode=[], dealerProvinceCode= '', dealerCityCode='', dealerIds=[] } = this.defaultValue
        arr.splice(arr.findIndex(({ field }) => { return field === 'salesIds' }, 0))
        arr.unshift(
          // 大区
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('大区'),
            field: 'parentCode',
            defaultValue: parentCode,
            options: this.parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
          },
          // 城市区域
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('城市区域'),
            multiple: true,
            field: 'childCode',
            defaultValue: childCode.join(','),
            options: [],
          },
          // 省份
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('省份'),
            field: 'dealerProvinceCode',
            defaultValue: dealerProvinceCode,
            options: this.provinceList.map(({ code, name }) => ({ id: code, code, name })),
          },
          // 城市
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('城市'),
            field: 'dealerCityCode',
            defaultValue: dealerCityCode,
            options: [],
          },
          // 门店
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('销售门店'),
            multiple: true,
            field: 'dealerIds',
            defaultValue: dealerIds.join(','),
            dealerType: 1,
            options: this.dealerResult.dataList?.map((item) => { return { code: item.id, name: item.name } })
          }
        )
      }
      return arr 
    }
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val.type === 0) {
          // 总部获取
          this.getRegionAndAddress()
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.controlModelSales()
  },
  activated() {
    this.onRefresh()
  },
  methods: {
    beforeReset(options) {
      this.filterParams.start = ''
      this.filterParams.end = ''
      for (let i in options) {
        const item = options[i]
      } 
    },
    changeSelectOptions(field, value) {
      this.filterParams.start = field === 'start' ? value : this.filterParams.start
      this.filterParams.end = field === 'end' ? value : this.filterParams.end
    },
    onScreening() {  
      this.getStaffList()   
      console.log(this.filterParams.start, this.filterParams.end) 
      this.filterActive = true
    },
    onRefresh:debounce(function() {            
      this.$refs.list.onRefresh()
    },500),
    fetchApi(obj = {}) {
      const page = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
      }
      const { ...query } = this.filterParams
      return getOrderList({
        ...page,
        ...query,
        searchStr: this.searchStr,
        createTime: {
          start: this.filterParams.start,
          end: this.filterParams.end
        }
      }).then(res=>{
        console.log('res-----------', res)
        this.countNum = res?.page?.totalCount || 0
        return res
      })
    },
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      const { parentCode='', childCode=[], dealerProvinceCode='', dealerCityCode='', dealerIds=[] } = this.filterParams
      this.defaultValue = { parentCode,childCode,dealerProvinceCode,dealerCityCode,dealerIds }
      this.onRefresh()
      this.filterActive= false
    },
    handleConfigList(){
      this.$router.push({
        path: '/special-car-order-config',
        query:{ }
      })
    },
    handleDetail(item){
      const { id }=item
      this.$router.push({
        path: '/special-car-stock/detail',
        query:{
          id
        }
      })
    },
    // 获取大区 身份城市 交付门店
    async getRegionAndAddress() {
      this.parentList = await salesAssistantService.getRegionTreeApi()
      this.provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
      this.dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '', dealerType: 1 })
    },
  }
}
</script>

<style lang="less" scoped>
.specialCarOrderConfig {
  .special-order-top{
    position: sticky;
    top: 0;
    z-index: 20;
    display: flex;
    .search-l{
      font-size: 14px;
    }
  }
  .filter-icon {
    width: 20px;
    height: 20px;
    // position: fixed;
    // right: 12px;
    // top: 12px;
  }
  .totality{
    padding: 10px 16px 0;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
