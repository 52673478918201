<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import CardOptions from '@/components/card-view/cardOptions.vue'
import { dateFormat } from '@/utils'
import { i18n } from '@/main'
let vm
export default {
  components:{ shortConsumerInfo, CardOptions },
  filters: {
    // sourceText(data) {
    //   let str = ''
    //   const { channelOne, channelTwo } = data
    //   const splitStr = channelOne&&channelTwo?'/':''
    //   str+= (channelOne || '')
    //   str+= `${splitStr}${channelTwo || ''}`
    //   return str
    // },
    sourceText(data) {
      let arr = []
      const { channelOne, channelTwo, channelThree } = data
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      return arr.length > 0 ? arr.join('/') : vm.$t('暂无')
    },
  },
  props:{
    clue:{
      type:Object,
      default:()=>({})
    },
    activeTab:{
      type: [Number,String],
      default:''
    },
    userInfo:{
      type: Object,
      default:()=>({})
    }
  },
  data() {
    return {
      i18n
    }
  },
  computed:{
    getRoleReceive() {
      let index = -1
      try {
        const roleLogos = JSON.stringify(this.userInfo.roleLogos)
        // dcc || 销售（含店长）有领取按钮
        index = ['1014001','1014002'].findIndex(item=>roleLogos.indexOf(item)!== -1)
      } catch (error) {
      }
      return index !== -1
    }
  },
  created() {
    vm = this
  },
  methods:{
    dateFormat
  }
}
</script>
<template>
  <div class="clue-item">
    <div class="info" @click.stop="$emit('goDetail',clue)">
      <div class="row">
        <shortConsumerInfo :data="clue || {}" />
      </div>
      <CardOptions :data="clue"/>
      <div class="van-ellipsis">
        <span class="label">{{ $t('用户来源') }}：</span>
        <span  v-if="clue.channelOne || clue.channelTwo" class="channel-name">{{ clue | sourceText }}</span>
      </div>
      <div v-if="(activeTab === 3 && clue.followUserRoleCode && userInfo.isManager) || userInfo.type === 0" class="van-ellipsis">
        <span class="label">{{ $t('原跟进') }}{{ clue.followUserRoleCode === '1014001'? $t('DCC专员'): $t('销售顾问') }}：</span>
        <span>{{clue.followUserName}}</span>
      </div>
      <div class="van-ellipsis">
        <!-- <span class="label">{{ $t('回收时间') }}：</span> -->
        <span v-if="i18n.locale.includes('zh')">于{{ clue.recycleTime | datetimeText }}超期回收</span>
        <span v-else>Overdue at {{ clue.recycleTime | datetimeText }}</span>
      </div>
    </div>
    <div v-if="getRoleReceive && activeTab != 4" class="bottom">
      <div class="btn-yellow" @click.stop="$emit('receive',clue)">{{ $t('领取') }}</div>
    </div>
    <slot name="check"></slot>
  </div>
</template>
<style lang="less" scoped>
@lineColor: #F3F3F4;
.label{
  color: #7D7D7D;
}
.clue-item{
  background-color: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px ;
  padding: 16px 12px 12px;
  font-size: 13px;
  position: relative;
  .van-ellipsis {
    max-width: calc(100% - 60px);
  }
  .info{
    position: relative;
    .row {
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(13,23,26,0.05);
    }
    >div:not(.row){
      margin-bottom: 8px;
    }
    .value{
      display: inline-block;
      width: 50%;
      vertical-align: middle;
    }
  }
  // .channel-name{
  //     position: relative;
  //     padding-left: 8px;
  //   }
  //   .channel-name::before{
  //       position: absolute;
  //       top:50%;
  //       transform: translateY(-50%);
  //       left: 0;
  //       width: 1px;
  //       height: 14px;
  //       content: '';
  //       background: #D9D9D9;
  //   }
  .btn-yellow{
    color: #B9921A;
  }
  .bottom{
    border-top: 1px solid @lineColor; 
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    >div{
      padding: 8px 8px 0;
      cursor: pointer;
    }
  }
}

</style>
