<template>
	<div :ref="`deliverItem${dataSource.id}`" class="deliverItem" @click="$emit('goDetail')">
		<div class="info">
			<div class="orderTitle-id mb-8">
				<span class="blod">
					<span>{{dataSource.orderType === 3? dataSource.appOrderId: dataSource.dcosOrderId}}</span>
					<Labels v-if="dataSource.orderLabel && dataSource.orderLabel.length > 0" :special="true" :specialData="orderLabels" class="special-label"></Labels>
					<!-- lockStatus 锁定状态 0未定 2质损 4 冻结 6都  -->
					<!-- portalLockStatus 总部锁定 0未锁定 1锁定 2解锁 -->
					<span v-if="dataSource.portalLockStatus === 1" class="risk">{{ $t('锁单') }}</span>
					<span v-if="[2, 6].includes(dataSource.lockStatus)" class="risk">{{ $t('质损') }}</span>
					<span v-if="[4, 6].includes(dataSource.lockStatus)" class="risk">{{ $t('车辆冻结') }}</span>
					<span v-if="[1,2,5].includes(dataSource.orderType)" class="bigTye">
						{{ dataSource.orderType===1 ? $t('大客户') : dataSource.orderType===2 ? $t('试驾车') : $t('大客户试驾车') }}
					</span>
				</span>
				<span class="status">
					{{ dataSource.deliveryStatus | codeFormat }}
				</span>
			</div>
			<div v-if="dataSource.customerName" class="name mb-8">
				{{ dataSource.customerName }}
			</div>
			<div class="tag mb-8">
				<div>
					{{ dataSource.customerType == 1 ? $t('个人') : $t('企业') }}
				</div>
				<template v-if="[1, 0].includes(dataSource.installmentsFlag)">
					<div class="gap"></div>
					<div>
						{{ dataSource.installmentsFlag == 1 ? $t('分期') : $t('全款') }}
					</div>
				</template>
				<template v-if="dataSource.lossStatus === 1">
					<div class="gap"></div>
					<div>
						{{ $t('质损车') }}
					</div>
				</template>
				<template v-if="dataSource?.isExhibition === 1">
						<div class="gap"></div>
						<div>
								{{ $t('展车') }}
						</div>
				</template>
				<!--  v-if="dataSource.pdiStatus === '4004001'" -->
				<template v-if="dataSource.pdiStatus === '4004001'">
					<div class="gap"></div>
					<div>
						PDI{{ dataSource.pdiStatus | codeFormat }}
					</div>
				</template>
			</div>
			<div v-if="carInfo" class="car-info mb-8" v-html="carInfo"></div>
			<div class="mb-8" style="color: rgba(13,23,26,0.45)">VIN： {{ dataSource.vin || '--' }}</div>
			<!-- 是否授权 -->
			<div v-if="isShowAuthDate" class="mb-8" style="color: rgba(13,23,26,0.45)">{{ $t('授权交付') }}： {{ dataSource.authDate }}</div>
			<div class="follow-user">
				<span>{{ $t('交付专员') }}：</span>
				<span>
					{{
						dataSource.deliveryUserName || dataSource.deliveryPreUserName || '--'
					}}
				</span>
				<span>
					{{
						!dataSource.deliveryUserName && dataSource.deliveryPreUserName
							? `(${$t('预分配')})`
							: ''
					}}
				</span>
			</div>
			<ul class="options">
				<li
					v-if="dataSource.balancePayStatus && calculateCode(dataSource.balancePayStatus)"
					class="li"
					:class="{ 'li-4011003': dataSource.balancePayStatus == 4011003 }"
				>
					{{ dataSource.balancePayStatus | codeFormat }}
				</li>
				<li
					v-if="dataSource.invoiceStatus && calculateCode(dataSource.invoiceStatus)"
					class="li"
					:class="{ 'li-202402': dataSource.invoiceStatus == 202402 }"
				>
					{{ dataSource.invoiceStatus | codeFormat }}
				</li>
				<li
					v-if="dataSource.financeStatus && calculateCode(dataSource.financeStatus)"
					class="li "
					:class="{ 'li-40050012': dataSource.financeStatus == 40050012 }"
				>
					{{ dataSource.financeStatus | codeFormat }}
				</li>
				<li v-if="dataSource?.insureStatus" class="li 4002 insureStatus">{{ dataSource.insureStatus | insuranceCodeFormat }}</li>
				<li v-if="dataSource?.coVehicleSource === 2" class="li sepcial-car">{{ $t('特殊车（非一车一价）') }}</li>
			</ul>
			<div v-if="showOrderUpdateInfo" style="color: #E4002C; margin-top: 8px;">{{$t('退单申请：')}}{{ dataSource.orderUpdateStatus | codeFormat }}</div>
			<van-checkbox
				v-if="isDistributionState"
				:value="dataSource.checked"
				shape="square"
				class="check-item"
				@click.native.stop="onClick">
			</van-checkbox>
		</div>
		<div v-if="isDeliverSalesOwner" class="bottom">
			<div class="follow-user">
				<!-- 顾问自己的数据 展示跟进 -->
				<template v-if="isDeliverSalesOwner">
					<div class="followUp" @click.stop="goFollow">{{$t('跟进填写')}}</div>
				</template>
				<!-- <template v-else>
					<div v-if="isDeliverManage">
						{{$t('交付专员：')}}{{ dataSource.deliveryUserName || dataSource.deliveryPreUserName || '--' }}
						{{!dataSource.deliveryUserName && dataSource.deliveryPreUserName ? `(${$t('预分配')})`: ''}}
					</div>
				</template> -->
			</div>
			<div v-if="isDeliverSalesOwner" class="icons">
				<!-- 未绑定微信时，展示【加微、绑定微信客户、拨号icon】 -->
				<!-- 绑定时，展示【企微会话、拨号icon】 -->
				<externalUserIcon v-if="!dataSource.customerWeChat" :id="dataSource.leadId" class="icon" @selectUser="selectUser"/>
				<qwIcon v-if="dataSource.customerWeChat" :leadId="dataSource.leadId" :externalUserIds="dataSource.customerWeChat" class="icon"/>
				<wxIcon v-if="!dataSource.customerWeChat" class="icon"/>
				<phoneIcon :phone="dataSource.customerMobile" class="icon"/>
			</div>
		</div>
	</div>
	</template>
<script>
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import Labels from '@/components/card-view/label.vue'
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
import store from '@/store'
import { calculateCode } from '@/utils/index.js'
export default {
  components: {
    externalUserIcon,
    phoneIcon,
    qwIcon,
    wxIcon,
    Labels
  },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    isDistributionState:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      calculateCode,
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    isShowAuthDate(){
      const { id } = this.userInfo
      const { authDate, authedDeliveryUserId, deliveryUserId } = this.dataSource || {}
      return authDate && authedDeliveryUserId !== '0' && [deliveryUserId,authedDeliveryUserId].includes(id)
    },
    orderLabels() {
      return this.dictHash[4009].filter(({ code }) => { return this.dataSource.orderLabel.includes(code) })
    },
    carInfo() {
      const {
        seriesName,
        modelName,
        colourName,
        interiorName,
      } = this.dataSource
      const arr = []
      if (seriesName) {
        arr.push(`<span>${seriesName}</span>`)
      }
      if (modelName) {
        arr.push(`<span>${modelName}</span>`)
      }
      if (colourName) {
        arr.push(`<span>${colourName}</span>`)
      }
      if (interiorName) {
        arr.push(`<span>${interiorName}</span>`)
      }
      return arr.join(
        '<span class="gap" style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"></span>'
      )
    },
    // 是否交付店长
    isDeliverManage() {
      const { roleLogos=[] } = this.userInfo
      return roleLogos.includes(ROLES.DELIVERMANAGE)
    },
    // 是否是自己的数据
    isDeliverSalesOwner() {
      const { roleLogos=[], id } = this.userInfo
      return roleLogos.includes('1014006') && (this.dataSource.deliveryUserId == id || this.dataSource.authedDeliveryUserId == id)
    },
    // 展示退单信息
    showOrderUpdateInfo() {
      const { orderUpdateStatus, abnormalType } = this.dataSource
      return (
        orderUpdateStatus && !['3001000', '3001035'].includes(orderUpdateStatus) && abnormalType === 3
      )
    },
  },
  methods: {
    selectUser(params={}){
      const { externalUserid,name } = params
      this.$set(this.dataSource, 'customerName', name )
      this.$set(this.dataSource, 'customerWeChat', externalUserid)
    },
    onClick(){
      this.$emit('change', !this.dataSource.checked, this.dataSource)
    },
    // 跟进记录填写
    goFollow() {
      this.$router.push({
        path: '/deliver-follow',
        query: {
          id: this.dataSource.id
        }
      })
    }
  }
}
</script>
	<style lang="less" scoped>
	.deliverItem{
		background: #fff;
		box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
		border-radius: 8px;
		padding: 16px 12px 0px 12px;
		font-size: 13px;
		.van-ellipsis{
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.info{
			position: relative;
			padding-bottom: 12px;
			/deep/ .label-component{
				margin: 0 0 8px 0;
			}
			.mb-8 {
				margin-bottom: 8px;
			}
			.car-info {
				display: flex;
				align-items: center;
				color: rgba(13, 23, 26, 0.45);
			}
			.orderTitle-id {
				display: flex;
				align-items: center;
				padding-bottom: 12px;
				border-bottom: 1px solid rgba(13, 23, 26, 0.05);
				line-height: 16px;
				.blod {
					flex: 1;
					.special-label{
						display: inline-flex;
						margin-left: 8px;
					}
					.bigTye{
						display: inline-block;
						height: 20px;
						line-height: 20px;
						padding: 0 8px;
						color: #fff;
						margin-left: 8px;
						background: #029640;
						font-size: 12px;
					}
					.risk{
						display: inline-block;
						height: 22px;
						line-height: 22px;
						padding: 0 8px;
						color: #fff;
						margin-left: 8px;
						background: #E4002C;
					}
				}
				.status {
					font-size: 14px;
					color: #029640;
				}
			}
			.tag {
				display: flex;
				align-items: center;
				color: #b9921a;
			}

			.gap {
				width: 1px;
				margin: 0 8px;
				height: 12px;
				background-color: #d9d9d9;
			}
			.options {
				display: flex;
				flex-wrap: wrap;
				margin-left: -4px;
				margin-right: -4px;
				.li {
					padding: 2px 8px;
					border-radius: 4px;
					border-width: 1px;
					border-style: solid;
					margin-top: 8px;
					margin-left: 4px;
					line-height: 16px;
					font-size: 12px;
					background: rgba(102,102,102,0.1);
					border: 1px solid rgba(102,102,102,0.1);
					&.sepcial-car{
						padding: 2px 8px;
						border-radius: 4px;
						border-width: 1px;
						border-style: solid;
						line-height: 16px;
						font-size: 12px;
						border-color: rgba(0, 64, 197, 0.1);
						color: #ee0a24;
          	background: #ee0a2421;
					}
					&-4011003 {
						border-color: rgba(255, 125, 0, 0.1);
						color: #ff7d00;
						background: rgba(255, 125, 0, 0.1);
					}

					&-202402 {
						color: #029640;
						background: rgba(2, 150, 64, 0.1);
						border-color: rgba(2, 150, 64, 0.1);
					}
					&-40050012 {
						color: #b9921a;
						background: rgba(185, 146, 26, 0.1);
						border-color: rgba(185, 146, 26, 0.1);
					}
					&:last-child {
						margin-right: 4px;
					}
				}
			}
		}
		.bottom{
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid rgba(13,23,26,0.05);
			height: 53px;
			.follow-user{
				font-size: 13px;
				color: #0D171A;
				line-height: 20px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				.followUp{
					font-size: 14px;
					color: @yellow-text;
					background: @black;
					width: fit-content;
					padding: 2px 6px;
					border-radius: 4px
					// padding-left: 16px;
				}
			}
			.icons{
				display:flex;
				padding: 8px 0 12px 0;
				justify-content: space-between;
				.icon{
					margin-left: 16px;
					&:first-of-type {
						margin-left: 0;
					}
				}
			}
		}
		.check-item{
			position: absolute;
			right: 0px;
			top: calc(50% - 8px);
			z-index: 1;
			/deep/.van-checkbox__icon{
				font-size: 16px;
				.van-icon{
						border: 2px solid rgba(13,23,26,0.45);
				}
			}
			/deep/.van-checkbox__icon--checked .van-icon{
				background-color: #0D171A;
				border-color: #0D171A;
				line-height: 1;
			}
		}
	}
	</style>
	