<template>
  <div class="pageBox">
    <div :class="['clue-detail', isEn ? 'clue-detail-en' : '', $store.getters.userInfo.type == 1 ? 'clue-detail-pab' : '']">
    <Detail v-if="id" :id="id" ref="clueDetail">
      <template v-slot:top="{ data }">
        <div :class="['sticky']">
          <van-steps :active="getStepIndex(data)">
            <van-step v-for="(item, index) in stepList" :key="index.code">{{ item.text}}</van-step>
          </van-steps>
        </div>
      </template>
      <template v-if="$store.getters.userInfo.type == 1" v-slot:btns="{ data }">
        <div class="btns-box">
          <div class="btns">
            <div v-if="noloseAndselflose(data)" class="btns-l">
              <van-popover v-if="noloseAndselflose(data)" v-model="showPopover" theme="dark" trigger="click"
                placement="top-start">
                <div class="clue-detail-actions">
                  <div v-btn-premission="['BTN-CLUE-DISTRIBUTE']" class="btn_item mg-b-10" @click="onDistribution(data)">
                    {{ $t('重新分配') }}
                  </div>
                  <externalUserIcon v-if="data.userWeChat" :id="data.id" class="btn_item mg-b-10"
                    :isShowImg="false" :name="$t('重新绑定客户微信')" @selectUser="onRefresh" />
                  <div
                    v-if="getUserInfo.id && data.followUserId === getUserInfo.id && (getUserInfo.roleLogos.includes('1014004') || getUserInfo.roleLogos.includes('1014005'))"
                    class="btn_item mg-b-10"  @click.stop="onSelect({ value: 1 }, data)">{{ $t('授权他人接待') }}
                  </div>
                  <div v-if="showIcon(data) && !data.userWeChat" class="addWx">
                    <externalUserIcon :id="data.id" class="btn_item mg-b-10" :isShowImg="false" :name="$t('绑定客户微信')"
                      @selectUser="onRefresh" />
                    <wxIcon class="btn_item mg-b-10" :name="$t('添加微信')" :isShowImg="false" :phone="data.userMobile" />
                  </div>
                  <div class="btn_item mg-b-10" @click="handleAddMessage">{{ $t('快速填写跟进') }}</div>
                  <div v-if="!data.userWeChat" class="btn_item mg-b-10" @click="SMSAddwechat">{{ $t('短信邀请加微') }}</div>
                  <div v-if="showMergeClue(data)" class="btn_item mg-b-10" @click="mergeClue(data)">{{ $t('合并线索') }}</div>
                </div>
                <template #reference>
                  <span class="more">{{ $t('更多') }}</span>
                </template>
              </van-popover>
              <div v-if="noloseAndselflose(data)" class="btns-l-icon">
                <qwIcon v-if="data.userWeChat" :leadId="data.id" :externalUserIds="data.userWeChat" class="icon" />
                <phoneIcon class="icon" :phone="data.userMobile" :clueId="data.id" :oneself="data.followUserId === userInfo.id" />
              </div>
            </div>
            <template v-if="data.id">
              <!-- 被回收则不展示跟进按钮 -->
              <van-button v-if="data.isRecycle !== '1' && data.followUserId && showFollow(data)" class="btn" type="primary"
                @click="follow">
                <span>{{ $t('跟进') }}</span>
                <span v-if="data.planTime" class="time">{{ $t('截止时间：') }}{{ data.planTime | datetimeText }}</span>
              </van-button>
              <!-- 战败线索且不是自己的有认领按钮 -->
              <van-button v-if="['2000020', '2000045'].includes(data.state) && !data.followUserId" :loading="claimLoading"
                class="btn" type="primary" @click="claimLead(data)">
                <span>{{ $t('认领') }}</span>
              </van-button>
              <van-button v-if="!data.followUserId && isShopManage" class="btn" type="primary"
                @click="onDistribution(data)">{{ $t('分配') }}
              </van-button>
            </template>
          </div>
        </div>
      </template>
    </Detail>
    <!-- 员工列表面板 -->
    <BusinessFilters ref="filter"
      v-model="showDistribution"
      :options="businessFilterOptions"
      :defaultActiveKey="['saIdList']"
      :buttonNameReset="$t('取消')"
      @reset="showDistribution = false"
      @submit="distributionFilter">
    </BusinessFilters>
    <!-- 授权弹窗 -->
    <AuthorizeTransfer v-if="visible" v-model="visible" :clueDetails="clueDetails" @refresh="onRefresh" />
    <!-- 分配线索 -->
    <TransferClue
      v-if="showTransfer"
      ref="transferRef"
      v-model="showTransfer"
      :distributionRole="businessFilterOptions[0].roleCodes"
      :distributionIds="[id]"
      @close="showTransfer = false"
      @transferConfirm="transferConfirm">
    </TransferClue>
  </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Detail from '../component/detail'
import AuthorizeTransfer from '../component/authorizeTransfer.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import AddWx from '@/components/detail-view/add-wx.vue'
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import loading from '@/utils/loading'
import TransferClue from '@/modules/clue/component/transferClue.vue'
import clueServices from '@/services/clueServices'
import clueDistributionServices from '@/services/clueDistributionServices'
import { ROLES } from '@/constants/constants'
export default {
  name: 'clueDetails',
  components: {
    Detail,
    AuthorizeTransfer, phoneIcon, qwIcon, AddWx, externalUserIcon, wxIcon,
    TransferClue
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'clueDetails')
      if (['/talk-skill-details', '/talk-skill-edit'].includes(from.path)) {
        // TODO
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (!['/talk-skill-details', '/talk-skill-edit'].includes(to.path)) {
      this.$store.commit('app/deleteKeepAlive', 'clueDetails')
    }
    next()
  },
  data() {
    return {
      businessFilterOptions:
        [
          {
            name: '',
            type: 'B_PEOPLE_SELECTION',
            field: 'saIdList',
            isSelection: true,
            valueType: 'object',
            shopIds: '',
            height: '80vh',
            roleCodes: '',
          },
        ],
      showDistribution: false,
      id: '',
      showPopover: false,
      actions: [],
      visible: false,
      clueDetails: {},
      stepList: [
        { text: this.$t('待首跟'), code: '2000005' },
        { text: this.$t('未排程'), code: '2000010' },
        { text: this.$t('已排程'), code: '2000012' },
        { text: this.$t('已试驾'), code: '2000013' },
        { text: this.$t('定金待支付'), code: '2000050' },
        { text: this.$t('定金已支付'), code: '2000052' },
        { text: this.$t('已锁单'), code: '2000055' },
        { text: this.$t('已推合同'), code: '2000057' },
        { text: this.$t('已签合同'), code: '2000060' },
        { text: this.$t('已交车'), code: '2000065' }
      ],
      claimLoading: false,
      leadState: '',
      showTransfer: false,
    }
  },
  
  computed: {
    ...mapGetters(['userInfo']),
    authDistribution() {
      let authRoleCodes = ['1014003', '1014004'] // 店长、MASTER
      const roleHash = this.$store.getters.getDictHash(1014) || []
      return roleHash.some(({ code }) => authRoleCodes.includes(code))
    },
    showFollow() {
      return function (data) {
        if (!data.state) return false
        // 屏蔽掉 https://jira.inner.vwadsso.cc/browse/SCRMSAL-4982
        // // 战败要是自己的才能跟进
        // if (data.state === '2000045') {
        //   return this.userInfo.id === data.followUserId
        // }
        // 无效申请中 战败申请中不能跟进
        return !['2000015', '2000040'].includes(data.state)
      }
    },
    // 非战败 或者 是自己的战败数据
    noloseAndselflose() {
      return function (data) {
        if (!data.state) return false
        return data.state !== '2000045' || (data.state === '2000045' && this.userInfo.id === data.followUserId)
      }
    },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
    isEn() {
      return window.navigator.language.toLocaleLowerCase().includes('en')
    },
    // 是否显示合并线索
    showMergeClue(data) {
      // 待首跟，未排程，已排程， 已试驾  店长 / Master / buddy /DCC
      return function (data) {
        const { roleLogos } = this.getUserInfo
        return data.state && ['2000005','2000010','2000012','2000013'].includes(data.state) && roleLogos.some((item) => { return ['1014001','1014003','1014004','1014005'].includes(item) })
      }
    },
    // 是否有店长权限
    isShopManage() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos.some((item) => ROLES.SHOWOWNER === item)
    },
  },
  created() {
    this.id = this.$route.query.id
  },
  methods: {
    // 分配
    onDistribution(data) {
      this.businessFilterOptions[0].roleCodes = data.state === '2000000' ? `${ROLES.DCC},${ROLES.SALES}` : (data.followUserRoleCode || ROLES.SALES) 
      this.leadState = data.state
      // 如果是待分配的线索 还是按老的分配交互方式
      if (this.leadState === '2000000') {
        this.showDistribution = true
      } else {
        // 走新的分配交付逻辑
        this.showTransfer = true
      }
    },
    async distributionFilter({ saIdList = [] }) {
      if (!saIdList || !saIdList.length) {
        this.$toast(this.$t('请选择顾问'))
        return
      }
      const [{ id, name }] = saIdList
      let params = {
        empId: id,
        empName: name,
        ids: [this.id],
      }
      if (this.leadState === '2000000') {
        const res = await clueDistributionServices.handleDistribution(params)
      } else {
        params.roleLogo = this.businessFilterOptions[0].roleCodes
        await clueServices.clueDistribution(params)
      }
      this.onRefresh()
    },
    getStepIndex(data) {
      const index = this.stepList.findIndex(({ code }) => code === data.state)
      if (index >= 3) {
        const scrollContainer = document.querySelector('.van-steps')
        if (scrollContainer) {
          scrollContainer.scrollLeft = index * 135
        }
      }
      return index
    },
    showIcon(data) {
      return data.state && !['2000015', '2000020', '2000040', '2000045'].includes(data.state)
    },
    // 刷新详情
    onRefresh() {
      this.visible = false
      this.$refs.clueDetail.getClueDetail()
      this.$refs.clueDetail.getTrips()
    },
    // 跟进
    follow() {
      const { id } = this
      this.$router.push({
        path: '/clue-follow',
        query: {
          id
        },
      })
    },
    // 认领
    async claimLead({ id }) {
      // if (this.claimLoading) return false
      // this.claimLoading = true
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确定领取该线索'),
        confirmButtonColor: '#B9921A',
        cancelButtonText: this.$t('取消'),
        confirmButtonText: this.$t('立即领取'),
      }).then(async () => {
        const params = { id }
        loading.showLoading()
        await clueServices.receiveClue(params)
        loading.hideLoading()
        this.$toast.success(this.$t('领取成功'))
        setTimeout(() => {
          this.onRefresh()
        }, 1500)
      })
      // const params = { id }
      // loading.showLoading()
      // await clueServices.receiveClue(params)
      // loading.hideLoading()
      // this.$toast.success(this.$t('领取成功'))
      // setTimeout(() => {
      //   this.onRefresh()
      // }, 1500)
    },
    onAuthorizeTransfer() {
      this.visible = true
    },
    // 授权他人接待
    onSelect({ value }, data) {
      if (value === 1) {
        this.$set(this, 'clueDetails', data)
        this.onAuthorizeTransfer()
      }
    },
    // 快速填写跟进
    handleAddMessage() {
      this.$router.push({
        path: '/clue-remark',
        query: {
          id: this.id,
          followResult: 1011041
        }
      })
    },
    SMSAddwechat() {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确认发送短信，邀请客户添加企业微信好友 ？'),
        confirmButtonColor: '#B9921A',
      }).then(async () => {
        await clueServices.inviteByLead({ ids: [this.id] })
        this.$toast(this.$t('发送短信成功'))
      })
    },
    // 合并线索
    mergeClue(data) {
      const { appointments=[], userName, userMobile, followUserId, id } = data
      if (Array.isArray(appointments) && appointments.length > 0){
        this.$toast(this.$t('该线索有未关闭的预约单，不可发起线索合并'))
        return false
      }
      this.$router.push({
        path: '/clue-merge',
        query: {
          originClueId: id,
          originFollowUserId: followUserId,
          originName: userName,
          originMobile: userMobile,
        }
      })
    },
    transferConfirm() {
      this.showTransfer = false
      this.onRefresh()
    },
  }
}
</script>
<style lang="less" scoped>
.clue-detail {
  padding: 76px 16px 0;
  overflow-x: hidden;
  &.clue-detail-pab{
    padding: 76px 16px 78px;
  }
  &.clue-detail-en {
    .sticky {
      /deep/ .van-steps {
        .van-steps__items {
          width: 1400px;
        }
      }
    }
  }

  .sticky {
    position: fixed;
    top: 0;
    max-width: 500px;
    width: 100%;
    z-index: 999;
    margin-left: -16px;
    // padding: 0 16px;
    background: #fff;

    /deep/.van-steps {
      overflow-x: scroll;

      .van-steps__items {
        width: 680px;

        .van-step--horizontal {
          .van-step__icon {
            font-size: 20px;
          }
        }
      }
    }
  }
  .sticky-pt {
    top: 45px;
  }

  /deep/ .btns-box {
    display: flex;
    justify-content: center;
  }
  /deep/ .btns {
    height: 78px;
    max-width: 500px;
    // margin-left: -16px;
    width: 100%;
    // height: 96px;
    display: flex;
    position: fixed;
    align-items: center;
    z-index: 999;
    bottom: 0px;
    // left: 50%;
    // transform: translateX(-50%);
    align-items: center;
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 16px;
    justify-content: space-between;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
    color: @black;
    box-shadow: 0px 2px 8px 2px #ddd;

    .btns-l {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .btns-l-icon {
      display: flex;
      margin-left: 16px;
      .icon {
        &:not(:last-of-type) {
          margin-right: 16px;
        }
      }
    }

    .btn {
      flex: 1;
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: 20px;
      }
      // &:first-of-type{
      //   margin-right: 0px;
      // }
      .van-button__text {
        display: flex;
        flex-direction: column;
        .time {
          font-size: 12px;
          // color: #E4002C;
        }
      }
    }
  }
}
</style>
<style lang="less">
.pageBox {
  /deep/ .van-nav-bar--fixed {
    left: 50%;
    max-width: 500px;
    transform: translateX(-50%);
  }
}
.clue-detail-actions {
  padding: 10px 20px;
  padding-bottom: 0px;
  .btn_item{
    line-height: 44px;
  }
  .mg-b-10{
    // margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
}
</style>
