<template>
<div class="loan-material" :style="{'padding-bottom':isEdit?'96px':'16px'}">
  <div class="common-block item">
   <!-- 姓名 -->
   <div class="vehicle">{{ detail.customerName }}</div>
   <!-- 车系/车型/外饰/内饰 -->
   <div class="label">
     <div class="common-label-t">
      {{ detail.carOrderMaterial?.seriesName }} |
      {{ detail.carOrderMaterial?.modelName }} |
      {{ detail.carOrderMaterial?.colourName }} |
      {{ detail.carOrderMaterial?.interiorName }} 
    </div>
   </div>
     <!-- <div class="common-label-c">{{ detail.carOrderMaterial?.interiorName }}</div> -->
   
   <div class="label">
     <div class="common-label-t">VIN : {{ detail.vin }}</div>
   </div>
 </div>
  <div class="common-block item">
    <div class="common-t">
      <!-- 交车确认函 -->
      <div class="common-t-l">{{$t('提车确认书')}}</div>
    </div>
    <van-form ref="form" class="labelTop" labelWidth="8.3em" inputAlign="right" @submit.native.prevent>
      <van-field
        v-if="form.contractType===2"
        v-model.trim="form.deliveryContractUrl"
        :label="$t('提车确认书已同步')"
      >
        <template #input>
          <span class="check-btn" @click="viewContract">{{$t('查看详情')}}</span>
        </template>
      </van-field>
      <van-field v-if="form.contractType===1" :label="$t('提车确认书已同步')" readonly>
          <template #input>
            <van-uploader
              class="upload"
              accept="image/*"
              :file-list="contractList"
              :preview-options="{closeable: true}"
              :deletable="false"
              :max-count="contractList.length"/>
            </template>
        </van-field>
    </van-form>
  </div>
  <div class="common-block item">
    <div class="common-t">
      <!-- 购车发票 -->
      <div class="common-t-l">{{$t('购车发票')}}</div>
    </div>
    <van-form ref="form" class="labelTop" labelWidth="8.2em" inputAlign="right" @submit.native.prevent>
      <van-field :label="$t('购车发票已同步')" readonly>
          <template #input>
            <van-uploader
              class="upload"
              accept="image/*"
              :file-list="buyCarInvoiceFileList"
              :preview-options="{closeable: true}"
              :deletable="false"
              :max-count="buyCarInvoiceFileList.length"/>
            </template>
        </van-field>
    </van-form>
  </div>
  <div class="common-block item">
    <FormVue ref="form" class="formVue" labelWidth="8.2em" :isLabelTop="true" :isEdit="isEdit" inputAlign="left" :list="info" :formData="formData"/> 
  </div>
  <div v-if="isEdit" class="common-footer">
      <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
      <van-button class="btn submit" type="submit" :loading="submitLoading" @click="onSubmit">{{$t('提交')}}</van-button>
  </div>
</div>
</template>

<script>
import FormVue from '@/modules/components/form/index.vue'
import deliveryServices from '@/services/deliveryServices.js'
import { thenResolve } from '@/utils/index'
export default {
  components:{ FormVue },
  data() {
    return {
      detail:{},
      taskDetail:{},
      operation: '',
      info:[],
      orderId:'',
      taskCode:'', // FINANCE_MATERIAL
      taskId: '',
      contractTaskId: '',
      form: {
        deliveryContractUrl: '',
        contractType: 1
      },
      contractList: [],
      buyCarInvoiceFileList: [], // 购车发票
      formData:{},
      submitLoading: false
    }
  },
  computed:{
    isEdit() {
      const flag = this.operation === 'edit'
      return flag
    }
  },
  created() {
    const { orderId,taskId,contractTaskId,operation,einvoiceUrl } = this.$route.query
    this.orderId = orderId
    this.taskId = taskId
    this.contractTaskId = contractTaskId
    this.operation = operation
    this.buyCarInvoiceFileList = [{
      url: einvoiceUrl,
      status: 'done'
    }]
    if (orderId && taskId){
      this.getDetail()
      this.deliveryTaskDetail()
      this.contractTaskDetail()
    }
  },  
  methods:{
    getInfo() {
      const { taskDetail } = this
      const { loanUrl ,buyCarInvoiceUrl,carRegisterUrl } = taskDetail?.executionJson || {}
      this.formData = { loanUrl ,carRegisterUrl }
      const info = [
        {
          label:this.$t('车辆保单上传'), // 保单
          field:'loanUrl',
          value:'',
          fileList:getUrlArr(loanUrl),
          type:'upload',
          maxlength: 2,
          required: true,
          format: ['image','.pdf'],
        },
        {
          label:this.$t('机动车登记证'), // 机动车登记证
          field:'carRegisterUrl',
          value:'',
          fileList:getUrlArr(carRegisterUrl),
          type:'upload',
          maxlength: 2,
          required: true,
          format: ['image','.pdf'],
        }
      ]        
      this.info = info
      function getUrlArr(str) {        
        const imgArr = []
        if (str){
          (str.split(',')).map((url=>{
            imgArr.push({
              status:'done',
              message:'',
              url
            })
          }))
        }
        return imgArr
      }
    },
    // 获取订单详情
    async getDetail() {
      const res = await deliveryServices.deliveryDetail({ id: this.orderId })
      this.detail = res || {}      
    },
    // 任务详情
    deliveryTaskDetail() {
      deliveryServices.deliveryTaskDetail({
        taskId: this.taskId
      }).then(res=>{
        this.taskCode = res.taskCode
        this.taskDetail = res
        // this.buyCarInvoiceFileList = [{
        //   status:'done',
        //   message:'',
        //   url: res.buyCarInvoiceUrl
        // }]
        this.getInfo()
      })
    },
    // 交车确认书任务详情
    contractTaskDetail() {
      deliveryServices.deliveryTaskDetail({
        taskId: this.contractTaskId
      }).then(res=>{
        const { contractUrl,contractType } = res.executionJson
        this.form.contractType = contractType
        this.form.deliveryContractUrl = contractUrl
        if (contractType===1){
          const urlArr = contractUrl.split(',')
          this.contractList = urlArr.map(url=>({
            url,
            status: 'done',
          }))
        }
      })
    },
    // 取消
    goBack() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.$refs.form.validate().then(res=>{
        if (this.submitLoading) return false
        this.submitLoading = true
        const { orderId,taskId,formData } = this
        if (!orderId && !taskId) return
        const { loanUrl ,carRegisterUrl } = formData
        if (!loanUrl || !carRegisterUrl){
          // this.$toast('请上传')
          return 
        }
        deliveryServices.deliveryExecution({
          executionJson:{
            taskCode: 'LOAN_MATERIAL_COMMIT',
            ...formData,
            deliveryContractUrl: this.form.deliveryContractUrl
          },
          id: taskId,
          taskStatus:'4004003',
        }).then(thenResolve).then(res=>{
          this.submitLoading = false
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        }).catch(() => {
          this.submitLoading = false
        })
      })
    },
    viewContract(){
      let url = this.form.deliveryContractUrl
      if (this.$isMobile){
        window.location.href = url
      } else {
        window.open(url,'myWindow')
      }
    }
  }
  
}
</script>

<style lang="less" scoped>
.loan-material{
  padding: 16px;
}
.item{
  padding:0 12px 12px;
  color: @black;
  margin-top: 10px;
  .common-t-l{
    &.required::after{
      content: '*';
      color: #EED484;
    }
  }
  &:not(:last-child){
    margin-bottom: 16px;
  }
  .vehicle{
    font-size: 16px;
    padding: 12px 0;
  }
  .label{
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .check-btn{
    color: #0040C5;
    cursor: pointer;
  }
}
.formVue{
  /deep/ .van-field{
    padding: 10px 0;
    &::before{
      left: -6px;
    }
    .van-cell__title{
      margin-bottom: 12px;
    }
  } 
}
.labelTop{
  /deep/ .van-field{
    display: block;
  }
  /deep/.van-field__control--right{
    justify-content: flex-start;
  }
}
</style>
