import hashWith from '@/utils/hash-with'
import { ROLES } from '@/constants/constants'
import { v4 as uuidv4 } from 'uuid'
const state = {
  keepAliveComponents: [], // 缓存组件name数组
  globalQuery: null,
  token: window.sessionStorage.getItem('Token'),
  dict: null,
  dictHash: null,
  hasMapping: false, // 门店是否开通虚拟号标识
  templateClueInfo: {},
  selectedShopId: '', // 当前选中的店铺id
  enumeration: {
    hasAppoinment: { // 有预约单的情况
      // 2000000: ['1011002', '1011005', '1011006'], // 待分配
      2000005: ['1011002', '1011005', '1011006', '1011007'], // 首次分配
      2000010: ['1011002', '1011005', '1011006', '1011007', '1011045'], // 跟进中
      2000012: ['1011002', '1011005', '1011006', '1011007', '1011045'], // 已排程，待试驾
      2000013: ['1011002', '1011005', '1011006', '1011007', '1011045'], // 已试驾
      2000025: ['1011002', '1011005', '1011006', '1011007', '1011045'], // 待跟进
      2000045: ['1011002', '1011005', '1011006', '1011045'], // 战败
      2000020: ['1011002', '1011005', '1011006', '1011045'], // 无效
      2000050: ['1011002', '1011005', '1011006', '1011045'], // 定金待支付
      2000052: ['1011002', '1011005', '1011006'], // 定金已支付
      2000055: ['1011002', '1011005', '1011006'], // 已锁单
      2000057: ['1011002', '1011005', '1011006'], // 已推合同
      2000060: ['1011002', '1011005', '1011006'], // 已签约
      2000065: ['1011002', '1011005', '1011006'], // 已交车
    },
    noAppointment: { // 没预约单的情况
      1000000: ['1011002', '1011004'], // 新建 自定义的value 为了方便编码
      2000005: ['1011001', '1011002', '1011004', '1011003','1011007'], // 待首跟
      2000010: ['1011002', '1011004', '1011007', '1011045'], // 跟进中
      2000013: ['1011002', '1011004', '1011007', '1011045'], // 已试驾
      2000025: [ '1011002', '1011003', '1011004', '1011045'], // 待跟进
      2000045: ['1011002', '1011004', '1011045'], // 战败
      2000020: ['1011002', '1011004', '1011045'], // 无效
      2000050: ['1011002', '1011004', '1011045'], // 定金待支付
      2000052: ['1011002', '1011004'], // 定金已支付
      2000055: ['1011002', '1011004'], // 已锁单
      2000057: ['1011002', '1011004'], // 已退合同
      2000060: ['1011002', '1011004'], // 已签约
      2000065: ['1011002', '1011004'], // 已交车
    },

  },
  menu: null,
  cosConfig: {}
}

const mutations = {
  addKeepAlive(state, componentName) {
    !state.keepAliveComponents.includes(componentName) &&
      state.keepAliveComponents.push(componentName)
  },
  deleteKeepAlive(state, componentName) {
    const index = state.keepAliveComponents.indexOf(componentName)
    index !== -1 && state.keepAliveComponents.splice(index, 1)
  },
  deleteAllKeepAlive(state, componentName=[]) {
    state.keepAliveComponents = componentName
  },
  setGloblaQuery(state, query) {
    state.globalQuery = query
  },
  setToken(state, t) {
    state.token = t
    window.sessionStorage.setItem('Token', t)
  },
  setDict(state, dict) {
    state.dict = dict
    state.dictHash = dict
  },
  setMenu(state, val) {
    window.sessionStorage.setItem('menu', val)
    state.menu = val
  },
  setTemplateInfo(state, val) {
    state.templateClueInfo = val
  },
  setHasMapping(state, val) {
    state.hasMapping = val
    window.sessionStorage.setItem('hasMapping', val)
  },
  setCosConfig(state, val) {
    state.cosConfig = val
  },
  setSelectedShopId(state, val) {
    state.selectedShopId = val
    window.sessionStorage.setItem('selectedShopId', val)
  }
}

const actions = {

}
const getters = {
  getMenu: state => state.menu,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
