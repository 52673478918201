<script>
import store from '@/store'
import formSelectWrap from './form-select-wrap'
import formSelectWrapCascader from '../select-car/form-select-wrap-cascader'
import mockData from './mock-dict'
import { Toast } from 'vant'

export default {
  resolve: null,
  reject: null,
  components: {
    formSelectWrap,
    formSelectWrapCascader
  },
  data() {
    return {
      descr: undefined,
      showPicker: false,
      dictType: null,
      status: null,
      shopId: null,
      multiple: false,
      followMode: null,
      excludeCode: [],
      isDisableTips: '',
      lastSelect: 0,
      initOptions: null,
      defeatList: [], // 默认数据源
      cellOptions: [],
      reasonList: [],
      contactResultList: [],
      min: undefined
    }
  },
  computed: {
    cellList() {
      let { dictHash } = store.getters
      dictHash = Object.assign(mockData, dictHash)
      // 有类型参数返回数据
      // 战败原因 和无效原因 走接口查询
    
      if (['invalidReason', 'defeatReason'].includes(this.dictType)) {
        return this.reasonList.map((item) => ({ ...item, dictCode: item.id, dictName: item.reason }))
      }
      // 联系结果
      if (['contactResultName'].includes(this.dictType)) {
        return this.contactResultList
      }
      if (this.dictType && dictHash[this.dictType]) {
        let ret = dictHash[this.dictType].map((item) => ({ ...item, dictCode: item.code, dictName: item.name }))
        if (this.dictType == 1038) {
          return ret.filter(({ descr })=> this.descr === +descr)
        }
        if (this.dictType == 3002) {
          ret = ret.filter((item) => { return item.id !=='295' })
        }
        if (this.excludeCode && this.excludeCode.length > 0) {
          let t = this
          ret = ret.map(item => (
            {
              ...item,
              isDisable: t.excludeCode.includes(item.dictCode)
            }
          ))
        }
        if (this.initOptions) return ret.filter(({ dictName })=>this.initOptions.includes(dictName))
        return ret
      } else {
        return this.cellOptions
      }
    },
    // 用来判断是否是级联的
    isCascade() {
      if (!this.cellList.length) return false
      let flag = false
      this.cellList.forEach(item => {
        item.children = item.codeList || item.children
        if (!item.codeList || !item.codeList.length) return
        flag = true
      })
      return flag
    }
  },
  methods: {
    confirm(data) {
      // 判断 是否选到了最底层
      if (data.length > 0) {
        if (data[data.length - 1].children && data[data.length - 1].children.length) {
          Toast('请完成全部选择')
          return false
        }
      }
      this.resolve(data)
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    }
  }
}
</script>
<template>
  <div>
    <form-select-wrap
      v-if="!isCascade"
      :showPicker="showPicker"
      :cell-list="cellList"
      :dict-type="dictType"
      :isDisableTips="isDisableTips"
      :shop-id="shopId"
      :followMode="followMode"
      :multiple="multiple"
      :lastSelect='lastSelect'
      :min='min'
      @cancel="cancel"
      @confirm="confirm"
    />
    <form-select-wrap-cascader
      v-else
      :columns="cellList"
      :showPicker="showPicker"
      @confirm="confirm"
      @cancel="cancel"
    >
    </form-select-wrap-cascader>
  </div>
</template>
