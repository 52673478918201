<template>
  <div class="batchClueTask">
    <div class="desc">
      <div class="top-tips">{{ $t('您可在该页面查看总部用户或店内圈选线索创建的任务，') }}
        <span style="color: red;">{{ $t('总部下发的任务若还未分配顾问') }}</span>
        {{ $t('（圈选的线索有顾问则默认原顾问跟进，无顾问则需店长指定），') }}
        <span style="color: red;">{{ $t('请及时分配销售顾问，避免逾期，未分配顾问的任务在任务明细中无法查看到') }}</span>
      </div>
      <div class="search-box">
        <div class="search-l">  
          <form action="#" class="search-input-container">
            <van-search
              v-model.trim="searchStr"
              clearable
              class="search-input"
              :placeholder="$t('输入任务名称模糊查询')"
              :formatter="(value)=> value.replace(/\s+/g, '')"
              format-trigger="onBlur"
              @input="onInput"
              @search="onRefresh"
              @clear="onRefresh"
            />
          </form>
        </div>
        <div class="search-r">
          <div class="filter-panel">
            <img class="img" :src="Object.values(filterParams).length > 0 ? filterActiveIcon : filterIcon" @click="filterPanelVisible = true"/>
          </div>
        </div>
      </div>
      <!-- Tabs -->
      <van-tabs v-model="active" class="stock-tabs" @change="changeTab">
        <van-tab v-for="(tab, index) of tabs" :key="index" :title="tab.name" :name="tab.value"></van-tab>
      </van-tabs>
      <!-- Tabs -->
      <ul v-if="active === 1" style="padding-top: 10px;">
        <li v-for="(item, index) in tabs2" :key="index" :class="['content-item', item.active ? 'active' : '']" @click.stop="onChange(item)">
         {{ item.name }}{{ countInfo[item.type] || 0 }}
        </li>
      </ul>
    </div>
    <FilterPanel
      ref="filterPanel"
      v-model="filterPanelVisible"
      :options="filterOptions"
      @submit="onFilter"
    />
    
    <!-- List -->
    <div v-if="active === 2" class="totality">{{$t('共')}} {{ countNum }} {{$t('条数据')}}</div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <ItemView :data="item" @go="(type)=>handleGo(type,item)"></ItemView>
      </template>
    </List>
    <!-- 分配线索 -->
    <TransferClue
      v-if="showTransfer"
      ref="transferRef"
      v-model="showTransfer"
      :taskBatchObj="taskBatchObj"
      @close="showTransfer = false"
      @transferConfirm="transferConfirm">
    </TransferClue>
  </div>
</template>

<script>
// Tools
import formatParams from '@/utils/formatParams'
import { debounce } from '@/utils/index.js'
import { v4 as uuidv4 } from 'uuid'

// Components
import List from '@/components/baseList/list.vue'
import FilterPanel from '@/components/filter-panel'
import ItemView from './components/item.vue'
import TransferClue from './components/transferClue.vue'
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'

// APIs
import { getTaskBatchList, getTaskBatchCount } from '@/services/batchClueTask.js'

export default {
  name: 'batchClueTask',
  components: { List, FilterPanel, ItemView, TransferClue },
  data() {
    return {
      filterIcon,
      filterActiveIcon,
      searchStr: '',
      filterParams: {},
      filterPanelVisible: false,
      filterOptions: [],
      active: 1,
      assignStatus: 0,
      list: [],
      countNum:0,
      countInfo: {},
      tabs2: [
        {
          name: '全部',
          value: 999,
          type: 'total',
          active: false
        },
        {
          name: '未分配顾问',
          value: 0,
          type: 'toAssign',
          active: true
        },
        {
          name: '已全部分配顾问',
          value: 1,
          type: 'assigned',
          active: false
        }
      ],
      showTransfer: false, // 分配销售弹窗
      taskBatchObj: {},
    }
  },
  watch:{
    dictHash:{
      handler(){
        // 过滤条件
        this.createFilterOptions()
      },
      immediate: true
    },
    '$store.getters.userInfo': {
      immediate: true,
      handler(val) {
        if (val && val.id) {   
          if (!val.roleLogos.includes('1014003') && val.roleLogos.includes('1014004')) {
            this.active === 2
          } else {
            this.taskCount()
          }
          this.onRefresh()
        }
      },
    },
  },
  computed: {
    tabs() {
      const { type, roleLogos = [] } = this.$store.getters.userInfo
      if (type === 1) {
        // 店端
        if (roleLogos.includes('1014003')) { // 店长
          return [
            {
              name: '总部下发任务',
              value: 1
            },
            {
              name: '店内创建任务',
              value: 2
            }
          ]
        } else if (roleLogos.includes('1014004')) { // master
          return [
            {
              name: '店内创建任务',
              value: 2
            }
          ]
        }
        return []
      }
      return []
    },
  },
  created() {
    const { taskBatchCode='', assignStatus } = this.$route.query
    if (taskBatchCode || assignStatus) {
      this.searchStr = taskBatchCode
      this.assignStatus = assignStatus
      this.tabs2.map(item => {
        if (assignStatus == item.value) {
          item.active = true
        } else {
          item.active = false
        }
      })
    }
    // if (this.active == 1) {
    //   this.taskCount() 
    // }   
  },
  // activated() {
  //   this.onRefresh()
  // },
  methods: {
    onInput(value) {
      this.searchStr = value
    },
    // 切换tab
    changeTab(val) {
      this.countNum = 0
      // this.filterParams = this.$options.data().filterParams
      this.$refs.list.list = []
      this.onRefresh()
    },
    onChange (item) {
      this.tabs2.map((data) => {
        if (item.value === data.value) {
          item.active = !item.active
        } else {
          data.active = false
        }
      })
      this.assignStatus = item.value
      this.onRefresh()
    },
    onRefresh:debounce(function() { 
      if (this.active === 1) {
        this.taskCount() 
      }          
      this.$refs.list.onRefresh()
    },500),
    fetchApi(obj = {}) {
      const page = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
      }
      const { ...query } = this.filterParams
      return getTaskBatchList({
        ...page,
        ...query,
        type: this.active,
        searchStr: this.searchStr,
        assignStatus: this.active === 2 ? '' : this.assignStatus == 999 ? '' : this.assignStatus,
      }).then(res=>{
        console.log('res-----------', res)
        this.countNum = res?.page?.totalCount || 0
        return res
      })
    },
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      if (params.createTimeStart||params.createTimeEnd){
        this.filterParams.createTime = {}
      }
      if (params.planTimeStart||params.planTimeEnd){
        this.filterParams.planTime = {}
      }
      // 创建时间
      if (params.createTimeStart){
        this.filterParams.createTime.start = this.filterParams.createTimeStart
      }
      if (params.createTimeEnd){
        this.filterParams.createTime.end = this.filterParams.createTimeEnd
      }
      this.onRefresh()
      this.filterPanelVisible= false
    },
    async taskCount(){
      const { ...query } = this.filterParams
      const params = {
        ...query,
        type: 1,
        searchStr: this.searchStr,
      }
      const res = await getTaskBatchCount(params)
      this.$set(this,'countInfo',res)
    },
    handleGo(type,item){
      if (type === 'progress') {
        this.$router.push({
          path: '/taskManage',
          query: {
            searchStr: item.taskBatchCode
          }
        })
      } else {
        this.taskBatchObj = item
        this.showTransfer = true
      }
    },
    transferConfirm() {
      this.showTransfer = false
      this.onRefresh()
    },
    // 搜索面板条件
    async createFilterOptions(){
      const ret = []
      // 任务创建时间
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: '任务创建时间',
        start: {
          field: 'createTimeStart',
          value: this.filterParams.createTimeStart
        },
        end: {
          field: 'createTimeEnd',
          value: this.filterParams.createTimeEnd
        },
      })
      // 任务完成状态
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '任务完成状态',
        field: 'execStatus',
        options: [
          { id: uuidv4(), label: '未完成', value: 0 },
          { id: uuidv4(), label: '已完成', value: 1 },
        ],
      })
      this.filterOptions = ret
    },
  }
}
</script>

<style lang="less" scoped>
.batchClueTask {
  .desc{
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #ffffff;
    .top-tips {
      font-size: 12px;
      padding: 2px 10px;
    }
  }
  .search-box{
    position: sticky;
    top: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    .search-l{
      font-size: 14px;
      color: #7e7979;
      margin-right: 8px;
      flex: 1;
      .search-input-container {
        position: relative;
        z-index: 20;
        width: 100%;
        background-color: #fff;
          .search-input {
            padding: 0;
            border-radius: 20px;
            .van-search__content {
              background-color: #f5f5f5;
              border-radius: 24px;
            }
            // placeholder字体颜色
            /deep/ input::placeholder {
              color: rgba(13,23,26,0.45);
            }
            // 前缀图标颜色
            /deep/.van-icon-search{
              color: #666;
              font-size: 15px;
            }
            /deep/.van-field__left-icon{
              margin-right: 6px;
            }
          }
        }
    }
    .search-r {
      img{
        width: 24px;
      }
    }
  }
  .filter-icon {
    width: 20px;
    height: 20px;
    // position: fixed;
    // right: 12px;
    // top: 12px;
  }
  .totality{
    padding: 10px 16px 0;
    font-size: 12px;
  }
  ul {
    overflow-y: auto;
    display: flex;
    padding: 12px 0;
    .content-item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 4px 8px;
      border-radius: 20px;
      border: 1px solid #ccc;
      background: #f2f2f2;
      margin-left: 16px;
      font-size: 14px;
      cursor: pointer;
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
      &.active {
        background: #faefdc;
        color: #df9d40;
      border: 1px solid #df9d40;
      }
      .play {
        transform: rotate(90deg);
        margin-left: 4px;
        &.ascend {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
</style>
