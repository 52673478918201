<template>
  <div class="CommonCascader">
    <div style="overflow-y: scroll;" :class="['left', getHasRightView ? 'with-right-view' : '']">
      <van-index-bar ref="indexBar" :index-list="indexList.arr" highlight-color="#EED484">
        <div
          v-for="(item, index) of getItems"
          :key="item[keys._uuid] || index"
          @click="handleLeftClick(item.value)">
          <van-index-anchor
            v-if="indexList.ids.includes(item.value)"
            :index="item.brandPinyin[0].toUpperCase()">
            <!-- <span></span> -->
          </van-index-anchor>
          <div
            :class="[
              'left-node',
              activeLeftKey == item.value ? 'active' : '',
            ]">
            <CheckBox
              :check="item._check"
              :disabled="disableds.includes(item.value)"
              :nameClickCheck="nameClickCheck"
              :half="item._half"
              @change="handleCheck(item, index)"
            ></CheckBox>
            <div class="name" :title="item.name">{{ item.name }}</div>
            <van-icon
              v-show="item.children && item.children.length"
              name="arrow"
              class="right-icon"
            />
          </div>
        </div>
      </van-index-bar>
    </div>
    <div v-if="getHasRightView && cacheAllNodes" class="right">
      <div v-for="(item, index) of getItems" :key="item[keys._uuid] || index">
        <TreeNode
          v-for="(treeNode, indexTreeNode) of item.children || []"
          v-show="activeLeftKey == item.value"
          :key="treeNode[keys._uuid] || indexTreeNode"
          :disabled="disableds.includes(item.value)"
          :nameClickCheck="nameClickCheck"
          :data="treeNode"
          @change="handleTreeNodeChange(treeNode, index)"
        ></TreeNode>
      </div>
    </div>
    <div v-if="getHasRightView && !cacheAllNodes" class="right">
      <TreeNode
        v-for="(treeNode, indexTreeNode) of getRightItems.children || []"
        :key="treeNode[keys._uuid] || indexTreeNode"
        :disabled="disableds.includes(treeNode.value)"
        :nameClickCheck="nameClickCheck"
        :data="treeNode"
        @change="handleTreeNodeChange(treeNode)"
      ></TreeNode>
    </div>
  </div>
</template>

<script>
// Components
import TreeNode from './TreeNode.vue'
import CheckBox from './CheckBox.vue'
import Empty from '@/components/v2/common/empty'

// Tools
import { cloneDeep, last, isArray, isEqual, debounce, throttle } from 'lodash'

export default {
  name: 'CommonCascader',
  components: {
    TreeNode,
    CheckBox,
    Empty,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    // 视同name节点点击等于点击了checkbox
    nameClickCheck: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Array,
      default: () => [], // [[1,2,3], [4,5,6], [7,8,9]]
    },
    defaultActiveLeftKey: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
    disableds: {
      type: Array,
      default: () => [],
    },
    cacheAllNodes: {
      // 是否缓存所有节点，当节点过多时，创建需要消耗时间，建议开启，开启后会消耗更多内存
      type: Boolean,
      default: false,
    },
    keys: {
      type: Object,
      default: () => {
        return {
          uuid: '_uuid',
        }
      },
    },
    emitTreeDatas: {
      // 是否需要返回树形结构的数据，默认为true，不需要此数据时可设置为false，节约性能
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeLeftKey: '',
      // 滚动
      divScrollTop: 0,
      startY: 0,
    }
  },
  computed: {
    indexList() {
      const arr = []
      const ids = []
      this.items.map(({ brandPinyin, value }) => {
        const str = brandPinyin[0].toUpperCase()
        if (!arr.includes(str)) {
          arr.push(str)
          ids.push(value)
        }
      })
      arr.sort()
      return {
        arr,
        ids,
      }
    },
    getItems() {
      return this.items.filter((i) => !i._hiddenSelf)
    },
    getRightItems() {
      return (
        this.getItems.find((item) => item.value == this.activeLeftKey) || {}
      )
    },
    getHasRightView() {
      return this.getItems.some((i) => i.children && i.children.length)
    },
    getRightRefName() {
      return `CommonCascader_${this.field}_RightView`
    },
  },
  watch: {
    defaultActiveLeftKey: {
      handler(newVal) {
        console.log('defaultActiveLeftKey', newVal)
        this.activeLeftKey = newVal
      },
    },
    defaultValue: {
      handler(newVal) {
        console.log('defaultValue changed', newVal)
        this.init()
      },
      deep: true,
    },
    items: {
      handler(newVal = [], oldArr = []) {
        if (newVal.length == oldArr.length) return
        console.log('items changed', newVal)

        // 计算以及设置当前路径
        this.setArraysPath(this.items)
      },
      deep: true,
    },
  },
  created() {
    this.init()
  },
  mounted() {
    this.$refs.indexBar.scrollTo(this.indexList.arr[0])
  },
  methods: {
    // 滚动优化代码块结束
    init() {
      this.activeLeftKey =
        this.defaultActiveLeftKey ||
        (this.items && this.items.length ? this.items[0].value : '')
      // 计算以及设置当前路径
      this.setArraysPath(this.items)

      this.clearCheck()

      // 设置默认值， defaultValue格式是 [[1,2,3], [4,5,6], [7,8,9]]
      if (!this.defaultValue || !isArray(this.defaultValue))
        return console.error('defaultValue格式错误')

      // console.log('cc init', this.defaultValue)
      console.log('===init===', this.defaultValue)

      // 设置默认值数据回显，根据_path
      const setDefaultCheck = (data, defaultValue = []) => {
        data.forEach((item) => {
          // 判断长度
          if (item._path.length !== defaultValue.length) {
            item.children && setDefaultCheck(item.children, defaultValue)
            return
          }

          if (isEqual(item._path, defaultValue)) {
            console.log('===============sdfasdf===============')
            this.$set(item, '_check', true)
            // 设置子节点
            item.children && setChildren(item.children, true)
            return
          }
        })
      }

      // 递归设置子节点
      const setChildren = (children, newCheck) => {
        children.forEach((child) => {
          this.$set(child, '_check', newCheck)
          child.children && setChildren(child.children)
        })
      }

      this.defaultValue.forEach((item) => {
        setDefaultCheck(this.items, item)
      })

      this.reComputeCheckHalf(this.items)
      this.handleChange()
      this.ready()
    },
    ready() {
      this.$emit('ready')
    },
    reComputeCheckHalf(data, resetWhenNull = false) {
      // 重新计算选中状态的全选和半选
      data.forEach((item) => {
        item.children && this.reComputeCheckHalf(item.children, resetWhenNull)

        const childs = item.children || []
        const childsLength = childs.length

        if (!childsLength) return

        const checkedLength = childs.filter((item) => item._check).length
        const halfLength = childs.filter((item) => item._half).length
        const half =
          halfLength || (checkedLength > 0 && checkedLength < childsLength)
        // checkedLength
        if (!checkedLength && !halfLength && !resetWhenNull) return

        const _check = checkedLength && checkedLength === childsLength

        this.$set(item, '_check', Boolean(_check))
        this.$set(item, '_half', Boolean(half))
      })
    },
    setArraysPath(data, path = []) {
      data.forEach((item) => {
        const thisPath = [...path, item.value]
        // 设置当前路径
        this.$set(item, '_path', thisPath)
        item.children && this.setArraysPath(item.children, thisPath)
      })
    },
    clearCheck() {
      // 清除选中状态
      const handle = (data, arr = []) => {
        data.forEach((item) => {
          this.$set(item, '_check', false)
          this.$set(item, '_half', false)
          item.children && handle(item.children, arr)
        })
      }
      handle(this.items, [])
    },
    // 删除对象的keys，返回旧对象
    deleteObjectKeys(keys = [], obj = {}) {
      if (!keys || !keys.length)
        return console.error('deleteObjectKeys keys格式错误')
      if (!obj || !Object.keys(obj).length)
        return console.error('deleteObjectKeys obj格式错误')

      keys.forEach((key) => delete obj[key])
      return obj
    },
    // 仅保留对象的keys，返回旧对象
    onlyObjectKeys(keys = [], obj = {}) {
      if (!keys || !keys.length)
        return console.error('onlyObjectKeys keys格式错误')
      if (!obj || !Object.keys(obj).length)
        return console.error('onlyObjectKeys obj格式错误')

      Object.keys(obj).forEach((key) => {
        if (!keys.includes(key)) delete obj[key]
      })

      return obj
    },
    computeCheckedData() {
      // 多维对象数组，展平为二维数组
      let checkedObjectArray = [] // [ [{}, {}, {}], [{}, {}, {}], [{}, {}, {}] ]

      const handle = (data, arr = []) => {
        data.forEach((item) => {
          let thisArr = [...arr]
          if (item._check || item._half) {
            let obj = cloneDeep(item)
            const deleteKeys = ['children', 'child', 'onClick']
            obj = this.deleteObjectKeys(deleteKeys, obj)
            thisArr.push(obj)
          }
          item.children && handle(item.children, thisArr)

          if (
            thisArr.length > 0 &&
            (!item.children || !item.children.length) &&
            item._check
          ) {
            checkedObjectArray.push(thisArr)
          }
        })
      }
      handle(this.items, [])

      // 多维value数组
      const checkedArray = checkedObjectArray.map((i) => i.map((j) => j.value))

      // 一维对象数组
      const checkedValues = checkedArray.map((i) => last(i))

      // 树型数据
      let checkedTree = []

      // 仅选中（不含半选）的树型数据，但是扁平化处理 [[1,2,3], [1,2,3], [1,2,3]], 其中第0项为level0所有选中的，第1项为level1，以此类推
      let checkedOnlyArray = []
      // 仅选中（不含半选）的树型数据，但是扁平化处理 [[{}, {}, {}], [{}, {}, {}], [{}, {}, {}]], 其中第0项为level0所有选中的，第1项为level1，以此类推
      let checkedObjOnlyArray = []

      if (this.emitTreeDatas) {
        // 选中或半选的树型数据
        checkedTree = cloneDeep(this.items)
        const handleTree = (data) => {
          return data
            .filter((item) => {
              if (!item._check && !item._half) return false
              if (item.children && item.children.length) {
                item.children = handleTree(item.children)
              }

              return true
            })
            .map((item) => {
              // 仅保留需要的key
              const {
                id,
                label,
                treeId,
                uuid,
                _uuid,
                name,
                nameEn,
                value,
                children,
                _check,
                _half,
                brandPinyin
              } = item
              return {
                id,
                label,
                treeId,
                uuid,
                _uuid,
                name,
                nameEn,
                value,
                children,
                _check,
                _half,
                brandPinyin
              }
            })
        }
        checkedTree = handleTree(checkedTree)

        const handle2 = (arr = [], level = 0) => {
          if (!checkedOnlyArray[level]) checkedOnlyArray[level] = [] // 初始化
          if (!checkedObjOnlyArray[level]) checkedObjOnlyArray[level] = [] // 初始化

          arr.forEach((item) => {
            if (item._check) {
              checkedOnlyArray[level].push(item.value)

              let itemClone = cloneDeep(item)
              const keys = ['children', 'child', 'childs', 'childrens']
              itemClone = this.deleteObjectKeys(keys, itemClone)
              checkedObjOnlyArray[level].push(itemClone)
              return
            }
            item.children && handle2(item.children, level + 1)
          })
        }

        handle2(checkedTree)
      }

      console.log('emitTreeDatas', this.emitTreeDatas)
      return {
        checkedObjectArray,
        checkedArray,
        checkedValues,
        checkedTree,
        checkedOnlyArray,
        checkedObjOnlyArray,
        emitTreeDatas: this.emitTreeDatas,
      }
    },
    handleLeftClick(keyValue) {
      console.log('handleLeftClick', keyValue)
      this.activeLeftKey = keyValue

      // 滚动右侧视图到顶部
      const rightView = this.$refs[this.getRightRefName]
      rightView && rightView.scrollTo(0, 0)
    },
    handleTreeNodeChange(treeNode, index) {
      console.log('handleTreeNodeChange', treeNode, index)

      // 子节点变更了，计算该节点的选中状态
      const childs = this.getRightItems.children || []
      const childsLength = childs.length
      const checkedLength = childs.filter((item) => item._check).length
      const halfLength = childs.filter((item) => item._half).length
      const half =
        (checkedLength > 0 && checkedLength < childsLength) || halfLength > 0

      const _check = checkedLength === childsLength
      const _half = !_check && half

      this.$set(this.getRightItems, '_check', _check)
      this.$set(this.getRightItems, '_half', _half)

      this.handleChange()
      treeNode.onClick && treeNode.onClick(treeNode, index)
    },
    handleCheck(item, index, autoLeft = true) {
      console.log('handleCheck', item, index)
      autoLeft && this.handleLeftClick(item.value)

      const newCheck = !item._check
      this.$set(item, '_check', newCheck)
      this.$set(item, '_half', false)

      // 递归设置子节点
      const setChildren = (children) => {
        children.forEach((child) => {
          if (child._hiddenSelf) return
          this.$set(child, '_check', newCheck)
          this.$set(child, '_half', false)
          child.children && setChildren(child.children)
        })
      }

      // 设置子节点
      setChildren(item.children)

      this.handleChange()
      item.onClick && item.onClick(item, index)
    },
    handleChange() {
      const result = this.computeCheckedData()
      console.log('handleChange', result)
      this.$forceUpdate()
      this.$emit('change', result)
    },
  },
}
</script>

<style lang="less" scoped>

/deep/ .van-index-bar{
  padding-left: 25px;
  .van-index-bar__sidebar {
    left: 0;
    top: 260px;
    width: 25px;
    z-index: 4000 !important;
    .van-index-bar__index {
      padding: 4px 8px;
      width: 25px;
      font-size: 12px;
      display: inline-block;
      z-index: 5000 !important;
    }
  }
}
.CommonCascader {
  display: flex;
  // border: 1px solid #f4f4f4;
  @leftWidth: 120px;
  @leftMaxWidth: 200px;
  width: 100%;
  height: 100%;
  .left {
    min-width: @leftWidth;
    flex: 1;
    background: #f4f4f4;
    color: #323232;
    overflow-x: auto;
    &.with-right-view {
      max-width: @leftMaxWidth;
    }
    .left-node {
      padding: 0px 12px;
      height: 50px;
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active,
      &:active,
      &:hover {
        background: #fff;
        color: #eed484;
      }

      .name {
        flex: 1;
        margin-left: 8px;
        // 超出文本省略号
        /* 规定段落中的文本不进行换行： */
        white-space: nowrap;
        /* 内容会被修剪，并且其余内容是不可见的。 */
        overflow: hidden;
        /* 显示省略符号来代表被修剪的文本。 */
        text-overflow: ellipsis;
      }
    }
  }

  .right {
    min-width: calc(100% - @leftWidth);
    overflow-x: auto;
    overflow-y: auto;
    overscroll-behavior: none;
    background: #fff;
  }
}
</style>
