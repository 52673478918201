<template>
<div class="deliver-record" :style="{'padding-bottom':operation==='edit'?'96px':'16px'}">
  <BasicInfo :carInfo="detail"/>
  <div class="card" style="margin-top: 10px;">
    <div class="common-title">{{$t('资料上传')}}</div>
    <div class="card-form">
      <van-form ref="form" @submit.native.prevent>
        <!-- <div>
          <span>5fe3ec，单一物品以及交与客户的合影</span>
          <span>(最多拍摄6张)</span>
        </div> -->
        <van-field :label="$t('交车礼品配图')" readonly required>
          <template #input>
            <van-uploader
              class="upload"
              accept="image/*"
              :file-list="fileList"
              :preview-options="{closeable: true}"
              :before-read="beforeRead"
              :deletable="operation === 'edit'"
              :after-read="(file) => afterRead(file)"
              :before-delete="(file) => beforeDelete(file)"
              :max-count="operation === 'edit' ? 6 : fileList.length"/>
            </template>
        </van-field>
        <van-field
          v-model.trim="form.giftRemark"
          :label="$t('礼品数量说明')"
          rows="3"
          autosize
          type="textarea"
          maxlength="50"
          :placeholder="$t('请输入礼品数量说明')"
          show-word-limit
          required
          :disabled="operation==='view'"
        />
        <div v-if="operation==='edit'" class="common-footer">
          <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('返回')}}</van-button>
          <van-button class="btn submit" type="submit" @click="onSubmit">{{$t('提交')}}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</div>
</template>
<script>
import { uploadFile } from '@/utils/cos'
import BasicInfo from './component/basic-info.vue'
import { v4 as uuidv4 } from 'uuid'
import loading from '@/utils/loading'
import deliveryServices from '@/services/deliveryServices.js'
export default {
  name:'deliverRecord',
  components:{ BasicInfo },
  data(){
    return {
      fileList: [],
      form: {
        giftUrl: '',
        giftRemark: '' // 礼品数量说明
      },
      params: {
        id: '',
        taskStatus: '4004003',
        executionJson: {
          taskCode: 'DELIVERY_GIFT',
          giftUrl: '',
          giftRemark: '' // 礼品数量说明
        },
      },
      operation: '',
      orderId:'',
      detail:{},
      fileUrlArr:[]
    }
  },
  mounted() {
    const { operation, orderId, taskId } = this.$route.query
    this.operation = operation || 'edit'
    this.orderId = orderId
    this.params.id = taskId
    this.getDetail()
    this.getTaskDetail()
  },
  methods:{
    // 获取交付详情
    async getDetail() {
      const res = await deliveryServices.deliveryDetail({ id: this.orderId })
      this.detail = res || {}
    },
    // 获取任务详情
    async getTaskDetail() {
      const params = {
        taskId: this.params.id,
      }
      const res = await deliveryServices.deliveryTaskDetail(params)
      this.params.executionJson.giftRemark = res.executionJson.giftRemark
      this.form.giftRemark = res.executionJson.giftRemark
      this.fileUrlArr = res.executionJson.giftUrl.split(',')
      this.fileList = this.fileUrlArr.map(url=>({
        url,
        status:'done',
        uuid: uuidv4(),
        message:''
      }))
    },
    // 上传前校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/jpeg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        file.status = 'done'
        file.message = ''
        file.url = content
        file.cosUrl = url
        this.fileList.push(file)
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
        this.fileList.push(file)
      })
    },
    beforeDelete(file){
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    goBack(){
      this.$router.go(-1)
    },
    async onSubmit(){
      if (!this.form.giftRemark.trim()||!this.fileList.length){
        this.$toast(this.$t('请完成必填项填写'))
        return false
      }
      this.fileUrlArr = []
      this.fileList.forEach(item=>{
        if (item.status === 'done'){
          this.fileUrlArr.push(item.cosUrl)
        }
      })
      this.params.executionJson.giftUrl = this.fileUrlArr.join(',')
      this.params.executionJson.giftRemark = this.form.giftRemark
      loading.showLoading()
      const res = await deliveryServices.deliveryExecution(this.params)
      loading.hideLoading()
      if (res.code === 0) {
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      } else {
        this.$toast(res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.deliver-record{
  padding: 16px;

  .card{
  padding:  0 12px 4px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  .sub-title{
    padding: 12px 0;
  }
}
.item{
  margin-bottom: 8px;
  .label{
    height: 16px;
    font-size: 14px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    color: rgba(13,23,26,0.45);
    line-height: 16px;
  }
  .value{
    height: 16px;
    font-size: 14px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    color: #0D171A;
    line-height: 16px;
  }
}
}
</style>