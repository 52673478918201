import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'
// 查询库存
export const getWarehouseList = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/specConfig/query-warehouse`,params).then(thenResolve)

// 新建配置单
export const createSpecialOrder = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/specConfig/create`,params).then(thenResolve)

// 配置单详情
export const specialOrderDetail = (params={}) =>request.get(`${SERVER.ORDER_SERVICE}/api/v1/specConfig/detail`, { params }).then(thenResolve)

// 查询物料
export const queryMaterial = (params={}) =>request.get(`${SERVER.ORDER_SERVICE}/api/v1/specConfig/query-material`, { params }).then(thenResolve)

// 查询配置单
export const getOrderList = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/specConfig/page`,params).then(thenResolve)

// 取消配置单
export const invalidOrder = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/specConfig/invalid?id=${params}`).then(thenResolve)

// 审批配置单
export const reviewOrder = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/specConfig/review`, params)

// 按VIN下单判断配置单是否存在
export const isExistSpecialOrder = (params={}) =>request.post(`${SERVER.ORDER_SERVICE}/api/v1/specConfig/is-exist`,params)
