<script>
import Tag from '@/components/card-view/tag.vue'
import UserInfo from '@/components/detail-view/user-info.vue'
import AddWx from '@/components/detail-view/add-wx.vue'
import Trips from '@/modules/clue/component/trips/index.vue'
import Tags from '@/modules/clue/component/tags/index.vue'
import OrderTitle from '@/modules/order/component/orderTitle.vue'
import VehicleInfo from '@/modules/order/component/vehicleInfo.vue'
import TalkSkill from '@/modules/talk-skill/index.vue'
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
import { driveUpdatePost,appointmentReviewPost } from '@/services/appointment'
import { dateFormat } from '@/utils'
import UpdateVue from '@/modules/appointment/update.vue'
import CardOptions from '@/components/card-view/cardOptions.vue'
import appointmentItem from './appointmentItem.vue'
let vm
export default {
  components:{ UserInfo,Tags,Tag,AddWx,Trips,VehicleInfo,OrderTitle,UpdateVue,CardOptions,appointmentItem, TalkSkill },
  filters: {
    
  },
  props:{
    id:{ // 线索id
      type: [Number,String],
      default: ''
    },
    appointmentId:{ // 预约id
      type: [Number,String],
      default: ''
    },
  },
  data() {
    return {
      type: 'Tags',
      tabs: [
        {
          name: this.$t('标签'),
          value: 1,
          componentName: 'Tags',
        },
        {
          name: this.$t('轨迹'),
          value: 0,
          componentName: 'Trips',
        }
      ],
      info:{
        detail:{},
        labels:[],
      },
      appointmentList:[],
      tripDetails:{},
      activeCode: '',
      showScheduling:false,
      appointmentInfo: {},
      isShowReview: false,
      reviewRemark: '',
      flag: false
    }
  },
  computed:{
    
    getChannelName() {
      const { onlineName,channelOne,channelTwo, channelThree } = JSON.parse(JSON.stringify(this.info))
      const arr = []
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      return arr.join('/')
    },
    
  },
  mounted(){
    vm = this
    this.activeCode = ''
    this.getTrips(this.activeCode)   
    // 店端展示话术，厂端不展示
    if (this.$store.getters.userInfo.type == 1) {
      this.tabs.push( {
        name: this.$t('话术'),
        value: 2,
        componentName: 'TalkSkill',
      })
    }
  },
  created() {
    this.getClueDetail()
  },
  methods:{
    authAudits() {
      this.$dialog
        .confirm({
          title: this.$t('接待授权审核'),
          message: `【${this.info.authTemp.authorizedEmployeeName}】${this.$t('向您申请该客户的接待授权')}`,
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('同意'),
          cancelButtonText: this.$t('拒绝'),
          closeOnClickOverlay: true,
          beforeClose: async (action, done)=> {
            done()
            if (action == 'confirm' || action == 'cancel') { // 审核
              loading.showLoading()
              await clueServices.examineEmpower({ authTempId: this.info.authTemp.id, examineType: action == 'confirm' ? 1 : 0 })
              loading.hideLoading()
              this.getClueDetail()
            } 
          }
        })
    },
    async getClueDetail() {
      loading.showLoading()
      try {
        const res = await clueServices.getClueDetail({ id: this.id, appointmentId: this.appointmentId })
        loading.hideLoading()
        this.appointmentList = res.appointments || []
        // this.info = Object.assign(this.info, res)
        // this.$set(this, 'info', this.info)
        this.info = res
        if (!this.flag && res.authTemp && res.authTemp.authorizedEmployeeId) {
          this.flag = true
          this.authAudits()
        }
      } catch (error) {
        if (error.message === '30031') {
          setTimeout(() => {
            this.$router.replace({
              path: '/clue-list',
              query: {
                fromAssistant: true,
                replace: true
              }
            })
          }, 1500)
        }
      }
    },
    // 编辑
    onEdit(data){
      this.$router.push({
        name:'editClue',
        query:{
          id: this.id,
          operate: data,
        }
      })
    },
    // 获取轨迹
    async getTrips(label){
      const { id } =this
      const params = { id }
      if (label) params.label = label
      const res = await clueServices.getTrips(params)
      this.tripDetails = res
    },
    onChangeTrip(code){
      this.getTrips(code)
    },
    handleRegetClueInfo() {
      this.getClueDetail()
    },
    goSchedule(item){
      this.appointmentInfo = {}
      this.$set(this,'appointmentInfo',item)
      this.showScheduling = true
    },
    //  确认排程
    async confirmScheduling(data) {
      data.appointmentStartTime = dateFormat(data.appointmentStartTime, 'YYYY-MM-DD HH:mm:ss')
      data.appointmentEndTime = dateFormat(data.appointmentEndTime, 'YYYY-MM-DD HH:mm:ss')
      data.modelCode = data.vehicleCode
      // 直接排程
      data.id = this.appointmentInfo.id
      await driveUpdatePost(data)      
      this.showScheduling = false
      window.location.reload()
    },
    goOrderDetail(id) {
      this.$router.push({
        path: '/order-detail',
        query: {
          id,
        },
      })
    },
    openReview(item){
      this.appointmentInfo = {}
      this.$set(this,'appointmentInfo',item)
      this.reviewRemark = ''
      this.isShowReview = true
    },
    // 审核驳回 1：驳回、2：通过
    async onReview(action, done){
      if (action==='overlay')done()
      if (action==='cancel'&&!this.reviewRemark.trim()) {
        this.$toast(this.$t('请输入审核意见'))
        done(false)
        return
      }
      if (['confirm','cancel'].indexOf(action)>-1){
        const params = {
          reviewStatus: action==='cancel'?1:2,
          reviewRemark: this.reviewRemark,
          id: this.appointmentInfo.id
        }
        const res = await appointmentReviewPost(params)
        done()
        window.location.reload()
      }
    },
  }
}
</script>
<template>
<div class="pageBox">
  <slot name="top" :data="info"></slot>
  <div class="top card">
    <UserInfo :data="info" sourcePage="clue" @edit="onEdit"/>
    <div class="info">      
      <div style="margin-bottom: 0"><span class="label">{{ $t('用户来源') }}： </span> {{getChannelName || '--'}}</div>
      <CardOptions :data="info"/>
      <div style="display: flex;">
        <div style="width: fit-content;" lass="label">{{ $t('跟进人') }}： </div> 
        <div style="flex: 1; word-break: break-word">
          {{info.followUserName || '--'}}
          <span v-if="info.followRemark">| {{ info.followRemark }}</span>
        </div>
      </div>
    </div>
    <div class="status">{{ info.state | codeFormat }} </div>
  </div>
  <template v-for="(item,index) in appointmentList">
    <appointmentItem :key="index" :item="item" :info="info" @goSchedule="goSchedule" @openReview="openReview"/>
  </template>
  <div v-if="info.orders && info.orders.length > 0" class="orderInfo">
    <div v-for="(item, index) in info.orders" :key="index" class="odrerItem">
      <div class="orderItem-li">
        <div style="display: flex; flex: 1;" >
          <span style="max-width: 150px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap;">{{ item.orderType === 3 ? item.appOrderId : item.dcosOrderId }}</span>
          <span style="color:#B9921A; width: 35px;"> | {{ item.installmentsFlag == 1 ? $t('分期') : $t('全款') }}</span>
        </div>
        <div style="width: 122px;">{{ item.orderCreatedTime | timeFormat('YYYY-MM-DD HH:mm') }}</div>
      </div>
      <div class="orderItem-li">
        <div>{{
          `${item.carOrderMaterial?.seriesName} ${item.carOrderMaterial?.modelName}`
        }}</div>
        <div v-if="item.orderStatus" :class="[`STATUS_`+item.orderStatus]">{{ item.orderStatus | codeFormat }}</div>
      </div>
      <div class="orderItem-li">
        <div><span class="label">{{ $t('销售顾问') }}</span> {{ item.salesUserName || "--" }}</div>
        <div><span class="label">{{ $t('交付专员') }}</span> {{ item.deliveryUserName || "--" }}</div>
        <div class="amount">
          <span content="¥"></span>{{ `${formatRMB(item.totalAmount)}` }}
        </div>
      </div>
      <div class="footer" @click="goOrderDetail(item.id)">{{ $t('查看订单详情') }}</div>
    </div>
  </div>
  <div class="card noPadding">
    <van-tabs v-model="type">
      <van-tab v-for="item in tabs" :key="item.value" :title="item.name" :name="item.componentName">
        <component :is="type" :id="id" :class="{ 'comp-tall-skill': type == 'TalkSkill' }" :clueInfo="info" :css="['without-padding']" :details="tripDetails" :active="activeCode" @change="onChangeTrip" @updateClueInfo="handleRegetClueInfo"></component>
      </van-tab>
    </van-tabs>
  </div>
  <div>
    <slot name="btns" :data="info"></slot>
  </div>
  <!-- 审核弹窗 -->
  <van-dialog v-model="isShowReview" :title="$t('审核意见')" 
          show-cancel-button 
          :cancel-button-text="$t('驳回')" 
          :confirm-button-text="$t('通过')"
          :close-on-click-overlay="true"
          :before-close="onReview">
          <van-field
            v-model="reviewRemark"
            rows="2"
            autosize
            label=""
            type="textarea"
            maxlength="50"
            :placeholder="$t('请输入审核意见')"
            show-word-limit
          />
        </van-dialog>
  <!-- 排程信息 -->
  <van-popup v-model="showScheduling" style="width:100%; max-width: 500px;height:100%">
    <UpdateVue v-if="showScheduling" :data="appointmentInfo" :showArrow="true" @confirm="confirmScheduling"  @close="showScheduling=false"></UpdateVue>
  </van-popup>
</div>
</template>
<style lang="less" scoped>
.card{
  background-color: #fff;
  border-radius: 8px;
  padding:12px;
  margin-bottom: 16px;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  &.noPadding{
    padding-top: 0;
  }
}
.label{
  color: #929798;
}
.rivalBox{
  display: inline-block;
  width: calc(100% - 110px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pageBox{
  /deep/ .noPadding{
    .comp-tall-skill {
      background-color: #f5f5f5;
      min-height: 400px;
    }
  }
  .top{
    font-size: 13px;
    position: relative;
    .status{
      position: absolute;
      top:12px;
      right:12px;
    }
    .info{
      border-top:1px solid #F3F3F4;
      margin-top:12px;
      padding-top:12px;
      position: relative;
      >div:not(.icons,.add,.tags){
        margin-bottom: 12px;
      }
      
      .tags{
        .tag{
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }
      .add{
        border-top:1px solid #F3F3F4;
        padding-top:12px;
      }      
      // .icons{
      //   position: absolute;
      //   top: 30%;
      //   right: 12px;
      //   display:flex;
      //   flex-direction: column;
      //   .icon{
      //     margin-bottom: 16px;
      //   }
      // }
    }
  }
  .orderInfo{
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
    border-radius: 8px 8px 8px 8px;
    width: 100%;
    margin-bottom: 16px;
    .odrerItem{
      box-sizing: border-box;
      font-size: 13px;
      padding: 12px;
      &:not(:last-of-type) {
        border-bottom: 1px solid #F3F3F4;
      }
      .orderItem-li{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        overflow: hidden;
      }
    }
    .footer {
      font-size: 16px;
      color: @yellow-text;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .carNumber{
    background-color: #3476FE;
    color:#fff;
    border-radius: 2px;
    margin-left: 12px;
    padding:2px 4px;
  }
}
.amount {
  font-size: 14px;
  color: #e4002c;
}
</style>
