<template>
  <van-popup
    :value="value"
    position="right"
    :lazy-render='false'
    :style="{ minWidth: '335px', width: '90%', height: '100%' }"
    @click-overlay="onClose">
    <div class="transferClueBox">
      <van-form ref="transferForm" @submit.native.prevent>
        <div class="content">
          <div class="content_item">
          <van-cell class="requiredCell" :title="$t('任务名称')"></van-cell>
          <van-field
              v-model="taskBatchObj.name"
              :required="true"
              readonly >
            </van-field>
          </div>
          <!-- 分配销售顾问 -->
          <div class="content_item">
            <van-cell class="requiredCell" :title="$t('分配销售顾问')"></van-cell>
            <van-field
              v-model="formParams.assignEmpsNames"
              :placeholder="$t('请选择销售顾问')"
              :required="true"
              :rules="[{required: true }]"
              @click="selectSales">
              <template #right-icon>
                <van-icon v-if="formParams.assignEmpsNames" name="close" @click.stop="onclean('assignEmpsNames')" />
                <van-icon v-else name="arrow" />
              </template>
            </van-field>
            <div class="tips">
              {{ `共${taskBatchObj.totalCount - taskBatchObj.assignCount}条线索未分配销售顾问；选择多位销售顾问时，默认轮循平均分配` }}
            </div>
          </div>
        </div>
        <div class="common-footer">
          <van-button class="btn back" @click="onClose">{{$t('取消')}}</van-button>
          <van-button class="btn submit" type="submit" :loading="submitLoading" @click="onSubmit">{{ $t('确定') }}</van-button>
        </div>
      </van-form>
    </div>
    <!-- 员工列表面板 -->
    <BusinessFilters
      v-if="showDistribute"
      ref="filter"
      v-model="showDistribute"
      :options="distributionOptions"
      :multiple="true"
      :defaultActiveKey="['saIdList']"
      :buttonNameReset="$t('取消')"
      @reset="showDistribute = false"
      @submit="distributionFilter"
    >
    </BusinessFilters>
  </van-popup>
</template>
<script>
import { assignTaskBatch } from '@/services/batchClueTask.js'
import loading from '@/utils/loading'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
export default {
  components: {
    BusinessFilters
  },
  props: {
    value: {
      // 是否显示
      type: Boolean,
      default: false,
    },
    taskBatchObj: {
      // 是否显示
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      submitLoading: false,
      formParams: {   
        taskBatchCode: '', 
        assignEmpsNames: '',
        users: [],
      },
      showDistribute: false
    }
  },
  computed: {
    distributionOptions() {
      return [
        {
          name: '',
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds: '',
          height: '80vh',
          roleCodes: '1014001,1014002',
        },
      ]
    },
  },
  mounted() {
  },
  methods: {
    // 选择销售顾问
    selectSales() {
      this.showDistribute = true
    },
    async distributionFilter({ saIdList }) {
      console.log('saIdList', saIdList)
      this.formParams.users = saIdList.map((item) => {
        return {
          dealerId: item.dealerId,
          userId: item.id,
          userName: item.name
        }
      })
      this.formParams.assignEmpsNames = this.formParams.users.map(({ userName }) => { return userName }).join(',')
    },
    // 清空已选择
    onclean(type) {
      this.formParams[type] = ''
      if (type === 'assignEmpsNames') {
        this.formParams.users = []
      }
    },
    // 关闭
    onClose() {
      this.$emit('close', false)
    },
    // 确定
    async onSubmit() {
      await this.$refs.transferForm.validate()
      const formParamsObj = JSON.parse(JSON.stringify(this.formParams))
      const {
        users,
      } = formParamsObj
      let params = {
        users,
        taskBatchCode: this.taskBatchObj.taskBatchCode
      }
      loading.showLoading()
      await assignTaskBatch(params)
      loading.hideLoading()
      this.$emit('transferConfirm')
    },
  }
}
</script>
<style lang="less" scoped>
.transferClueBox{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 96px;
  .mg_8{
    margin-bottom: 8px
  }
  .van-form{
    flex: 1;
    display: flex;
    overflow: hidden;
  }
  .content{
    flex: 1;
    overflow-y: auto;
    display: flex;
    padding: 0 16px 16px 16px;
    font-size: 13px;
    flex-direction: column;
    .font-12{
      font-size: 12px;
    }
    .title{
      font-size: 14px;
      font-weight: 600;
      padding: 16px 0 8px;
      border-bottom: 1px solid #ebedf0;
    }
    .content_item{
      .taskTagField{
        border: 1px solid #ebedf0
      }
      .requiredCell{
        padding: 10px 0;
        font-weight: 600;
        font-size: 13px;
        &.van-cell::after{
          display: none
        }
        &.noRequired{
          .van-cell__title{
            span::after{
              content: ' ';
            }
          }
        }
        .van-cell__title{
          span::after{
            margin-left: 2px;
            color: #EED484;
            font-size: 14px;
            content: '*';
            align-self: baseline;
          }
        }
      }
      .tips{
        font-size: 12px;
        color: #B9921A;
        margin-top: 8px;
        padding-left: 16px
      }
    }
  }
  .common-footer{
    max-width: 100%
  }
}
</style>