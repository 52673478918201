<script>
import { ImagePreview } from 'vant'
import testDriveServices from '@/services/testDrive'
import wxSDK from '@/scripts/wxsdk'
import msgIcon from '@/images/icon_msg.svg'
import appIcon from '@/images/icon_app.svg'
import wechatIcon from '@/images/icon_wechat.svg'
import loading from '@/utils/loading'
import { showReceiveGift, editReceiveGift } from '@/services/carManageService'
export default {
  data() {
    return {
      msgIcon,
      appIcon,
      wechatIcon,
      bg : 'https://7465-test-9g7knxbfd9f9e403-1308921303.tcb.qcloud.la/travel_path_placeholder.png?sign=2b0540438018d7d1d9a09665ba8d28f8&t=1663844847',
      detailData:{},
      hasReport: false,
      recordItem: [],
      driveRecordId: '',
      popupShow: false,
      shareTypeList: [
        { txt: this.$t('短信'), url: msgIcon, type: 'msg' },
        { txt: 'APP', url: appIcon, type: 'app' },
        { txt: this.$t('微信'), url: wechatIcon, type: 'wechat' },
      ]
    }
  },
  async mounted() {
    const { driveRecordId='' } = this.$route.query
    this.driveRecordId = driveRecordId
    const res = await testDriveServices.reportDetailV3({ driveId:driveRecordId })
    this.detailData = res.driveInfoResp
    this.hasReport = res.hasReport // 是否有报告
    const codeList = ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'A']
    res.recordItemResp.map((item, index) => {
      this.recordItem[index] = { ...item, interfaceCode: codeList[index] }
    })
    this.recordItem.sort((a, b) => {
      return a.interfaceCode.localeCompare(b.interfaceCode)
    })
  },
  methods:{
    goView(val, type){
      if (!val){
        if (type === 'license') return this.$toast(this.$t('未上传驾照'))
        if (type === 'contractUrl') return this.$toast(this.$t('未上传协议'))
      }
      const url = val.split('?')[0]
      const str = url.substring(url.lastIndexOf('.') + 1)
      const rules = ['jpg','png','jpeg']
      // 图片直接打开
      if (rules.includes(str.toLocaleLowerCase())) {
        ImagePreview([val])
      } else {
        if (this.$isMobile) {
          window.location.href = val
        } else {
          window.open(val, 'myWindow')
        }
      }
    },
    // 发送
    async shareReport() {
      // http://jira.inner.vwdsso.com/browse/SCRMSAL-2297
      // 发送报告之前 判断礼物领取
      // https://jira.inner.vwadsso.cc/browse/SCRMSAL-3183 移除礼品选择
      // const res = await showReceiveGift({ id: this.driveRecordId })
      // if (res) {
      //   this.$dialog.confirm({
      //     title: this.$t('提示'),
      //     message: this.$t('请确认用户是否已线下领取礼物！'),
      //     confirmButtonColor: '#B9921A',
      //     closeOnClickOverlay: true,
      //     confirmButtonText: this.$t('线上领取'),
      //     cancelButtonText: this.$t('线下领取'),
      //     beforeClose: async (action, done)=>{
      //       if (action === 'confirm') {
      //         await editReceiveGift({ id: this.driveRecordId, receiveGift: 2 })
      //         this.$set(this.detailData, 'receiveGift', 2)
      //         this.popupShow = true
      //         done()
      //       } else if (action === 'cancel') {
      //         await editReceiveGift({ id: this.driveRecordId, receiveGift: 1 })
      //         this.$set(this.detailData, 'receiveGift', 1)
      //         this.popupShow = true
      //         done()
      //       } else {
      //         done()
      //       }
      //     }
      //   })
      // } else {
      //   // 没有开启礼物领取配置 默认线上领取
      //   await editReceiveGift({ id: this.driveRecordId, receiveGift: 2 })
      //   this.$set(this.detailData, 'receiveGift', 2)
      //   this.popupShow = true
      // }
      this.popupShow = true
    },
    // 预览试驾报告
    viewReport() {
      this.$router.push({
        path: '/test-drive-report',
        query: {
          driveRecordId: this.driveRecordId
        }
      })
    },
    async shareClick({ type }) {     
      const wechatLink = `${process.env.VUE_APP_ENV === 'uat' ? 'https://uat-oneapp-web.idux-vw.com' : 'https://m.idux-vw.com'}/WXminiBridge?path=pages%2FuniversalLinks%2FuniversalLinks&query=appointmentid%3D${this.$route.query.appointmentId}%26toPage%3DSCRMLink%26type%3Dreport` // 分享链接
      const contentJson = {
        // title: `${this.detailData.modelName}${this.$t('试驾报告')}`, // 分享标题
        title: `${this.$t('ID. 与众试驾报告')}`, // 分享标题
        desc: `${this.$t('尊敬的客户，感谢体验ID. 与众，已为您生成专属试驾报告，点击开启您的试驾回顾之旅！')}`, // 分享描述
        // link: `${window.location.origin}/test-drive-report?driveRecordId=${this.driveRecordId}`, // 分享链接
        link: wechatLink,
        // imgUrl: 'https://bu5-dev-1256488924.cos.ap-chengdu.myqcloud.com/digital-ne-scrm-dev/6c479b67-6337-4bb1-848b-5302d3dc6057.png' // 分享封面
        imgUrl: `${window.location.origin}/report-share.png` // 分享封面
      }
      let params = {
        sendWay: '',
        driveReportUrl: `${window.location.origin}/test-drive-report?driveRecordId=${this.driveRecordId}`,
        driveRecordId: this.driveRecordId
      }
      switch (type) {
        case 'msg':
          params.sendWay= 1
          loading.showLoading()
          await testDriveServices.reportSend(params)
          this.$toast(this.$t('发送成功'))
          this.popupShow = false
          loading.hideLoading()
          break
        case 'app':
          params.sendWay= 2
          loading.showLoading()
          await testDriveServices.reportSend(params)
          this.$toast(this.$t('发送成功'))
          this.popupShow = false
          loading.hideLoading()
          break
        case 'wechat':
          wxSDK.shareAppMessage(contentJson)
          this.popupShow = false
          break
        default:
          break
      }
    }
  }
}
</script>
<template>
<div class="viewRecord">
  <!-- <img v-if="detailData.personalInfoCheckStatus == 1" :src="bg" class="bg" /> -->
  <!-- <div class="test-drive-info m-b-8">
    <div class="test-drive-info-item">
      <div class="test-drive-speed">{{ detailData?.report?.traveledMileage }} <span>km</span></div>
      <div class="test-drive-title">试驾里程</div>
    </div>
    <div class="test-drive-info-item">
      <div class="test-drive-speed">{{ detailData?.report?.drivingDuration }} <span>min</span></div>
      <div class="test-drive-title">试驾时长</div>
    </div>
    <div class="test-drive-info-item">
      <div class="test-drive-speed">{{ detailData?.report?.avgSpeed }} <span>km/h</span></div>
      <div class="test-drive-title">平均速度</div>
    </div>
  </div> -->

  <!-- <div class="record m-b-8">
    <div class="record-item">
      <div class="record-left">试驾订单号</div>
      <div class="record-right">{{ detailData.id }}</div>
    </div>
  </div> -->
  
  <div v-if="detailData.personalInfoCheckStatus == 0 || (detailData.personalInfoCheckStatus == 1 && detailData.routePictureUrl)" class="record m-b-16">
    <div class="record-item">
      <div class="record-left title-left">{{$t('试驾GPS轨迹图')}}</div>
    </div>
    <div class="record-item line m-b-8"></div>
    <div v-if="detailData.personalInfoCheckStatus == 0">
      <div style="width: 100%;height: 100px;display: flex;align-items: center;justify-content: center;background-color: #96979952;">
        {{ $t('客户未授权个人信息') }}
      </div>
    </div>
    <div v-else-if="detailData.personalInfoCheckStatus == 1 && detailData.routePictureUrl" class="record-item">
      <van-image :src="detailData.routePictureUrl"></van-image>
    </div>
  </div>
  <div class="record m-b-16">
    <div class="record-item">
      <div class="record-left title-left">{{$t('试驾信息')}}</div>
      <div v-if="hasReport" class="record-right title-right">
        <!-- <span @click="viewReport">{{$t('报告预览')}}</span> -->
        <span style="margin-left: 8px;" @click="shareReport">{{$t('发送报告')}}</span>
      </div>
    </div>
    <div class="record-item line m-b-8"></div>
    
    <div class="record-item">
      <!-- 试驾类型-到店试驾/上门试驾/集中试驾 -->
      <div class="record-left">{{$t('试驾类型')}}</div>
      <div class="record-right">
        {{ detailData.driveType == '2002001' ? $t('到店试驾') : detailData.driveType == '2002002' ? $t('上门试驾') : detailData.driveType == '2002003' ? $t('集中试驾') : detailData.driveType == '2002004' ? $t('当场试驾') : '' }}
      </div>
    </div>
    <div class="record-item">
      <!-- 预约方式-当场试驾/walk-in/预约试驾 -->
      <div class="record-left">{{$t('预约方式')}}</div>
      <div class="record-right">
        {{ detailData.appointmentMethod | codeFormat }}
      </div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾项目')}}</div>
      <div class="record-right">
        {{ detailData.type == '0' ? $t('试乘试驾') : detailData.type == '1' ? $t('试乘') : detailData.type == '2' ? $t('试驾') : '' }}
      </div>
    </div>
    <div class="record-item line m-b-8"></div>
    <div class="record-item">
      <div class="record-left">{{$t('预约人姓名')}}</div>
      <div class="record-right">{{ detailData.appointmentName || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('预约人电话')}}</div>
      <div class="record-right">{{ detailData.appointmentPhone || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾人姓名')}}</div>
      <div class="record-right">{{ detailData.driveCustomerName || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾人电话')}}</div>
      <div class="record-right">{{ detailData.driveCustomerPhone || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾证件号')}}</div>
      <div class="record-right">{{ detailData.driveCustomerLicenseNumber || '--' }}</div>
    </div>
    <div class="record-item line m-b-8"></div>
    <div class="record-item">
      <div class="record-left">{{$t('销售顾问')}}</div>
      <div class="record-right">{{ detailData.employeeName || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾接待人')}}</div>
      <div class="record-right">{{ detailData.testDriveReceptionist || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾车型')}}</div>
      <div class="record-right">{{ detailData.modelName }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾车辆')}}</div>
      <div class="record-right">{{ detailData.plateNumber }}</div>
    </div>
    <div class="record-item">
      <div class="record-left"></div>
      <div class="record-right">{{ detailData.vin }}</div>
    </div>
    <!-- <div class="record-item">
      <div class="record-left">{{$t('礼物领取方式')}}</div>
      <div class="record-right">{{ detailData.receiveGift == 1 ? $t('线下领取') : detailData.receiveGift == 2 ? $t('线上领取') : '--' }}</div>
    </div> -->
    <div class="record-item line m-b-8"></div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾预约时间')}}</div>
      <div class="record-right">{{ detailData?.appointmentStartTime | timeFormat('YYYY-MM-DD HH:mm')}} - {{ detailData?.appointmentEndTime | timeFormat('HH:mm') }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('实际试驾时间')}}</div>
      <div class="record-right">{{ detailData.driveStartTime | timeFormat('YYYY-MM-DD HH:mm')}} - {{ detailData.driveEndTime | timeFormat('HH:mm') }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('驾驶证')}}</div>
      <div class="record-right">
        <span class="link" @click="goView(detailData.customerLicenseUrl, 'license')">{{$t('查看照片')}}</span>
      </div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('试驾协议')}}</div>
      <div class="record-right">
        <span class="link" @click="goView(detailData.contractUrl, 'contractUrl')">{{$t('查看协议')}}</span>
      </div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('个人信息声明')}}</div>
      <div class="record-right">
        <span class="status">
          {{ detailData.personalInfoCheckStatus == 0 ? $t('未授权') : detailData.personalInfoCheckStatus == 1 ? $t('已授权') : '--' }}
        </span>
      </div>
    </div>
  </div>

  <!-- v-if="detailData.recordFiles && detailData.recordFiles.length"  -->
  <!-- <div v-if="detailData.recordFiles && detailData.recordFiles.length && detailData.personalInfoCheckStatus == 1" class="record m-b-16">
    <div class="record-item justify-content">
      <div class="record-left title-left">{{$t('试驾录音')}}</div>
    </div>

    <div class="record-item">
      <audio ref="playerRef" style="width: 100%" controls></audio>
    </div>
  </div> -->
  <div class="record m-b-16">
    <div class="record-item">
      <div class="record-left title-left">{{$t('试驾数据记录')}}</div>
    </div>
    <div class="record-item line m-b-8"></div>
    <div v-for="(item, index) in recordItem" :key="index">   
      <div class="record-item">
        <div class="record-left title">{{ item.desc }}</div>
        <div class="record-right"></div>
      </div>
      <div v-for="(dataItem, index2) in item.items" :key="index2" class="record-item">
        <div class="record-left">{{ dataItem.desc }}</div>
        <div v-if="Array.isArray(dataItem.value)">
          <div v-for="(valueItem, index3) in dataItem.value" :key="index3" class="record-right">{{ valueItem }}</div>  
        </div>
        <div v-else class="record-right">{{ dataItem.value }}</div>
      </div>
    </div>
  </div>

  <!-- <div class="record m-b-16">
    <div class="record-item">
      <div class="record-left title-left">{{$t('试驾数据记录')}}</div>
      <div class="record-right title-right"></div>
    </div>
    <div class="record-item line m-b-8"></div>
    <div class="record-item">
      <div class="record-left title">{{$t('试驾行驶数据')}}</div>
      <div class="record-right"></div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('平均时速')}}</div>
      <div class="record-right">{{ detailData?.report?.avgSpeed || 0}} KM/h</div>
    </div>

    <div class="record-item">
      <div class="record-left">{{$t('最高时速')}}</div>
      <div class="record-right">{{ detailData?.report?.maxSpeed || 0 }} KM/h</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('急加速')}}</div>
      <div class="record-right">{{ detailData?.report?.rapidAccelerate || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('Travel Assist启动')}}</div>
      <div class="record-right">{{ detailData?.report?.travelAssistStartup || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('车辆转向')}}</div>
      <div class="record-right">{{ detailData?.report?.carSteering || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('行驶时长')}}</div>
      <div class="record-right">{{ detailData?.report?.drivingDuration || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('行驶功耗')}}</div>
      <div class="record-right">{{ detailData?.report?.drivePowerConsumption || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left title">{{$t('One-App控车')}}</div>
      <div class="record-right"></div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('APP查看车辆状态')}}</div>
      <div class="record-right">{{ detailData?.report?.carStatus || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('APP查看用车报告')}}</div>
      <div class="record-right">{{ detailData?.report?.carReport || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('APP控制车辆空调')}}</div>
      <div class="record-right">{{ detailData?.report?.carAirCondition || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('APP寻找车辆')}}</div>
      <div class="record-right">{{ detailData?.report?.car || '--' }}</div>
    </div>

    <div class="record-item">
      <div class="record-left title">{{$t('车联网体检')}}</div>
      <div class="record-right"></div>
    </div>

    <div class="record-item">
      <div class="record-left">{{$t('SDS车辆控制')}}</div>
      <div class="record-right">{{ detailData?.report?.sdsCarControl || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('SDS导航使用')}}</div>
      <div class="record-right">{{ detailData?.report?.sdsNavigationUsage || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">SDS LLM</div>
      <div class="record-right">{{ detailData?.report?.sdsLlm || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('在线音乐播放')}}</div>
      <div class="record-right">{{ detailData?.report?.OnlineMusicPlayback || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('avatar商店')}}</div>
      <div class="record-right">{{ detailData?.report?.avatarStore || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('APP使用记录')}}</div>
      <div class="record-right">{{ detailData?.report?.appUsageRecord || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left title">{{$t('智能泊车')}}</div>
      <div class="record-right"></div>
    </div>

    <div class="record-item">
      <div class="record-left">{{$t('IPA泊车')}}</div>
      <div class="record-right">{{ detailData?.report?.ipaParking || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('远程泊车')}}</div>
      <div class="record-right">{{ detailData?.report?.remoteParking || '--' }}</div>
    </div>
    <div class="record-item">
      <div class="record-left title">{{$t('车辆充电')}}</div>
      <div class="record-right"></div>
    </div>
    <div class="record-item">
      <div class="record-left">{{$t('充电体验')}}</div>
      <div class="record-right">{{ detailData?.report?.chargingExperience || '--' }}</div>
    </div>
  </div> -->
  <van-popup v-model="popupShow" position="bottom">
    <div class="shareBox">
      <div v-for="(item, index) in shareTypeList" :key="index" class="share-i" @click="shareClick(item)">
        <img class="icon" :src="item.url" />
        <div>{{ item.txt }}</div>
      </div>
    </div>
  </van-popup>
</div>
</template>

<style scoped lang="less">
.viewRecord{
  padding-top: 16px;
}
.bg {
  width: calc(100% - 32px);
  margin: 16px 16px 0 16px;
  border: 2px solid #fff;
  border-radius: 8px;
}

.test-drive-info {
  display: flex;
  justify-content: space-around;
  margin: 0 16px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;

  .test-drive-info-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    .test-drive-speed {
      font-size: 30px;
      font-weight: bold;
      color: #0d171a;
      margin-bottom: 8px;
      span {
        font-size: 14px;
        color: #0d171a;
      }
    }

    .test-drive-title {
      font-size: 14px;
      color: #0d171a;
    }
  }
}

.record {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 16px;

  .record-item {
    display: flex;
    padding: 4px 0;
    align-items: center;
    justify-content: space-between;
    .record-left {
      font-size: 13px;
      color: #999;
      margin-right: 10px;
      min-width: 65px;
      max-width: 128px;
      display: flex;
      align-items: center;
      .img {
        width: 16px;
        margin-right: 8px;
      }
    }
    .record-right {
      font-size: 13px;
      color: #0d171a;
      .link {
        color: #b9921aff;
        cursor: pointer;
      }
      .status {
        font-size: 13px;
        color: #029640;
      }
    }
    .title {
      font-size: 14px;
      color: #0d171a;
    }
    .title-left {
      font-size: 16px;
      color: #0d171a;
      &::before {
        content: '';
        width: 2px;
        height: 13px;
        margin-right: 6px;
        background-color: #0040c5;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .title-right {
      font-size: 14px;
      color: #0040c5;
      span{
        color: @yellow-text;
      }
    }
  }
  .justify-content {
    justify-content: space-between;
  }
  .line {
    border-bottom: 1px solid #f0f0f0;
    // margin: 8px 0;
  }
  .m-b-8 {
    margin-bottom: 8px;
  }
}

.m-b-8 {
  margin-bottom: 8px;
}
.m-b-16 {
  margin-bottom: 16px;
}
.shareBox{
  display: flex;
  padding: 20px 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  justify-content: space-between;
  .share-i{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    .icon{
      width: 25px;
      height: 25px;
      margin-bottom: 12px;
    }
  }
}
</style>
