export default {
  '大众金融': '大众金融',
  '下单人信息': '下单人信息',
  '查看下单人信息': '查看下单人信息',
  '姓名': '姓名',
  '电话': '电话',
  '车主信息': '车主信息',
  '证件类型': '证件类型',
  '证件号码': '证件号码',
  '上牌城市': '上牌城市',
  '企业信息': '企业信息',
  '企业名称': '企业名称',
  '统一社会信用代码': '统一社会信用代码',
  '联系人': '联系人',
  '贴息金额': '贴息金额',
  '金融机构': '金融机构',
  '金融单号': '金融单号',
  '申请时间': '申请时间',
  '订单状态': '订单状态',
  '产品类型': '产品类型',
  '产品名称': '产品名称',
  '银行授权': '银行授权',
  '合同签署': '合同签署',
  '首付金额': '首付金额',
  '贷款金额': '贷款金额',
  '贷款期限': '贷款期限',
  '月供': '月供',
  '总利息': '总利息',
  '提交金融单撤销申请': '提交金融单撤销申请',
  '确认撤销当前订单的金融申请': '确认撤销当前订单的金融申请',
  '请确认订单信息是否准确，金融订单将在购车人确认后进行撤销 ？': '请确认订单信息是否准确，金融订单将在购车人确认后进行撤销 ？',
  '请求成功': '请求成功',
  '请求失败，请联系管理员': '请求失败，请联系管理员',
  '折扣金额': '折扣金额',
  '订单来源': '订单来源',
  '客户类型': '客户类型',
  '订单总金额': '订单总金额',
  '意向金': '意向金',
  '定金': '定金',
  '尾款': '尾款',
  '实际金额': '实际金额',
  '优惠券折扣明细': '优惠券折扣明细',
  '折扣备注说明': '折扣备注说明',
  '无折扣原因！': '无折扣原因！',
  '订单修改申请：': '订单修改申请：',
  '销售顾问：': '销售顾问：',
  '交付专员：': '交付专员：',
  '倒计时0小时': '倒计时0小时',
  '倒计时': '倒计时',
  '小时': '小时',
  '大客户': '大客户',
  '查看订单详情': '查看订单详情',
  '车身颜色：': '车身颜色：',
  '内饰颜色：': '内饰颜色：',
  '选装：': '选装：',
  '预计交付时间：': '预计交付时间：',
  '销售门店：': '销售门店：',
  '交付门店：': '交付门店：',
  '订单状态：': '订单状态：',
  '开发中。。。': '开发中。。。',
  '返回': '返回',
  '请选择分配人员！': '请选择分配人员！',
  '更多': '更多',
  '填写跟进': '填写跟进',
  '推送线上合同': '推送线上合同',
  '取消': '取消',
  '这是第一条选装信息': '这是第一条选装信息',
  '这是第二条选装信，可以换行展示更多的数据': '这是第二条选装信，可以换行展示更多的数据',
  '订单详情': '订单详情',
  '订单轨迹': '订单轨迹',
  '查看修改申请': '查看修改申请',
  '取消修改订单': '取消修改订单',
  '重新编辑订单': '重新编辑订单',
  '申请修改订单': '申请修改订单',
  '订单分配': '订单分配',
  '预览合同': '预览合同',
  '购车合同查看': '购车合同查看',
  '添加客户微信': '添加客户微信',
  '绑定客户微信': '绑定客户微信',
  '金融详情': '金融详情',
  '下订': '下订',
  '定金支付': '定金支付',
  '锁单': '锁单',
  '合同推送': '合同推送',
  '订单完成': '订单完成',
  '确认推送合同至客户签署 ？': '确认推送合同至客户签署 ？',
  '推送合同成功': '推送合同成功',
  '推送合同失败，请联系管理员': '推送合同失败，请联系管理员',
  '分配成功': '分配成功',
  '提示': '提示',
  '取消修改后，系统会删除修改的内容，保持原订单不变。确认取消 ？': '取消修改后，系统会删除修改的内容，保持原订单不变。确认取消 ？',
  '操作成功！': '操作成功！',
  '请选择一个外部联系人进行绑定': '请选择一个外部联系人进行绑定',
  '转交付备注': '转交付备注',
  '销售门店': '销售门店',
  '驳回': '驳回',
  '修改前：': '修改前：',
  '车系车型': '车系车型',
  '该选装无物料信息！': '该选装无物料信息！',
  '配置信息': '配置信息',
  '请选择车系车型': '请选择车系车型',
  '外饰': '外饰',
  '请选择外饰颜色': '请选择外饰颜色',
  '内饰': '内饰',
  '请选择内饰颜色': '请选择内饰颜色',
  '选装': '选装',
  '请选择选装': '请选择选装',
  '购车类型': '购车类型',
  '购车方式': '购车方式',
  '请输入姓名': '请输入姓名',
  '手机号码': '手机号码',
  '请输入手机号码': '请输入手机号码',
  '请选择证件类型': '请选择证件类型',
  '请输入证件号码': '请输入证件号码',
  '请输入企业名称': '请输入企业名称',
  '请输入联系人姓名': '请输入联系人姓名',
  '手机号': '手机号',
  '请输入手机号': '请输入手机号',
  '请输入统一社会信用代码': '请输入统一社会信用代码',
  '交付门店': '交付门店',
  '请选择交付门店': '请选择交付门店',
  '优惠券折扣明细': '优惠券折扣明细',
  '修改前金额': '修改前金额',
  '车辆总价': '车辆总价',
  '购车总价': '购车总价',
  '保存': '保存',
  '提交审核': '提交审核',
  '取消修改': '取消修改',
  '重新编辑': '重新编辑',
  '一键催办': '一键催办',
  '通过': '通过',
  '确认驳回': '确认驳回',
  '确定': '确定',
  '驳回原因': '驳回原因',
  '请填写驳回原因': '请填写驳回原因',
  '个人': '个人',
  '企业': '企业',
  '分期': '分期',
  '全款': '全款',
  '识别中...': '识别中...',
  '身份证': '身份证',
  '请选择正确的车系车型': '请选择正确的车系车型',
  '确认提交审核 ？': '确认提交审核 ？',
  '催办成功！': '催办成功！',
  '确认审批通过 ？': '确认审批通过 ？',
  '确认': '确认',
  '立即驳回': '立即驳回',
  '订单编号：': '订单编号：',
  '请输入订单编号/申请人': '请输入订单编号/申请人',
  '共': '共',
  '条数据': '条数据',
  '申请人：': '申请人：',
  '申请时间：': '申请时间：',
  '全选': '全选',
  '订单修改审核': '订单修改审核',
  '批量审核': '批量审核',
  '审核结果': '审核结果',
  '请至少选择一条订单！': '请至少选择一条订单！',
  '请输入不少于10个字的跟进记录': '请输入不少于10个字的跟进记录',
  '跟进信息': '跟进信息',
  '提交': '提交',
  '分配': '分配',
  '全部': '全部',
  '已下定': '已下定',
  '已锁单': '已锁单',
  '已签合同': '已签合同',
  '已交车': '已交车',
  '20小时（含）以内': '20小时（含）以内',
  '20-24小时': '20-24小时',
  '24小时后': '24小时后',
  '下单时间': '下单时间',
  '订单车型': '订单车型',
  '付款方式': '付款方式',
  '金融状态': '金融状态',
  '审核中': '审核中',
  '已取消': '已取消',
  '销售顾问': '销售顾问',
  '请选择待分配的订单': '请选择待分配的订单',
  '购车信息': '购车信息',
  '确认取消？': '确认取消？',
  '请上传不超过30MB的图片': '请上传不超过30MB的图片',
  '上传线下合同': '上传线下合同'
}
