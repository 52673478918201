<template>
  <div class="StockItem" @click.stop="handleClick()">
    <div class="order-num">{{data.confOrderId}}</div>
    <div class="title-box">
      <div class="name">{{data.materialJson?.seriesName}} {{ data.materialJson?.modelName }}</div>
      <div class="type-name">{{getTypeName}}</div>
    </div>

    <div class="tags">
      <template v-for="(tag, index) of getTags">
        <div  v-if="tag.value" :key="index" class="tag" :class="tag.class">{{ tag.value }}</div>
      </template>
    </div>

    <div class="lines">
      <template v-for="(item, index) of getLines">
        <div v-if="!item.isHide" :key="index" class="line">
          <div class="name">{{ item.name }}</div>
          <template v-if="item.field === 'orders'">
            <div class="value">
              <div v-for="(item1, index1) in item.value" :key="index1">{{ item1.appOrderId }}</div>
            </div>
          </template>
          <div v-else class="value">{{ item.value }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StockItem',
  props:{
    data:{
      type: Object,
      default:()=>({})
    }
  },
  computed: {
    getLines() {
      const { materialJson,salesDealerName,salesName,deliveryDealerName,vin,specialVehicleType,createTime, status, orders, configType } = this.data
      return [
        {
          name: '选装',
          value: materialJson?.optionList.map(item => { return item.optionsName }).join(',')
        },
        {
          name: '预留VIN',
          value: vin,
          isHide: status !== 5
        },
        {
          name: '车辆类型',
          value: this.getDictNameByCode(4055, specialVehicleType)
        },
        {
          name: '配置单类型',
          value: configType == 1 ? '按配置下单' : configType == 2 ? '按VIN下单' : ''
        },
        {
          name: '销售门店',
          value: salesDealerName
        },
        {
          name: '交付门店',
          value: deliveryDealerName
        },
        {
          name: '销售顾问',
          value: salesName
        },
        {
          name: '创建时间',
          value: createTime
        },
        {
          name: '关联订单编号',
          value: orders,
          isHide: status !== 15,
          field: 'orders'
        },
      ]
    },
    getTags() {
      const { materialJson } = this.data
      return [
        {
          name: '外饰',
          value: materialJson?.colourName,
          class:'blue',
        },
        {
          name: '内饰',
          value: materialJson?.interiorName,
          class:'green'
        },
      ]
    },
    getTypeName() {
      const obj={
        2: '店长审批中',
        5: '已生效',
        10:'已失效',
        15:'已关联订单',
        20:'已关闭',
      }
      return this.data.status && obj[this.data.status] || ''
    }
  },
  methods:{
    handleClick(){
      this.$emit('go')
    },
  }
}
</script>

<style lang="less" scoped>
.StockItem {
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 16px 12px 8px 12px;
  font-size: 13px;
  // margin: 10px 16px;
  .order-num {
    margin-bottom: 6px;
  }
  .title-box {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f3f3f3;
    padding: 6px 0;

    .type-name {
      font-size: 13px;
    }
  }

  .lines {
    display: flex;
    flex-wrap: wrap;
    .line {
      display: flex;
      line-height: 24px;
      width: 100%;
      // &:first-child{
      //   width: 100%;
      // }
      // &:not(:first-child){
      //   width: 50%;
      // }
      // &:last-child{
      //   width: 100%;
      // }
      .name {
        &::after {
          content: '：';
        }
      }
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    margin-bottom: 8px;
    .tag {
      padding: 2px 8px;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      margin-top: 8px;
      margin-left: 4px;
      line-height: 16px;
      font-size: 12px;

      &.blue {
        border-color: rgba(0, 64, 197, 0.1);
        color: #0040c5;
        background: rgba(0, 64, 197, 0.1);
      }
      &.green{
        color: #b9921a;
        background: rgba(185, 146, 26, 0.1);
        border-color: rgba(185, 146, 26, 0.1);
      }
      &.lightBlue {
        color: #029640;
        background: rgba(2, 150, 64, 0.1);
        border-color: rgba(2, 150, 64, 0.1);
      }
      // &:nth-of-type(4) {
      //   color: #666666;
      //   background: #efefef;
      //   border-color: #e1e1e1;
      // }
      &:last-child {
        margin-right: 4px;
      }
    }
  }
  .btns{
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    border-top: 1px solid rgba(13,23,26,0.05);
    span{
      flex:1;
      font-size: 16px;
      font-family: HYQiHei-60S, HYQiHei;
      font-weight: normal;
      color: #B9921A;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
