<template>
  <div class="clue-defeat">
    <div class="totalCount">{{$t('共')}} {{ totalCount }} {{$t('条数据')}}</div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <!-- <Item :item-data="item" :checked="commonFilterParams.queryType === 0" @change="onCheckChanged" @click="handlerTo(item)" /> -->
        <Item :item-data="item" :checked="false" @click="handlerTo(item)" />
      </template>
    </List>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import List from '@/components/baseList/list.vue'
import Item from './item.vue'
import loading from '@/utils/loading'
import { getOrderList } from '@/services/specialCar.js'
import { judgeMobile } from '@/utils/index'
import mixins from '../mixins.js'

export default {
  name: 'leadChangeInto',
  components: { List, Item },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'clueDefeat')
    })
  },
  mixins: [mixins],
  data() {
    return {
      // 列表数据源集合
      list: [],
      totalCount: 0
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    judgeMobile,
    isAll() {
      return this.checkedItems.length > 0 && this.checkedItems.length === this.list.filter(item => item.applyUserRole === this.checkedItems[0].applyUserRole).length
    },
  },
  activated() {
    this.onRefresh()
  },
  methods: {
    handlerTo(item) {
      const { id }=item
      this.$router.push({
        path: '/special-car-stock/detail',
        query:{
          id,
          entryType: 'approve'
        }
      })
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const {
        reviewTimeStart = '',
        reviewTimeEnd = '',
        applyTimeStart = '',
        applyTimeEnd =' ',
        salesUserIds = [],
        queryType,
        ...o
      } = this.commonFilterParams
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        createTime: {
          start: applyTimeStart,
          end: applyTimeEnd,
        },
        reviewTime: {
          start: reviewTimeStart,
          end: reviewTimeEnd,
        },
        salesIds: salesUserIds,
        filterType: queryType == 0 ? 1 : 2,
        ...o
      }
      loading.showLoading()
      return getOrderList(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        res.dataList.forEach((item) => {
          item.checked = false
        })
        if (obj.currentPage === 1) {
          this.list = res.dataList
          this.checkedItems = []
        } else {
          this.list = this.$refs.list.list.concat(res.dataList)
        }
        return res
      })
    },
    // 刷新
    onRefresh() {
      this.$refs.list.onRefresh()
    },
  
  }
}
</script>
<style lang="less" scoped>
  .btns-bar {
    width: 100%;
    position: absolute;
    justify-content: space-between;
    bottom: 0px;
    display: flex;
    background: #fff;
    padding-left: 16px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  
    /deep/.van-button {
      color: #0D171A !important;
      font-weight: 500;
      // width: 92px;
    }
  }
  
  .totalCount {
    color: @black;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }
</style>
  