<template>
<div :ref="`clueItem${dataSource.id}`" class="clue-item">
  <div class="clue-info" @click="$emit('goDetail')">
    <div class="user-status">
      <shortConsumerInfo :data="dataSource" :showLevel="false" :showIntention="false"></shortConsumerInfo>
      <div :class="['status']">{{ dataSource.state | codeFormat }} </div>
    </div>
    <CardOptions :data="dataSource"/>
    <div class="van-ellipsis">
      <span>{{ $t('用户来源') }}：</span>
      <span>{{ dataSource | sourceText }}</span>
    </div>
    <!-- 最新记录 -->
    <div class="van-ellipsis">
      <span>{{$t('最新记录')}}：</span>
      <span>{{ dataSource.followTime | getTime }} {{ dataSource.followTime ? '|' : ''}} {{ dataSource.followRemark || $t('暂无') }}</span>
    </div>
    <!-- 已下定 -->
    <div v-if="['2000050', '2000052'].includes(dataSource.state) && dataSource.orderCreatedTime" class="van-ellipsis">
      <span>{{ $t('下订时间') }}：</span>
      <span>{{ dataSource.orderCreatedTime }} | <span style="color: #B9921A;">{{ $t('下订') }}</span></span>
    </div>
    <!-- 逾期时长 -->
    <div v-if="$moment().diff(dataSource.planTime, 'hours') > 0" class="van-ellipsis">
      <span class="overdue">{{$t('逾期时长')}}：</span>
      <span class="overdue">
					{{ getYuQiTime(dataSource.planTime, 'overdue') }}
				</span>
    </div>
    <!-- 任务倒计时 -->
    <div v-if="dataSource.planTime && $moment().diff(dataSource.planTime, 'hours') <= 0 && $moment().diff(dataSource.planTime, 'hours') > -24" class="van-ellipsis">
      <span class="overdue">{{$t('任务倒计时')}}：</span>
      <span class="overdue">
					{{ getYuQiTime(dataSource.planTime, 'timeline') }}
				</span>
    </div>
    <div v-if="isDistributionState" class="checkBox">
      <van-checkbox
        :value="dataSource.checked"
        shape="square"
        class="check-item"
        @click.native.stop="onClick">
      </van-checkbox>
      <div>{{ dataSource|roleName }}</div>
    </div>
  </div>
  <div class="bottom">
    <!-- <div class="follow-user">{{ dataSource.followUserRoleCode | codeFormat }}：{{ dataSource.followUserName }} </div> -->
    <div class="follow-user">
      <span>{{ $t('跟进人') }}：</span>
      <span>{{ dataSource.followUserName || $t('暂无') }}</span>
    </div>
    <div class="icons">
      <externalUserIcon v-if="!dataSource.userWeChat && showIcon" :id="dataSource.id" class="icon" @selectUser="selectUser"/>
      <qwIcon v-if="dataSource.userWeChat" :leadId="dataSource.id" :externalUserIds="dataSource.userWeChat" class="icon"/>
      <wxIcon v-if="!dataSource.userWeChat && showIcon" class="icon" :phone="dataSource.userMobile"/>
      <phoneIcon
        v-if="dataSource.state !== '2000045' || (dataSource.state === '2000045' && dataSource.followUserId === userInfo.id)"
        :phone="dataSource.userMobile"
        :oneself="dataSource.followUserId === userInfo.id"
        :clueId="dataSource.id"
        class="icon"/>
    </div>
  </div>
</div>
</template>
<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import { dateFormat } from '@/utils'
import IntentionLevel from '@/components/card-view/intention-level.vue'
import CountDown from '@/modules/components/countDown'
import CardOptions from '@/components/card-view/cardOptions.vue'
import { mapGetters } from 'vuex'
let vm
export default {
  components:{ shortConsumerInfo, externalUserIcon, phoneIcon, qwIcon, wxIcon, IntentionLevel, CountDown, CardOptions },
  filters: {
    sourceText(data) {
      let arr = []
      const { channelOne, channelTwo, channelThree } = data
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      return arr.length > 0 ? arr.join('/') : vm.$t('暂无')
    },
    timeText(data){
      const year = new Date(data).getFullYear()
      const theYear = new Date().getFullYear()
      if (year!=theYear){
        return dateFormat(data, 'YYYY-MM-DD HH:mm')
      } else {
        return dateFormat(data, 'MM-DD HH:mm')
      }
    },
    roleName({ followUserRoleCode }){
      return followUserRoleCode ? {
        '1014002': 'Sales',
        '1014001': 'DCC'
      }[followUserRoleCode] + (window.navigator.language.toLocaleLowerCase().includes('en') ? ' '+vm.$t('跟进') : vm.$t('跟进'))
        : ''
    },
    getTime(val){
      if (!val) return ''
      const list = val.split(' ')[0].split('-')
      return `${list[1]}-${list[2]}`
    },
  },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    isDistributionState:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hiddenId: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    getLevel() {
      const code = this.dataSource.tryDriveLevel
      const obj = this.$store.getters.getDictHash(1012).find(item=>{
        return item.code === code
      })
      return obj && obj.name
    },
    showIcon() {
      return !['2000015', '2000020', '2000040', '2000045'].includes(this.dataSource.state)
    },
    showCountDown() {
      // 有回收时间 且当前时间大于跟进截止时间
      return this.dataSource.planRecycleTime && this.$moment().diff(this.dataSource.planTime, 'seconds') > 0
    },
    // 已下定的数据
    ordered() {
      return ['2000050','2000052', '2000055', '2000057', '2000060','2000065'].includes(this.dataSource.state)
    },
  },
  created() {
    vm = this
  },
  methods: {
    onClick(){
      this.$emit('change', !this.dataSource.checked, { ...this.dataSource })
    },
    selectUser(params={}){
      const { externalUserid,name } = params
      this.$set(this.dataSource, 'customerName', name )
      this.$set(this.dataSource, 'userWeChat', externalUserid)
    },
    deleteItem(id) {
    },
    getYuQiTime(planTime, type) {
      let flag = false
      if (type === 'overdue') {
        flag = this.$moment().diff(planTime, 'hours') > 0
      } else {
        flag = this.$moment().diff(planTime, 'hours') <= 0 && this.$moment().diff(planTime, 'hours') > -24
      }
      if (flag) {
        let diff = this.$moment().diff(planTime, 'seconds')
        if (type === 'timeline') {
          diff = Math.abs(diff)
        }
        // 将秒数转换为天和小时
        const days = Math.floor(diff / (24 * 3600))
        const hours = Math.floor((diff % (24 * 3600)) / 3600)
        const minutes = Math.ceil(((diff % (24 * 3600)) % 3600) / 60) // 向上取整
        const str1 = days ? `${days} day ` : ''
        const str2 = hours ? `${hours} h ` : ''
        const str3 = minutes ? `${minutes} min` : ''
        return str1.concat(str2).concat(str3)
      } else {
        return ''
      }
    },
  }
}
</script>
<style scoped lang="less">
@checkbox-color:#0D171A;
.clue-item{
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 16px 12px 0px 12px;
  font-size: 13px;
  .clue-info{
    position: relative;
    .user-status{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      padding-bottom: 8px;
      .status{
        width: 70px;
        text-align: right;
        // &.status_2000005{
        //   color: #0040C5
        // }
        // &.status_2000010{
        //   color: #FFD100
        // }
        // &.status_2000040,&.status_2000015{
        //   color: #E4002C
        // }
        // &.status_2000045,&.status_2000040,&.status_2000020{
        //   color: rgba(13,23,26,0.45) !important
        // }
      }
    }
    .level{
      background-color: #EED484;
      padding: 1px 4px;
      border-radius: 8px;
      display: inline-block;
      color: #0D171A;
      font-size: 10px;
      text-align: center;
      line-height: 14px;
      margin-left: 8px;
      flex-shrink: 0;
    }
    >div:not(.user-status){
      margin-bottom: 8px;
    }
  }
  .bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(13,23,26,0.05);
    min-height: 52px;
    .follow-user{
      font-size: 13px;
      color: #0D171A;
      line-height: 20px;
    }
    .icons{
      display:flex;
      padding: 8px 0 12px 0;
      justify-content: space-between;
      .icon{
        margin-left: 16px;
      }
    }
  }
  .van-ellipsis{
    color: @black;
    &.flex{
      display: flex;
      justify-content: space-between;
    }
    .title{
      color: rgba(13,23,26,0.45);
    }
    .overdue{
      color: #e4002c;
    }
  }

  .checkBox{
    position: absolute;
    right: 0px;
    top: 50%;
    // transform: translateX(-50%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    /deep/.van-checkbox__icon{
        font-size: 16px;
        .van-icon{
            border: 2px solid rgba(13,23,26,0.45);
        }
    }

    /deep/.van-checkbox__icon--checked .van-icon{
        background-color: @checkbox-color;
        border-color: @checkbox-color;
        line-height: 1;
    }
  }
}
</style>
