/* * @Author: 张超越 * @Date: 2024-03-19 09:10:14 * @Last Modified by: 张超越 *
@Last Modified time: 2024-04-25 15:57:37 */

<template>
  <div class="SelectCompeting">
      <div class="title-box">
        <div class="title">{{ title }}</div>
        <van-search v-model.trim="value" input-align="left" placeholder="请输入关键字搜索" />
      </div>
      <div class="bodyContent">
        <CommonCascader
          v-if="viewList.length"
          :items="viewList"
          :defaultValue="defaultValue"
          :nameClickCheck="true"
          :tagsInput="false"
          @change="handleChange"
        ></CommonCascader>
        <Empty v-else :style="{ width: '100%' }" />
      </div>
    <div class="footer-box">
      <div class="button" @click="handleClose">{{ $t("返回") }}</div>
      <div class="button submit" @click="handleOK">{{ $t("确定") }}</div>
    </div>
  </div>
</template>

<script>
// APIs

// Tools
import loading from '@/utils/loading'

// Components
import CommonCascader from './CommonCascader/index.vue'
import { isArray } from 'lodash'

export default {
  name: 'SelectCompeting',
  components: {
    CommonCascader,
  },
  props: {
    details: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '兴趣爱好',
    },
  },
  data() {
    return {
      value: '',
      tags: [],
      ids: [],
      defaultValue: [],
      checkedObjectArray: [],
      checkedArray: [],
      checkedTree: [],
    }
  },
  computed: {
    viewList() {
      const list = JSON.parse(JSON.stringify(this.list))
      list.sort((a, b) => a.nameEn - b.nameEn)
      if (this.value) {
        const arr = []
        list.forEach((item) => {
          const { children, name } = item
          const c = children.filter(({ name }) => name.includes(this.value))
          if (c.length || name.includes(this.value)) {
            arr.push({ ...item, children: c })
          }
        })
        return arr
      }

      return list
    },
    getTags() {
      return this.tags || []
    },
    getSelectCodes() {
      return this.ids.map((i) => i.valueCode)
    },
    getSelectObjArray() {
      // 暂时不用
      return this.checkedObjectArray.map((arr) => {
        return arr.map((i, index) => {
          const { name, value, brandNameEn, modelNameEn } = i
          let nameEn = index === 0 ? brandNameEn : modelNameEn
          return { name, value, nameEn }
        })
      })
    },
    getCheckedTree() {
      return this.checkedTree.map((i) => {
        console.info('getCheckedTree item', i)
        let { children: models, name, nameEn, value: code } = i
        if (models && isArray(models) && models.length) {
          models = models.map((j) => {
            let { name, nameEn, value: code } = j
            return {
              code,
              name,
              nameEn,
            }
          })
        }
        return {
          code,
          name,
          models,
          nameEn,
        }
      })
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let defaultValue = []
      this.details?.forEach((item) => {
        if (!item.models.length) {
          defaultValue.push([item.code])
          return
        }

        item.models.forEach((j) => {
          defaultValue.push([item.code || item.code, j.code || j.value])
        })
      })

      this.defaultValue = defaultValue
      console.info('this.defaultValue', this.defaultValue)
    },
    handleClose() {
      this.$emit('close')
    },
    handleChange(values) {
      console.info('handle change', values)
      const { checkedTree = [] } = values
      this.checkedTree = checkedTree
    },
    async handleOK() {
      console.info('getCheckedTree', this.getCheckedTree)
      this.$emit('ok', this.getCheckedTree)
    },
  },
}
</script>

<style lang="less" scoped>
.SelectCompeting {
  background-color: #f6f6f6;
  height: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  z-index: 3000;
  .title-box {
    margin: 0 25px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    .title {
      height: 48px;
      line-height: 48px;
      margin: 0 12px;
      flex: 1;
      font-size: 16px;
      color: #0d171a;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      // text-transform: capitalize;
    }
  }
  .bodyContent {
    flex: 1;
    // margin: 0 25px;
    padding-right: 25px;
    overflow-y: hidden;
    // background-color: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 8px 8px;
    margin-bottom: 16px;
  }

  .footer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: #fff;
    .button {
      flex: 1;
      text-align: center;
      height: 42px;
      line-height: 42px;
      background: #ffffff;
      border-radius: 100px 100px 100px 100px;
      border: 1px solid #0d171a;
      margin-right: 8px;
      color: #0d171a !important;

      &.submit {
        background-color: #eed484;
        border-color: #eed484;
      }
    }
  }
}
</style>
