import salesAssistantPortalLang from '@/modules/sales-assistant-portal/lang/zh-CN'
import salesAssistantLang from '@/modules/salesAssistant/lang/zh-CN'
import deliverManageLang from '@/modules/deliverManage/lang/zh-CN'
import orderLang from '@/modules/order/lang/zh-CN'
import invalidManage from '@/modules/invalidManage/lang/zh-CN'
import clueDefeat from '@/modules/clueDefeat/lang/zh-CN'
import carManage from '@/modules/carManage/lang/zh-CN'
import testDrive from '@/modules/testDrive/lang/zh-CN'
import offlineOrder from '@/modules/offlineOrder/lang/zh-CN'
import approveManage from '@/modules/approveManage/lang/zh-CH'
import charge from '@/modules/charge/lang/zh-CN'
import testDriveReport from '@/modules/testDriveReport/lang/zh-CN'
import specialCarStock from '@/modules/specialCarStock/lang/zh-CN'

export default {
  ...salesAssistantPortalLang,
  ...testDrive,
  ...salesAssistantLang,
  ...orderLang,
  ...deliverManageLang,
  ...invalidManage,
  ...clueDefeat,
  ...carManage,
  ...offlineOrder,
  ...approveManage,
  ...charge,
  ...testDriveReport,
  ...specialCarStock,
  最新跟进时间: '最新跟进时间',
  试驾意向: '试驾意向',
  购车意向: '购车意向',
  选配信息: '选配信息',
  试驾人数: '试驾人数',
  客户顾虑: '客户顾虑',
  客户顾虑补充: '客户顾虑补充',
  请输入客户顾虑补充: '请输入客户顾虑补充',
  具体取消原因: '具体取消原因',
  请输入具体取消原因: '请输入具体取消原因',
  查询: '查询',
  总部锁定状态: '总部锁定状态',
  展车: '展车',
  合同签署状态: '合同签署状态',
  实质性退单: '实质性退单',
  非实质性退单: '非实质性退单',
  退单原因: '退单原因',
  申请描述: '申请描述',
  请输入申请描述: '请输入申请描述',
  申请材料: '申请材料',
  请选择退单原因: '请选择退单原因',
  订单编号: '订单编号',
  '实质性退单表示客户有切实退单需求，而非外部因素导致需要先退单再重新下单': '实质性退单表示客户有切实退单需求，而非外部因素导致需要先退单再重新下单',
  '大区/城市区域/交付门店有联动关系；': '大区/城市区域/交付门店有联动关系；',
  '门店省份/门店城市/交付门店有联动关系；': '门店省份/门店城市/交付门店有联动关系；',
  请上传正确图片文件: '请上传正确图片文件',
  拒绝原因: '拒绝原因',
  改派前门店: '改派前门店',
  改派后门店: '改派后门店',
  请输入拒绝原因: '请输入拒绝原因',
  中央客服改派线索: '中央客服改派线索',
  中央客服: '中央客服',
  选择更多渠道: '选择更多渠道',
  最近使用渠道: '最近使用渠道',
  '请输入完整VIN号或后8位查询': '请输入完整VIN号或后8位查询',
  权益明细: '权益明细',
  权益类型: '权益类型',
  权益价值: '权益价值',
  权益数量: '权益数量',
  权益Code: '权益Code',
  权益说明: '权益说明',
  大定应用卡券明细: '大定应用卡券明细',
  卡券Code: '卡券Code',
  折扣金额: '折扣金额',
  一级类目: '一级类目',
  一级类目名称: '一级类目名称',
  二级类目: '二级类目',
  二级类目名称: '二级类目名称',
  领取试驾礼: '领取试驾礼',
  '确定发送短信，邀请客户添加企业微信好友？': '确定发送短信，邀请客户添加企业微信好友？',
  '批量短信邀请': '批量短信邀请',
  '短信加微': '短信加微',
  '添加客户': '添加客户',
  '批量添加': '批量添加',
  '添加群成员': '添加群成员',
  '执行人': '执行人',
  '注：新线索，联系客户未接通时选择': '注：新线索，联系客户未接通时选择',
  重新分配文本客服: '重新分配文本客服',
  审批状态: '审批状态',
  异常订单: '异常订单',
  审批时间: '审批时间',
  有效线索量: '有效线索量',
  无金融信息: '无金融信息',
  选装价格: '选装价格',
  选装编码: '选装编码',
  创建人: '创建人',
  保费金额: '保费金额',
  '保单确认时间/承保时间': '保单确认时间/承保时间',
  失效时间: '失效时间',
  生效时间: '生效时间',
  保单号: '保单号',
  险种类型名称: '险种类型名称',
  险种类型code: '险种类型code',
  保险信息: '保险信息',
  任务状态: '任务状态',
  任务号: '任务号',
  处理中: '处理中',
  订单明细: '订单明细',
  大定物料信息: '大定物料信息',
  物料编码: '物料编码',
  车系编码: '车系编码',
  车型编码: '车型价格',
  车型价格: '车型价格',
  外观编码: '外观编码',
  外观价格: '外观价格',
  内饰编码: '内饰编码',
  内饰价格: '内饰价格',
  数量: '数量',
  金融: '金融',
  保险: '保险',
  选装明细: '选装明细',
  '请输入客户姓名/电话查询': '请输入客户姓名/电话查询',
  请输入跟进记录: '请输入跟进记录',
  跟进历史: '跟进历史',
  '支持上传10张图片，单张最大20M': '支持上传10张图片，单张最大20M',
  确定提交: '确定提交',
  补充材料: '补充材料',
  请输入改派原因: '请输入改派原因',
  改派原因: '改派原因',
  请选择门店: '请选择门店',
  改派至门店: '改派至门店',
  省份城市: '省份城市',
  已分配门店: '已分配门店',
  门店信息: '门店信息',
  留言: '留言',
  '已达到置顶话术上限，再次置顶将按操作时间自动更新已置顶话术，请确认!': '已达到置顶话术上限，再次置顶将按操作时间自动更新已置顶话术，请确认!',
  已置顶: '已置顶',
  取消置顶: '取消置顶',
  选择或录入新增分类: '选择或录入新增分类',
  '最多支持添加8类个人分类，超出后系统将按创建时间进行更新': '最多支持添加8类个人分类，超出后系统将按创建时间进行更新',
  '新增': '新增',
  '标准话术': '标准话术',
  '个人话术': '个人话术',
  '收藏话术': '收藏话术',
  实际交付时间: '实际交付时间',
  下单人: '下单人',
  联系方式: '联系方式',
  购车人: '购车人',
  拒绝: '拒绝',
  同意: '同意',
  向您申请该客户的接待授权: '向您申请该客户的接待授权',
  接待授权审核: '接待授权审核',
  填写备注: '填写备注',
  快速填写跟进: '快速填写跟进',
  留言成功: '留言成功',
  客户省份城市: '客户省份城市',
  发送内容: '发送内容',
  短信邀请时间: '短信邀请时间',
  建群人: '建群人',
  任务生成时间: '任务生成时间',
  微信客户: '微信客户',
  批量发送: '批量发送',
  欢迎语任务: '欢迎语任务',
  重新发送: '重新发送',
  批量建群: '批量建群',
  建群任务: '建群任务',
  短信邀请加微: '短信邀请加微',
  欢迎语发送任务: '欢迎语发送任务',
  '周交付量/周目标': '周交付量/周目标',
  '月交付量/月目标': '月交付量/月目标',
  '待首次沟通': '待首次沟通',
  '待金融申请': '待金融申请',
  '待支付首付': '待支付首付',
  '待开票申请': '待开票申请',
  '待支付尾款': '待支付尾款',
  '待确认交付': '待确认交付',
  '待上传交付仪式': '待上传交付仪式',
  '待放款': '待放款',
  '留存量': '留存量',
  '周交付量': '周交付量',
  '日交付量': '日交付量',
  '月交付量': '月交付量',
  '满意度': '满意度',
  '交付单': '交付单',
  '保险未出单': '保险未出单',
  '暂无来源': '暂无来源',
  '交付排行': '交付排行',
  '预约提车': '预约提车',
  '未完成': '未完成',
  '取消收藏': '取消收藏',
  '编辑': '编辑',
  '已收藏': '已收藏',
  '收藏': '收藏',
  '修改成功！': '修改成功！',
  '新增成功！': '新增成功！',
  '适用车型': '适用车型',
  '复制': '复制',
  '更新时间': '更新时间',
  '按标题/内容/关键词搜索': '按标题/内容/关键词搜索',
  '于': '于',
  '下发到店': '下发到店',
  '点击下载': '点击下载',
  '基础素材': '基础素材',
  '暂无素材': '暂无素材',
  '发送': '发送',
  '图片': '图片',
  '视频': '视频',
  '文件': '文件',
  '图文': '图文',
  '请输入搜索关键词': '请输入搜索关键词',
  '收起': '收起',
  '展开': '展开',
  '代理商大客户': '代理商大客户',
  '厂端大客户': '厂端大客户',
  '驳回': '驳回',
  '已通过': '已通过',
  '未审核': '未审核',
  '上牌人信息': '上牌人信息',
  '企业信息': '企业信息',
  '证件号码不正确': '证件号码不正确',
  '最多18个字符仅可为字母和数字': '最多18个字符仅可为字母和数字 ',
  '请选择审核结果': '请选择审核结果',
  '请输入客户姓名/订单编号/顾问/客户手机号': '请输入客户姓名/订单编号/顾问/客户手机号',
  '请输入客户姓名/订单编号/客户手机号': '请输入客户姓名/订单编号/客户手机号',
  '已授权': '已授权',
  '未授权': '未授权',
  '已签署': '已签署',
  '未签署': '未签署',
  '个人': '个人',
  '企业': '企业',
  '分期': '分期',
  '全款': '全款',
  '暂无': '暂无',
  '提醒': '提醒',
  '超期线索': '超期线索',
  '基本信息': '基本信息',
  '客户姓名': '客户姓名',
  '联系电话': '联系电话',
  '客户微信': '客户微信',
  '客户性别': '客户性别',
  '公司名称': '公司名称',
  '是否大客户': '是否大客户',
  '常住地区': '常住地区',
  '常住城区': '常住城区',
  '居住地区': '居住地区',
  '居住小区': '居住小区',
  '详细地址': '详细地址',
  '其他联系方式1': '其他联系方式1',
  '其他联系方式2': '其他联系方式2',
  '购车信息': '购车信息',
  '其他信息': '其他信息',
  '意向车型': '意向车型',
  '预计试驾时间': '预计试驾时间',
  '试驾意向等级': '试驾意向等级',
  '购车意向等级': '购车意向等级',
  '购车时间': '购车时间',
  '共同决策人': '共同决策人',
  '共同决策人是否试驾': '共同决策人是否试驾',
  '关注竞品': '关注竞品',
  '是否置换': '是否置换',
  '置换需求': '置换需求',
  '非置换': '非置换',
  '置换': '置换',
  '是否需要安装充电桩': '是否需要安装充电桩',
  '充电桩安装地址': '充电桩安装地址',
  '是否估价': '是否估价',
  '是否有固定车位': '是否有固定车位',
  '车辆用途': '车辆用途',
  '购车用途': '购车用途',
  '车辆指标': '车辆指标',
  '用车指标': '用车指标',
  '车辆指标类型': '车辆指标类型',
  '车辆指标来源': '车辆指标来源',
  '油车指标': '油车指标',
  '电车指标': '电车指标',
  '暂无指标': '暂无指标',
  '个人购车': '个人购车',
  '公司购车': '公司购车',
  '是': '是',
  '否': '否',
  '男': '男',
  '女': '女',
  '返回': '返回',
  '保存': '保存',
  '开始时间': '开始时间',
  '结束时间': '结束时间',
  '共': '共',
  '条数据': '条数据',
  '姓名': '姓名',
  '电话': '电话',
  '请输入客户姓名': '请输入客户姓名',
  '请输入联系电话': '请输入联系电话',
  '请选择一个微信好友': '请选择一个微信好友',
  '请选择': '请选择',
  '请输入': '请输入',
  '请输入不少于10个字的跟进记录': '请输入不少于10个字的跟进记录',
  '请输入不少于10个字的跟进内容': '请输入不少于10个字的跟进内容',
  '请选择跟进方式': '请选择跟进方式',
  '请先预览合同': '请先预览合同',
  '确认推送合同至客户签署': '确认推送合同至客户签署',
  '无数据': '无数据',
  '取消': '取消',
  '确认': '确认',
  '全选': '全选',
  '分配': '分配',
  '重新分配': '重新分配',
  '全部': '全部',
  '订单待分配': '订单待分配',
  '定金待支付': '定金待支付',
  '待锁单': '待锁单',
  '合同待确认': '合同待确认',
  '待签约': '待签约',
  '车身颜色': '车身颜色',
  '内饰颜色': '内饰颜色',
  '客户信息': '客户信息',
  '销售顾问': '销售顾问',
  '订单状态': '订单状态',
  '下单时间': '下单时间',
  '订单车型': '订单车型',
  '订单车系车型': '订单车系车型',
  '付款方式': '付款方式',
  '金融状态': '金融状态',
  '审核中': '审核中',
  '已取消': '已取消',
  '重置': '重置',
  '待分配': '待分配',
  'PDI待确认': 'PDI待确认',
  '待预约': '待预约',
  '预计交车时间': '预计交车时间',
  '尾款状态': '尾款状态',
  '发票状态': '发票状态',
  '保险状态': '保险状态',
  '交付专员': '交付专员',
  '预分配': '预分配',
  '测试': '测试',
  '选装': '选装',
  '车主信息': '车主信息',
  '暂无数据': '暂无数据',
  '变更顾问': '变更顾问',
  '选择顾问': '选择顾问',
  '请输入姓名搜索': '请输入姓名搜索',
  'week': '周',
  'weeks': '周',
  '检测未通过': '检测未通过',
  '跟进记录填写': '跟进记录填写',
  '更多': '更多',
  '跟进方式': '跟进方式',
  '跟进结果': '跟进结果',
  '联系结果': '联系结果',
  '跟进记录': '跟进记录',
  '跟进内容': '跟进内容',
  '下次跟进时间': '下次跟进时间',
  '提交': '提交',
  '请选择分配人员': '请选择分配人员',
  '请选择跟进结果': '请选择跟进结果',
  '请选择联系结果': '请选择联系结果',
  '请选择无效原因': '请选择无效原因',
  '请选择战败原因': '请选择战败原因',
  '通过': '通过',
  '关键': '关键',
  '没有更多了': '没有更多了',
  '有指标': '有指标',
  '无指标': '无指标',
  '本地不限号': '本地不限号',
  '车辆信息登记': '车辆信息登记',
  '意愿确认': '意愿确认',
  '更新': '更新',
  '购买意愿确认': '购买意愿确认',
  '已确认': '已确认',
  '临牌信息登记': '临牌信息登记',
  '临牌变更': '临牌变更',
  '临牌办理确认': '临牌办理确认',
  '已办理': '已办理',
  '未办理': '未办理',
  '正式牌提车': '正式牌提车',
  '临牌失效时间': '临牌失效时间',
  '已完成': '已完成',
  '试驾中': '试驾中',
  '未执行': '未执行',
  '资料上传': '资料上传',
  '交车仪式记录': '交车仪式记录',
  '最少1张，最多拍照6张': '最少1张，最多拍照6张',
  '上传失败': '上传失败',
  '请完成上传': '请完成上传',
  '请上传正确图片文件(支持jpg/png/gif)': '请上传正确图片文件(支持jpg/png/gif)',
  '车辆状态': '车辆状态',
  'loading': '加载中...',
  '请选择车型': '请选择车型',
  '上传中': '上传中...',
  '预约试驾': '预约试驾',
  '预约到店': '预约到店',
  '修改授权': '修改授权',
  '取消授权': '取消授权',
  '待确认': '待确认',
  '审核状态': '审核状态',
  '店内公共线索领取': '店内公共线索领取',
  '同城公共线索领取': '同城公共线索领取',
  '隐私号': '隐私号',
  '大客户': '大客户',
  '普通客户': '普通客户',
  '提示': '提示',
  '该线索已授权': '该线索已授权【',
  '可接待': '】可接待',
  '授权后，他人可代替接待客户并填写一次跟进记录': '授权后，他人可代替接待客户并填写一次跟进记录',
  '授权顾问': '授权顾问',
  '请选择授权顾问': '请选择授权顾问',
  '用户来源': '用户来源',
  '最新记录': '最新记录',
  '意向信息': '意向信息',
  '审核截止时间': '审核截止时间',
  '跟进截止时间': '跟进截止时间',
  '预约信息': '预约信息',
  '预约时间': '预约时间',
  '试驾车辆': '试驾车辆',
  '预约方式': '预约方式',
  '预约车系': '预约车系',
  '轨迹': '轨迹',
  '标签': '标签',
  '话术': '话术',
  '试驾等级': '试驾等级',
  '意向等级': '意向等级',
  '来源渠道': '来源渠道',
  '预约状态': '预约状态',
  '接待顾问': '接待顾问',
  '已逾期': '已逾期',
  '线索所属变更': '线索所属变更',
  '线索状态': '线索状态',
  '逾期时长': '逾期时长',
  '取消记录': '取消记录',
  '超时自动取消': '超时自动取消',
  '授权类型': '授权类型',
  '订单号': '订单号',
  '车型': '车型',
  '总金额': '总金额',
  '定金金额': '定金金额',
  '尾款金额': '尾款金额',
  '已支付金额': '已支付金额',
  '待支付金额': '待支付金额',
  '元': '元',
  '分配门店': '分配门店',
  '修改方式': '修改方式',
  '预约信息修改前': '预约信息（修改前）',
  '预约信息修改后': '预约信息（修改后）',
  '修改信息修改前': '修改信息（修改前）',
  '修改信息修改后': '修改信息（修改后）',
  '线索归属': '线索归属',
  '意向金': '意向金',
  '跟进备注': '跟进备注',
  '原跟进人': '原跟进人',
  '回收记录': '回收记录',
  '共享原因': '共享原因',
  '备注': '备注',
  '订单所属变更': '订单所属变更',
  '退订原因': '退订原因',
  '退订金额': '退订金额',
  '关注车型': '关注车型',
  '操作时间': '操作时间',
  '、': '、',
  'router': {
    'clueManagement': '线索明细',
    'clueDowngradingManagement': '线索降级管理'
  },
  '(已逾期)': '(已逾期)',
  '转交顾问': '转交顾问',
  '跟进事项': '跟进事项',
  '审核结果': '审核结果',
  '审核备注': '审核备注',
  '订单变化': '订单变化',
  '领取类型': '领取类型',
  '分配结果': '分配结果',
  '到店时间': '到店时间',
  '到店人数': '到店人数',
  '是否参加活动': '是否参加活动',
  '预约信息（修改前）': '预约信息（修改前）',
  '预约信息（修改后）': '预约信息（修改后）',
  '试驾车型（修改后）': '试驾车型（修改后）',
  '试驾方式': '试驾方式',
  '是否预约': '是否预约',
  '试驾车型（修改前）': '试驾车型（修改前）',
  '试驾时间（修改前）': '试驾时间（修改前）',
  '试驾时间（修改后）': '试驾时间（修改后）',
  '试驾车型': '试驾车型',
  '试驾时间': '试驾时间',
  '试驾活动': '试驾活动',
  '线索信息': '线索信息',
  '添加': '添加',
  '绑定': '绑定',
  '请选择来源渠道': '请选择来源渠道',
  '客户类型': '客户类型',
  '请选择意向信息': '请选择意向信息',
  '请选择分配顾问': '请选择分配顾问',
  '请选择下次跟进时间': '请选择下次跟进时间',
  '完善客户信息': '完善客户信息',
  '暂存': '暂存',
  '保存并跟进': '保存并跟进',
  '请完善车系车型': '请完善车系车型',
  '请完善车系': '请完善车系',
  '线索验证中': '线索验证中',
  '该线索还未分配跟进人，是否领取 ？': '该线索还未分配跟进人，是否领取 ？',
  '领取': '领取',
  '领取成功': '领取成功',
  '您正在跟进该客户，无需重复创建，是否去填写跟进记录？': '您正在跟进该客户，无需重复创建，是否去填写跟进记录？',
  '去填写': '去填写',
  '】正在跟进该客户，不允许重复创建，您是否去填写跟进记录？': '】正在跟进该客户，不允许重复创建，您是否去填写跟进记录？',
  '由于您未及时跟进，该线索已被回收至门店公共池，不允许重复创建': '由于您未及时跟进，该线索已被回收至门店公共池，不允许重复创建',
  '店内公共池已存在同号码线索，不允许重复创建，是否直接领取？': '店内公共池已存在同号码线索，不允许重复创建，是否直接领取？',
  '店内公共池已存在同号码线索，不允许重复创建；由于您无原跟进同角色权限（': '店内公共池已存在同号码线索，不允许重复创建；由于您无原跟进同角色权限（',
  '），无法直接领取': '），无法直接领取',
  '请仅选择一个外部联系人进行绑定': '请仅选择一个外部联系人进行绑定',
  '无效联系人，请重新选择': '无效联系人，请重新选择',
  '请选择正确的车系车型': '请选择正确的车系车型',
  '请完成必填字段的填写后再提交': '请完成必填字段的填写后再提交',
  '创建成功': '创建成功',
  '请选择试驾意向等级': '请选择试驾意向等级',
  '跟进': '跟进',
  '截止时间：': '截止时间：',
  '授权他人接待': '授权他人接待',
  '级': '级',
  '置换车辆': '置换车辆',
  '创建时间': '创建时间',
  '无': '无',
  '有车位': '有车位',
  '培育人员': '培育人员',
  'DCC专员': 'DCC专员',
  '不支持修改': '不支持修改',
  '保存成功': '保存成功',
  '请输入您的跟进备注': '请输入您的跟进备注',
  '跟进备注成功': '跟进备注成功',
  '缺少线索id': '缺少线索id',
  '跟进信息': '跟进信息',
  '完善线索信息': '完善线索信息',
  '提交审核': '提交审核',
  '跟进成功': '跟进成功',
  '购车意向评级': '购车意向评级',
  '预约事项': '预约事项',
  '本人是否试驾选配': '本人是否试驾选配',
  '竞品了解程度': '竞品了解程度',
  '进店时间': '进店时间',
  '进店人数': '进店人数',
  '线索管理': '线索管理',
  '公共线索': '公共线索',
  '请选择待分配的线索': '请选择待分配的线索',
  '请输入客户姓名/电话/顾问/隐私号/线索ID': '请输入客户姓名/电话/顾问/隐私号/线索ID',
  '今日待跟进': '今日待跟进',
  '逾期待跟进': '逾期待跟进',
  '新建线索': '新建线索',
  '店内线索查询': '店内线索查询',
  '店内商机查询': '店内商机查询',
  '3日内待跟进': '3日内待跟进',
  '7日内待跟进': '7日内待跟进',
  '请选择同类型角色跟进的线索': '请选择同类型角色跟进的线索',
  '待首跟': '待首跟',
  '未排程': '未排程',
  '已排程': '已排程',
  '已试驾': '已试驾',
  '已下定': '已下定',
  '已锁单': '已锁单',
  '已签合同': '已签合同',
  '已交车': '已交车',
  '跟进中': '跟进中',
  '状态': '状态',
  '是否加微': '是否加微',
  '已加微': '已加微',
  '未添加': '未添加',
  '试驾情况': '试驾情况',
  '试驾1次': '试驾1次',
  '试驾多次': '试驾多次',
  '是否试驾': '是否试驾',
  '是否即将回收': '是否即将回收',
  '线索创建时间': '线索创建时间',
  '顾问': '顾问',
  '最新跟进时间：': '最新跟进时间：',
  '无效原因': '无效原因',
  '请输入完整的电话号码': '请输入完整的电话号码',
  '来源渠道：': '来源渠道：',
  '意向信息：': '意向信息：',
  '跟进人：': '跟进人：',
  '跟进人': '跟进人',
  '查看详情': '查看详情',
  '申请填写更多': '申请填写更多',
  '确定领取该线索': '确定领取该线索',
  '立即领取': '立即领取',
  '确定向【': '确定向【',
  '】申请客户接待授权？': '】申请客户接待授权？',
  '申请成功': '申请成功',
  '第一个值必须大于等于': '第一个值必须大于等于',
  '第二个值必须大于等于': '第二个值必须大于等于',
  '第二个值必须大于第一个值': '第二个值必须大于第一个值',
  '待回访': '待回访',
  '待回访状态': '待回访状态',
  '首次待回访': '首次待回访',
  '继续回访': '继续回访',
  '已回访': '已回访',
  '回访结果': '回访结果',
  '战败': '战败',
  '激活': '激活',
  ' 至 ': ' 至 ',
  '请先选择经销商门店': '请先选择经销商门店',
  '请选择一个外部联系人进行绑定': '请选择一个外部联系人进行绑定',
  '隐私号码复制成功': '隐私号码复制成功',
  '请使用手机外呼': '请使用手机外呼',
  '版本过低请升级': '版本过低请升级',
  '绑定客户微信': '绑定客户微信',
  '添加微信': '添加微信',
  '号码复制成功': '号码复制成功',
  '查看更多>': '查看更多>',
  '置换客户': '置换客户',
  '复制成功': '复制成功',
  '待分配线索': '待分配线索',
  '请输入客户姓名、电话': '请输入客户姓名、电话',
  '留资线索': '留资线索',
  '预约试驾线索': '预约试驾线索',
  '下发时间': '下发时间',
  '2小时前': '2小时前',
  '1小时前': '1小时前',
  '自定义': '自定义',
  '店内超期': '店内超期',
  '同城超期': '同城超期',
  '首次超期': '首次超期',
  '跟进中超期': '跟进中超期',
  '领取并分配': '领取并分配',
  '原跟进': '原跟进',
  '回收时间': '回收时间',
  '预约待确认': '预约待确认',
  '正在跟进线索': '正在跟进线索',
  '无需领取': '无需领取',
  '分配成功': '分配成功',
  '无法跳转': '无法跳转',
  '请输入审核意见': '请输入审核意见',
  '开始试驾': '开始试驾',
  '预约排程': '预约排程',
  '修改预约': '修改预约',
  '取消预约': '取消预约',
  '取消提车预约': '取消提车预约',
  '审核': '审核',
  '到店接待': '到店接待',
  '排程待确认': '排程待确认',
  '待试驾': '待试驾',
  '预约类型': '预约类型',
  '预约车型信息': '预约车型信息',
  '请选择分配线索': '请选择分配线索',
  '请输入客户姓名/电话': '请输入客户姓名/电话',
  '试驾用户': '试驾用户',
  '预约试驾时间': '预约试驾时间',
  '实际试驾时间': '实际试驾时间',
  '试驾地点': '试驾地点',
  '试驾协议': '试驾协议',
  '个人信息保护声明': '个人信息保护声明',
  '审核意见': '审核意见',
  '取消备注': '取消备注',
  '超时取消': '超时取消',
  '主动取消': '主动取消',
  '试驾报告': '试驾报告',
  '查看报告': '查看报告',
  '试驾详情': '试驾详情',
  '试驾类型': '试驾类型',
  '取消试驾': '取消试驾',
  '修改试驾': '修改试驾',
  '确认出席': '确认出席',
  '结束试驾': '结束试驾',
  '试驾回访': '试驾回访',
  '请选择试驾车': '请选择试驾车',
  '请选择上门试驾地址并填写详细地址': '请选择上门试驾地址并填写详细地址',
  '请选择时间': '请选择时间',
  '请确认，结束时间要大于开始时间': '请确认，结束时间要大于开始时间',
  '已预约': '已预约',
  '时间段已有以下排程，': '时间段已有以下排程，',
  '已预约信息': '已预约信息',
  '请选择试驾地点': '请选择试驾地点',
  '试驾车': '试驾车',
  '今': '今',
  '开始': '开始',
  '结束': '结束',
  '选择时间': '选择时间',
  '周日': '日',
  '周一': '一',
  '周二': '二',
  '周三': '三',
  '周四': '四',
  '周五': '五',
  '周六': '六',
  '预约到店日期': '预约到店日期',
  '已超时': '已超时',
  '待到店': '待到店',
  '已到店': '已到店',
  '战败原因': '战败原因',
  '暂无评级结果': '暂无评级结果',
  '试驾信息': '试驾信息',
  '预约车型': '预约车型',
  '活动名称': '活动名称',
  '排程': '排程',
  '到店试驾': '到店试驾',
  '上门试驾': '上门试驾',
  '集中试驾': '集中试驾',
  '年': '年',
  '月': '月',
  '日': '日',
  'DCC': 'DCC',
  '下定时间': '下定时间',
  '下定': '下定',
  '查看订单详情': '查看订单详情',
  '跟进人员': '跟进人员',
  '试驾状态': '试驾状态',
  '首页': '首页',
  '用户': '用户',
  '工作台': '工作台',
  '下订时间': '下订时间',
  '下订': '下订',
  '跟进量': '跟进量',
  '客流量': '客流量',
  '实际': '实际',
  '目标': '目标',
  '差值': '差值',
  '已试驾未选配': '已试驾未选配',
  '未试驾未选配': '未试驾未选配',
  '请选择所在地区': '请选择所在地区',
  '自然进店': '自然进店',
  '门店新建': '门店新建',
  '今天': '今天',
  '手机号复制成功': '手机号复制成功',
  '天': '天',
  '小时': '小时',
  '线索分配到店时间': '线索分配到店时间',
  '报告生成中，请稍后查看': '报告生成中，请稍后查看',
  '修改成功': '修改成功',
  '修改失败': '修改失败',
  '确定': '确定',
  '请确认用户是否已线下领取礼物！': '请确认用户是否已线下领取礼物！',
  '线上领取': '线上领取',
  '线下领取': '线下领取',
  '目标门店': '目标门店',
  '转移原因': '转移原因',
  '申请信息': '申请信息',
  '请输入11位的手机号': '请输入11位的手机号',
  '长期战败': '长期战败',
  '认领': '认领',
  '立即认领': '立即认领',
  '认领成功': '认领成功',
  '该留资客户已在本店战败，请直接认领跟进': '该留资客户已在本店战败，请直接认领跟进',
  '请选择明确付款方式': '请选择明确付款方式',
  '非常明确：客户已经明确付款方式，比如：全款/分期/融资租赁': '非常明确：客户已经明确付款方式，比如：全款/分期/融资租赁',
  '较明确：有选择付款倾向但并不太了解分期所需首付、月供、资质等基本概念': '较明确：有选择付款倾向但并不太了解分期所需首付、月供、资质等基本概念',
  '一般：知道几种付款方式但无选择倾向': '一般：知道几种付款方式但无选择倾向',
  '不太明确：对各种付款方式均不了解': '不太明确：对各种付款方式均不了解',
  '请选择竞品了解程度': '请选择竞品了解程度',
  '非常明确：准确说出竞品品牌和车型且已线下体验（进店/试驾）': '非常明确：准确说出竞品品牌和车型且已线下体验（进店/试驾）',
  '较明确：有竞品品牌和车型，但未线下体验（进店/试驾）': '较明确：有竞品品牌和车型，但未线下体验（进店/试驾）',
  '一般：仅有竞品的品牌，并无明确车型': '一般：仅有竞品的品牌，并无明确车型',
  '不太明确：不了解任何竞品': '不太明确：不了解任何竞品',
  '暂存成功': '暂存成功',
  '预计购车时间': '预计购车时间',
  '竞品品牌车型': '竞品品牌车型',
  '竞品关注点': '竞品关注点',
  '昨天': '昨天',
  '线索已转出至其他门店，无法查看': '线索已转出至其他门店，无法查看',
  '交付方式': '交付方式',
  '到店交付': '到店交付',
  '上牌信息': '上牌信息',
  '上牌指标': '上牌指标',
  '发票信息': '发票信息',
  '发票抬头': '发票抬头',
  '备注特殊资源需求': '备注特殊资源需求',
  '特殊资源备注': '特殊资源备注',
  '请准确填写客户的特殊资源备注，以便于OTD调整资源': '请准确填写客户的特殊资源备注，以便于OTD调整资源',
  '客户需求资料上传': '客户需求资料上传',
  '返回试驾报告首页': 'Return to home page',
  '辅助驾驶': 'Assisted Driving',
  '全旅程驾驶辅助': 'Travel Assist 3.0',
  '交互式辅助变道': 'Trigger Lane Change',
  '请上传客户需求资料': '请上传客户需求资料',
  '提交后，OTD会按需要调整资源配置，确定继续提交？': '提交后，OTD会按需要调整资源配置，确定继续提交？',
  '查看更多': '查看更多',
  '结束试驾成功': '结束试驾成功',
  '申请开票': '申请开票',
  '开票信息查看': '开票信息查看',
  '查看资料': '查看资料',
  '操作成功': '操作成功',
  '请选择交车时间': '请选择交车时间',
  '交付跟进': '交付跟进',
  '预约验车': '预约验车',
  '车系': '车系',
  '外饰颜色': '外饰颜色',
  '点检结果': '点检结果',
  '请根据要求对车辆进行拍照': '请根据要求对车辆进行拍照',
  '外观': '外观',
  '内饰': '内饰',
  '电量': '电量',
  '正前方': '正前方',
  '左前侧': '左前侧',
  '正后方': '正后方',
  '右后侧': '右后侧',
  '前排位置': '前排位置',
  '后排位置': '后排位置',
  '请到手机端上传!': '请到手机端上传!',
  '查看详情>': '查看详情>',
  '已点检': '已点检',
  '未及时点检': '未及时点检',
  '点检人': '点检人',
  '点检时间': '点检时间',
  '发票号': '发票号',
  '开票时间': '开票时间',
  '呼入': '呼入',
  '呼出': '呼出',
  '请拍摄上传': '请拍摄上传',
  '照片': '照片',
  '业务继承': '业务继承',
  '好友继承': '好友继承',
  '群主继承': '群主继承',
  '继承历史': '继承历史',
  '最后跟进时间：': '最后跟进时间：',
  '选择人员': '选择人员',
  '已选择': '已选择',
  '条': '条',
  '请至少选择一条数据！': '请至少选择一条数据！',
  '离职': '离职',
  '请输入员工名称': '请输入员工名称',
  '请选择员工！': '请选择员工！',
  '人': '人',
  '创建时间：': '创建时间：',
  '请输入离职员工名称': '请输入离职员工名称',
  '继承业务类型：': '继承业务类型：',
  '分配人：': '分配人：',
  '分配时间：': '分配时间：',
  '线索': '线索',
  '订单': '订单',
  '交付': '交付',
  '继承状态：': '继承状态：',
  '完成时间：': '完成时间：',
  '接替完毕': '接替完毕',
  '等待接替': '等待接替',
  '客户拒绝': '客户拒绝',
  '接替成员客户达上限': '接替成员客户达上限',
  '群名称：': '群名称：',
  '群人数：': '群人数：',
  '试驾礼品': '试驾礼品',
  '无领取资格': '无领取资格',
  '未发送': '未发送',
  '已发未领': '已发未领',
  '已领取': '已领取',
  '试驾礼品领取方式': '试驾礼品领取方式',
  '线下领取拍照': '线下领取拍照',
  '请上传不超过10MB的图片': '请上传不超过10MB的图片',
  '请上传线下领取照片': '请上传线下领取照片',
  '请拍摄一张客户领取试驾礼品的照片': '请拍摄一张客户领取试驾礼品的照片',
  '收货人': '收货人',
  '手机号码': '手机号码',
  '收货地址': '收货地址',
  '修改礼品': '修改礼品',
  '请选择要修改的礼品！': '请选择要修改的礼品！',
  '订单信息': '订单信息',
  '试驾礼变更记录': '试驾礼变更记录',
  '原试驾礼品': '原试驾礼品',
  '修改后礼品': '修改后礼品',
  '变更结果': '变更结果',
  '试驾礼抽奖': '试驾礼抽奖',
  '活动规则': '活动规则',
  '获奖记录': '获奖记录',
  '点击抽取试驾礼': '点击抽取试驾礼',
  '恭喜您获得': '恭喜您获得',
  '礼品一份': '礼品一份',
  '请填写您的收件地址！': '请填写您的收件地址！',
  '请输入收货人姓名': '请输入收货人姓名',
  '请输入收货人手机号': '请输入收货人手机号',
  '请输入收货人详细地址': '请输入收货人详细地址',
  '省市区': '省市区',
  '请选择省市区': '请选择省市区',
  '领取成功！': '领取成功！',
  '发送成功！': '发送成功！',
  '试驾礼品抽奖': '试驾礼品抽奖',
  '开发中。。。': '开发中。。。',
  '该试驾单已抽取过试驾礼！': '该试驾单已抽取过试驾礼！',
  '订单创建时间：': '订单创建时间：',
  '支付状态': '支付状态',
  '收款账号': '收款账号',
  '车辆PDI结果': '车辆PDI结果',
  '车辆PDI检测请求已于': '车辆PDI检测请求已于',
  '正在等待售后系统反馈PDI结果': '正在等待售后系统反馈PDI结果',
  '首付金额': '首付金额',
  '贷款金额': '贷款金额',
  '开户行': '开户行',
  '未申请': '未申请',
  '撤销金融申请': '撤销金融申请',
  '客户订单为分期订单，还未签署金融合同，请使用工作台中的金融计算器协助客户尽快完成金融合同办理。': '客户订单为分期订单，还未签署金融合同，请使用工作台中的金融计算器协助客户尽快完成金融合同办理。',
  '关闭': '关闭',
  '整备完成': '整备完成',
  '整备确认': '整备确认',
  '整备未完成': '整备未完成',
  '风险': '风险',
  '客户详细地址': '客户详细地址',
  '交付时间预约': '交付时间预约',
  '交付轨迹': '交付轨迹',
  '交付任务': '交付任务',
  '接待门店': '接待门店',
  '内容': '内容',
  '交付状态': '交付状态',
  '客户跟进': '客户跟进',
  '请先完成车辆发票任务并上传发票': '请先完成车辆发票任务并上传发票',
  '失败': '失败',
  '异地交付信息': '异地交付信息',
  '异地交付方式': '异地交付方式',
  '交付地点': '交付地点',
  '导航距离里程': '导航距离里程',
  '请从客户所在地政府所在地导航到交付门店所在位置': '请从客户所在地政府所在地导航到交付门店所在位置',
  '请导航到客户详细地址': '请导航到客户详细地址',
  '驳回原因': '驳回原因',
  '重新发起申请': '重新发起申请',
  '提交申请': '提交申请',
  '请输入驳回原因': '请输入驳回原因',
  '创建': '创建',
  '店长': '店长',
  '区域': '区域',
  '总部': '总部',
  '请上传正确图片文件(支持jpg/png)': '请上传正确图片文件(支持jpg/png)',
  '文件不能超过': '文件不能超过',
  '客户预约交付时间': '客户预约交付时间',
  '下午': '下午',
  '上午': '上午',
  '交付工位超限提示': '交付工位超限提示',
  '确认排程': '确认排程',
  '另选时间': '另选时间',
  '您选择交付数量和时段': '您选择交付数量和时段',
  '交付数量': '交付数量',
  '预约时段': '预约时段',
  '您所选择的交付时间，已经超过门店交付工位上限，工位上限为': '您所选择的交付时间，已经超过门店交付工位上限，工位上限为',
  '当前已预约': '当前已预约',
  '确认继续在该时段进行交付': '确认继续在该时段进行交付',
  'PDI检测结果': 'PDI检测结果',
  '检测人员': '检测人员',
  '检测完成时间': '检测完成时间',
  '检测委托书编号': '检测委托书编号',
  '再次PDI': '再次PDI',
  '同一合同下还有以下订单可以申请开票，是否进行批量开票': '同一合同下还有以下订单可以申请开票，是否进行批量开票',
  '上传发票文件(上传发票时，请注意确认信息)': '上传发票文件(上传发票时，请注意确认信息)',
  '未排产': '未排产',
  '已排产': '已排产',
  '已下线': '已下线',
  '已发运': '已发运',
  '已入库': '已入库',
  '已PDI': '已PDI',
  '已整备': '已整备',
  '判定有效/无效时间': '判定有效/无效时间',
  '线索有效性': '线索有效性',
  '待定': '待定',
  '有效': '有效',
  '无效': '无效',
  '充电桩': '充电桩',
  '服务包': '服务包',
  '交车时间': '交车时间',
  '车辆合格证上传': '车辆合格证上传',
  '车辆与交付中心门头合影': '车辆与交付中心门头合影',
  '购车发票': '购车发票',
  '购车发票已同步': '购车发票已同步',
  '业务分类': '业务分类',
  '试驾': '试驾',
  '企微任务': '企微任务',
  '跟进时间': '跟进时间',
  '首次待跟进': '首次待跟进',
  '待跟进': '待跟进',
  '即将逾期': '即将逾期',
  '战败回访': '战败回访',
  '待签署合同': '待签署合同',
  '待推送合同': '待推送合同',
  '交付回访': '交付回访',
  '加微任务': '加微任务',
  '添加群成员任务': '添加群成员任务',
  '实际执行人': '实际执行人',
  '任务跟进人': '任务跟进人',
  '截止时间': '截止时间',
  '实际完成时间': '实际完成时间',
  '逾期': '逾期',
  '确定时间': '确定时间',
  '确定日期': '确定日期',
  '今日': '今日',
  '请选择日期': '请选择日期',
  '请选择起止时间': '请选择起止时间',
  '确认取消当前客户的预约提车？': '确认取消当前客户的预约提车？',
  '车辆状态告知书': '车辆状态告知书',
  '提车确认书': '提车确认书',
  '驳回时间': '驳回时间',
  '故障描述': '故障描述',
  '维修建议': '维修建议',
  '委托书信息': '委托书信息',
  '进店日期': '进店日期',
  '服务顾问': '服务顾问',
  '超期未提车': '超期未提车',
  '请上传附件': '请上传附件',
  '复制信息': '复制信息',
  '交付单预览': '交付单预览',
  '交付工具': '交付工具',
  '无车位': '无车位',
  '【提车确认书线下签署】确认书': '【提车确认书线下签署】确认书',
  '车辆状况告知书': '车辆状况告知书',
  '被驳回,请重新上传': '被驳回,请重新上传',
  '当前交付单存在风险，需签署车辆状态告知书': '当前交付单存在风险，需签署车辆状态告知书',
  '销售顾问交接备注': '销售顾问交接备注',
  '更新资料': '更新资料',
  '已分配待处理': '已分配待处理',
  '承保': '承保',
  '顾问呼出': '顾问呼出',
  '客户呼入': '客户呼入',
  '关联进店客户': '关联进店客户',
  '通话时长：': '通话时长：',
  '未接通': '未接通',
  '线下确认书签署': '线下确认书签署',
  '质损交车': '质损交车',
  '请选择上传客户签署完成的车辆状态告知书': '请选择上传客户签署完成的车辆状态告知书',
  '已被驳回，请': '已被驳回，请',
  '重新上传': '重新上传',
  '交付单已锁定,暂无法操作': '交付单已锁定,暂无法操作',
  '待支付': '待支付',
  '发票到店时间': '发票到店时间',
  '距离里程': '距离里程',
  '费用信息': '费用信息',
  '里程数（公里）': '里程数（公里）',
  '预估费用（元）': '预估费用（元）',
  '实际花费（元）': '实际花费（元）',
  '质损车': '质损车',
  '上传导航截图': '上传导航截图',
  '上传费用图片': '上传费用图片',
  '车辆存放地': '车辆存放地',
  '运营单位法人代表姓名': '运营单位法人代表姓名',
  '运营单位法人代表手机': '运营单位法人代表手机',
  '运营单位地址': '运营单位地址',
  '运营地址': '运营地址',
  '车辆充电桩地址': '车辆充电桩地址',
  '定金已支付': '定金已支付',
  '已推合同': '已推合同',
  '试驾礼状态': '试驾礼状态',
  '申请时间：': '申请时间：',
  '申请人：': '申请人：',
  '新建申请': '新建申请',
  '礼品报缺清单': '礼品报缺清单',
  '删除': '删除',
  '报缺礼品名称': '报缺礼品名称',
  '请填写报缺礼品名称': '请填写报缺礼品名称',
  '请填写报缺礼品数量(0-999)': '请填写报缺礼品数量(0-999)',
  '请填写正确报缺礼品数量': '请填写正确报缺礼品数量',
  '车辆信息查看': '车辆信息查看',
  '可选择省份城市过滤交付门店，或输入门店名称模糊查询': '可选择省份城市过滤交付门店，或输入门店名称模糊查询',
  '门店省份': '门店省份',
  '门店城市': '门店城市',
  '门店名称': '门店名称',
  '选择省份': '选择省份',
  '选择城市': '选择城市',
  '请选择门店省份': '请选择门店省份',
  '请选择门店城市': '请选择门店城市',
  '请输入门店名称': '请输入门店名称',
  '未处理': '未处理',
  '已处理': '已处理',
  '查询更多门店': '查询更多门店',
  '到店指引': '到店指引',
  '其他': '其他',
  '当日外卖消费小票与车架号合影、本地居住证明、本地社保卡等，以上材料任一即可。': '当日外卖消费小票与车架号合影、本地居住证明、本地社保卡等，以上材料任一即可。',
  '发票更新': '发票更新',
  '授权交付': '授权交付',
  '2-64位字符': '2-64位字符',
  '2-72位中文或英文字符': '2-72位中文或英文字符',
  '当前订单': '当前订单',
  '关联订单': '关联订单',
  '合并线索': '合并线索',
  '该线索有未关闭的预约单，不可发起线索合并': '该线索有未关闭的预约单，不可发起线索合并',
  '确认发送短信，邀请客户添加企业微信好友 ？': '确认发送短信，邀请客户添加企业微信好友 ？',
  '发送短信成功': '发送短信成功',
  '大客户试驾车': '大客户试驾车',
  '选择合并至目标线索': '选择合并至目标线索',
  '请输入完整电话号码/客户姓名搜索目标线索': '请输入完整电话号码/客户姓名搜索目标线索',
  '注：功能适用于，人工识别出不同的两条线索为同一个客户。合并后，只需要跟进目标线索，被合并的线索自动战败（微信线索与电话线索合并，默认战败微信线索）': '注：功能适用于，人工识别出不同的两条线索为同一个客户。合并后，只需要跟进目标线索，被合并的线索自动战败（微信线索与电话线索合并，默认战败微信线索）',
  '请选择合并目标线索': '请选择合并目标线索',
  '线索需要同属一个顾问跟进才可合并': '线索需要同属一个顾问跟进才可合并',
  '下一步': '下一步',
  '合并后，被合并线索自动战败，确定继续合并？': '合并后，被合并线索自动战败，确定继续合并？',
  '请输入合并原因': '请输入合并原因',
  '待分配订单': '待分配订单',
  '开票结果': '开票结果',
  '上传发票时，请注意VIN号等信息一致': '上传发票时，请注意VIN号等信息一致',
  '发票尚未同步': '发票尚未同步',
  '发票尚未同步，暂不支持上传': '发票尚未同步，暂不支持上传',
  '资料提交': '资料提交',
  '合并至': '合并至',
  '线索ID': '线索ID',
  '合并原因': '合并原因',
  '试乘试驾信息登记': '试乘试驾信息登记',
  '身份证号': '身份证号',
  '领取试驾礼品': '领取试驾礼品',
  '开启试驾盲盒': '开启试驾盲盒',
  '签署方式': '签署方式',
  '送车上门信息': '送车上门信息',
  '送车上门申请': '送车上门申请',
  '厂端分配': '厂端分配',
  '原交付店': '原交付店',
  '新交付店': '新交付店',
  '合同签署时间': '合同签署时间',
  '试驾场景': '试驾场景',
  'Block信息': 'Block信息',
  '性别': '性别',
  '请选择性别': '请选择性别',
  '是否了解电车': '是否了解电车',
  '任务截止时间': '任务截止时间',
  '是否逾期': '是否逾期',
  '收款账户': '收款账户',
  '任务创建时间': '任务创建时间',
  '锁单时间': '锁单时间',
  '线索明细': '线索明细',
  '销售工具': '销售工具',
  '预约明细': '预约明细',
  'Dshowroom': 'Dshowroom',
  '申请退单': '申请退单',
  '订单状态：': '订单状态：',
  '金融状态：': '金融状态：',
  '申请原因': '申请原因',
  '请输入申请原因': '请输入申请原因',
  '申请待料': '申请待料',
  '请上传正确图片文件，单张最大20M': '请上传正确图片文件，单张最大20M',
  '请上传正确文件附件，单个最大30M': '请上传正确文件附件，单个最大30M',
  '图片至多20张，单张不超过20M，文件至多10个，单个不超过30M': '图片至多20张，单张不超过20M，文件至多10个，单个不超过30M',
  '取消申请': '取消申请',
  '确认取消申请？': '确认取消申请？',
  '确认提交退单申请？': '确认提交退单申请？',
  '上传图片': '上传图片',
  '上传附件': '上传附件',
  '退单申请：': '退单申请：',
  '查看退单申请': '查看退单申请',
  '撤回后，可以修改申请信息重新提交审核，若客户无需退单，则可以撤回后取消退单申请': '撤回后，可以修改申请信息重新提交审核，若客户无需退单，则可以撤回后取消退单申请',
  '请输入撤回原因': '请输入撤回原因',
  '请输入取消原因': '请输入取消原因',
  '取消则视为客户不需要退单，维持原订单信息': '取消则视为客户不需要退单，维持原订单信息',
  '销售门店': '销售门店',
  '交付门店': '交付门店',
  '撤销原因': '撤销原因',
  '取消原因': '取消原因',
  '审核岗位': '审核岗位',
  '退单审批全流程节点：': '退单审批全流程节点：',
  '流程发起': '流程发起',
  '城市经理审批': '城市经理审批',
  '大区经理审批': '大区经理审批',
  'SO审批': 'SO审批',
  '财务审批': '财务审批',
  '申请门店': '申请门店',
  '申请类型': '申请类型',
  '订单变更': '订单变更',
  '退单': '退单',
  '同配置换车': '同配置换车',
  '注：': '注：',
  '战败原因补充': '战败原因补充',
  '请输入战败补充原因': '请输入战败补充原因',
  '已解决': '已解决',
  '单号：': '单号：',
  '未解决': '未解决',
  '工单信息': '工单信息',
  '提交结果': '提交结果',
  '请选择跟进人': '请选择跟进人',
  '请输入跟进结果': '请输入跟进结果',
  '确认提交': '确认提交',
  '请确认是否已经完成了对低满的处理，信息提交后将不能进行修改': '请确认是否已经完成了对低满的处理，信息提交后将不能进行修改',
  '跟进结果：': '跟进结果：',
  '门店名称：': '门店名称：',
  '下载待签署合同': '下载待签署合同',
  '提交确认': '提交确认',
  '请再次确认以下信息是否已正常填写，此信息提交后将不可更改!': '请再次确认以下信息是否已正常填写，此信息提交后将不可更改!',
  '电子合同编号：': '电子合同编号：',
  '上传前请确认系统展示的订单信息与上传的合同内呈现的内容一致': '上传前请确认系统展示的订单信息与上传的合同内呈现的内容一致',
  '请务必确保图片从左到右显示顺序依次对应线下合同的第1页，第2页，第3页...保证后续读取图片生成的合同文件正确。': '请务必确保图片从左到右显示顺序依次对应线下合同的第1页，第2页，第3页...保证后续读取图片生成的合同文件正确。',
  '合同为图片': '合同为图片',
  '合同为文件': '合同为文件',
  '图片至多20张，单张不超过20M，文件至多1个，单个不超过30M': '图片至多20张，单张不超过20M，文件至多1个，单个不超过30M',
  '线下合同审批节点：提交审核-店长/Master审核-城市经理审核': '线下合同审批节点：提交审核-店长/Master审核-城市经理审核',
  '重新提交审核': '重新提交审核',
  '查看线下合同': '查看线下合同',
  '购车相关协议': '购车相关协议',
  '下载失败，请稍后重试！': '下载失败，请稍后重试！',
  '客户选择线下签署合同时，请在"更多"下载待签署合同，签署合同前请确认合同内容与订单信息一致': '客户选择线下签署合同时，请在"更多"下载待签署合同，签署合同前请确认合同内容与订单信息一致',
  '请选择回访结果': '请选择回访结果',
  '店长/Master审核中': '店长/Master审核中',
  '城市经理审核中': '城市经理审核中',
  '确认取消？': '确认取消？',
  '继续激活': '继续激活',
  '该留资客户已存在本店并无效，请直接认领跟进': '该留资客户已存在本店并无效，请直接认领跟进',
  '长期无效': '长期无效',
  '获奖时间': '获奖时间',
  '获奖礼品': '获奖礼品',
  '收货人信息': '收货人信息',
  '下单方式': '下单方式',
  '线下下单': '线下下单',
  '线上下单': '线上下单',
  '请选择筛选条件后，点击‘查询’按钮筛选数据，': '请选择筛选条件后，点击‘查询’按钮筛选数据，',
  '大区/城市区域/销售门店有联动关系；': '大区/城市区域/销售门店有联动关系；',
  '门店省份/门店城市/销售门店有联动关系；': '门店省份/门店城市/销售门店有联动关系；',
  '但区域与门店省份城市不联动，支持按区域或省份城市查询账号权限范围内的数据。': '但区域与门店省份城市不联动，支持按区域或省份城市查询账号权限范围内的数据。',
  '大区': '大区',
  '城市区域': '城市区域',
  '请至少选择一项': '请至少选择一项',
  '审核时请重点检查系统实时展示的订单信息与上传的合同内呈现的订单信息一致': '审核时请重点检查系统实时展示的订单信息与上传的合同内呈现的订单信息一致',
  '请确保按照下载时的电子版合同签署，并按照原页码及顺序，逐页上传签署完成的合同': '请确保按照下载时的电子版合同签署，并按照原页码及顺序，逐页上传签署完成的合同',
  '请登录SCRM系统PC端，或企业微信电脑版，进入订单详情页面后下载合同': '请登录SCRM系统PC端，或企业微信电脑版，进入订单详情页面后下载合同',
  '线下合同申请：': '线下合同申请：',
  '任务倒计时': '任务倒计时',
  '关联已有线索': '关联已有线索',
  '创建新线索': '创建新线索',
  '关联微信客户': '关联微信客户',
  '确认关联微信客户与线索 ？': '确认关联微信客户与线索 ？',
  '创建微信线索任务': '创建微信线索任务',
  '【待首跟】线索：若跟进任务已逾期，分配后自动生成2小时跟进任务；跟进任务未逾期，分配后任务截止时间不变。' : '【待首跟】线索：若跟进任务已逾期，分配后自动生成2小时跟进任务；跟进任务未逾期，分配后任务截止时间不变。',
  '【战败待审核】【无效待审核】线索：无需生成回访任务；需要您进入“审批中心”及时审批。': '【战败待审核】【无效待审核】线索：无需生成回访任务；需要您进入“审批中心”及时审批。',
  '已下单线索：重新分配销售顾问后，订单的任务截止时间不变。': '已下单线索：重新分配销售顾问后，订单的任务截止时间不变。',
  '分配销售顾问': '分配销售顾问',
  '有顾问保持不变，无顾问则分配至指定销售顾问': '有顾问保持不变，无顾问则分配至指定销售顾问',
  '全部分配至指定销售顾问': '全部分配至指定销售顾问',
  '请选择销售顾问': '请选择销售顾问',
  '【待跟进】线索下次跟进时间': '【待跟进】线索下次跟进时间',
  '指定下次跟进时间': '指定下次跟进时间',
  '保持原跟进时间不变': '保持原跟进时间不变',
  '若设置的时间大于线索原下次跟进时间，则以原下次跟进时间为准': '若设置的时间大于线索原下次跟进时间，则以原下次跟进时间为准',
  '【战败】线索回访任务': '【战败】线索回访任务',
  '分配立即生成回访任务': '分配立即生成回访任务',
  '不生成任务': '不生成任务',
  '请选择回访时间': '请选择回访时间',
  '【无效】线索回访任务': '【无效】线索回访任务',
  '同步线索企业微信好友': '同步线索企业微信好友',
  '同步转移': '同步转移',
  '不转移': '不转移',
  '同步转移企业微信好友关系会将原顾问跟该线索的企业微信好友关系解除新顾问自动添加该客户好友(若客户拒绝则添加失败)，该客户会收到顾问变更的消息提醒。': '同步转移企业微信好友关系会将原顾问跟该线索的企业微信好友关系解除新顾问自动添加该客户好友(若客户拒绝则添加失败)，该客户会收到顾问变更的消息提醒。',
  '分配后生成的跟进任务名称': '分配后生成的跟进任务名称',
  '可标记该批任务为某活动邀约等': '可标记该批任务为某活动邀约等',
  '选择日期': '选择日期',
  '退款中': '退款中',
  '战败线索': '战败线索',
  '无效线索': '无效线索',
  '最新战败时间：': '最新战败时间：',
  '最新战败原因：': '最新战败原因：',
  '最新无效时间：': '最新无效时间：',
  '最新无效原因：': '最新无效原因：',
  '最新战败时间': '最新战败时间',
  '是否长期战败': '是否长期战败',
  '是否长期无效': '是否长期无效',
  '店内超期：在总部启用线索回收规则时，店内未跟进的线索逾期，且超过缓冲期（新线索缓冲期为2小时，持续跟进的缓冲期为24小时），触发回收规则后被回收到店内超期池的线索, 需要店长及时重新分配销售顾问。': '店内超期：在总部启用线索回收规则时，店内未跟进的线索逾期，且超过缓冲期（新线索缓冲期为2小时，持续跟进的缓冲期为24小时），触发回收规则后被回收到店内超期池的线索, 需要店长及时重新分配销售顾问。',
  '同城超期：店内超期的线索，城市经理将其同城共享后，同城的其他门店可主动领取为店内线索。': '同城超期：店内超期的线索，城市经理将其同城共享后，同城的其他门店可主动领取为店内线索。',
  '公共线索池': '公共线索池',
  '客户不愿被联系': '客户不愿被联系',
  '请选择是否长期战败': '请选择是否长期战败',
  '选择是，线索战败后不生成战败回访任务': '选择是，线索战败后不生成战败回访任务',
  '标记为长期战败，线索战败后不生成战败回访任务': '标记为长期战败，线索战败后不生成战败回访任务',
  '无效回访': '无效回访',
  '以下微信客户还未创建或关联线索，需及时创建线索': '以下微信客户还未创建或关联线索，需及时创建线索',
  '若跟进任务已逾期，分配后自动生成2小时跟进任务；跟进任务未逾期，分配后任务截止时间不变。': '若跟进任务已逾期，分配后自动生成2小时跟进任务；跟进任务未逾期，分配后任务截止时间不变。',
  '特殊车（非一车一价）': '特殊车（非一车一价）',
  '普通商品车': '普通商品车',
  '订单类型细分': '订单类型细分',
  '订单类型': '订单类型',
  'C端订单': 'C端订单',
  '大客户订单': '大客户订单',
  '经销商试驾车': '经销商试驾车',
  '特殊车（一车一价）': '特殊车（一车一价）',
  '订单修改审批流程节点（未修改交付门店时，只需店长审批）：': '订单修改审批流程节点（未修改交付门店时，只需店长审批）：',
  '申请发起 - 店长审批 - 城市经理审批 - 城市经理审批（跨区）': '申请发起 - 店长审批 - 城市经理审批 - 城市经理审批（跨区）',
  '注：修改成功后，系统会自动向客户推送新合同；请务必在发起修改订单前完成相关折扣补贴的申请': '注：修改成功后，系统会自动向客户推送新合同；请务必在发起修改订单前完成相关折扣补贴的申请',
  '若修改了配置或交付门店，申请提交后，OTD会在一定时间内为您预留新车辆资源；为确保预留资源有效，请及时提醒审批人完成审批': '若修改了配置或交付门店，申请提交后，OTD会在一定时间内为您预留新车辆资源；为确保预留资源有效，请及时提醒审批人完成审批',
  '注：特殊车订单暂不支持修改配置和交付门店': '注：特殊车订单暂不支持修改配置和交付门店',
  '车辆所有人信息': '车辆所有人信息',
  '请上传申请材料': '请上传申请材料',
  '不超过20张图片，单张不大于20M': '不超过20张图片，单张不大于20M',
  '该订单的新配置无资源，请联系客户修改配置': '该订单的新配置无资源，请联系客户修改配置',
  '我知道了': '我知道了',
  '订单配置无资源': '订单配置无资源',
  '重新推送线上合同': '重新推送线上合同',
  '注：特殊车订单暂不支持修改配置信息': '注：特殊车订单暂不支持修改配置信息',
  '注：特殊车订单暂不支持修改交付门店': '注：特殊车订单暂不支持修改交付门店',
  '注：未联系上客户，会根据归属顾问排班生成4小时后的任务': '注：未联系上客户，会根据归属顾问排班生成4小时后的任务',
  '未试驾': '未试驾',
  '最新一次到店时间': '最新一次到店时间',
  '新购': '新购',
  '增购': '增购',
  '换购': '换购',
  '最新一次战败原因': '最新一次战败原因',
  '是否保存心愿单': '是否保存心愿单',
  '最新一次试驾时间': '最新一次试驾时间',
  '最新一次战败时间': '最新一次战败时间',
  '输入任务名称模糊查询': '输入任务名称模糊查询',
  '输入任务名称模糊查询/批量任务编号': '输入任务名称模糊查询/批量任务编号',
  '任务名称': '任务名称',
  '您可在该页面查看总部用户或店内圈选线索创建的任务，': '您可在该页面查看总部用户或店内圈选线索创建的任务，',
  '总部下发的任务若还未分配顾问': '总部下发的任务若还未分配顾问',
  '（圈选的线索有顾问则默认原顾问跟进，无顾问则需店长指定），': '（圈选的线索有顾问则默认原顾问跟进，无顾问则需店长指定），',
  '请及时分配销售顾问，避免逾期，未分配顾问的任务在任务明细中无法查看到' : '请及时分配销售顾问，避免逾期，未分配顾问的任务在任务明细中无法查看到',
  '销售门店：': '销售门店：'
}
