<template>
  <div class="noteSpecial">
    <van-form ref="form" :labelWidth="100" input-align="right" error-message-align="right" @submit.native.prevent>
      <van-cell :title="$t('特殊资源备注')" class="required"></van-cell>
      <van-field
        v-model="submitParams.specialRemark"
        required
        label=""
        rows="4"
        autosize
        type="textarea"
        maxlength="200"
        :rules="[{required: true}]"
        :placeholder="$t('请准确填写客户的特殊资源备注，以便于OTD调整资源')"
        show-word-limit>
      </van-field>
      <van-collapse v-model="activeNames" style="padding: 16px;" :right-icon="false">
        <van-collapse-item class="form-collapse-item special-collapse-item" name="1" :title="$t('客户需求资料上传')">
          <van-field
            label="">
            <template #input>
              <van-uploader
                v-model="submitParams.fileList"
                class="upload"
                accept="image/*"
                :preview-options="{closeable: true}"
                :before-read="beforeRead"
                :after-read="(file) => afterRead(file)"
                :before-delete="(file) => beforeDelete(file)"
                :max-count="5"/>
            </template>
          </van-field>
        </van-collapse-item>
      </van-collapse>
      <!-- <van-cell :title="$t('客户需求资料上传')"></van-cell>
      <van-field
        label="">
        <template #input>
          <van-uploader
            v-model="submitParams.fileList"
            class="upload"
            capture="camera"
            accept="image/*"
            :preview-options="{closeable: true}"
            :before-read="beforeRead"
            :after-read="(file) => afterRead(file)"
            :before-delete="(file) => beforeDelete(file)"
            :max-count="5"/>
        </template>
      </van-field> -->
      <div class="common-footer">
        <van-button class="btn back" native-type="button" @click.stop="cancel">{{ $t('取消') }}</van-button>
        <van-button class="btn submit" type="submit" @click="onSubmit">{{ $t('提交') }}</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import orderServices from '@/services/orderServices'
import loading from '@/utils/loading'
export default {
  data() {
    return {
      submitParams: {
        specialRemark: '',
        fileList: []
      },
      submitLoading: false,
      orderDetail: {},
      activeNames: [],
    }
  },
  async mounted() {
    const res = await orderServices.getOrderDetail({ id: this.$route.query.id })
    this.orderDetail = res
    const { specialRemark, specialRemarkUrl } = res
    this.submitParams.specialRemark = specialRemark
    const specialRemarkUrlList = specialRemarkUrl.split(',')
    specialRemarkUrlList.forEach(item => {
      this.submitParams.fileList.push(
        {
          uuid: uuidv4(),
          cosUrl: item,
          url: item,
          status: 'done'
        }
      )
    })
  },
  methods: {
    // 图片校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/jpeg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      if (file.size > 30 * 1024 * 1024) {
        this.$toast.fail(this.$t('请上传不超过30MB的图片'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.submitParams.fileList = this.submitParams.fileList.filter(item => item.uuid !== file.uuid)
    },
    // 取消
    cancel() {
      this.$dialog.confirm({
        title: '',
        message: this.$t('确认取消？'),
        confirmButtonColor: '#B9921A',
      }).then(() => {
        this.$router.go(-1)
      })
    },
    onSubmit() {
      this.$refs.form.validate().then(async() => {
        let fileList = this.submitParams.fileList || []
        // 已经签名的图片 需要截取掉签名信息之后 再传给后端
        fileList = fileList.map(({ cosUrl }) => {
          return cosUrl?.indexOf('?q-sign-algorithm') == -1 ? cosUrl : cosUrl.slice(0, cosUrl.indexOf('?q-sign-algorithm'))
        })
        const params = {
          orderId: this.$route.query.id,
          specialRemark: this.submitParams.specialRemark,
          specialRemarkUrl: fileList.join(',')
        }
        this.$dialog.confirm({
          title: '',
          message: this.$t('提交后，OTD会按需要调整资源配置，确定继续提交？'),
          confirmButtonColor: '#B9921A',
        }).then(async() => {
          if (this.submitLoading) return false
          loading.showLoading()
          this.submitLoading = true
          try {
            const result = await orderServices.specialRemark(params)
            this.$toast(this.$t('操作成功'))
            setTimeout(() => {
              this.$router.go(-1)
              loading.hideLoading()
              this.submitLoading = false
            }, 1000)
          } catch {
            loading.hideLoading()
            this.submitLoading = false
          }
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.noteSpecial{
  padding: 16px;
  padding-bottom: 96px;
  .van-form{
    background: #fff;
    .upload{
      // margin: 8px 0 0 8px;
    }
    .required{
      /deep/ .van-cell__title{
        span::after{
          content: '*';
          color: #EED484;
        }
      }
    }
    /deep/.van-field__control--right{
      justify-content: flex-start;
    }
    /deep/ .van-collapse{
      .van-collapse-item__title{
        padding-left: 0;
        padding-right: 0;
      }
      .van-collapse-item__content{
        // padding: 12px 0;
      }
      .van-cell{
        padding: 0;
      }
    }
  }
}
</style>