import i18nFun from '../router_i18n'
const appName = i18nFun.clueManagement
const routes = [
  {
    path: '/dshowRoomh5',
    name: 'DshowRoom',
    component: () => import('@/modules/Dshowroom/index'),
    meta: {
      title: i18nFun['智能巡店'],
      appName,
      routerPath: 'dshowRoomh5'
    }
  },
  {
    path: '/dshowRoomh5Factory',
    name: 'DshowRoom',
    component: () => import('@/modules/Dshowroom/index'),
    meta: {
      title: i18nFun['智能巡店'],
      appName,
      routerPath: 'dshowRoomh5Factory'
    }
  },
]

export default routes
