<template>
  <div class="car-detail">
    <!-- 基础信息 -->
    <div v-if="![5, 10, 15, 20].includes(detailInfo.status)" class="basic-info">
      <div class="card">
        <div class="info">
          <span class="label">配置单审批节点：</span>
          <span class="val">发起申请 - 店长审批</span>
        </div>
        <div v-if="detailInfo.status === 2" class="info error-tips">
          <span>申请状态：</span>
          <span>{{ detailInfo.status | getTypeName }}</span>
        </div>
      </div>
    </div>
    <div class="basic-info">
      <div class="hr">配置信息</div>
      <div class="card">
        <div v-if="this.detailInfo.status !== 2" class="info">{{ detailInfo.confOrderId }}</div>
        <div class="info">
          <span class="val">{{ detailInfo.materialJson.seriesName }} {{ detailInfo.materialJson.modelName }}</span>
          <template v-if="entryType === 'approve'">
            <span v-if="detailInfo.applyStatus === 1" class="tag">待审批</span>
            <span v-if="detailInfo.applyStatus === 10" class="tag">通过</span>
            <span v-if="detailInfo.applyStatus === 20" class="tag">拒绝</span>
          </template>
          <template v-else>
            <span v-if="detailInfo.status === 5" class="tag">已生效</span>
            <span v-if="detailInfo.status === 10" class="tag">已失效</span>
            <span v-if="detailInfo.status === 20" class="tag">已关闭</span>
          </template>
        </div>
        <div class="info">
          <span class="label">{{$t('外饰：')}}</span>
          <span class="val">{{ detailInfo.materialJson.colourName }}</span>
        </div>
        <div class="info">
          <span class="label">{{$t('内饰：')}}</span>
          <span class="val">{{ detailInfo.materialJson.interiorName }}</span>
        </div>
        <div class="info">
          <span class="label">{{$t('选装：')}}</span>
          <div class="val">
            <div v-for="(optionsItem, index) in (detailInfo.materialJson.optionList || [])" :key="index">
              {{ `${detailInfo.materialJson.optionList.length > 1 ? index+1 + '、' : ''}${ detailInfo.confOrderId || entryType === 'operate' ? optionsItem.optionsName : optionsItem.dictName || ''}` }}
            </div>
          </div>
        </div>
        <div class="info">
          <span class="label">车辆类型:</span>
          <span class="val">{{ getDictNameByCode(4055, detailInfo.specialVehicleType) }}</span>
        </div>
        <div class="info">
          <span class="label">配置单类型:</span>
          <span class="val">{{ detailInfo.configType == 1 ? '按配置下单' : detailInfo.configType == 2 ? '按VIN下单' : '' }}</span>
        </div>
        <div v-if="detailInfo.configType == 1 && ![5, 10, 15, 20].includes(detailInfo.status)" class="info">
          <div class="tag">审批通过后，OTD将按车辆资源分配规则为您预留车辆资源</div>
        </div>
        <div v-if="detailInfo.configType == 2 && ![5, 10, 15, 20].includes(detailInfo.status)" class="info">
          <span class="label">下单VIN：</span>
          <span class="val">{{ detailInfo.vin }}</span>
        </div>
        <!--  详情展示 -->
        <template v-if="detailInfo.confOrderId && detailInfo.status !== 2 && entryType !== 'approve' && entryType !== 'operate'">
          <div class="info">
            <span class="label">交付门店：</span>
            <span class="val">{{ detailInfo.deliveryDealerName }}</span>
          </div>
          <div class="info">
            <span class="label">创建人：</span>
            <span class="val">{{ detailInfo.salesDealerName }} | {{ detailInfo.salesName }}</span>
          </div>
          <div class="info">
            <span class="label">创建时间：</span>
            <span class="val">{{ detailInfo.createTime }}</span>
          </div>
          <div v-if="detailInfo.status !== 20" class="info">
            <span class="label">有效期至：</span>
            <span class="val">{{ detailInfo.planInvalidTime }}</span>
          </div>
          <!-- 只在已生效的状态下显示   -->
          <div v-if="detailInfo.status === 5" class="info">
            <span class="label">预留VIN：</span>
            <span class="val">{{ detailInfo.vin }}</span>
          </div>
          <!-- 已关联订单 -->
          <template v-if="detailInfo.status === 15">
            <div class="info">
              <span class="label">关联订单编号：</span>
              <div :key="index" class="val">
                <div v-for="(item, index) in detailInfo.orders" :key="index" style="display: flex;justify-content: space-between;">
                  <span>{{ item.appOrderId }}</span>
                  <span style="color: #c59e25;cursor: pointer;" @click="goOrdeDetail(item)">查看订单详情>></span>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <!-- 创建时展示/详情不展示 -->
    <!-- <div v-if="!detailInfo.id" class="basic-info">
      <div class="hr">申请信息</div>
      <div class="card">
        <div class="info">
          <span class="label">{{$t('申请人')}}：</span>
          <span class="val">{{ detailInfo.id ? detailInfo.salesDealerName : dealerName }} | {{ detailInfo.id ? detailInfo.salesName : userInfo.name }}</span>
        </div>
        <div class="info">
          <span class="label">{{$t('交付门店')}}：</span>
          <span class="val">{{ detailInfo.id ? detailInfo.deliveryDealerName : detailInfo.deliveryStoreName }}</span>
        </div>
      </div>
    </div> -->
    <div v-if="![5, 10, 15, 20].includes(detailInfo.status) || entryType === 'approve'" class="refundOrder-item basic-info">
      <div class="hr">申请信息</div>
      <van-form ref="form" input-align="right" error-message-align="right" class="formBox" @submit.native.prevent>
        <van-field :label="$t('申请人')">
          <template #input>
            <span>{{ detailInfo.confOrderId ? detailInfo.salesDealerName : dealerName }} | {{ detailInfo.confOrderId ? detailInfo.salesName : userInfo.name }}</span>
          </template>
        </van-field>
        <van-field :label="$t('交付门店')">
          <template #input>
          <span>{{ detailInfo.confOrderId ? detailInfo.deliveryDealerName : detailInfo.deliveryStoreName }}</span>
          </template>
        </van-field>
        <van-field
          v-model="detailInfo.applyReason"
          :required="showEdit"
          show-word-limit
          :label="$t('申请描述')"
          :placeholder="$t('请输入申请描述')"
          :rules="[{ required: showEdit }]"
          maxlength="200"
          :readonly="!showEdit"
          rows="4"
          autosize
          type="textarea"
        />
        <van-field :label="$t('申请材料')" readonly></van-field>
        <div :class="['uploadBox', !showEdit ? 'uploadBox_notEdit' : '']">
          <div class="imgBox">
            <van-uploader
              :disabled="!showEdit"
              :fileList="imgList"
              accept="image/*"
              :deletable="showEdit"
              :preview-options="{ closeable: true }"
              :before-read="(file) => beforeRead(file, 'img')"
              :after-read="(file) => afterRead(file, 'img')"
              :before-delete="(file) => beforeDelete(file, 'img')"
              :upload-text="$t('上传图片')"
              :max-count="20"/>
          </div>
          <div v-if="showEdit" class="tips">至多可上传20张图片，单张不超过20M</div>
        </div>
      </van-form>
    </div>
    <!-- 在配置单已失效时显示 -->
    <div v-if="[10, 20].includes(detailInfo.status) && entryType !== 'approve'" class="basic-info">
      <div class="hr">操作记录</div>
      <div class="card">
        <div class="info">
          <span class="label">{{ detailInfo.status === 10 ? '失效时间：' : '关闭时间：' }}</span>
          <span class="val">{{ detailInfo.status === 10 ? detailInfo.invalidTime : detailInfo.updateTime }}</span>
        </div>
        <div class="info">
          <span class="label">操作人：</span>
          <span class="val">{{ detailInfo.invalidByName }}</span>
        </div>
        <div class="info">
          <span class="label">备注：</span>
          <span class="val">{{ detailInfo.invalidReason }}</span>
        </div>
      </div>
    </div>
    <div v-if="[10, 20].includes(detailInfo.applyStatus) && entryType === 'approve'" class="basic-info">
      <div class="hr">审批记录</div>
      <div class="card">
        <div class="info">
          <span class="label">审批时间：</span>
          <span class="val">{{ detailInfo.reviewTime }}</span>
        </div>
        <div class="info">
          <span class="label">审批人：</span>
          <span class="val">{{ detailInfo.reviewerName }}</span>
        </div>
        <div v-if="detailInfo.applyStatus === 20" class="info">
          <span class="label">拒绝原因：</span>
          <span class="val">{{ detailInfo.invalidReason }}</span>
        </div>
      </div>
    </div>
    <div v-if="detailInfo.status === 5 && entryType !== 'approve'" class="basic-info">
      <div class="hr">下单二维码</div>
      <div class="card">
        <div class="qrcode-box">
          <div class="qrcode">
            <div>
              <van-image width="10rem" height="10rem" :src="detailInfo.qrCodeUrl">
                <template v-slot:error>加载失败</template>
              </van-image>
              <div class="btns">
                <span class="btn" @click="downloadImg">{{ judgePc ? '长按二维码可分享或保存' : '保存图片' }}</span>
              </div>
            </div>
            <div class="valid-time">
              <div>有效期至</div>
              <div>{{ detailInfo.planInvalidTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="detailInfo.applyStatus !== 1 && entryType !== 'approve'">
      <div v-if="!detailInfo.confOrderId || detailInfo.status === 2" class="error-tips">注：提交申请后，请及时提醒店长完成审批；在店长审批完成后，系统才会正式为您预留车辆资源</div>
      <div v-if="!detailInfo.confOrderId || detailInfo.status === 2" class="error-tips">审批通过后，请及时将下单二维码提供至客户下单，超过时限二维码将会失效同时会释放车辆资源</div>
      <div v-if="detailInfo.status === 5" class="error-tips">请及时将下单二维码提供至客户下单，超过有效期二维码将会失效同时会释放车辆资源</div>
      <div v-if="detailInfo.status === 5" class="error-tips">请务必引导客户从分享的链接或二维码下单，才可确保提交的是特殊车订单;客户下定后您可在订单列表查看到特殊车标签   </div>
    </template>
    <template v-if="detailInfo.applyStatus === 1">
      <div v-if="!detailInfo.confOrderId || detailInfo.status === 2" class="error-tips">注：提交申请后，请及时提醒店长完成审批；在店长审批完成后，系统才会正式为您预留车辆资源</div>
      <div v-if="!detailInfo.confOrderId || detailInfo.status === 2" class="error-tips">审批通过后，请及时将下单二维码提供至客户下单，超过时限二维码将会失效同时会释放车辆资源</div>
    </template>
    <!-- 创建-取消/立即创建 -->
    <!-- 详情-取消/发送二维码至客户下单 -->
    <template>
      <div v-if="!detailInfo.confOrderId && userInfo.type === 1" class="common-footer">
        <van-button class="btn back" native-type="button" @click.stop="goBack">{{$t('取消')}}</van-button>
        <van-button class="btn submit" type="submit" @click="create">提交审核</van-button>
      </div>
      <div v-if="detailInfo.status === 2 && isShopManage" class="common-footer">
        <van-button class="btn back" native-type="button" @click.stop="reviewRefuseOrder()">拒绝</van-button>
        <van-button class="btn submit" type="submit" @click.stop="reviewPassOrder()">审核通过</van-button>
      </div>
      <div v-if="detailInfo.status === 5 && entryType !== 'approve' && userInfo.type === 1" class="common-footer">
        <van-button class="btn back" native-type="button" @click.stop="cancelOrder">取消配置单</van-button>
        <van-button class="btn submit long-btn" type="submit" @click="shareImg">发送二维码至客户下单</van-button>
      </div>
    </template>
    <!--审批拒绝弹窗 -->
    <van-dialog
      v-model="revokeDialog"
      class="revokeDialog"
      :title="''"
      show-cancel-button
      confirmButtonColor="#EED484"
      :confirm-button-text="$t('确认')"
      :before-close="beforeCloseDialog">
      <van-form ref="revokeDialogForm" label-width='65px' validate-trigger="onSubmit">
        <van-field
          v-model="revokeParams.reason"
          :label="$t('')"
          style="margin-top: 16px;"
          placeholder="请输入拒绝原因"
          show-word-limit
          maxlength="200"
          rows="4"
          autosize
          type="textarea"
          :rules="[{ required: true }]" />
      </van-form>
    </van-dialog>
  </div>
  </template>
<script>
import { mapGetters } from 'vuex'
import loading from '@/utils/loading'
import { createSpecialOrder, specialOrderDetail, invalidOrder, reviewOrder } from '@/services/specialCar.js'
import wxSDK from '@/scripts/wxsdk'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import { ROLES } from '@/constants/constants'
export default {
  name: 'specialStockDetail',
  filters: {
    getTypeName(val) {
      if (!val) return ''
      return {
        2: '店长审批中',
        5: '已生效',
        10:'已失效',
        15:'已关联订单',
        20:'已关闭',
      }[val]
    }
  },
  data(){
    return {
      formData: {
        // coVehicleSource: 2, // 1 正常 2 非一车一价 3一车一价
        // deliveryDealerCode: 'VWRDCA20231200026', 
        // deliveryDealerName: 'ID.UX 零售空间合肥包河店', 
        // materialCode: 'A24ER1C5D1P1PAZ00207', // 车辆物料
        // specialVehicleType: '40551002', // 车辆类型 字典4055
        configType: null,
        coVehicleSource: 2, // 1 正常 2 非一车一价 3一车一价
        deliveryDealerCode: '', 
        deliveryDealerName: '', 
        materialCode: '', // 车辆物料
        specialVehicleType: '', // 车辆类型 字典4055
        applyReason: '',
        vin: '',
      },
      detailInfo: {
        status: 2,
        configType: null,
        applyReason: '',
        materialJson: {}
      },
      records:[],
      entryType: '',
      imgList: [], // 图片
      revokeDialog: false, // 拒绝弹窗
      revokeParams: {
        reason: ''
      },
      // 0 正常 1 有vin 无资源 2 无vin无资源
      msgObj: {
        0: '操作成功',
        1: '该指定车辆不可用，无法创建配置单，系统将自动关闭配置单',
        2: '该配置暂无可用车辆资源，无法创建配置单，系统将自动关闭配置单'
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    dealerName() {
      const { dealers=[] } = this.userInfo
      return this.selectedShopId ? dealers.find(({ id }) => { return id === this.selectedShopId })?.name : dealers[0]?.name
    },
    judgePc() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    },
    // 初次提交
    showEdit() {
      return !this.detailInfo.confOrderId
    },
    // 是否有店长权限
    isShopManage() {
      const { roleLogos = [], type } = this.userInfo
      return roleLogos.some((item) => ROLES.SHOWOWNER === item)
    },
  },
  mounted(){
    const { id, entryType, configType } = this.$route.query
    this.entryType = entryType
    id && this.getDetail(id)
    if (entryType === 'operate') {
      const config = JSON.parse(sessionStorage.getItem('specialOrderConfig'))
      // 创建时参数
      this.formData.deliveryDealerCode = String(config.deliveryStoreCode)
      this.formData.deliveryDealerName = config.deliveryStoreName
      this.formData.materialCode = config.materialCode
      this.formData.specialVehicleType = config.specialVehicleType
      this.detailInfo = config
      this.detailInfo.materialJson = {
        seriesName: config.seriesName,
        modelName: config.modelName,
        colourName: config.colourName,
        interiorName: config.interiorName,
        optionList: config.optionList
      }
    } else if (entryType === 'list') {
      const params = JSON.parse(this.$route.query.params)
      // 创建时参数
      this.formData.deliveryDealerCode = String(params.deliveryStoreCode)
      this.formData.deliveryDealerName = params.deliveryStoreName
      this.formData.materialCode = params.materialCode
      this.formData.specialVehicleType = params.specialVehicleType
      this.formData.vin = params.vin
      this.detailInfo = params
      this.detailInfo.materialJson = {
        seriesName: params.seriesCodeName,
        modelName: params.modelCodeName,
        colourName: params.colourCodeName,
        interiorName: params.interiorCodeName,
        optionList: [{
          dictCode: params.optionsCode,
          dictName: params.optionsCodeName,
        }]
      }
    }
    this.formData.configType = configType
    this.detailInfo.configType = configType
  },
  methods:{ 
    getDetail(id) {
      specialOrderDetail({ id }).then(res=>{
        // status 状态  2店长审核中 5.已生效 ；10已失效 ；15已关联订单
        this.detailInfo = res
        this.imgList = res.applyUrl ? res.applyUrl.split(',').map((item) => {
          return {
            url: item,
            cosUrl: item,
            status: 'done',
            uuid: uuidv4(),
          }
        }) : []
      })
    },
    // 图片校验
    beforeRead(file, type) {
      const isLt10M = file.size / 1024 / 1024 > 20
      if (type === 'img' && (!file.type.includes('image') || isLt10M)) {
        this.$toast.fail(this.$t('请上传正确图片文件，单张最大20M'))
        return false
      }
      return true
    },
    afterRead(file, type) {
      const uuid = uuidv4()
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file)
        .then(({ url, previewUrl }) => {
          if (file) {
            file.status = 'done'
            file.message = ''
            file.url = previewUrl
            file.cosUrl = url
            if (type === 'img') {
              this.imgList.push(file)
            }
          }
        })
        .catch(() => {
          file.status = 'failed'
          file.message = this.$t('上传失败')
        })
    },
    beforeDelete(file, type) {
      if (type === 'img') {
        this.imgList = this.imgList.filter((item) => item.uuid !== file.uuid)
      }
    },
    async downloadImg() {
      if (!this.judgePc){
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const img = new Image()
        img.crossOrigin = 'Anonymous' // 如果图片需要跨域访问，需要设置该属性
        img.onload = function() {
          canvas.width = img.width
          canvas.height = img.height
          ctx.drawImage(img, 0, 0)
          const imgData = canvas.toDataURL('image/png')

          const a = document.createElement('a')
          a.href = imgData
          a.download = 'image.png'
          a.click()
        }
        img.src = this.detailInfo.qrCodeUrl
      }
    },
    create() {
      this.$refs.form.validate().then(async () => {
        this.$dialog.confirm({
          title: '',
          message: '确认提交审核？',
          beforeClose: async (action, done) => {
            done()
            if (action === 'confirm') {
              loading.showLoading()
              let imgList = this.imgList || []
              // 已经签名的图片 需要截取掉签名信息之后 再传给后端
              imgList = imgList.map(({ cosUrl }) => {
                return cosUrl?.indexOf('?q-sign-algorithm') == -1 ? cosUrl : cosUrl.slice(0, cosUrl.indexOf('?q-sign-algorithm'))
              })
              const params = {
                ...this.formData,
                applyReason: this.detailInfo.applyReason,
                applyUrl: imgList.join(',')
              }
              const res = await createSpecialOrder(params)
              loading.hideLoading()
              this.$toast.success('创建成功')
              setTimeout(() => {
                // this.onback()
                this.getDetail(res)
              }, 500)
            }
          },
        })
      })
    },
    // 取消配置单
    cancelOrder () {
      this.$dialog.confirm({
        title: '',
        message: '取消后配置单和二维码将失效，车辆资源会释放；确认取消 ？',
        confirmButtonColor: '#B9921A',
        beforeClose: async (action, done) => {
          done()
          if (action === 'confirm') {
            loading.showLoading()
            const res = await invalidOrder(this.detailInfo.id)
            loading.hideLoading()
            this.$toast.success('取消成功')
            setTimeout(() => {
              // this.onback()
              this.getDetail(this.detailInfo.id)
            }, 500)
          }
        },
      })
    },
    // 拒绝弹窗 1通过 2拒绝
    reviewRefuseOrder() {
      this.revokeDialog = true
    },
    // 撤回确认
    beforeCloseDialog(action, done) {
      if (action === 'confirm') {
        this.$refs.revokeDialogForm.validate().then(async () => {
          const params = {
            id: this.detailInfo.id,
            result: 2,
            reason: this.revokeParams.reason
          }
          if (this.btnLoading) return false
          this.btnLoading = true
          try {
            const res = await reviewOrder(params) 
            done()
            this.btnLoading = false
            loading.hideLoading()
            if (res.code === 0 && res.data) { 
              this.$dialog.confirm({
                title: '',
                message: this.msgObj[res.data],
                confirmButtonText: '我知道了',
                showCancelButton: false
              }).then(() => {})
            } else if (res.code === 0 && !res.data) { 
              this.$toast.success('操作成功')
            } else { 
              this.$toast.fail(res.msg)
            }
            setTimeout(() => {
              // this.onback()
              this.getDetail(this.detailInfo.id)
            }, 500)
          } catch {
            done()
            this.btnLoading = false
          }
        }, done.bind(null, false))
      } else {
        done()
      }
    },
    // 审批
    reviewPassOrder() {
      this.$dialog.confirm({
        title: '',
        message: '确认审核通过？',
        confirmButtonColor: '#B9921A',
        beforeClose: async (action, done) => {
          done()
          if (action === 'confirm') {
            const params = {
              id: this.detailInfo.id,
              result: 1
            }
            loading.showLoading()
            const res = await reviewOrder(params)
            loading.hideLoading()
            if (res.code === 0 && res.data) { 
              this.$dialog.confirm({
                title: '',
                message: this.msgObj[res.data],
                confirmButtonText: '我知道了',
                showCancelButton: false
              }).then(() => {})
            } else if (res.code === 0 && !res.data) { 
              this.$toast.success('操作成功')
            } else { 
              this.$toast.fail(res.msg)
            }
            setTimeout(() => {
              // this.onback()
              this.getDetail(this.detailInfo.id)
            }, 500)
          }
        },
      })
    },
    // 发送二维码
    shareImg() {
      const wechatLink = `${process.env.VUE_APP_ENV === 'uat' ? 'https://uat-oneapp-web.idux-vw.com' : 'https://m.idux-vw.com'}/WXminiBridge?path=pages%2FuniversalLinks%2FuniversalLinks&query=v%3D${this.detailInfo.coVehicleSource}%26c%3D${this.detailInfo.confOrderId}` // 分享链接
      const contentJson = {
        title: '大众汽车ID. UNYX', // 分享标题
        desc: '尊敬的客户，请点击获取您的专属下单配置', // 分享描述
        link: wechatLink,
        imgUrl: this.detailInfo.qrCodeUrl // 分享封面
      }
      wxSDK.shareAppMessage(contentJson)
    },
    goBack(){
      this.$router.go(-1)
    },
    goOrdeDetail({ id }) {
      this.$router.push({
        path: '/order-detail',
        query: {
          id,
        },
      })
    },
  }
}
</script>
<style lang="less" scoped>
.car-detail{
  padding: 16px;
  padding-bottom: 96px;
  .basic-info{
    margin-bottom: 16px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
   .hr {
      padding: 4px 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      &::before {
        content: ' ';
        background-color: #B9921A;
        width: 3px;
        height: 12px;
        display: inline-block;
        margin-right: 10px;
      }
    }
    .card {
      padding: 10px 4px;
      .info {
        margin-bottom: 8px;
        display: flex;
        // align-items: center;
        .label {
          min-width: 65px;
          color: rgba(13, 23, 26, 0.45);
          font-size: 13px;
        }

        .val {
          white-space: normal;
          flex: 1;
          min-width: 0;
          overflow-wrap: break-word;
          font-size: 13px;
        }
        .tag {
          justify-content: end;
          color: #B9921A;
        }
      }       
      .qrcode-box{
        background-color: #000;
        padding: 20px 10px;
        .qrcode {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          .valid-time {
            color: #fff;
            padding-left: 8px;
            font-size: 10px;
          }
        }
        
        .btns {
          display: flex;
          justify-content: space-around;
          margin-top: 10px;
          .btn {
              color: white;
      cursor: pointer;
            }
        }
      }
    }
  }
  .error-tips {
    color: red;
  }  
  .long-btn {
    flex: 2;
  }
}
.refundOrder-item{
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  margin-bottom: 16px;
  .tips{
    font-size: 12px;
  }
}
.revokeDialog{
  .van-form{
    .van-cell::after{
      display: none;
    }
    .tips{
      padding: 0 12px;
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
}
</style>
