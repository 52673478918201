<!-- 业务继承 -->
<template>
  <div class="leaveJob-work">
    <van-collapse ref="vcollapse" v-model="activeLevelNames">
      <van-collapse-item v-for="(item1, index1) in dataSource" :key="index1" :name="index1" class="common-block">
        <template #title>
          <div class="title-b">{{ `${item1.name}（${item1.total}）` }}</div>
        </template>
        <template v-if="item1.childs" #default>
          <van-collapse :ref="`collapse_${index1}`" v-model="dynamicActive[`${index1}`]" @change="changeCollapse($event, index1)">
            <van-collapse-item v-for="(item2, index2) in item1.childs" :key="index2" :name="index2" class="collapse-child">
              <template #title>
                <div class="title-s">
                  <div class="title-s-l" @click.stop=''>
                    <van-checkbox
                      checked-color="#B9921A"
                      shape="square"
                      icon-size="16"
                      :disabled="calculateDisabled(item1)"
                      :value="getCheckBool(item2, index1, index2)"
                      @click.stop="checkAll(item2, index1, index2)">
                    </van-checkbox>
                    <div class="title-s-l-name">{{ `${item2.name}（${item2.total}）` }}</div>
                  </div>
                  <div class="title-s-r">{{ item2.departureTime | timeFormat('YYYY-MM-DD') }} {{ $t('离职') }}</div>
                </div>
              </template>
              <van-checkbox-group
                :ref="`checkboxGroup_${index1}_${index2}`"
                v-model="baseSelectArr[index1][index2]"
                :disabled="calculateDisabled(item1)"
                @change="checkFun($event, item1)">
                <div v-for="(item3, index3) in item2.datas" :key="index3" class="info">
                  <div class="info-l">
                    <van-checkbox
                      checked-color="#B9921A"
                      icon-size="16"
                      shape="square"
                      :name="item3">
                    </van-checkbox>
                  </div>
                  <div class="info-r">
                    <div class="info-r-i">
                      <span class="name">{{ item3.customerName }}</span>
                      <span class="msg">{{ item3.customerMobile }}</span>
                    </div>
                    <div class="info-r-i">
                      <span>{{ item3.bizType === 1 ? $t('最新跟进时间：') : $t('订单创建时间：')}}</span>
                      <span>{{ item3.followTime || '--' }}</span>
                    </div>
                  </div>
                </div>
              </van-checkbox-group>
            </van-collapse-item>
          </van-collapse>
        </template>
      </van-collapse-item>
      <Empty v-if="dataSource.length == 0 && queryMark" :description="$t('暂无数据')"></Empty>
    </van-collapse>
  </div>
</template>
<script>
export default {
  props: {
    dataSource: {
      type: Array,
      default: () => { [] }
    },
    queryMark: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      activeLevelNames: [],
      dynamicActive: {},
      checkBizType: '', // 当前选中的业务类型
      baseSelectArr: [], // 可选择基础数据合集
      checkData: [], // 已选择数据
    }
  },
  computed: {
  },
  watch: {
    dataSource: {
      handler(val) {
        this.activeLevelNames = this.dataSource.map((item, index) => { return index })
        this.checkBizType = ''
        this.checkData = []
        this.baseSelectArr = []
        val.forEach((item1, index1) => {
          this.baseSelectArr.push([])
          this.dynamicActive[`${index1}`] = [0]
          this.$set(this.dynamicActive, [`${index1}`], [0])
          if (item1.childs && item1.childs.length > 0) {
            item1.childs.forEach((item2, index2) => {
              this.baseSelectArr[index1].push([])
            })
          }
        })
      },
      immediate: true,
      deep: true
    },
    baseSelectArr: {
      handler(val) {
        this.checkData = []
        val.forEach(item => {
          item.forEach(item1 => {
            if (item1.length > 0) {
              this.checkData = this.checkData.concat(item1)
            }
          })
        })
      },
      immediate: true,
      deep: true
    },
    checkData: {
      handler(val) {
        if (val.length == 0) {
          this.checkBizType = ''
        }
        this.$emit('dataChange', val, val[0]?.bizType || '')
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    // 选择业务类型组
    checkAll(data, index1, index2) {
      if (!this.$refs[`collapse_${index1}`][0].$children[`${index2}`].show) {
        this.$refs[`collapse_${index1}`][0].$children[`${index2}`].toggle()
      }
      this.$nextTick(() => {
        if (!this.$refs[`checkboxGroup_${index1}_${index2}`][0].disabled) {
          this.$refs[`checkboxGroup_${index1}_${index2}`][0].toggleAll(!this.getCheckBool(data, index1, index2))
        }
      })
    },
    // 展开与收起
    changeCollapse(data, index1) {
      this.$set(this.dynamicActive, [`${index1}`], data)
      this.$forceUpdate()
    },
    // 是否选择
    getCheckBool(item, index1, index2, child='datas') {
      const { baseSelectArr } = this
      if (item[child].length == 0) {
        return false
      }
      if (baseSelectArr[index1][index2] && baseSelectArr[index1][index2].length === item[child].length ){
        return true
      } else {
        return false
      }
    },
    checkFun(data, { bizType }) {
      if (this.checkBizType && this.checkBizType != bizType) {
        return false
      }
      if (data.length) {
        this.checkBizType = bizType
      }
    },
    // 计算是否置灰
    calculateDisabled({ bizType }) {
      if (!this.checkBizType) {
        return false
      }
      return bizType !== this.checkBizType
    }
  }
}
</script>
<style lang="less" scoped>
.leaveJob-work{
  .common-block{
    padding: 12px;
    overflow: hidden;
    .van-collapse-item--border::after {
      display: none !important;
    }
    .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after{
      border: 0
    }
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
  /deep/ .van-collapse-item{
    .van-checkbox__icon{
      margin-right: 10px;
    }
    .van-checkbox__icon--disabled .van-icon {
      background-color: #8080806e
    }
    .van-cell{
      padding: 0;
      .van-cell__title{
        height: 36px;
        .title-b{
          font-size: 16px;
          padding-left: 8px;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            height: 12px;
            width: 2px;
            background: #0040C5;
            transform: translateY(-50%);
            left: 0;
            top: 50%
          }
        }
        .title-s{
          display: flex;
          justify-content: space-between;
          .title-s-l{
            font-size: 13px;
            display: flex;
            .title-s-l-name{
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .title-s-r{
          }
        }
      }
    }
    .van-collapse-item__title--expanded{
      .van-cell__right-icon::before{
        color: @yellow-text
      }
      &::after {
        display: none;
      }
    }
    .van-collapse-item__content{
      border-radius: 8px;
      overflow: hidden;
      padding: 0px;
      .van-cell__title{
        height: 32px;
      }
      .collapse-child{
        background: #F4F8FE;
        padding: 12px;
        padding-bottom: 0px;
        .van-collapse-item__content{
          background: #F4F8FE;
          .info{
            display: flex;
            margin-top: 8px;
            color: rgba(13,23,26,0.45);
            font-size: 13px;
            &:last-of-type{
              margin-bottom: 12px
            }
            .info-r{
              .info-r-i{
                &:not(:last-of-type){
                  margin-bottom: 4px
                };
                display: flex;
                height: 20px;
                align-items: center;
                .name{
                  max-width: 100px;
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .msg{
                  display: inline-block;
                  padding-left: 16px;
                  position: relative;
                  &::before{
                    content: '';
                    position: absolute;
                    height: 12px;
                    width: 2px;
                    background: rgba(13,23,26,0.25);
                    transform: translateY(-50%);
                    left: 8px;
                    top: 50%
                  }
                }        
              }
            }
          }
        }
        &:not(:last-of-type) {
          margin-bottom: 12px
        }
        .van-cell{
          background: #F4F8FE;
        }
        border-radius: 8px 8px 8px 8px;
        .van-collapse-item__title--expanded::after {
          display: block;
          border-color: rgba(13,23,26,0.05);
          transform: scaleY(1.0);
          left: 0px;
          right: 0px
        }
      }
    }
  }
}
</style>