<!--用来模拟跳转的弹出层 * cellList 数据列表 * title 选择器标题 * multiple 是否多选 * max 最大个数限制-->
<!--multiple为true时有效 * min 最小个数限制 multiple为true时有效 -->
<!--* 对外广播confirm、change和cancel事件-->
<script>
import { Toast } from 'vant'
import store from '@/store'
import { isArray } from '@/utils'
import { i18n } from '@/main'
import { areaList } from '@vant/area-data'
export default {
  directives: {
    checkPer: {
      inserted: function (el, binding, vnode) {
        
      },
    },
  },
  props: {
    showPicker: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'right',
    },
    cellList: {
      type: Array,
      default: function () {
        return []
      },
    },
    dictType: {
      type: Number,
      default: function () {
        return 0
      },
    },
    shopId: {
      type: String,
      default: function () {
        return ''
      },
    },
    // 选择器标题
    title: {
      type: String,
      default: i18n.t('请选择'),
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 最大个数限制
    max: {
      type: [Number, String],
    },
    // 最小个数限制
    min: {
      type: [Number, String],
    },
    lastSelect: {
      type: [Number, String, Array],
      default: 0,
    },
    isDisableTips: {
      type: String,
      default: '未开通此权限'
    },
    // 专供门店查询 选择更多门店的字段
    showSearchForShop: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      actives: [],
      i18n,
      showFilter: false,
      filterParams: {
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        shopName: ''
      },
      filterType: 'province',
      provinceCode: '',
      areaList
    }
  },
  computed: {
    // 筛选返回数据
    resultArray() {
      return this.cellList.filter((v, i) => this.actives.includes(i))
    },
    // 弹窗样式
    style() {
      return this.position === 'left' || this.position === 'right'
        ? { height: '100%', width: '100%' }
        : {}
    },
    // 遮罩层样式
    overlayStyle() {
      return this.position === 'left' || this.position === 'right'
        ? { background: '#fff' }
        : {}
    },
    provinceList() {
      const list = areaList.province_list || {}
      let obj = []
      for (var key in list) {
        obj.push({
          name: list[key],
          code: key
        })
      }
      return obj
    },
    cityList() {
      const provinceFlag = this.filterParams.provinceCode.slice(0,2)
      const list = areaList.city_list || {}
      let obj = []
      for (var key in list) {
        if (!provinceFlag || key.slice(0,2) === provinceFlag) {
          obj.push({
            name: list[key],
            code: key
          })
        }
      }
      return obj
    },
    actions() {
      return this.filterType === 'province' ? this.provinceList : this.cityList
    },
    newCellList() {
      let arr = this.cellList
      if (this.showSearchForShop) {
        if (this.filterParams.provinceCode) arr = arr.filter(({ provinceCode }) => { return provinceCode === this.filterParams.provinceCode })
        if (this.filterParams.cityCode) arr = arr.filter(({ cityCode }) => { return cityCode === this.filterParams.cityCode })
        if (this.filterParams.shopName) arr = arr.filter(({ dictName }) => { return dictName.indexOf(this.filterParams.shopName) != -1 })
      }
      return arr
    }
  },
  watch: {
    lastSelect(newVal, oldVal) {
      if (isArray(newVal)){
        newVal.forEach((code) => {
          this.actives.push(this.cellList.findIndex(item => {
            return item.dictCode === code
          }))
        })
      } else if (newVal){
        this.actives.push(this.cellList.findIndex(item => (this.selectShop ? item.dealerShopNo === newVal : item.dictCode === newVal)))
      }
    }
  },
  methods: {
    confirm() {
      if (
        this.multiple &&
        this.min !== undefined &&
        this.actives.length < this.min
      ) {
        return Toast.fail(`必须选择${this.min}个以上的选项`)
      }
      this.$emit(
        'confirm',
        this.multiple ? this.resultArray : this.resultArray[0]
      )
    },
    onChange(item, index) {
      if (this.multiple) {
        // 多选
        if (this.actives.includes(index)) {
          const _i = this.actives.indexOf(index)
          this.actives.splice(_i, 1)
        } else {
          if (this.max !== undefined) {
            // 最大个数限制
            if (this.actives.length >= this.max) return
          }
          this.actives.push(index)
        }
      } else {
        // 单选
        if (item.isDisable){
          this.$toast(this.isDisableTips)
          return
        }
        // if (this.lastSelect) this.actives = []
        if (this.actives.includes(index)) {
          const _i = this.actives.indexOf(index)
          this.actives.splice(_i, 1)
          this.$emit('confirm', {})
        } else {
          this.actives.splice(0, 1, index)
          this.$emit('confirm', item)
        }
      }
      this.$emit(
        'change',
        this.multiple ? this.resultArray : this.resultArray[0]
      )
    },
    cancel() {
      this.$emit('cancel')
    },
    isActive(index) {
      return this.actives.includes(index)
    },
    // 打开选择省市面板
    openAction(type) {
      this.showFilter = true
      this.filterType = type
    },
    // 选择城市
    selectItem(data) {
      const { code , name } = data
      this.showFilter = false
      if (this.filterType === 'province') {
        if (!this.filterParams.provinceCode || (this.filterParams.provinceCode && this.filterParams.provinceCode !== code)) {
          this.filterParams.province = name
          this.filterParams.provinceCode = code
          this.filterParams.city = ''
          this.filterParams.cityCode = ''
        } 
      } else {
        if (!this.filterParams.cityCode || (this.filterParams.cityCode && this.filterParams.cityCode !== code)) {
          this.filterParams.city = name
          this.filterParams.cityCode = code
        }
      }
    },
    // 删除选择
    clearChoose(type) {
      if (type === 'province') {
        this.filterParams.province = ''
        this.filterParams.provinceCode = ''
        this.filterParams.city = ''
        this.filterParams.cityCode = ''
      } else {
        this.filterParams.city = ''
        this.filterParams.cityCode = ''
      }
    },
    inputShopName(name) {
      this.filterParams.shopName = name
    }
  },
}
</script>
<template>
  <div>
    <van-popup
      v-model="showPicker"
      class="back-popup"
      get-container="#app"
      :position="position"
      :style="style"
      :overlay-style="overlayStyle">
      <div id="header-popup">
        <div class="opt" @click="cancel">
          <slot name="header-left">
            <van-icon name="arrow-left" size="18"></van-icon>
          </slot>
        </div>
        <div id="title">
          <slot name="header-title">{{ title }}</slot>
        </div>
        <div v-if="multiple" class="opt" @click="confirm">
          <slot name="header-right"> {{ i18n.t('确认') }} </slot>
        </div>
      </div>
      <!-- 专门为选择店铺定制的过滤条件 -->
      <template v-if="showSearchForShop">
        <div class="showSearchForShop">
          <p>{{ i18n.t('可选择省份城市过滤交付门店，或输入门店名称模糊查询') }}</p>
          <van-field
            v-model="filterParams.province"
            :label="i18n.t('门店省份')"
            :placeholder="i18n.t('请选择门店省份')"
            right-icon="close"
            readonly
            @click="openAction('province')"
            @click-right-icon.stop="clearChoose('province')">
          </van-field>
          <van-field
            v-model="filterParams.city"
            :label="i18n.t('门店城市')"
            :placeholder="i18n.t('请选择门店城市')"
            right-icon="close"
            readonly
            @click="openAction('city')"
            @click-right-icon.stop="clearChoose('city')">
          </van-field>
          <van-field
            v-model="filterParams.shopName"
            :label="i18n.t('门店名称')"
            :placeholder="i18n.t('请输入门店名称')"
            right-icon="close"
            @click-right-icon.stop="filterParams.shopName = ''"
            @input="inputShopName">
          </van-field>
        </div>
      </template>
      <div>
        <slot name="content">
          <p v-if="cellList.length === 0" class="no-data">{{ i18n.t('暂无数据!') }}</p>
          <van-cell
            v-for="(item, index) in showSearchForShop ? newCellList : cellList"
            :key="index"
            :class="{ active: isActive(index),disable: item.isDisable || false }"
            clickable
            border
            :title="item.dictName"
            @click="onChange(item, index)">
            <template #right-icon>
              <van-icon
                v-show="isActive(index)"
                name="success"
                size="22"
                color="#B9921A"
              ></van-icon>
            </template>
          </van-cell>
        </slot>
      </div>
    </van-popup>
    <van-action-sheet
      v-if="showSearchForShop"
      v-model="showFilter"
      :title="filterType === 'province' ? i18n.t('选择省份') : i18n.t('选择城市')"
      :actions="actions"
      @select="selectItem">
    </van-action-sheet>
  </div>
</template>
<style lang="less">
.back-popup {
  #header-popup {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    border-bottom: #f5f5f5 solid 1px;
    color: @black;
    background: @yellow;
    position: sticky !important;
    top: 0px;
    z-index: 20;
    .opt {
      position: absolute;
      top: 0;
      bottom: 0;
      line-height: 46px;
      padding: 0 10px;
      font-size: 17px;
      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
      .van-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
  .showSearchForShop{
    width: 100%;
    padding-top: 16px;
    text-align: center;
    color: @yellow-text;
    font-size: 14px;
    position: sticky;
    background: #fff;
    top: 46px;
    z-index: 20;
    box-shadow: 0px 2px 5px #21212138;
  }
  .no-data {
    font-size: 12px;
    color: #aaa;
    text-align: center;
    padding-top: 25px;
  }

  .van-cell {
    &.active {
      color: @yellow-text;
    }
    &.disable {
      color: #999;
    }
    .van-icon {
      font-size: 17px;
      line-height: inherit;
    }
  }

  .van-cell:after {
    transform: none;
  }
}
</style>
