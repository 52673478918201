<template>
  <div class="StockItem">
    <div class="title-box">
      <div class="name">{{data.seriesName}} {{ data.modelName }}</div>
      <div class="type-name">已退役试驾车</div>
    </div>

    <div class="tags">
      <template v-for="(tag, index) of getTags">
        <div v-if="tag.value" :key="index" class="tag" :class="tag.class">{{ tag.value }}</div>
      </template>
    </div>

    <div class="lines">
      <div v-for="(item, index) of getLines" :key="index" class="line">
        <div class="name">{{ item.name }}</div>
        <div class="value">{{ item.value || '--' }}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'StockItem1',
  props:{
    data:{
      type: Object,
      default:()=>({})
    },
  },
  // data() {
  //   return {

  //   }
  // },
  computed: {
    getLines() {
      const { optionNames, carNumber, dealerName } = this.data
      return [
        {
          name: '选装',
          value: optionNames
        },
        {
          name: '车牌号',
          value: carNumber
        },
        {
          name: '销售门店',
          value: dealerName
        },
      ]
    },
    getTags() {
      const { vinCode,interiorColor,exteriorColor } = this.data
      return [
        {
          name: '外饰',
          value: exteriorColor,
          class:'blue',
        },
        {
          name: '内饰',
          value: interiorColor,
          class:'green'
        },

        {
          name: 'VIN',
          value: vinCode,
          class:' lightBlue '
        },
      ]
    },
  }
}
</script>

<style lang="less" scoped>
.StockItem {
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 16px 12px 8px 12px;
  font-size: 13px;
  //margin: 10px 16px;

  .title-box {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 6px;

    .type-name {
      font-size: 13px;
    }
  }

  .lines {
    .line {
      display: flex;
      align-items: center;
      height: 24px;
      .name {
        &::after {
          content: '：';
        }
      }
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    margin-bottom: 8px;
    .tag {
      padding: 2px 8px;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      margin-top: 8px;
      margin-left: 4px;
      line-height: 16px;
      font-size: 12px;

      &.blue {
        border-color: rgba(0, 64, 197, 0.1);
        color: #0040c5;
        background: rgba(0, 64, 197, 0.1);
      }
      &.green{
        color: #b9921a;
        background: rgba(185, 146, 26, 0.1);
        border-color: rgba(185, 146, 26, 0.1);
      }
      &.lightBlue {
        color: #029640;
        background: rgba(2, 150, 64, 0.1);
        border-color: rgba(2, 150, 64, 0.1);
      }
      // &:nth-of-type(4) {
      //   color: #666666;
      //   background: #efefef;
      //   border-color: #e1e1e1;
      // }
      &:last-child {
        margin-right: 4px;
      }
    }
  }
}
</style>
