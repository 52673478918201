
<template>
  <div class="Item" >
    <div class="left" @click="$emit('click',itemData)">
      <div style="display: flex; justify-content: space-between;">
        <div class="van-ellipsis info-item">{{ itemData.confOrderId }}</div>
        <span :style="{ marginLeft: '8px', color: [10].includes(itemData.applyStatus) ? '#029640' : '#FA5410' }">{{ itemData.applyStatus | getTypeName }}</span>
      </div>
      <template>
        <div class="van-ellipsis info-item">
          <div class="label">特殊车辆类型：</div>
          <div class="value">{{ getDictNameByCode(4055, itemData.specialVehicleType) }}</div>
        </div>
        <div class="van-ellipsis info-item">
          <div class="label">销售门店：</div>
          <div class="value">{{ itemData.salesDealerName }}</div>
        </div>
        <div class="van-ellipsis info-item">
          <div class="label">交付门店：</div>
          <div class="value">{{ itemData.deliveryDealerName }}</div>
        </div>
        <div class="van-ellipsis info-item flex">
          <div class="label">销售顾问：</div>
          <div class="van-ellipsis value">{{itemData.salesName}}</div>
        </div>
      </template>
    </div>
  </div>
  </template>
<script>
let vm
export default {
  name: 'Item',
  filters: {
    coVehicleSourceFormat(val) {
      if (!val) return ''
      return {
        1: vm.$t('普通商品车'),
        2: vm.$t('特殊车（非一车一价）')
      }[val]
    },
    getTypeName(val) {
      if (!val) return ''
      return {
        1: '店长审批中',
        10:'通过',
        20:'拒绝',
      }[val]
    }
  },
  props: {
    itemData: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  
  data() {
    return {
      customerOption : {
        name: 'customerName',
        sex: 'customerSex',
        phone: 'customerMobile',
      },
    }
  },
  created() {
    vm = this
  },
  methods: {
    onClick() {
      this.$emit('change', { ...this.itemData })
    },
  }
}
</script>
  <style lang="less" scoped>
  @checkbox-color: #0D171A;
  .tag {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 8px;
    line-height: 16px;
    font-size: 12px;
    border-color: rgba(0, 64, 197, 0.1);
    color: #0040c5;
    background: rgba(0, 64, 197, 0.1);
  }
  .Item {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 16px 12px 12px 12px;
    width: 100%;
    overflow: hidden;
    .left {
      flex: 1;
      width: 80%;
      color: @checkbox-color;
      font-size: 13px;
      line-height: 16px;
      overflow: hidden;
      .info-item{
        margin-bottom:8px;
        display: flex;
        // width: 100%;
        &.flex {
          display: flex;
        }
        .label{
          color: #929798;
          min-width: 65px;
        }
        .value {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  
    .right {
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/.van-checkbox__icon {
        font-size: 16px;
  
        .van-icon {
          border: 2px solid rgba(13,23,26,0.45);
        }
      }
  
      /deep/.van-checkbox__icon--checked .van-icon {
        background-color: @checkbox-color;
        border-color: @checkbox-color;
        line-height: 1;
      }
    }
  }
  </style>
  