<template>
  <div id="test-drive-report" class="test-drive-report">
    <div v-if="pageActive === 1" ref="swipe">
      <div class="page-item-home page-bg ">
        <div class="module-1">
          <div class="main-title">{{$t('您的试驾体验专属瞬间')}}</div>
          <!-- 达人称号 -->
          <div class="module-title">
            <div class="driver-title"> 
              <div class="customer">
                <div class="left">
                  <div class="line-1">{{$t('恭喜您荣膺')}}</div>
                  <div class="line-2">{{ lang === 'zh_CN' ? detailInfo.titleDisplay : detailInfo.titleDisplayEn }}</div>
                  <div class="line-3">{{$t('本次试驾获取的')}}</div>
                  <div class="line-4">
                    <!-- <span>{{$t('积分')}}：<span class="num-light">{{ detailInfo.headResp.points }}</span></span> -->
                    <span>{{$t('成长值')}}：<span class="num-light">{{ detailInfo.headResp.growthValue }}</span></span>
                  </div>
                </div>
                <div class="right">
                  <img class="img" src="./imgs/module1-title.png" width="100%" />
                </div>
              </div>
            </div> 
            <van-row :gutter="20" class="rule-explain" @click="changeActive(3)">
              <van-col span="1"><img src="./imgs/module1-rule-icon.png" /></van-col>
              <van-col span="20">{{$t('点击了解定级规则')}}</van-col>
              <van-col span="2"><img src="./imgs/module1-right-go.png" /></van-col>
            </van-row>
          </div>
        </div>
        <!-- 雷达图 -->
        <div class="module-2 module-item">
          <div class="big-title">{{$t('您的驾驶表现')}}</div>
          <div class="charts-radar-box">
            <div
              id="chart-container-radar"
              class="grid-item charts"
            ></div>
          </div>
        </div>
        <div class="module-3 module-item">   
          <div class="big-title">{{$t('试驾信息回顾')}}</div>
          <!-- 试驾路线轨迹 -->
          <van-image v-if="detailInfo.routePictureUrl" class="test-drive-track" fit="cover" :src="detailInfo.routePictureUrl"/>
          <!-- 地图容器 -->
          <div v-else style="width:100%;height: 250px;">
            <div id="container" style="width:100%;height: 249px;"></div>
          </div>
          <van-row>
            <van-col span="14">
              <div class="charts-gauge-box" style="flex: 1;">
                <!-- 仪表盘 -->
                <div
                  id="chart-container-gauge"
                  class="grid-item charts"
                ></div>
              </div>
            </van-col>
            <van-col span="10" class="charts-right">
              <van-row class="review-item">
                <van-col span="24">
                  <div>{{$t('试驾报告试驾车型')}}</div>
                  <div>{{ lang === 'zh_CN' ? detailInfo.modelName : detailInfo.modelNameEn }}</div>
                </van-col>
                <van-col span="24">
                  <div>{{$t('试驾路程')}}</div>
                  <div>{{ detailInfo.infoResp.traveledMileage }} km</div>
                </van-col>
                <van-col span="24">
                  <div>{{$t('试驾时长')}}</div>
                  <div>{{ detailInfo.infoResp.drivingDuration }} mins</div>
                </van-col>
                <van-col span="24">
                  <div>{{$t('试驾报告最高车速')}}</div>
                  <div>{{ detailInfo.infoResp.maxSpeed }} km/h</div>
                </van-col>
              </van-row>
            </van-col>
            <van-col :span="24">
            <div span="24" class="save-money">
              {{$t('与传统燃油车相比，这次试驾行驶预计可为您节省费用')}}<span style="color: #efd484;font-family: 'VWHead-Bold';"> {{ detailInfo.infoResp.saveMoney }} </span>{{$t('元')}}，{{$t('驾驶ID. UNYX，让节能环保成为您的日常选择')}}
            </div> 
            </van-col>
          </van-row>        
        </div>  
        <!-- 试驾门店顾问 --> 
        <div class="module-4 module-item">
          <div class="big-title">{{$t('试驾门店')}}</div>
          <van-row class="sales-store">
            <van-col span="4"> <img src="./imgs/sales-store.png" width="50px" /></van-col>
            <van-col span="20">{{ lang === 'zh_CN' ? detailInfo.dealerName : detailInfo.dealerNameEn }}</van-col>
          </van-row>
          <van-row class="sales-onsultant">
            <van-col span="4"> <img src="./imgs/sales-onsultant.png" width="44px" /></van-col>
            <van-col span="16">
              <div>{{ detailInfo.salesName }}</div>
              <div>{{$t('您的专属服务顾问')}}</div>
            </van-col>
            <van-col span="4">
              <img src="./imgs/sales-phone.png" width="44px" @click.stop="onCallPhone" />
            </van-col>
          </van-row>
        </div>    
        <div class="module-5 module-item">
          <div class="big-title">{{$t('85%的用户还会想了解')}}</div>
          <van-row class="more-information">
            <van-row v-for="(item, index) in moreInfosList" :key="index">
              <van-col span="4"><img :src="item.imgSrc"/></van-col>
              <van-col span="14">{{ lang === 'zh_CN' ? item.describe : item.describeEn }}</van-col>
              <van-col span="6" @click="goMorePage(item, index)">{{ lang === 'zh_CN' ? item.btnName : item.btnNameEn }}<img src="./imgs/right-go.png" /></van-col>
            </van-row>
          </van-row>
        </div>    
        <!-- 试驾礼 -->
        <div v-if="giftConfig" class="module-6 module-item">
          <div class="big-title">{{$t('试驾有礼')}}</div>
          <div class="gift-bg"  :class="[giftConfig ? 'gift-bg' : 'no-gift-bg' ]">
            <div class="gift">       
              <div class="footer">
                <!-- 未发送NOT_SEND 已发未领NOT_RECEIVE -->
                <div v-if="['NOT_SEND', 'NOT_RECEIVE'].includes(statusEnum)" class="outer" @click="receive">
                  <div class="btn">{{ $t('试驾报告领取试驾礼品') }}</div>
                </div>
                <div v-else-if="['NOT_LOTTERY'].includes(statusEnum)" class="outer">
                  <div class="btn">{{ $t('未中奖') }}</div>
                </div>
                <!-- RECEIVED -->
                <div v-else class="outer">
                  <div class="btn">{{$t('礼物已领取')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="module-7">
          <img src="./imgs/share.png" width="56px" @click="shareClick" />
        </div>
      </div>
    </div>
    <template v-if="pageActive === 2">
      <div class="template2"> 
        <div class="module-container">
          <div class="goBack" @click="changeActive(1)">
            <van-icon name="close" color="#B2B6C1" size="20px" />
          </div>
          <div class="fz">{{ highLightTitle }}</div>
          <van-swipe :show-indicators="false" :loop="false" :width="270" @change="changeSwipe">
            <van-swipe-item v-for="(item, index) in showHighLightList" :key="index">
              <div class="swipeTitle">
                <div :class="['item', activeTab == index ? 'active' : '']">{{ lang === 'zh_CN' ? item.title : item.titleEn}}</div>
              </div>
              <div class="image-container"> 
                <!-- <img :src="item.imgSrc" class="frame1"> -->
                <van-image lazy-load :src="item.imgSrc" class="frame1">
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                  <template v-slot:error>加载失败</template>
                </van-image>
                <div class="overlay"></div>
              </div>
              <div v-if="!item.isFullScore && showPlayIcon" class="no-full">{{$t('尚未深度体验')}}</div>
              <div v-if="item.videoUrl && showPlayIcon" class="playIcon">
                <van-icon name="play-circle-o" size="60" @click="goPlay(item.videoUrl)"/>
                <div>{{$t('播放演示视频')}}</div>
              </div>
              <div class="footer">
                <div v-for="(desc, index2) in (lang === 'zh_CN' ? item.describe : item.describeEn)" :key="index2" class="describe">
                  <div v-if="showPlayIcon">{{ desc }}</div>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
          <van-popup v-model="showVideo" :style="{ height: '60%', width: '100%' }">
            <video-player
              v-if="showVideo"
              ref="videoPlayer"
              class="video-player-box"
              :options="playerOptions"
              :playsinline="true"
            ></video-player>
          </van-popup>
        </div>
      </div>
    </template>
    <template v-if="pageActive === 3">
      <div class="template2">
        <div class="module-container">
          <div class="goBack" @click="changeActive(1)">
            <van-icon name="close" color="#B2B6C1" size="20px" />
          </div>
          <div class="fz">{{$t('定级规则')}}</div>
          <div v-if="lang === 'zh_CN'" class="text">荣誉称号将在试驾结束后自动生成，系统会根据您在试驾过程中表现最突出的核心维度授予相应的荣誉。本次评估涵盖五大核心维度：动态驾驶、智能座舱、互联性、辅助驾驶和无忧泊车。每个维度不仅全面覆盖了驾驶行为的各个方面，还深入分析了您在实际道路环境中的表现。</div>
          <div v-if="lang === 'zh_CN'" class="text">您的得分基于试驾过程中成功触发并深度体验的产品亮点。这些亮点可能包括智能驾驶辅助系统的高效应用、紧急情况下的快速反应、复杂路况下的稳定操控，以及对交通规则的严格遵守等。通过这一评估与奖励机制，我们希望每位试驾者都能充分了解车辆的多项功能，并激励您追求更加安全、智能、环保的驾驶方式。</div>
          <div v-if="lang === 'en_US'" class="text">The honorary title is automatically generated at the end of your test drive, with the system identifying the dimension in which you performed most outstandingly and awarding the corresponding title. This evaluation is based on five core dimensions: Agile Driving, Smart Cockpit, Intelligent Interaction, Driving Assistance, and Parking Assistance. Each dimension comprehensively covers various aspects of driving behavior and deeply analyzes your performance in real-world driving conditions.</div>
          <div v-if="lang === 'en_US'" class="text">Your score is determined by the product highlights you successfully triggered and deeply experienced during the test drive. These highlights may include the efficient use of intelligent driving assistance systems, quick reactions in emergency situations, stable control in complex road conditions, and strict adherence to traffic regulations. Through this evaluation and reward mechanism, we aim to help each test driver fully understand the vehicle’s functions and encourage you to pursue a safer, smarter, and more environmentally friendly driving experience.</div>
        </div>
      </div>
    </template>
    <div v-if="pageActive === 1" class="share-poster">   
      <!-- <div class="share-poster">    -->
      <div id="poster" class="home-bg">
        <!-- 封面 -->
        <template>
          <img class="share-img" src="./imgs/share-bg.png" alt="">
          <div class="introduce">
              <van-row class="title-box">
                <van-col :span="6"><img src="./imgs/share-title.png" alt="" ></van-col>
                <van-col :span="16">
                  <div>{{$t('恭喜您荣膺')}}</div>
                  <div>{{ lang === 'zh_CN' ? detailInfo.titleDisplay : detailInfo.titleDisplayEn }}</div>
                </van-col>
              </van-row>
          </div>
          <div class="module-3 module-item">   
            <div class="big-title">{{$t('试驾信息回顾')}}</div>
            <van-row class="review-container" gutter="20">
              <van-col span="14">
                <div class="charts-gauge-box" style="flex: 1;">
                  <!-- 仪表盘 -->
                  <div
                    id="chart-container-gauge2"
                    class="grid-item charts"
                  ></div>
                </div>
              </van-col>
              <van-col span="10" class="charts-right">
                <van-row class="review-item">
                  <van-col span="24">
                    <div>{{$t('试驾报告试驾车型')}}</div>
                    <div>{{ lang === 'zh_CN' ? detailInfo.modelName : detailInfo.modelNameEn }}</div>
                  </van-col>
                  <van-col span="24">
                    <div>{{$t('试驾路程')}}</div>
                    <div>{{ detailInfo.infoResp.traveledMileage }} km</div>
                  </van-col>
                  <van-col span="24">
                    <div>{{$t('试驾时长')}}</div>
                    <div>{{ detailInfo.infoResp.drivingDuration }} mins</div>
                  </van-col>
                  <van-col span="24">
                    <div>{{$t('试驾报告最高车速')}}</div>
                    <div>{{ detailInfo.infoResp.maxSpeed }} km/h</div>
                  </van-col>
                </van-row>
              </van-col>
              <van-col :span="24">
              <div span="24" class="save-money">
                {{$t('与传统燃油车相比，这次试驾行驶预计可为您节省费用')}}<span style="color: #efd484;font-family: 'VWHead-Bold';"> {{ detailInfo.infoResp.saveMoney }} </span>{{$t('元')}}，{{$t('驾驶ID. UNYX，让节能环保成为您的日常选择')}}
              </div> 
              </van-col>
            </van-row>      
          </div>  
          <div v-if="qrcode" class="qrcode-box"> 
            <van-row>
              <van-col :span="16" class="qrcode-text">
                <div>
                  <p>{{$t('扫描二维码并预约试驾')}}</p>
                  <p>{{$t('领取精美试驾礼品')}}</p>
                </div>
              </van-col>
              <van-col :span="8" class="qrcode-img"><div id="qrcode" ref="qrcode"></div></van-col>
            </van-row>
          </div>
        </template>
      </div>
    </div>
    <van-popup 
      v-model="showSharePictureUrl" 
      :lock-scroll="false" 
      :safe-area-inset-top="true" 
      :safe-area-inset-bottom="true"
      :style="{ height: '1100px', width: '100%' }"
       @click="showSharePictureUrl = false">
      <div class="wrapper" @click.stop>
        <div class="share-poster-img">
          <van-image
            width="60%"
            :src="sharePictureUrl"
          />
          <div class="close-icon">{{$t('长按海报可保存至本地哦')}}~</div>
          <div class="close-icon" @click="showSharePictureUrl = false">
            <van-icon name="cross" size="28" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import testDriveServices from '@/services/testDrive'
import html2canvas from 'html2canvas'
import COS from 'cos-js-sdk-v5'
import { judgeMobile } from '@/utils/index'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import { highlightImgVideoList, moreInfos, radarLabel, radarLabelEn } from './constants'
import QRcode from 'qrcodejs2'

import * as echarts from 'echarts'
import light1Active from './imgs/light1-a.png'
import light2Active from './imgs/light2-a.png'
import light3Active from './imgs/light3-a.png'
import light4Active from './imgs/light4-a.png'
import light5Active from './imgs/light5-a.png'
import light1 from './imgs/light1.png'
import light2 from './imgs/light2.png'
import light3 from './imgs/light3.png'
import light4 from './imgs/light4.png'
import light5 from './imgs/light5.png'

import light1ActiveEn from './imgs/light1-a-en.png'
import light2ActiveEn from './imgs/light2-a-en.png'
import light3ActiveEn from './imgs/light3-a-en.png'
import light4ActiveEn from './imgs/light4-a-en.png'
import light5ActiveEn from './imgs/light5-a-en.png'
import light1En from './imgs/light1-en.png'
import light2En from './imgs/light2-en.png'
import light3En from './imgs/light3-en.png'
import light4En from './imgs/light4-en.png'
import light5En from './imgs/light5-en.png'
/* eslint-disable */
export default {
  name: 'testDriveReport',
  components: { videoPlayer },
  data() {
    return {
      cos: '',
      bucket: '', // 桶名称
      region: '', // 地域
      detailInfo: {
        gps: [],
        infoResp: {},
        headResp: {},
        radarList: [], // 接口返回雷达数据
        // routePictureUrl: '',
        nationalRanking: '85'
      }, // 详情
      sharePictureUrl: '',
      categoryCodeActive: [], // 当前高亮雷达
      map: null, // 地图实例
      markers: null,
      polyline: null,
      containerWidth: null, // 记录容器原本的宽、高
      containerHeight: null,
      giftConfig: false, // portal端试驾礼品领取是否开启,默认关闭不展示
      statusEnum: '',
      lang: 'zh_CN', // zh_CN   en_US

      highLightTitle: '', // 亮点子页面标题
      pageActive: 1,     
      activeTab: 0,
      myChart1: null,
      myChart2: null,
      myChart3: null,
      
      showHighLightList: [], // 展示的亮点列表
      showVideo: false,
      videoUrl: '',
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        // preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          src: '', // 路径
          type: 'video/mp4' // 类型
        }],
        poster: '', // 你的封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      },
      showSharePictureUrl: false, // 展示分享海报
      qrcode: {}, // 二维码
      oneId: '',
      showPlayIcon: false,
      accessToken: '', // token
      timerId: '',
    }
  },
  computed: { 
    judgeMobile,
    getRouteDragOptions() {
      return {
        showTraffic: false, // 可选，默认false，设置是否显示实时路况信息
        midMarkerOptions: {
          visible: false,
          draggable: false
        },
        startMarkerOptions: {
          visible: false,
          draggable: false
        },
        endMarkerOptions: {
          visible: false,
          draggable: false
        },
        polyOptions: {
          strokeColor: '#B9921A',
          outlineColor: '#0D171A',
          borderWeight: 2,
          isOutline: true,
          strokeWeight: 5,
          draggable: false
        }
      }
    },
    moreInfosList () {
      return moreInfos
    }
  }, 
  watch: {
    showVideo(val) {
      if (!val) {
        this.playerOptions.sources[0].src = ''
      }
    }
  },
  mounted() {
    const language = window.navigator.language.toLocaleLowerCase()
    this.lang = language.includes('en') ? 'en_US' : 'zh_CN'
    document.getElementById('test-drive-report').style.fontFamily = this.lang === 'zh_CN' ? 'HYQiHei' : 'VWHead-Regular'
    window.document.getElementById('poster').style.height = this.lang === 'zh_CN' ? '840px' : '870px'
    this.init()
  },
  beforeDestroy(){
    clearInterval(this.timerId)
  },
  methods: {
    // 循环获取token
    setIntervalToken () {
      this.timerId = setInterval(() => {
        if (this.accessToken) {
          clearInterval(this.timerId)             
          if (!this.sharePictureUrl) {
            this.creatPoster()
          }
        } else {
          this.refreshToken()
        }
      }, 2000)
    },
    // 获取token--refreshToken
    refreshToken () {
      const vm = this
      window.flutter_inappwebview.callHandler('refreshToken')
        .then(function (result) {
          vm.accessToken = result
        })
    },
    // 获取临时密钥
    async getCosInfo() {
      const res = await testDriveServices.getCosTmpKey({ target: 'UPLOAD_OBJECT' })
      this.bucket = res.bucketName
      this.region = res.region
      this.cos = new COS({
        SecretId: res.credentials.tmpSecretId,
        SecretKey: res.credentials.tmpSecretKey,
        XCosSecurityToken: res.credentials.sessionToken // 临时密钥
      })
    },
    // 获取详情
    async init() {
      const { driveRecordId } = this.$route.query
      const appointmentId = this.$route.query.appointmentid || this.$route.query.appointmentId
      const res = await testDriveServices.getTestDriveReportV3({ appointmentId, driveRecordId: driveRecordId })
      if (res) {
        this.oneId = res.oneId
        // 获取token
        if (this.getENVIR() === 'wxapp') {
          this.accessToken = this.$route.query.accessToken
        } else {  
          this.setIntervalToken()
        }
        // 如果图片不存在就绘图
        if (!res.routePictureUrl || !this.sharePictureUrl) {
          // 初始化cos信息
          this.getCosInfo()
          if (!res.routePictureUrl) {
            this.$nextTick(() => {
              this.initMap()
            })
          }
          if (!this.sharePictureUrl && this.getENVIR() === 'wxapp') {
            this.creatPoster()
          }
        }
        // 礼物领取状态
        this.statusEnum = res.receiveJudgeVo?.statusEnum
        // 雷达图数据处理
        const categoryCodeActiveList = res.radarList[0]
        this.categoryCodeActive.push(categoryCodeActiveList.categoryCode)
        res.radarList.map((item, index) => {
          if (item.score === categoryCodeActiveList.score) {
            this.categoryCodeActive.push(item.categoryCode)
          }
        })

        this.detailInfo = res
        // 获取规则入口开关
        this.giftRule(res.appointmentId)
        const radarList = JSON.parse(JSON.stringify(res.radarList))
        radarList.sort((a, b) => {
          return a.categoryCode.localeCompare(b.categoryCode)
        })
        // 雷达图顺序跟接口返回数据顺序对应ABCDE----EBCAD
        const codeList = ['E', 'B', 'C', 'A', 'D']
        radarList.map((item, index) => {
          this.detailInfo.radarList[index] = { ...item, interfaceCode: codeList[index] }
        })
        this.detailInfo.radarList.sort((a, b) => {
          return a.interfaceCode.localeCompare(b.interfaceCode)
        })

        // 初始化echarts  
        this.$nextTick(() => {
          this.chartsInit()
          this.chartInit2('chart-container-gauge')
          this.chartInit2('chart-container-gauge2')
        })
      }
    },
    // 生成海报
    async creatPoster() {
      this.$nextTick(async () => {
        const arr = [this.chartInit2('chart-container-gauge2')]
        if (this.oneId) {
          arr.push(this.createQrcode())
        }
        Promise.all(arr).then((res1) => {
          this.qrcode = res1[1]
        }).then(async () => {
          const res = await this.getRouteImageUrl('poster')
          const data = await this.uploadToCOS(res.Key, res.file, 'sharePictureUrl')
          testDriveServices.updateDriveRecordReportV3(data).then((imgInfo) => {
            this.sharePictureUrl = imgInfo.sharePictureUrl
          })
        })
        // setTimeout(async () => {
        //   const res = await this.getRouteImageUrl('poster')
        //   await this.uploadToCOS(res.Key, res.file, 'sharePictureUrl').then(() => {
        //     this.init('poster')
        //   })
        // }, 2000)
      }) 
    },
    async initMap() {
      const centerPoint = this.detailInfo?.gps?.length > 0 ? [this.detailInfo.gps[0].longitude, this.detailInfo.gps[0].latitude] : [ 117.239713, 31.702666 ]
      // 创建地图
      this.map = new AMap.Map('container', {
        // viewMode: '3D',
        // pitch: 50,
        // skyColor: '#0d171a',
        zoom: 17,
        // resizeEnable: false, // 是否监控地图容器尺寸变化，默认值为false
        animateEnable: false, // 地图平移过程中是否使用动画,默认为true，即使用动画
        dragEnable: false, // 地图是否可通过鼠标拖拽平移，默认为true
        zoomEnable: false, // 地图是否可缩放，默认值为true
        center: centerPoint,
        mapStyle: 'amap://styles/dark', // 黑色主题地图
        WebGLParams: {
          preserveDrawingBuffer: true
        }
      })
      const arr = [this.drawPoint(), await this.drawRoute()]
      this.map.on('complete', () => {
        Promise.all(arr).then(() => {   
          setTimeout(async () => {
            const res = await this.getRouteImageUrl('container')
            const data = await this.uploadToCOS(res.Key, res.file, 'routePictureUrl')
            testDriveServices.updateDriveRecordReportV3(data) 
          }, 3000)         
        })
      })

    },
    // 绘制地图上的点
    drawPoint() {
      // 创建一个 Icon
      const startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(25, 34),
        // 图标的取图地址
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        // 图标所用图片大小
        imageSize: new AMap.Size(135, 40),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(-9, -3)
      })

      // 将 icon 传入 marker
      const startMarker = new AMap.Marker({
        position: new AMap.LngLat(this.detailInfo.gps[0].longitude, this.detailInfo.gps[0].latitude),
        icon: startIcon,
        offset: new AMap.Pixel(-13, -30)
      })

      // 创建一个 icon
      const endIcon = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        imageSize: new AMap.Size(135, 40),
        imageOffset: new AMap.Pixel(-95, -3)
      })

      // 将 icon 传入 marker
      const endMarker = new AMap.Marker({
        position: new AMap.LngLat(this.detailInfo.gps[this.detailInfo.gps.length - 1].longitude, this.detailInfo.gps[this.detailInfo.gps.length - 1].latitude),
        icon: endIcon,
        offset: new AMap.Pixel(-13, -30)
      })

      // 将 markers 添加到地图
      this.map.add([startMarker, endMarker])
    },
    // 根据类型获取点节点
    getPointDivByType (content, active = false) {
      const activeClassName = active ? 'active' : ''
      // return `<div class="map-point ${activeClassName}">${content}</div>`
      return `<div class="map-point ${activeClassName}"></div>`
    },
    // 设置地图容器高宽
    updateMapContainerSize(width, height) {
      const container = document.getElementById('container')
      if (width) {
        container.style.width = width + 'px'
      } else {
        container.style.width = this.containerWidth + 'px'
      }

      if (height) {
        container.style.height = height + 'px'
      } else {
        container.style.height = this.containerHeight + 'px'
      }

      // 设置地图大小
      // this.amap.setFitView()
    },
    // 生成图片
    async getRouteImageUrl(el) {
      const container = document.getElementById(el)
      this.containerWidth = container.offsetWidth
      this.containerHeight = container.offsetHeight

      return new Promise(function (resolve, reject) {
        html2canvas(document.getElementById(el), {
          allowTaint: true, // 允许跨域
          backgroundColor: null,
          useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
          scale: 3
        }).then(async function (canvas) {
          canvas.toBlob(async (blob) => {
            const Key = `poster-${Date.now()}.png`
            const file = new File([blob], Key, { type: 'image/png' })
            resolve({ Key, file })
          })
        })
      })
    },
    // 临时上传
    uploadToCOS(key, file, picParamName) {
      return new Promise((resolve, reject) => {
        if (!file) return
        const myKey = `qw-scrm-h5/test-drive-report/${key}`
        this.cos.putObject({
          Bucket: this.bucket,
          Region: this.region,
          // Key: key,
          Key: myKey,
          Body: file,
        }, (err, data) => {
          if (err) {
            reject(err)
            return
          } 
          const url = `https://${data.Location}`
          const params = {
            id: this.detailInfo.id,
            [picParamName]: url,
          }
          resolve(params)
        })
      })
    },
    // 画线
    drawRoute() {
      this.clearRoute()
      const paths = this.detailInfo.gps.map(e => { return [e.longitude, e.latitude] })
      this.polyline = new AMap.Polyline({
        path: paths, // 设置线覆盖物路径
        strokeColor: '#B9921A',
        outlineColor: '#0D171A',
        borderWeight: 2,
        isOutline: true,
        strokeWeight: 5, // 线宽
        strokeStyle: 'solid', // 线样式
        showDir: true
      })
      this.map.add(this.polyline)
      
      // 设置地图大小
      this.map.setFitView()
    },
    // 清除地图上的路线
    clearRoute () {
      this.polyline && this.polyline.destroy()
    },
    // 展示规则
    async giftRule(id) {
      const res = await testDriveServices.giftConfigEntrance({ appointId: id })
      this.giftConfig = res
    },
    async onCallPhone() {
      if (this.$route.query.from === 'app') { 
        window.flutter_inappwebview.callHandler('makePhoneCall', this.detailInfo.salesMobile)
      } else {
        window.location.href = `tel://${this.detailInfo.salesMobile}`  
      } 
    },
    // 领取试驾礼品
    receive () {
      const appointmentId = this.detailInfo.appointmentId
      if (this.getENVIR() === 'wxapp') { 
        const receiveGiftUrl = `/pages/offlineTestDriveReportH5/offlineTestDriveReportH5?appointmentid=${appointmentId}`
        wx.miniProgram.navigateTo({
          url: receiveGiftUrl,
          success: function(res) {
            console.log('跳转试驾礼页面,success' + res)   
          },
          fail: function(res) {
            console.log('跳转试驾礼页面,fail' + res)   
          }
        })
      } else if (this.$route.query.from === 'app') { 
      // } else { 
        this.$router.push({
          path: '/receiveGift',
          query: {
            lang: this.lang,
            appointmentid: appointmentId,
            from: 'app',
            accessToken: this.accessToken
          }
        })
      }
    },
    
    chartsInit () {
      this.$nextTick(() => {
        const deviceWidth = window.innerWidth
        const radiusNum = deviceWidth < 400 ? '40%' : '50%'
        const el = document.getElementById('chart-container-radar')
        const myChart = echarts.init(el)
        this.myChart1 = myChart
        const option = {
          radar: {
            // shape: 'circle',
            axisName: {  
              padding: [0, -8],
              rich: {
                A: {
                  width: 86,
                  height: 36,
                  backgroundColor: {
                    image: this.lang === 'zh_CN' ? this.categoryCodeActive.includes('D') ? light1Active : light1 : this.categoryCodeActive.includes('D') ? light1ActiveEn : light1En 
                  }
                },
                B: {
                  width: 86,
                  height: 36,
                  backgroundColor: {
                    image: this.lang === 'zh_CN' ? this.categoryCodeActive.includes('B') ? light2Active : light2 : this.categoryCodeActive.includes('B') ? light2ActiveEn : light2En 
                  }
                },
                C: {
                  width: 86,
                  height: 36,
                  backgroundColor: {
                    image: this.lang === 'zh_CN' ? this.categoryCodeActive.includes('C') ? light3Active : light3 : this.categoryCodeActive.includes('C') ? light3ActiveEn : light3En 
                  }
                },
                D: {
                  width: 86,
                  height: 36,
                  backgroundColor: {
                    image: this.lang === 'zh_CN' ? this.categoryCodeActive.includes('E') ? light4Active : light4 : this.categoryCodeActive.includes('E') ? light4ActiveEn : light4En 
                  }
                },
                E: {
                  width: 86,
                  height: 36,
                  backgroundColor: {
                    image: this.lang === 'zh_CN' ? this.categoryCodeActive.includes('A') ? light5Active : light5 : this.categoryCodeActive.includes('A') ? light5ActiveEn : light5En 
                  }
                },
              },  
              formatter: function (value, indicator) {
                const str = value.toString()
                return `{${str}|}`
              }
            },
            center: ['50%', '50%'],
            radius: radiusNum,
            // radius: '40%',
            indicator: [
              { name: 'A', max: 100 },
              { name: 'B', max: 100 },
              { name: 'C', max: 100 },
              { name: 'D', max: 100 },
              { name: 'E', max: 100 }
            ],
            splitArea: {
              areaStyle: {
                color: 'rgba(238, 212, 132, 0.25)'
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(216, 216, 132, 0.35)'
              }
            },
            splitLine: {
              show: false,
            },
            triggerEvent: true // 只有加上这个，才能绑定上
          },
          series: [
            {
              name: '雷达图',
              type: 'radar',
              emphasis: {
                lineStyle: {
                  width: 4
                }
              },
              data: [
                {
                  value: this.detailInfo.radarList.map(item => { return item.score }),
                  itemStyle: {
                    normal: {
                      color: 'rgb(238, 212, 132)'
                    }
                  },
                  areaStyle: {
                    color: 'rgb(238, 212, 132)'
                  }
                }
              ]
            }
          ]
        }

        option && this.myChart1.setOption(option)
        // 为雷达图指示器添加点击事件
        const that = this
        this.myChart1.on('click', function (params) {
          if (params.targetType === 'axisName') {
            that.goHighLightPage(params)
          }
        })
      })
    },
    chartInit2 (elId) {
      this.$nextTick(() => {
        const el = document.getElementById(elId)
        const myChart = echarts.init(el)
        if (el === 'chart-container-gauge') {          
          this.myChart2 = myChart
        } else {
          this.myChart3 = myChart
        }
        
        const deviceWidth = window.innerWidth
        const option = {
          series: [
            {
              // 外层圆环-一根线
              type: 'gauge',
              clockwise: false, // 逆时针增长
              animation: false,
              center: ['30%', '50%'],
              radius: deviceWidth < 400 ? '96%' : '90%',
              startAngle: -120,
              endAngle: 100,
              splitNumber: 4,
              min: 0,
              max: 100,
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: '#00000000' // 0% 处的颜色
                  }, 
                  {
                    offset: 0.5, color: '#EED484' // 50% 处的颜色
                  },  
                  {
                    offset: 1, color: '#00000000' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              },
              pointer: {
                show: false
              },
              axisLine: { // 仪表盘轴线相关配置
                lineStyle: {
                  width: 1,
                  color: [
                    [1, '#cccccc33']
                  ],
                  opacity: 1
                }
              },
              splitLine: { // 分隔线样式
                show: false
              },
              axisTick: { // 刻度样式
                show: false
              },
              axisLabel: { // 刻度标签
                show: false
              }
            },
            {
              // 中间主体
              type: 'gauge',
              clockwise: false, // 逆时针增长
              animation: false,
              center: ['30%', '50%'],
              radius: deviceWidth < 400 ? '70%' : '60%',
              startAngle: -90,
              endAngle: 90,
              splitNumber: 4,
              min: 0,
              max: 100,
              itemStyle: {
                color: '#FFAB91'
              },
              progress: {
                show: true,
                width: 18,
                // overlap: false, // 多组数据时进度条是否重叠
                clip: false, // 是否裁掉超出部分。
                itemStyle: {    
                  // 定义颜色渐变 
                  /* 
                    线性渐变，前四个参数分别是 x0, y0, x2, y2, 
                    范围从 0 - 1，相当于在图形包围盒中的百分比，
                    如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
                   */
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#E0BF5C' // 0% 处的颜色
                    }, 
                    {
                      offset: 1, color: '#E0BF5C00' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                }
              },
              pointer: {
                show: false
              },
              axisLine: { // 仪表盘轴线相关配置
                lineStyle: {
                  width: 18,
                  color: '#E0BF5C94'
                }
              },
              axisTick: { // 刻度样式
                // show: false,
                distance: -30,
                lineStyle: {
                  width: 2,
                  color: '#999'
                }
              },
              splitLine: { // 分隔线样式
                // show: false,
                distance: -20,
                length: 0,
                lineStyle: {
                  width: 2,
                  color: '#999'
                }
              },
              axisLabel: { // 刻度标签
                // show: false,
                distance: -38,
                color: '#999',
                fontSize: 10,
                fontFamily: 'VWHead-Bold',
                formatter: '{value} %',
              },
              anchor: {
                show: false
              },
              title: {
                offsetCenter: [0, '-18%'],
                fontSize: 10,
                color: '#DBDBDB',
                fontFamily: 'HYQiHei'
              },
              detail: {
                valueAnimation: true,
                fontSize: 22,
                offsetCenter: [0, '15%'],
                color: '#EED484', // 中间比例颜色
                formatter: '{value} %',
                width: '60%',
                borderRadius: 8,
                fontFamily: 'VWHead-Bold'
              },
              data: [
                {
                  value: this.detailInfo.nationalRanking.replace(/%/g, ''),
                  name: this.lang === 'zh_CN' ? '超越全国用户数' : 'Exceeding',
                  itemStyle: {
                    normal: {
                      fontSize: 8,
                      color: 'rgb(238, 212, 132)'
                    }
                  },
                }
              ]
            },
            {
              // 上方阴影部分
              type: 'gauge',
              animation: false,
              center: ['30%', '50%'],
              radius: deviceWidth < 400 ? '70%' : '60%',
              startAngle: 100,
              endAngle: -100,
              splitNumber: 4,
              min: 100,
              max: 0,
              progress: {
                show: true,
                width: 30,
                itemStyle: {    
                  // 定义颜色渐变
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#00000000' // 0% 处的颜色
                    }, {
                      offset: 0.5, color: '#E0BF0043' // 50% 处的颜色
                    }, {
                      offset: 1, color: '#00000000' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                }
              },
              pointer: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false,
                inverse: true,
              },
              detail: {
                show: false
              },
              data: [
                {
                  value: 50
                }
              ]
            },
            {
              type: 'gauge',
              animation: false,
              center: ['30%', '50%'],
              radius: deviceWidth < 400 ? '70%' : '60%',
              startAngle: 100,
              endAngle: -100,
              splitNumber: 4,
              min: 100,
              max: 0,
              progress: {
                show: true,
                width: 22,
                itemStyle: {    
                  // 定义颜色渐变
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: '#00000000' // 0% 处的颜色
                    }, {
                      offset: 0.5, color: '#E0BF5C77' // 50% 处的颜色
                    }, {
                      offset: 1, color: '#00000000' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                }
              },
              pointer: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false,
                inverse: true,
              },
              detail: {
                show: false
              },
              data: [
                {
                  value: 50
                }
              ]
            }
          ]
        }

        if (el === 'chart-container-gauge') {    
          option && this.myChart2.setOption(option)
        } else {
          option && this.myChart3.setOption(option)
        }
      })
    },
    // 判断当前运行环境是否小程序
    getENVIR() {
      const ua = window.navigator.userAgent.toLowerCase()
      const isWeixin = ua.indexOf('micromessenger') !== -1

      if (isWeixin) {
        if (window.__wxjs_environment === 'miniprogram') {       
          return 'wxapp'
        } else {
          return 'wxh5'
        }
      } else {
        return 'browser'
      }
    },
    // 了解更多--跳转小程序或APP
    goMorePage(data, index) {
      if (this.getENVIR() === 'wxapp') {
        let wxUrlEnv = data.wxUrl[process.env.VUE_APP_ENV || 'dev'] || data.wxUrl
        wx.miniProgram.navigateTo({
          url: wxUrlEnv,
          success: function(res) {
            console.log('跳转小程序页面,success' + res)   
          },
          fail: function(res) {
            console.log('跳转小程序页面,fail' + res)   
          }
        })
      } else if (this.$route.query.from === 'app') {
      // } else {
        if (data.appUrl) {
          let appUrlEnv = data.appUrl[process.env.VUE_APP_ENV || 'dev']
          if (index === 3) {
            appUrlEnv = appUrlEnv.concat('?local=' + this.lang.toLowerCase())
          }       
          window.location.href = appUrlEnv
        } else {
          window.flutter_inappwebview.callHandler(data.jsBridgeMethod, data.jsBridgeParams)         
        }
      }
    },
    // 跳转亮点页面
    goHighLightPage(data) {
      this.pageActive = 2
      this.activeTab = 0
      const type = data.name.replace(/{/g, '').replace(/}/g, '').replace(/\|/g, '')
      this.highLightTitle = this.lang === 'zh_CN' ? radarLabel[type] : radarLabelEn[type]
      // 根据雷达图的name找到数据列表中对应的interfaceCode，再去拿interfaceCode这一条数据的detail
      const item = this.detailInfo.radarList.find(i => i.interfaceCode === type)
      const highLightShowList = highlightImgVideoList[type]
      highLightShowList.map(highItem => {
        item.detail.map(i => {
          if (i.subCategoryCode === highItem.subCategoryCode) {
            highItem.isFullScore = i.score !== 0
          }
        })
      })
      highLightShowList.sort(function (a, b) {
        return a.isFullScore - b.isFullScore
      })
      this.showHighLightList = highLightShowList
      this.showVideo = false
      setTimeout(() =>{
        this.showPlayIcon = true
      }, 500)
    },
    // 切换页面展示定级规则和亮点视频
    async changeActive(index) {
      this.pageActive = index
      if (index === 1) {
        this.$nextTick(() => {
          this.chartsInit()
          this.chartInit2('chart-container-gauge')
        })
      }
    },
    // 亮点图片视频切换展示
    changeSwipe(index) {
      this.activeTab = index
    },
    // 播放视频
    goPlay(url) {
      this.showVideo = true
      this.playerOptions.sources[0].src = url
      this.videoUrl = url
    },
    // 生成二维码
    createQrcode () {
      return new Promise( (resolve,reject) => {
        document.getElementById('qrcode').innerHTML = ''
        const res = 'https://uat-oneapp-web.idux-vw.com/inviteFriends?inviterID=AjkYw1pbXiu6sBXeEwgAgEWG&local=zh_CN'
        const qrcode = new QRcode(document.getElementById('qrcode'), {
          text: res,
          width: 74,
          height: 74,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRcode.CorrectLevel.H
        })
        resolve(qrcode)
      })
    },
    shareClick () {  
      if (!this.sharePictureUrl || !this.accessToken) {
        this.$toast(this.$t('海报图片生成中，请耐心等候!'))  
        return   
      }
      if (this.$route.query.from === 'app') {
        window.flutter_inappwebview.callHandler('sharePosterImage', this.sharePictureUrl)
      } else {
        this.showSharePictureUrl = true  
      }
      // if (this.getENVIR() === 'wxapp') {
      //   this.showSharePictureUrl = true  
      // } else {
      //   window.flutter_inappwebview.callHandler('sharePosterImage', this.sharePictureUrl)
      // }
    },  
  }
}
</script>
<style lang='less' scoped>
@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  to {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(-50%, 5px, 0);
    transform: translate3d(-50%, 5px, 0);
  }

  60% {
    -webkit-transform: translate3d(-50%, 3px, 0);
    transform: translate3d(-50%, 3px, 0);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  to {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(-50%, 5px, 0);
    transform: translate3d(-50%, 5px, 0);
  }

  60% {
    -webkit-transform: translate3d(-50%, 3px, 0);
    transform: translate3d(-50%, 3px, 0);
  }
}

.center {
  text-align: center !important;
}
.num-bold {
  font-family: 'VWHead-Bold';
}
.num-light {
  font-family: 'VWHead-Light';
}
.num-regular {
  font-family: 'VWHead-Regular';
}
.module-item {
  position: relative;    
  .big-title {
    padding-left: 20px;
    font-size: 20px;
    color: #EED484;
    background-image: url("./imgs/big-title-bg.png");
    background-size: 3px 20px;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 1;
  }
}
  
.module-3 {
  background-color: #1E1f23;
  border-radius: 10px;
  margin-top: 20px;
  .test-drive-track{
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .charts-gauge-box {
    width: 100%;
    height: 290px;
    .charts {
      width: 100%;
      height: 280px;
    }
  }
  // .charts-right {
  //   height: 300px;
  // }
  .save-money {
    padding: 10px;
    font-size: 14px;
    line-height: 26px;
    // margin-top: 20px;
  }
  .review-item {
    padding: 10px 6px;
    font-size: 14px;
    margin-top: 12px;
    .van-col {
      padding-bottom: 20px;
      div:first-of-type {
        color: #DBDBDB
      }
      div:last-of-type {
        color: #EED484
      }
    }
  }
}
.test-drive-report {
  height: 100vh;
  width: 100%;
  // background: #0f171a !important;
  background: #000000 !important;
  color: #ffffff !important;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  position: relative;
  .module-1 {
    .main-title {
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 16px;
    }
    .module-title {
      background-color: #6a5130;
      border-radius: 10px;
      .driver-title {
        background: linear-gradient(to top right, #eed484 0%, #82611b 75%);
        border-radius: 10px;
        .customer{
          display: flex;
          width: 100%;
          overflow: hidden;
          .left {
            flex: 1;
            padding: 20px 10px;
            .line-1 {
              font-size: 12px;
              // letter-spacing: 6px;
            }
            .line-2 {
              font-size: 22px;
              margin: 10px 0;
              // text-align-last: justify; // 文字自适应间距分散展示到整个宽度
            }
            .line-3 {
              font-size: 10px;
              color: #3e2f1b;
            }
            .line-4 {
              span{
                font-size: 10px;
                display: inline-block;
                padding: 0 2px;
                background-color: #8c7849;
                border-radius: 4px;
                &:first-of-type {
                  margin-right: 6px;
                }
              }
            }
          }
          .right{
            width: 40%;
            text-align: end;
            transform: skew(30deg, 0deg);
            padding: 20px 10px;
            background: linear-gradient(to top right, #eed484 0%, #82611b 75%);
            img {
              width: 100px;
              transform: skew(-30deg, 0deg);
            }

          }
        } 
      }
      .rule-explain {
        padding: 10px;
        color: #cc9f37;
        line-height: 16px;
        .van-col {
          &:first-of-type {
            img {
              height: 14px;
            }
          }
          &:last-of-type {
            text-align: end;
            img {
              height: 12px;
            }
          }
        }
      }
    }
  }
  .module-2 {
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    background-image: url("./imgs/module2-bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    .charts-radar-box {
      width: 100%;
      height: 320px;
      padding-top: 40px;
      .charts {
        width: 100%;
        height: 320px;
      }
    }
  }
  .module-4 {
    background-color: #1E1f23;
    border-radius: 10px;
    margin-top: 20px;
    .sales-store { 
      margin-top: 50px;
      padding: 12px 20px; 
      .van-col {
        &:last-of-type {
          height: 50px;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
      }
    }
    .sales-onsultant {
      padding: 12px 20px; 
      .van-col {
        &:nth-of-type(2) {
          padding-left: 10px;
        }
        &:last-of-type {
          text-align: right;
          a {
            display: inline-block;
            height: 44px;
          }
        }
        div {
          &:last-of-type {
            color: #949494;
          }
        }
      }
    }
  }
  .module-5 { 
    background-image: url("./imgs/module5-bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin-top: 20px;
    .more-information {
      margin-top: 50px;
      padding: 20px 10px;
      font-size: 12px;
      // line-height: 40px;
      .van-row  {
         margin: 16px 0;
        .van-col {
          // line-height: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          &:first-of-type {
            display: block;
            width: 50px;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 30px;
            text-align: center;
            img {
              margin-top: 14px;
            }
          }
          &:nth-of-type(2) {
            padding-left: 10px;
          }
          &:last-of-type {
            color: #EED484;
            justify-content: flex-end;
            text-align: end;
            img {
              height: 12px;
              padding-left: 4px;
            }
          }
        }
      }
    }
  }
  .module-6 {
    // background-color: #1E1f23;
    margin-top: 20px;
    margin-bottom: 40px;
    .gift-bg {
      background-image: url("./imgs/module6-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-color: #0f171a;
    }
    .no-gift-bg {
      background-image: url("./images/no-gift-bg.png");
      background-size: 100%, 100%;
      background-repeat: no-repeat;
      background-color: #0f171a;
    }
    .gift { 
      height: 300px;
      position: relative;
      // margin-bottom: 20px;
      .image {
        width: 100%;
        // overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      .footer{
        display: flex;
        position: absolute;
        bottom: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;
        .outer{
          padding: 2px 3px;
          border-radius: 5px;
          background-color: @yellow;
          .btn{
            width: fit-content;
            padding: 0 27px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 3px;
            color: #000000;
          }
        }
      }
    }
  }
  .module-7 {
    position: fixed;
    bottom: 5px;
    right: 10px
  }

  .page-item-home{
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    height: 100%;
    background-color: #0f171a;
  
    .report-title{
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 16px;
      font-weight: bold;
      color: #efd484;
    }
  }
  .page-bg { 
    width: 100%;
    background-image: linear-gradient(to bottom, #eed484, #000000, #000000, #000000, #000000, #000000, #000000);
    position: relative;
    z-index: 10;
  }
  .template2{
    // height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    background: #000000;
    .module-container {
      width: 94%;
      margin: 20px 3%;
      padding: 20px 10px;
      background: linear-gradient(to bottom right, #333333 0%, #000000 100%);
    }
    img{
      width: 100%;
      margin: 0 !important;
      vertical-align: top;
    }
    .goBack{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      p{
        flex: 1;
        color: white !important
      }
    }
    .fz{
      font-size: 28px;
      // margin: 10px 0;
      padding: 0 10px;
    }
    .text {
      color: #949494;
      font-size: 16px;
      margin: 10px;
      line-height: 28px;
    }
    .swipeTitle{
      display: flex;
      color: #949494;
      justify-content: space-around;
      margin: 12px 0;
      height: 60px;
      .item{
        width: fit-content;
        display: flex;
        // height: 26px;
        line-height: 26px;
        font-size: 20px;
        position: relative;
        flex: 1;
        align-items: center;
        &.active{
          color: #ffffff;
          font-size: 24px;
          &::after{
            content: '';
            height: 2px;
            width: 30px;
            font-size: 26px;
            background: #CDBD83;
            border-radius: 2px;
            position: absolute;
            bottom: -3px;
          }
        }
      }
    }
    .image-container {
      position: relative;
      .frame1 {
        display: block;
        width: 100%;
      }
    }     
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -50px;
      background: #10111245; /* 黑色半透明蒙层 */
    }
    /deep/ .van-swipe{
      // flex: 1;
      padding-bottom: 20px;
      .van-swipe-item{
        padding: 0 10px;
        position: relative;
        width: 330px;
        .no-full {
          position: absolute;
          top: 100px;
          right: 20px;
          color: #000000;
          background-color: #EED484;
          padding: 0 6px;
          text-align: center;
          border-radius: 4px;
        }
        .playIcon{
          width: 100px;
          position: absolute;
          top: calc(36vh - 50px);
          left: 80px;
          color: white;
          text-align: center;
        }
        .footer {
          position: absolute;
          bottom: 10px;
          .describe {
            font-size: 12px;
            line-height: 16px;
            color: #EED484;
            padding: 2px 10px;
          }
        }
      }
    }
  }
  /deep/ .video-player {
    padding: 0;
    height: 100%;
    width: 100vw;
    &.pdf{
      height: 100%;
      width: 100%;
    }
    .video-js{
      height: 100%;
      width: 100%;
    }
    // .vjs-user-active {
      .vjs-big-play-button {
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 30px);
        width: 60px;
        height: 30px;
        // font-size: 2em;
        font-size: 20px;
      }
    // }
  }
  .template3 {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    .goBack {
      width: 100%;
      color: #B2B6C1;
      height: 32px;
      line-height: 32px;
      display: flex;
      align-items: center;
      p{
        flex: 1;
        color: white !important
      }
    }
  }
}
.share-poster {
  // height: 100vh;
  width: 100%;
  // background: #0f171a !important;
  background: #000000 !important;
  color: #ffffff !important;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  .home-bg {
    position: relative;
    width: 340px;
    // max-width: 400px;
    margin: 0 auto;
    overflow-y: scroll;
    padding: 0px;
    background: linear-gradient(to bottom right, #63523d9e 25%, #000000 50%, #000000 75%, #000000 100%);
  }
  .share-img {
    width: 100%;
  }
  .introduce{
    // height: 100%;
    // background-image: url("./imgs/share-bg.png");
    // background-size: 100%;
    // background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    top: 0;
    .title-box {
      padding-top: 80px;
      .van-col {
        &:first-of-type {
          text-align: center;
          img {
            height: 80px;
          }
        }
        &:last-of-type {
          div {
            &:first-of-type {
              font-size: 12px;
              line-height: 20px;
              word-spacing: 6px;
              padding-left: 10px;
            }
            &:last-of-type {
              font-size: 48px;
              line-height: 48px;
              text-align-last: justify; // 文字自适应间距分散展示到整个宽度
            }
          }
        }
      }
    }
  }
  .module-3 {
    // margin: -100px 16px 0 16px;
    padding: 50px 0 0 0;
    background-color: #0f191dba;
    border-radius: 10px;
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 120px;
    .charts-gauge-box {
    width: 100%;
    height: 250px;
    .charts {
      width: 100%;
      height: 250px;
    }
  }
    .review-container {
      background-color: #1e1f23;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      // border-radius: 10px;
    }
    .save-money {
      padding: 10px;
      font-size: 14px;
      line-height: 20px;
      margin-top: 0;
    }
    .review-item {
      padding: 10px 6px;
      font-size: 12px;
      margin-top: 12px;
      .van-col {
        padding-bottom: 6px;
        div:first-of-type {
          color: #DBDBDB
        }
        div:last-of-type {
          color: #EED484
        }
      }
    }
  }
  .qrcode-box {
    background-color: #1e1f23;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 10px;
    .qrcode-text {
      display: flex;
      height: 80px;
      align-items: center;
      padding: 0 10px;
      color: #eed484;
      font-size: 12px;
      line-height: 16px;
    }
    .qrcode-img {
      width: 80px;
      height: 80px;
      padding: 3px;
      background-color: #ffffff;
      text-align: end;
      div {
        width: 74px;
        height: 74px;
      }
    }
  }
}
/deep/ .van-popup{
  background: #000000 !important;
  .share-poster-img {
    text-align: center;
    padding: 40px 0 100px 0;
    .close-icon {
      text-align: center;
    }
  }
}
/deep/ .amap-logo{
  display: none !important;
}
/deep/ .amap-copyright{
  display: none !important;
}
</style>
