import i18nFun from '../router_i18n'
const appName = i18nFun.clueManagement
const routerPath = 'clueHome'
const routes = [
  {
    path: '/clue-list',
    name: 'ClueHome',
    component: () => import('@/modules/clue/index'),
    meta: {
      routerPath
    },
    beforeEnter(to, from, next) {
      to.meta.appName = appName
      const { noticeType = '' } = to.query
      if (noticeType == 'reduce') {
        to.meta.title = i18nFun.clueDowngradingManagement
      } else if (noticeType == 'defeat') {
        to.meta.title = i18nFun.defeatWarningReminder
      } else to.meta.title = i18nFun.clueManagement
      next()
    },
  },
  {
    path: '/create-clue',
    name: 'createClue',
    component: () => import('@/modules/clue/create'),
    meta: {
      title: i18nFun.createNewClue,
      appName,
      routerPath
    }
  },
  {
    path: '/clue-remark',
    name: 'clueRemark',
    component: () => import('@/modules/clue/edit/remark'),
    meta: {
      title: i18nFun.clueRemark,
      appName,
      routerPath
    }
  },
  {
    path: '/clue-follow',
    name: 'clueFollow',
    component: () => import('@/modules/clue/follow'),
    meta: {
      title: i18nFun.followUp,
      appName,
      routerPath
    }
  },
  {
    path: '/clue-details',
    name: 'clueDetails',
    component: () => import('@/modules/clue/details'),
    meta: {
      routerPath
    },
    beforeEnter(to, from, next) {
      to.meta.appName = appName
      const { customerName } = to.query
      if (customerName) to.meta.title = `${customerName} ${i18nFun.clueDetails}`
      else to.meta.title = i18nFun.clueDetails
      next()
    },
  },
  {
    path: '/edit-clue',
    name: 'editClue',
    component: () => import('@/modules/clue/edit'),
    meta: {
      routerPath
    },
    beforeEnter(to, from, next) {
      to.meta.appName = appName
      const { operate } = to.query
      if (operate == 'edit') {
        to.meta.title = i18nFun.clueEditing
      } else if (operate == 'create') {
        to.meta.title = i18nFun.improveCustomerInformation
      } else {
        to.meta.title = i18nFun.clueDetails
      }
      next()
    },
  },
  {
    path: '/shop-clue-search',
    name: 'shopClueSearch',
    component: () => import('@/modules/clue/shop-clue-search'),
    meta: {
      title: i18nFun.instoreClueInquiry,
      appName,
      routerPath
    }
  },
  {
    path: '/shop-clue-search-list',
    name: 'shopClueSearchList',
    component: () => import('@/modules/clue/shop-clue-search/search-list.vue'),
    meta: {
      title: i18nFun.instoreClueInquiry,
      appName,
      routerPath
    }
  },
  {
    path: '/clue-merge',
    name: 'mergeClue',
    component: () => import('@/modules/clue/mergeClue/index.vue'),
    meta: {
      title: i18nFun['合并线索'],
      appName,
      routerPath
    }
  },
  {
    path: '/clue-merge-confirm',
    name: 'mergeClueConfirm',
    component: () => import('@/modules/clue/mergeClue/mergeConfirm.vue'),
    meta: {
      title: i18nFun['合并线索'],
      appName,
      routerPath
    }
  },
  {
    path: '/create-wechat-clue',
    name: 'createWechatClue',
    component: () => import('@/modules/clue/createWechatClue.vue'),
    meta: {
      title: i18nFun.createNewClue,
      appName,
      routerPath
    }
  },
  {
    path: '/clue-transfer',
    name: 'clueTransfer',
    component: () => import('@/modules/clue/transferClue/index.vue'),
    meta: {
      title: i18nFun['线索分配'],
      appName,
      routerPath
    }
  }
]

export default routes
