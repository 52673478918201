<script>
import gender_woman from '@/images/gender_woman.png'
import gender_unknown from '@/images/gender_unknown.svg'
import gender_man from '@/images/gender_man.png'
import IntentionLevel from '@/components/card-view/intention-level.vue'
export default {
  components:{ IntentionLevel },
  filters: {
    desensitization(val, type, isDesensitization) {
      if (!isDesensitization) return val
      let str = ''
      if (type === 'name' && val) {
        val = val.substring(0, 1) + '**'
        str = val
      }
      if (type === 'phone') {
        let reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/
        if (val) str = val.replace(reg, '$1****$2')
      }
      return str
    },
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    option: {
      type: Object,
      default: () => ({})
    },
    // 是否需要脱敏处理
    isDesensitization: {
      type: Boolean,
      default: false
    },
    // 是否展示试驾等级
    showLevel: {
      type:Boolean,
      default: true
    },
    // 是否显示意向等级
    showIntention:{
      type: Boolean,
      default: true
    },
    // 是否显示性別
    showSex:{
      type: Boolean,
      default: true
    },
    // 是否显示呼叫类型lastViCallType  0：顾问呼出，1：客户呼入
    showCallType:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keys: {
        name: 'userName',
        sex: 'userSex',
        phone: 'userMobile',
        tryDriveLevel:'tryDriveLevel',
        leadLevel:'leadLevel'
      }
    }
  },
  computed: {
    getSex() {
      const obj = {
        1: gender_man,
        2: gender_woman,
        0: gender_unknown,
      }
      return obj[this.data[this.keys.sex]] || gender_unknown
    },
    getLevel() {
      const code = this.data[this.keys.tryDriveLevel]
      const obj = this.$store.getters.getDictHash(1012).find(item=>{
        return item.code === code
      })
      return obj && obj.name 
    }
  },
  watch: {
    option: {
      handler(val) {
        Object.assign(this.keys, val)
      },
      immediate: true,
    }
  }
}
</script>
<template>
<div class="info">
  <div class="info-l">
    <div class="name van-ellipsis">{{ data[keys.name] }}</div>
  </div>
  <div class="phone van-ellipsis">{{ data[keys.phone] }}</div>
  <img v-if="showSex" class="sex" :src="getSex" width="14px" height="14px"/>
  <div v-if="showCallType && [0,1].includes(data.lastViCallType)" class="call-type">{{ data.lastViCallType == 1 ? '客户呼入' : data.lastViCallType == 0 ? '顾问呼出' : '' }}</div>
  <!-- 试驾后不显示“试驾意向等级”，要显示“购车意向等级”
  未试驾的线索，若购车评级为H 两个等级同时显示 -->
  <!--  <template v-if="data.drivingTime || (!data.drivingTime && data[keys.leadLevel] === 'H')">
    <IntentionLevel v-if="showIntention && data[keys.leadLevel]" :level="data[keys.leadLevel]"></IntentionLevel>
  </template>
  <template v-if="!data.drivingTime">
    <span v-if="showLevel&&data[keys.tryDriveLevel]" class="level">{{getLevel}}</span>
  </template> -->
  <slot></slot>
</div>
</template>
<style scoped lang="less">
.info {
  display: flex;
  align-items: center;
  flex: 1;

  .info-l {
    display: flex;
    align-items: center;
    margin-right: 8px;
    position: relative;
    .sex {
      width: 14px;
      height: 14px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 70%;
      background-color: #D9D9D9;
      transform: translateY(-50%);
    }

    .name {
      margin-right: 8px;
      max-width: 72px;
      font-size: 16px;
      line-height: 18px;
      color: #323232;
    }
  }

  .phone {
    font-size: 14px;
    line-height: 16px;
    color: #323232;
    margin-right: 4px;
  }
  .level{
    background-color: @yellow;
    border-radius: 8px;
    padding: 1px 4px;
    color: #0D171A;
    font-size: 10px;
    margin-left: 4px;
    flex-shrink: 0;
  }
  .call-type {
    font-size: 10px;
    background: #f3ebd1;
    padding: 2px 4px;
    margin-left: 4px;
  }
}</style>
