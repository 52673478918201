import i18nFun from '../router_i18n'
const appName = i18nFun['销售助手']
const routerPath = 'salesAssistantPortal'

const routes = [
  {
    path: '/sales-assistant-portal',
    redirect: 'home',
    component: () => import('@/modules/sales-assistant-portal/index'),
    meta: {
      title: i18nFun['销售助手'],
      appName,
      isAppIndex: true,
      routerPath,
    },
    children: [
      {
        path: 'home',
        name: 'salesAssistantPortalHome',
        component: () => import('@/modules/sales-assistant-portal/home'),
        meta: {
          routerPath
        }
      },
      {
        path: 'user',
        name: 'salesAssistantPortalUser',
        component: () => import('@/modules/clue/index.vue'),
        meta: {
          routerPath
        }
      },
      {
        path: 'staging',
        name: 'salesAssistantPortalStaging',
        component: () => import('@/modules/sales-assistant-portal/staging'),
        meta: {
          routerPath
        }
      },
    ],
  },
  {
    path: '/invalidDefeat',
    name: 'InvalidDefeat',
    component: () => import('@/modules/sales-assistant-portal/components/InvalidDefeat.vue'),
    meta: {
      appName,
      routerPath
    },
    beforeEnter(to, from, next) {
      const { type = '' } = to.query
      if (type == 'defeat') {
        to.meta.title = i18nFun['战败审核']
      } else {
        to.meta.title = i18nFun['无效审核']
      }
      next()
    },
  },
]

export default routes
