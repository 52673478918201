<template>
  <div :class="['clue-list', showBottom ? 'clue-list-b' : '']">
    <div style="position: sticky; top: 0px; z-index: 20;">
      <search-bar-new
        :placeholder-text="$t('请输入客户姓名/电话/顾问/隐私号/线索ID')"
        :str="searchStr"
        :showScreen="true"
        :showCategory="
          ['REDUCE', 'DEFEAT'].includes(leadStageCode) ? false : true
        "
        :actions="actions"
        :active="Object.values(filterParams).length > 0"
        @input="onInput"
        @search="onRefresh"
        @screening="filterPanelVisible = true"
        @operation="operation"
      >
      </search-bar-new>
      <FilterPanel
        ref="filterPanel"
        v-model="filterPanelVisible"
        :options="filterOptions"
        @submit="onFilter"
      />
      <VanTabs
        v-if="clueTablist.length > 0 && ['LEAD', 'OPPORTUNITY'].includes(leadStageCode)"
        :queryType="firstQueryType"
        :tabList="clueTablist"
        @change="changeTab"
      >
      </VanTabs>
      <VanTabs
        v-if="tabList.length > 0 && leadStageCode === 'REDUCE'"
        :queryType="queryType"
        :tabList="tabList"
        @change="changeTab"
      >
      </VanTabs>
      <ListSort :options="sortOptions" @change="onSort"></ListSort>
    </div>
    <div
      v-show="['LEAD', 'OPPORTUNITY'].includes(leadStageCode) && firstQueryType === 0"
      class="totalCount"
    >
      {{ $t('共') }} {{ totalCount}} {{ $t('条数据') }}
    </div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <div class="item-wrap">
          <ClueItem
            :dataSource="item"
            :isDistributionState="isDistributionState"
            @change="onCheckChanged"
            @goDetail="goDetail(item)"
          />
          <div v-if="showSingleCheck" class="select-icon" @click.stop="onSelect(item)">
						<van-icon
                v-if="currentObj.id == item.id"
                name="checked"
                color="#EED484"
                size="16"
              />
              <van-icon v-else size="16" name="circle" color="#0D171A" />
					</div>
        </div>
      </template>
    </List>
    <div v-if="showSingleCheck" class="footer safe-area-inset-bottom">
      <div class="action-box">
        <van-button
          type="primary"
          size="small"
          :disabled="!currentObj.id"
          @click="onBindClue"
        >
          关联微信客户
        </van-button>
      </div>
    </div>
    <!-- 员工列表面板 -->
    <BusinessFilters
      ref="filter"
      v-model="showDistribution"
      :options="distributionOptions"
      :defaultActiveKey="['saIdList']"
      :buttonNameReset="$t('取消')"
      @reset="showDistribution = false"
      @submit="distributionFilter"
    >
    </BusinessFilters>
    <div v-if="isDistributionState" class="action-bar">
      <div class="left">
        <span @click="cleanDistribution">{{
          $t('取消')
        }}</span>
        <span @click="onAllChecked">{{
          $t('全选')
        }}</span>
      </div>
      <div class="right">
        <van-button square color="#EED484" @click="onDistribution">
          {{ $t('分配')
          }}{{ distributionIds.length ? `(${distributionIds.length})` : "" }}
        </van-button>
      </div>
    </div>
    <!-- 分配线索 -->
    <TransferClue
      v-if="showTransfer"
      ref="transferRef"
      v-model="showTransfer"
      :distributionRole="distributionRole"
      :distributionIds="distributionIds"
      @close="showTransfer = false"
      @transferConfirm="transferConfirm">
    </TransferClue>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SearchBarNew from '@/components/search-bar-new'
import clueServices from '@/services/clueServices'
import clueDistributionServices from '@/services/clueDistributionServices'
import baseDataServices from '@/services/baseDataServices'
import OptionsBox from '@/components/OptionsBox'
import VanTabs from '@/components/VanTabs'
import List from '@/components/baseList/list.vue'
import ClueItem from '../component/ClueItem'
import FilterPanel from '@/components/filter-panel'
import ListSort from '@/components/list-sort/Index.vue'
import loading from '@/utils/loading'
import formatParams from '@/utils/formatParams'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import TransferClue from '../component/transferClue.vue'
import mixin from './mixin'
import dayjs from 'dayjs'

export default {
  name: 'ClueList',
  components: {
    List,
    FilterPanel,
    SearchBarNew,
    VanTabs,
    OptionsBox,
    ClueItem,
    BusinessFilters,
    ListSort,
    TransferClue
  },
  mixins: [mixin],
  props: {
    leadStageCode: {
      type: String,
      default: 'LEAD', // LEAD:线索 OPPORTUNITY:商机  REDUCE:线索降级 DEFEAT:战败
    },
    noticeTime: {
      type: String,
      default: '',
    },
  },
  data() { 
    return {
      sort: [{
        field: 'plan_time',
        asc: true
      }],
      sortOptions: [
        {
          field: 'plan_time',
          text: this.$t('跟进截止时间'),
          order: 'ascend'
        },
        {
          field: 'follow_time',
          text: this.$t('最新跟进时间')
        },
        {
          field: 'try_drive_level',
          text: this.$t('试驾意向')
        },
        {
          field: 'lead_level',
          text: this.$t('购车意向')
        },
      ],
      searchStr: '',
      filterPanelVisible: false,
      clueTablist:[
        { text: this.$t('全部'), value: 0 }, // 全部
        {
          text: this.$t('待分配'), // 待首跟
          value: 10,
          total: 0,
          key: 'toAssign',
        },
        {
          text: this.$t('待首跟'), // 待首跟
          value: 1,
          total: 0,
          key: 'firstFollowUp',
        },
        {
          text: this.$t('未排程'), // 未排程
          value: 2,
          total: 0,
          key: 'unscheduled',
        },
        {
          text: this.$t('已排程'), // 已排程
          value: 3,
          total: 0,
          key: 'scheduled',
        },{
          text: this.$t('已试驾'), // 已试驾
          value: 4,
          total: 0,
          key: 'testDriveCompleted',
        },
        {
          text: this.$t('定金待支付'), // 定金待支付
          value: 5,
          total: 0,
          key: 'ordered',
        },
        {
          text: this.$t('定金已支付'), // 定金已支付
          value: 11,
          total: 0,
          key: 'orderBeLock',
        },
        {
          text: this.$t('已锁单'), // 已锁单
          value: 6,
          total: 0,
          key: 'lockedOrder',
        },
        {
          text: this.$t('已推合同'), // 已推合同
          value: 12,
          total: 0,
          key: 'orderPushContract',
        },
        {
          text: this.$t('已签合同'), // 已签合同
          value: 7,
          total: 0,
          key: 'signed',
        },
        {
          text: this.$t('已交车'), // 已交车
          value: 8,
          total: 0,
          key: 'delivered',
        },
        // {
        //   text: this.$t('退款中'), // 退款中
        //   value: 13,
        //   total: 0,
        //   key: 'orderCanceling',
        // },
        {
          text: this.$t('战败'), // 战败
          value: 9,
          total: 0,
          key: 'defeat',
        },
      ],
      tabList: [
        { text: this.$t('全部'), value: 0 }, // 全部
        {
          text: this.$t('今日待跟进'), // 今日待跟进
          value: 1,
          total: 0,
          key: 'today',
        },
        {
          text: this.$t('3日内待跟进'), // 3日内待跟进
          value: 20,
          total: 0,
          key: 'threeDay',
        },
        {
          text: this.$t('7日内待跟进'), // 7日内待跟进
          value: 25,
          total: 0,
          key: 'sevenDay',
        },
        {
          text: this.$t('逾期待跟进'), // 逾期待跟进
          value: 30,
          total: 0,
          key: 'overdue',
        },
      ],
      optionsList: [
        {
          text: this.$t('全部'), // 全部
          value: 0,
          total: 0,
          key: 'allLead',
        },
        {
          text: this.$t('待首跟'), // 待首跟
          value: 20,
          total: 0,
          key: 'first',
        },
        {
          text: this.$t('未排程'), // 未排程
          value: 25,
          total: 0,
          key: 'toAppoint',
        },
        {
          text: this.$t('已排程'), // 已排程
          value: 30,
          total: 0,
          key: 'appointed',
        },
        {
          text: this.$t('已试驾'), // 已试驾
          value: 35,
          total: 0,
          key: 'drive',
        },
      ],
      queryType: 1,
      firstQueryType:0, // 线索和商机
      subType: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      filterOptions: [],
      filterParams: {},
      totalCount: 0,
      isDistributionState: false, // 是否分配状态
      distributionIds: [], // 勾选的线索 id
      distributionRole: '', // 选中的角色
      showDistribution: false,
      showTransfer: false,
      actions: [
        {
          text: this.$t('重新分配'),
          key: 'distribute',
          premission: 'BTN-CLUE-DISTRIBUTE',
        },
        {
          text: this.$t('新建线索'),
          key: 'create',
          premission: 'BTN-CLUE-CREAT',
        },
        { text: this.$t('店内线索查询'), key: 'search' },
      ],
      downGradeType: '',
      isFirst: true,
      roleCode: '',
      
      currentTotal: 0,
      currentObj: {},
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    distributionOptions() {
      return [
        {
          name: '',
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds: '',
          height: '80vh',
          roleCodes: this.distributionRole,
        },
      ]
    },
    showBottom() {
      return this.$route.query.fromAssistant
    },
    showSingleCheck() {
      return this.$route.query.fromWeChatTask
    },
  },
  watch: {
    dictHash: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.createClueFilterOptions()
        }
      },
      deep: true
    },
    leadStageCode: {
      handler(val) {
        if (val === 'OPPORTUNITY') {
          this.clueTablist=[
            { text: this.$t('全部'), value: 0 }, // 全部
            // {
            //   text: this.$t('待分配'), // 待首跟
            //   value: 10,
            //   total: 0,
            //   key: 'toAssign',
            // },
            {
              text: this.$t('已试驾'), // 已试驾
              value: 4,
              total: 0,
              key: 'testDriveCompleted',
            },
            {
              text: this.$t('定金待支付'), // 定金待支付
              value: 5,
              total: 0,
              key: 'ordered',
            },
            {
              text: this.$t('定金已支付'), // 定金已支付
              value: 11,
              total: 0,
              key: 'orderBeLock',
            },
            {
              text: this.$t('已锁单'), // 已锁单
              value: 6,
              total: 0,
              key: 'lockedOrder',
            },
            {
              text: this.$t('已推合同'), // 已推合同
              value: 12,
              total: 0,
              key: 'orderPushContract',
            },
            {
              text: this.$t('已签合同'), // 已签合同
              value: 7,
              total: 0,
              key: 'signed',
            },
            {
              text: this.$t('已交车'), // 已交车
              value: 8,
              total: 0,
              key: 'delivered',
            },
            // {
            //   text: this.$t('退款中'), // 退款中
            //   value: 13,
            //   total: 0,
            //   key: 'orderCanceling',
            // },
            {
              text: this.$t('战败'), // 战败
              value: 9,
              total: 0,
              key: 'defeat',
            }
          ]
        }
      },
      immediate: true,
    },
    filterPanelVisible:{
      handler(newVal){
        if (newVal){
          setTimeout(() => {
            this.filterPanelSetParams()
          }, 500)
        }
      }
    },
    userInfo: {
      handler(val) {
        const { roleLogos = [] } = val
        if (!roleLogos.includes('1014003')) {
          this.clueTablist = this.clueTablist.filter(({ key }) => { return key !== 'toAssign' })
        }
      },
      immediate: true
    }
  },
  created(){
    this.init()
  },
  mounted() {
    if (this.leadStageCode === 'REDUCE') {
      this.getDownGradeCount()
    } else if (['LEAD', 'OPPORTUNITY'].includes(this.leadStageCode)) {
      this.getLictCount()
    }
  },
  activated() {
    this.isFirst = true
    this.init()
    this.onRefresh()
  },
  methods: {
    onSort(data) {
      console.log('onSort', data)
      this.sort = data
      this.onRefresh()
    },
    init() {
      const { query } = this.$route
      const { 
        tabs,
        distributeTimeStart,
        distributeTimeEnd,
        followUserIds,
        filterFollowTimeStart,
        filterFollowTimeEnd,
        channelOneId,
        roleCode,
        validTimeStart,
        validTimeEnd,
        validStatus,
        parentCode='',
        childCode='',
        dealerProvinceCode='',
        dealerCityCode='',
        dealerIds=''
      } = query
      
      if (tabs) {
        const [zero, one, two] = tabs.split(',')
        if (zero === '0') {
          this.queryType = Number(one || 1)
          this.firstQueryType = Number(one || 1) // 线索和商机
          this.subType = Number(two || (this.queryType === 1 ? 0 : 1))
        }
      }
      // this.$set(this, 'filterParams', {})
      if (followUserIds){
        const userIds = Array.isArray(followUserIds) ? followUserIds : [followUserIds]
        this.$set(this.filterParams, 'followUserIds', userIds)
      }
      if (distributeTimeStart&&distributeTimeEnd){
        this.$set(this.filterParams,'distributeTimeStart',distributeTimeStart)
        this.$set(this.filterParams,'distributeTimeEnd',distributeTimeEnd)
      }
      if (filterFollowTimeStart&&filterFollowTimeEnd){
        this.$set(this.filterParams,'filterFollowTimeStart',filterFollowTimeStart)
        this.$set(this.filterParams,'filterFollowTimeEnd',filterFollowTimeEnd)
      }
      if (channelOneId){
        this.$set(this.filterParams,'channelOneId',[channelOneId])
      } 
      if (validTimeStart&&validTimeEnd) {
        this.$set(this.filterParams,'validTimeStart',validTimeStart)
        this.$set(this.filterParams,'validTimeEnd',validTimeEnd)
      }
      if (validStatus) {
        this.$set(this.filterParams,'validStatus',[Number(validStatus)])
      }
      if (parentCode) {
        this.$set(this.filterParams,'parentCode', parentCode)
      }
      if (childCode) {
        this.$set(this.filterParams,'childCode',[childCode])
      }
      if (dealerProvinceCode) {
        this.$set(this.filterParams,'dealerProvinceCode',dealerProvinceCode)
      }
      if (dealerCityCode) {
        this.$set(this.filterParams,'dealerCityCode',dealerCityCode)
      }
      if (dealerIds) {
        this.$set(this.filterParams,'dealerIds',[dealerIds])
      }
    },
    filterPanelSetParams(){
      // 只有第一次打开筛选框才按照 url 上的参数回显
      if (!this.isFirst) return
      const { followUserIds,distributeTimeStart,distributeTimeEnd,filterFollowTimeStart,filterFollowTimeEnd,channelOneId,validTimeStart, validTimeEnd, validStatus } = this.$route.query
      // 跟进人
      if (followUserIds){
        const userIds = Array.isArray(followUserIds) ? followUserIds : [followUserIds]
        this.$refs.filterPanel.formData.followUserIds = []
        userIds.forEach(id=>{
          this.$refs.filterPanel.formData.followUserIds.push({
            id: id,
            value: id
          }) 
        })
      }
      
      // 到店时间
      if (distributeTimeStart&&distributeTimeEnd){
        this.$refs.filterPanel.formData.distributeTimeStart = dayjs(distributeTimeStart).toDate()
        this.$refs.filterPanel.formData.distributeTimeEnd = dayjs(distributeTimeEnd).toDate()
      }
      // 最新跟进时间
      if (filterFollowTimeStart&&filterFollowTimeEnd){
        this.$refs.filterPanel.formData.filterFollowTimeStart = dayjs(filterFollowTimeStart).toDate()
        this.$refs.filterPanel.formData.filterFollowTimeEnd = dayjs(filterFollowTimeEnd).toDate()
      }
      // 一级渠道
      if (channelOneId){
        const idx = this.filterOptions.findIndex(item=>item.type==='channels' )
        this.$set(this.filterOptions[idx],'defaultValue',[[channelOneId]])
      }
      // 有效/无效判定时间
      if (validTimeStart&&validTimeEnd){
        this.$refs.filterPanel.formData.validTimeStart = dayjs(validTimeStart).toDate()
        this.$refs.filterPanel.formData.validTimeEnd = dayjs(validTimeEnd).toDate()
      }
      if (validStatus) {
        this.$refs.filterPanel.formData.validStatus = []
        this.$refs.filterPanel.formData.validStatus.push({
          id: Number(validStatus),
          value: Number(validStatus)
        })
      }
    },
    // 获取降级数据
    async getDownGradeCount() {
      const { subType, searchStr } = this
      const params = {
        ...this.filterParams,
        queryType: 5,
        subType,
        searchStr,
        planTime: {
          start: this.filterParams.start,
          end: this.filterParams.end,
        },
        createTime:{
          start: this.filterParams.createTimeStart,
          end: this.filterParams.createTimeEnd
        },
        downGradeTime: this.$route.query.time || '',
      }
      loading.showLoading()
      this.tabList = []
      const res = await clueServices.downGradeCount(params)
      loading.hideLoading()
      let result = res || {}
      for (var item of result) {
        this.tabList.push({
          text: item.name,
          total: item.count,
          value: item.name,
        })
      }
      const reduceQueryType = sessionStorage.getItem('reduceQueryType')
      if (reduceQueryType) {
        this.downGradeType =
          this.tabList.find(({ value }) => value === reduceQueryType)?.value ||
          ''
      } else {
        this.downGradeType = this.tabList[0]?.value || ''
      }
      sessionStorage.setItem('reduceQueryType', this.downGradeType)
      this.$refs.list.onRefresh()
    },
    // 输入框键入
    onInput(value) {
      this.searchStr = value
    },
    // 切换tab
    changeTab(val) {
      // 根据当前 是否属于线索降级列表 做判断
      if (this.leadStageCode === 'REDUCE') {
        this.queryType = val
        sessionStorage.setItem('reduceQueryType', val)
        this.downGradeType = val
        this.cleanDistribution()
        this.$refs.list.onRefresh()
      } else {
        // this.subType = 0
        this.firstQueryType = val
        this.onRefresh()
      }
      // 为什么不直接调用onRefresh 因为要区分当前是线索列表 还是线索降级列表 降级列表 切换tab时候不用再去调用count计数接口
    },
    // 刷新
    onRefresh() {
      // 清除重新分配信息
      this.cleanDistribution()
      if (this.leadStageCode === 'REDUCE') {
        this.$refs.list.list = []
        this.getDownGradeCount()
      } else if (this.leadStageCode === 'DEFEAT') {
        this.$refs.list.onRefresh()
      } else if (['LEAD', 'OPPORTUNITY'].includes(this.leadStageCode)) {
        this.$refs.list.onRefresh()
        this.getLictCount()
      }
    },
    // 操作面板
    operation(obj) {
      if (obj.key === 'create') {
        this.$router.push({
          path: '/create-clue',
        })
      } else if (obj.key === 'distribute') {
        // 重新分配
        this.isDistributionState = true
      } else {
        this.$router.push({
          path: '/shop-clue-search',
        })
      }
    },
    onFilter(params) {
      this.isFirst = false
      let filterParams = formatParams(params, false)

      // 额外兼容状态
      if (filterParams.states) {
        filterParams.states = filterParams.states.flat()
      }
      this.filterParams = filterParams

      // 常用省市
      if (params.dealerAddress){
        this.$set(this.filterParams,'userProvinceCode',params.dealerAddress.userProvinceCode)
        this.$set(this.filterParams,'userCityCode',params.dealerAddress.userCityCode)
        this.$set(this.filterParams,'userCountyCode',params.dealerAddress.userCountyCode)
      }
      
      this.filterPanelVisible = false
      this.onRefresh()
    },
    // 获取数据统计
    async getLictCount() {
      const { queryType,firstQueryType, subType, searchStr, leadStageCode,roleCode } = this
      const params = {
        roleCode,
        ...this.filterParams,
        firstQueryType,
        searchStr,
        planTime: {
          start: this.filterParams.start,
          end: this.filterParams.end,
        },
        createTime:{
          start: this.filterParams.createTimeStart,
          end: this.filterParams.createTimeEnd
        },
        filterFollowTime: {
          start: this.filterParams.filterFollowTimeStart,
          end: this.filterParams.filterFollowTimeEnd,
        },
        distributeTime:{
          start: this.filterParams.distributeTimeStart,
          end: this.filterParams.distributeTimeEnd,
        },
        validTime:{
          start: this.filterParams.validTimeStart,
          end: this.filterParams.validTimeEnd,
        },
        storeOfTime: {
          start: this.filterParams.storeOfTimeStart,
          end: this.filterParams.storeOfTimeEnd
        },
        drivingTime: {
          start: this.filterParams.drivingTimeStart,
          end: this.filterParams.drivingTimeEnd,
        },
        defeatTime: {
          start: this.filterParams.defeatTimeStart,
          end: this.filterParams.defeatTimeEnd,
        }
      }
      if (leadStageCode === 'OPPORTUNITY') {
        params.leadStageCode = leadStageCode
      }
      let res = await clueServices.getListCount(params)
      this.clueTablist.forEach((item) => {
        if (item.key) item.total = res[item.key] || 0
      })
    },
    // 跳转至详情
    goDetail({ id }) {
      this.$router.push({
        path: '/clue-details',
        query: {
          id,
        },
      })
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      if (this.leadStageCode === 'REDUCE' && !this.downGradeType) {
        return {
          dataList: [],
          page: {
            pageCount: 1,
            pageIndex: 1,
            pageSize: 20,
            totalCount: 0,
          },
        }
      }
      const { queryType,firstQueryType, subType, searchStr, leadStageCode,roleCode } = this
      const params = {
        sort: this.sort,
        roleCode,
        ...this.filterParams,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        // queryType: leadStageCode === 'REDUCE' ? 5 : leadStageCode === 'DEFEAT' ? 6 : queryType,
        // subType,
        searchStr,
        planTime: {
          start: this.filterParams.start,
          end: this.filterParams.end,
        },
        createTime:{
          start: this.filterParams.createTimeStart,
          end: this.filterParams.createTimeEnd
        },
        filterFollowTime: {
          start: this.filterParams.filterFollowTimeStart,
          end: this.filterParams.filterFollowTimeEnd,
        },
        distributeTime:{
          start: this.filterParams.distributeTimeStart,
          end: this.filterParams.distributeTimeEnd,
        },
        validTime:{
          start: this.filterParams.validTimeStart,
          end: this.filterParams.validTimeEnd,
        },
        storeOfTime: {
          start: this.filterParams.storeOfTimeStart,
          end: this.filterParams.storeOfTimeEnd
        },
        drivingTime: {
          start: this.filterParams.drivingTimeStart,
          end: this.filterParams.drivingTimeEnd,
        },
        defeatTime: {
          start: this.filterParams.defeatTimeStart,
          end: this.filterParams.defeatTimeEnd,
        }
      }
      if (['REDUCE','DEFEAT'].includes(leadStageCode)){
        params.queryType = leadStageCode === 'REDUCE' ? 5:6
      } else {
        params.firstQueryType = firstQueryType
      }
      // 线索 商机 带上leadStageCode查询
      if (leadStageCode == 'OPPORTUNITY') {
        params.leadStageCode = leadStageCode
      }
      // 降级消息提醒 带时间 和降级类别查询
      if (leadStageCode === 'REDUCE') {
        params.downGradeTime = this.noticeTime
        params.downGradeType = this.downGradeType
      }
      // 战败预警 带时间
      if (leadStageCode === 'DEFEAT') {
        params.expectedOrderTime = this.noticeTime
      }
      loading.showLoading()
      return clueServices.getclueList(params).then((res) => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        res.dataList.forEach((item) => (item.checked = false))
        this.currentTotal =
          obj.currentPage == 1
            ? res.dataList.length
            : this.currentTotal + res.dataList.length
        return res
      })
    },
    onCheckChanged(checked, theClue) {
      const { followUserRoleCode, id } = theClue
      if (checked) {
        // if (!this.distributionRole) {
        //   this.distributionRole = followUserRoleCode || '1014001,1014002'
        // } else if (this.distributionRole !== followUserRoleCode && followUserRoleCode) {
        //   return this.$toast(this.$t('请选择同类型角色跟进的线索'))
        // }
        if (!this.distributionRole) {
          this.distributionRole = followUserRoleCode || '1014001,1014002'
        } else {
          if (this.distributionRole === '1014001,1014002') {
            if (followUserRoleCode) return this.$toast(this.$t('请选择同类型角色跟进的线索'))
          } else {
            if (this.distributionRole !== followUserRoleCode) return this.$toast(this.$t('请选择同类型角色跟进的线索'))
          }
        }
      }
      const ids = []
      this.$refs.list.list.forEach((item) => {
        if (item.id === id) {
          item.checked = checked
        }
        if (item.checked) ids.push(item.id)
      })
      this.distributionIds = ids
      if (!this.distributionIds.length) this.distributionRole = ''
    },
    // 取消全选
    cleanDistribution() {
      this.$refs.list.list.forEach((item) => {
        item.checked = false
      })
      this.distributionIds = []
      this.isDistributionState = false
      this.distributionRole = ''
    },
    // 全选
    onAllChecked() {
      // 默认勾选当前列表第一条线索的角色
      // 只勾选同类型角色跟进的线索，其他角色的取消勾选
      // 如果是待分配线索的话
      this.distributionRole = this.$refs.list.list[0].followUserRoleCode || '1014001,1014002'
      const ids = []
      this.$refs.list.list.forEach((item) => {
        if (!item.followUserRoleCode) {
          if (this.distributionRole === '1014001,1014002') {
            item.checked = true
            ids.push(item.id)
          }
        } else {
          if (item.followUserRoleCode === this.distributionRole) {
            item.checked = true
            ids.push(item.id)
          } else {
            item.checked = false
          }
        }
      })
      this.distributionIds = ids
    },
    // 打开员工选择面板
    onDistribution() {
      if (!this.distributionIds.length)
        return this.$toast(this.$t('请选择待分配的线索'))
      const state = this.$refs.list.list.find(({ id }) => { return id === this.distributionIds[0] })?.state
      // 如果是待分配的线索 还是按老的分配交互方式
      if (state === '2000000') {
        this.showDistribution = true
      } else {
        // 走新的分配交付逻辑
        this.showTransfer = true
      }
    },
    // 区分 是待分配线索 还是已分配线索 调用不同的接口
    async distributionFilter({ saIdList }) {
      const [{ id, name }] = saIdList
      // 判断 选择的线索 是否是待分配线索（取第一条判断即可 只能选择同类跟进角色的线索）
      const state = this.$refs.list.list.find(({ id }) => { return id === this.distributionIds[0] })?.state
      let params = {
        empId: id,
        empName: name,
        ids: this.distributionIds,
      }
      if (state !== '2000000') {
        params.roleLogo = this.distributionRole
        const res = await clueServices.clueDistribution(params)
      } else {
        const res = await clueDistributionServices.handleDistribution(params)
      }
      this.onRefresh()
    },
    transferConfirm() {
      this.showTransfer = false
      this.onRefresh()
    },
    // 微信任务
    // 单选
    onSelect(item) {
      if (this.currentObj.id === item.id) {
        this.currentObj = {}
      } else {
        this.currentObj = item
      }
    },
    // 关联微信客户
    async onBindClue() {
      const { customerName, customerWechatId } = this.$route.query
      console.log('关联微信客户', this.currentObj, customerName, customerWechatId)
      this.$dialog
        .confirm({
          title: this.$t('提示'),
          message: this.$t('确认关联微信客户与线索 ？'),
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('确认'),
        })
        .then(async () => {
          loading.showLoading()
          const result = await baseDataServices.setWechat({
            id: this.currentObj.id,
            userWeChat: customerWechatId,
            userWeChatName: customerName
          })
          loading.hideLoading()
          if (result) {
            this.$toast(this.$t('关联成功'))
            setTimeout(() => {
              this.goDetail({ id: this.currentObj.id })
            }, 1000)
          }
        })
    },
  },
}
</script>

<style scoped lang="less">
.clue-list {
  &.clue-list-b{
    padding-bottom: 96px;
  }
  /deep/ .tab-title {
    padding: 0 6px !important;
  }

  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 34px;
  }

  .totalCount {
    color: @black;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }

  .action-bar {
    max-width: 500px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
    background: #ffffff;
    z-index: 999;

    .left {
      padding-left: 16px;

      span {
        display: inline-block;
        width: 28px;
        height: 22px;
        line-height: 22px;
        margin: 0 8px;
        font-size: 14px;
        cursor: pointer;
        user-select: none;
      }
    }

    /deep/.van-button {
      color: #0d171a !important;
      width: 92px;
      font-weight: 500;
    }
  }
  .item-wrap {
		position: relative;
		.select-icon {
			font-size: 0;
			padding: 4px;
			position: absolute;
			top: 50%;
			right: 24px;
			transform: translateY(-50%);
			cursor: pointer;
		}
	}
  .footer {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  background: white;
  .action-box {
    padding: 10px 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
}
</style>
