<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import { appointmentReviewPost } from '@/services/appointment.js'
import appointmentMixin from '@/modules/appointment/mixin'
import dayjs from 'dayjs'
export default {
  components:{ shortConsumerInfo,externalUserIcon },
  mixins:[appointmentMixin],
  props:{
    data:{
      type: Object,
      default:()=>({})
    },
    appointmentType:{
      type: String,
      default:''
    },
    tableType: {
      type: [String,Number],
      default:''
    },
    isTodo: {
      type: Boolean,
      default: false
    },
    yuqi: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      isShowReview: false,
      reviewRemark: '', // 审核意见
      reviewStatus: '', // 1：驳回、2：通过
      reviewStatusObj: {
        '0': '待审核',
        '1': '已驳回',
        '2': '已通过',
      },
    }
  },
  computed:{
    isShowConfirmBtn() {
      const { appointmentStartTime } = this.data
      if (dayjs().isAfter(dayjs(appointmentStartTime))) {
        // 当前时间在预约试驾时间之后，不显示
        return false
      }
      return true
    },
    getStatusColor() {
      const obj={
        'CONFIRMED':'yellow', // 未试驾
        'TIMEOUT_CONFIRMED':'red', // 超时未试驾
        'TO_BE_CONFIRM':'blue', // 试驾待确认
        'CANCELED':'gray', // 已取消
        'COMPLETED':'green' // 已试驾
      }
      return obj[this.data?.tableType] || ''
    },
    getChanneName() {
      const { onlineName,channelOne,channelTwo } = this.data
      const arr = []
      // onlineName && arr.push(onlineName)
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      return arr.join('/')
    },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
    // isShowBtns() {
    //   const { data, getUserInfo } = this
    //   const flag = ['TO_BE_CONFIRM','CONFIRMED','TIMEOUT_CONFIRMED'].includes(data.tableType) && (getUserInfo?.authorizeIds.includes(data?.followUserId) || getUserInfo.id == data.authorizedEmployeeId) && getUserInfo?.roleLogos.includes(data.followUserRoleCode)
    //   // 如果上门试驾未审核，不展示任何按钮
    //   const flag2 = data.reviewStatus!=='0'
    //   return flag && flag2
    // },
    // 店长才有审核权限,并且线索状态应该是待审核,并且排除超时已取消的【tableType=TIMEOUT_CANCEL】
    isShowReviewBtn(){
      const { roleLogos=[] } = this.getUserInfo
      const { reviewStatus,tableType } = this.data
      const flag = roleLogos.includes('1014003')&&reviewStatus==='0'&&!['TIMEOUT_CANCEL','ACTIVE_CANCEL'].includes(tableType)
      return flag
    },
    // 不展示修改预约按钮 上门试驾审核通过，但还未排程则不展示【修改预约】按钮
    isNoEdit(){
      let flag = false
      const { type,reviewStatus,status } = this.data
      if (type==='2002002'&&reviewStatus==='2'&&status===2){
        flag = true
      }
      return flag
    }
  },
  methods:{
    // 预约详情
    goDetail(item){
      this.$router.push({
        name:'appointmentTestDriveDetail',
        query:{
          id: item.id,
          clueId: item.leadId
        }
      })
    },
    upDatePage(item) {
      this.$router.push({
        name:'appointmentUpdate',
        query:{
          appointmentType: this.appointmentType,
          id: item.id,
          clueId: item.leadId
        }
      })
    },
    goFollow(data,followResult='',selfStatus='') {
      this.$router.push({
        path: '/clue-follow',
        query: {
          id: data.leadId,
          appointmentId: data.id,
          followResult, // '1011005', // 1011005取消预约  1011006修改预约
          selfStatus,
        }
      })
    },
    clueDetail(item) {
      this.$router.push({
        name:'clueDetails',
        query:{
          appointmentType: this.appointmentType,
          id: item.id
        }
      })
    },
    selectUser(params={}){
      const { avatar,externalUserid,name } = params
      // Object.assign(this.data,{
      //   customerName: name,
      //   userWeChat:externalUserid,
      // })
      this.$set(this.data,'customerName',name)
      this.$set(this.data,'userWeChat',externalUserid)
    },
    // getNone() {
    //   let appid = 'wxdafaf0480578a1b3'
    //   const { vehicleCode: modelCode, vinCode, type, id:appointId, leadId, customerMobileOrigin:appointmentPhone, testDriveActivityId } = this.data
    //   if (process.env.VUE_APP_ENV === 'dev') appid = 'wxdafaf0480578a1b3'
    //   // if (process.env.VUE_APP_ENV === 'uat') appid = ''
    //   if (!appid) return false
    //   window.wx.invoke('launchMiniprogram', {
    //     'appid': appid,
    //     'path': `pages/index/index?modelCode=${modelCode}&vinCode=${vinCode}&type=${type}&appointId=${appointId}&leadId=${leadId}&appointmentPhone=${appointmentPhone}&appointmentActivityId=${testDriveActivityId}&fromAppointment=true`
    //   }, (res) => {
    //     // console.log(res)
    //     if (res.err_msg === 'launchMiniprogram:ok'){
    //       console.log('跳转成功')
    //     } else {
    //       this.$toast.fail('无法跳转')
    //     }
    //   })
    // },
    // 上门试驾排程
    getSchedule(item){
      this.$emit('openSchedule',item)
    },
    openReview(){
      this.reviewRemark = ''
      this.isShowReview = true
    },
    // 审核驳回 1：驳回、2：通过
    async onReview(action, done){
      if (action==='cancel'&&!this.reviewRemark.trim()) {
        this.$toast(this.$t('请输入审核意见'))
        done(false)
        return
      }
      if (['confirm','cancel'].indexOf(action)>-1){
        const params = {
          reviewStatus: action==='cancel'?1:2,
          reviewRemark: this.reviewRemark,
          id: this.data.id
        }
        const res = await appointmentReviewPost(params)
        done()
        this.$emit('refresh')
      }
      if (action==='overlay')done()
    },
    showEndDriveBtn({ driveEndTime }) {
      const now = dayjs().format('YYYY-MM-DD')
      const driveEnd = dayjs(driveEndTime).format('YYYY-MM-DD')
      const diffDay = dayjs(now).diff(dayjs(driveEnd), 'day')
      return driveEndTime && diffDay >= 1
    }
  }
}
</script>
<template>
  <div class="card">
    
    <div class="info" @click="goDetail(data)">
      <div class="user-status">
        <shortConsumerInfo :data="data" :showLevel="false"
        :option="{
          name:'customerName',
          phone:'customerMobile',
          sex:'userSex'
        }"/>
        <div :class="[getStatusColor,'status-name']">
          {{data.tableType | getdectText(data, 'tableType', $store,'2022')}}
          <div v-if="data.tableType === 'TIMEOUT_CONFIRMED'">{{ $t('已超时') }}: {{ data.timeoutTime }}</div>
        </div>
      </div>
      <div class="van-ellipsis">
        <span class="label">{{ $t('试驾车型') }}： </span>
        <span v-if="!data.seriesName && !data.vehicleName">-- {{ data.carNumber }}</span>
        <span v-else>{{data.seriesName}}{{data.seriesName&&data.vehicleName?' | ': ''  }} {{data.vehicleName}} {{ data.carNumber }}</span>
      </div>
      <div class="van-ellipsis">
        <span class="label">{{ $t('试驾场景') }}： </span>
        <span>{{data.type | codeFormat}}{{data.type==='2002003'?` - ${data.activityTitle}`:''}}</span>
      </div>
      <div v-if="data.type==='2002002'" class="van-ellipsis">
        <span class="label">{{ $t('试驾地点') }}： </span>
        <span>{{ data.driveProvinceName }}{{ data.driveCityName }}{{ data.driveDistrictName }}{{ data.driveAddress }}</span>
      </div>
      <div class="van-ellipsis">
        <span class="label">{{ $t('预约试驾时间') }}： </span>
        <span>{{data.appointmentTime}}</span>
      </div>
      <div v-if="data.type === '2002002'" class="van-ellipsis">
        <span class="label">{{ $t('审核状态') }}： </span>
        <span>{{reviewStatusObj[data.reviewStatus]}}</span>
      </div>
      <div v-if="isTodo && yuqi" style="color: #e4002c;">
        {{ yuqi }}
      </div>
    </div>
    <div v-if="data.followUserRoleCode && data.followUserId" class="bottom">
        <template v-if="data.followUserRoleCode === '1014001'">
          <span>{{ $t('DCC专员') }}： {{data.followUserName || '--'}}</span>
        </template>
        <template v-else>
          <span>{{ $t('销售顾问') }}：{{data.followUserName || '--'}} </span>
        </template>
        <div v-if="!isTodo&&!['CANCELED','NOSHOW'].includes(data.tableType) && data.assignStatus !== 0 && $store.getters.userInfo.type == 1" class="btn-box">
            <!-- 非上门试驾的 -->
          <template v-if="data.type !== '2002002'">
            <!-- 待确认 -->
            <template v-if="data.status === 1">
              <div class="btn" @click="goFollow(data,'1011005')">{{ $t('取消试驾') }}</div>
              <div class="btn btn-yellow" @click="goFollow(data,'1011006')">{{ $t('修改试驾') }}</div>
            </template>
            <!-- 待试驾 -->
            <template v-if="data.status === 2">
              <div class="btn" @click="goFollow(data,'1011005')">{{ $t('取消试驾') }}</div>
              <div class="btn btn-yellow"  @click="goFollow(data, '1011006')">{{ $t('修改预约') }}</div>
              <div v-if="isShowConfirmBtn" class="btn btn-yellow"  @click="goFollow(data,'1011002','meet')">{{ $t('确认出席') }}</div>
              <div class="btn btn-yellow"  @click="getNone(data)">{{ $t('开始试驾') }}</div>
            </template>
            <!-- 试驾中 -->
            <div v-if="data.status === 3" class="btn btn-yellow"  @click="testDriveUpdate(data)">{{ $t('结束试驾') }}</div>
            <!-- 已完成 && 当前时间小于试驾结束+1 天-->
            <div v-if="data.status === 9 && showEndDriveBtn(data) && !data.tryDriveVisitStatus" class="btn btn-yellow"  @click="goFollow(data)">{{ $t('试驾回访') }}</div>   
          </template>
          <!-- 上门试驾的逻辑 -->
          <template v-if="data.status !== 0&&data.type === '2002002'">
            <!-- <template v-if="data.status !== 0"> -->
              <!-- 店长角色并且预约单状态是待审核 -->
              <template v-if="(getUserInfo?.roleLogos.includes('1014003') || getUserInfo?.roleLogos.includes('1014004'))&&data.reviewStatus==='0'&&data.tableType!=='TIMEOUT_CANCEL'">
                <div class="btn btn-yellow" @click="openReview">{{ $t('审核') }}</div>
              </template>
              <template v-if="data.reviewStatus!=='0'">
                <div v-if="data.status<3" class="btn"  @click="goFollow(data,'1011005')">{{ $t('取消试驾') }}</div>
                <!-- 审核被驳回时 -->
                <div v-if="data.reviewStatus==='1'" class="btn btn-yellow"  @click="goFollow(data,'1011006')">{{ $t('修改试驾') }}</div>
                <!-- 排程 -->
                <div v-if="data.reviewStatus==='2'&&data.status===1" class="btn btn-yellow"  @click="getSchedule(data)">{{ $t('预约排程') }}</div>
                <div v-if="data.reviewStatus==='2' && data.status===2" class="btn btn-yellow"  @click="goFollow(data,'1011006')">{{ $t('修改预约') }}</div>
                <!-- 确认出席 -->
                <div v-if="data.reviewStatus==='2'&&data.status===2 && isShowConfirmBtn" class="btn btn-yellow"  @click="goFollow(data,'1011002','meet')">{{ $t('确认出席') }}</div>
                <!-- 开始试驾 -->
                <div v-if="data.status===2" class="btn btn-yellow" @click="getNone(data)">{{ $t('开始试驾') }}</div>
                <div v-if="data.status === 3" class="btn btn-yellow"  @click="testDriveUpdate(data)">{{ $t('结束试驾') }}</div>
                <div v-if="data.status === 9 && showEndDriveBtn(data) && !data.tryDriveVisitStatus" class="btn btn-yellow"  @click="goFollow(data)">{{ $t('试驾回访') }}</div>   
              </template>
            <!-- </template> -->
          </template>
        </div>
        <slot name="btns" />
    </div>
    <!-- 审核弹窗 -->
    <van-dialog v-model="isShowReview" :title="$t('审核意见')" 
      show-cancel-button 
      :cancel-button-text="$t('驳回')" 
      :confirm-button-text="$t('通过')"
      :close-on-click-overlay="true"
      :before-close="onReview">
      <van-field
        v-model="reviewRemark"
        rows="2"
        autosize
        label=""
        type="textarea"
        maxlength="50"
        :placeholder="$t('请输入审核意见')"
        show-word-limit
      />
    </van-dialog>
    <slot name="check"></slot>
  </div>
</template>
<style lang="less" scoped>
@lineColor: #F3F3F4;
.btn-yellow{
  color: #B9921A;
}
.label{
  color: #7D7D7D;
}
.yellow{
  color:#FFD100;
}
.red{
  color:#E4002C;
}
.blue{
  color:#0040C5;
}
.gray{
  color:#0D171A;
}
.green{
  color: #029640;
}
.card{
  background-color: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px ;
  padding: 12px;
  font-size: 13px;
  position: relative;

  .ml-16{
    margin-left: 16px;
  }

  .status-name{
    // position: absolute;
    // top: 12px;
    // right: 12px;
    text-align: right;
  }
  .title{
    padding-bottom: 12px;
    border-bottom: 1px solid @lineColor; 
    margin-bottom:8px;
    display: flex;
    justify-content: space-between;
  }
  .info{
    position: relative;
    .user-status{
      display: flex;
      justify-content: space-between;
    }
    >div:not(:last-of-type){
      margin-bottom: 8px;
    }
    .icons{
      position: absolute;
      bottom:-8px;
      right:0;
      display:flex;
      .icon{
        margin-left: 16px;
      }
    }
    .value{
      display: inline-block;
      width: 50%;
      vertical-align: middle;
    }
  }
  .bottom{
    min-height: 28px;
    height: auto;
    line-height: 20px;
    border-top: 1px solid @lineColor; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0 0;
    font-size: 16px;
    font-size: 14px;
    margin-top: 8px;
    .btn-box{
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
    .btn{
      margin-left: 8px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
