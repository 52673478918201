<script>
import phoneImg from '@/images/phone.svg'
import { judgeMobile } from '@/utils/index'
import { copyPhone } from '@/utils'
import { mapGetters } from 'vuex'
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
import { debounce } from 'lodash'
export default {
  props:{
    name:{
      type: String,
      default:''
    },
    phone: { // 客户手机号
      type: String,
      default:''
    },
    clueId:{
      type: [Number,String],
      default: ''
    },
    appointmentId:{
      type: [Number,String],
      default: ''
    },
    isFollow:{
      type: Boolean,
      default: false
    },
    // 是否是自己的线索
    oneself: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneImg,
      showPhone: false,
      detailInfo: {},
      phoneList: [],
    }
  },
  computed:{
    judgeMobile,
    ...mapGetters(['hasMapping', 'userInfo']),
  },
  methods:{
    async clickPhoneIcon() {
      this.phoneList = []
      const res = await clueServices.getClueDetail({ id: this.clueId })
      this.detailInfo = res
      this.phoneList.push({
        name: '线索号码',
        keyCode: 'userMobile',
        value: res.userMobile
      })
      if (res.detail?.leadLabel?.userOtherMobile1) {
        this.phoneList.push({
          name: '其他联系方式',
          keyCode: 'userOtherMobile1',
          value: res.detail?.leadLabel?.userOtherMobile1
        })
      }
      if (res.detail?.leadLabel?.userOtherMobile2) {
        this.phoneList.push({
          name: '其他联系方式',
          keyCode: 'userOtherMobile2',
          value: res.detail?.leadLabel?.userOtherMobile2
        })
      }
      if (res.detail?.leadLabel?.userOtherMobile3) {
        this.phoneList.push({
          name: '其他联系方式',
          keyCode: 'userOtherMobile3',
          value: res.detail?.leadLabel?.userOtherMobile3
        })
      }
      if (res.detail?.leadLabel?.userOtherMobile1 || res.detail?.leadLabel?.userOtherMobile2 || res.detail?.leadLabel?.userOtherMobile3) {
        this.showPhone = true
      } else {
        this.onCallPhone(this.phone)
      }
    },
    onSelectPhone (value) {
      this.showPhone = false
      this.onCallPhone(value)
    },
    onCallPhone: debounce(async function(userPhone){
      const { judgeMobile, phone, isFollow, clueId, appointmentId, hasMapping, oneself } = this
      // 移动端直接拨打
      if (judgeMobile){
        if (hasMapping && oneself && phone === userPhone) { // 是自己的线索 且开通了虚拟号的门店 查询虚拟号 phone === userPhone拨打的是线索号码
          const params = {
            leadId: clueId,
            userId: this.userInfo.id
          }
          loading.showLoading()
          const result = await clueServices.generatePhonex(params)
          loading.hideLoading()
          const { code, data, msg } = result
          if (code === 0 && data) {
            location.href = `tel:${data}`
          } else {
            if (code === 519) {
              this.$dialog.confirm({
                title: this.$t('提示'),
                message: '24小时内呼叫该手机号超过次数限制，无法使用隐私号呼叫该客户；若使用真实号码呼叫存在封号风险。',
                confirmButtonColor: '#B9921A',
                confirmButtonText: '使用真实号码呼叫',
              }).then(() => {
                location.href = `tel:${userPhone}`
              })
            } else {
              location.href = `tel:${userPhone}`
            }
          }
          return false
        } else {
          if (userPhone.indexOf('*') !== -1){
            location.href = 'tel:'
          } else {
            location.href = `tel:${userPhone}`
          }
        }
        if (isFollow){
          this.$router.push({
            path: '/clue-follow',
            query: {
              id: clueId,
              appointmentId,
            }
          })
        }
      } else { // 非移动端 复制
        this.$toast(this.$t('请使用手机外呼'))
      }
    }, 1000) ,
  }
}
</script>
<template>
  <div>
    <div class="img" @click.stop="clickPhoneIcon">
      <img :src="phoneImg" />
      <span v-if="name" style="margin-left:8px;">{{ name }}</span>
    </div>
    <van-popup v-model="showPhone" position="bottom" teleport="#app">
      <div v-for="(item, index) in phoneList" :key="index" class="content">
        <div @click="onSelectPhone(item.value)">
          <div>{{item.name}}</div>
          <div>{{item.value}}</div>
        </div>
      </div>
      </van-popup>
  </div>
</template>
<style lang="less" scoped>
.img{
  cursor: pointer;
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 32px;
    &:hover,&:active{
      box-shadow: 0px 2px 5px #21212138;
      border-radius: 50%;
    }
  }
}
.content {
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid #e1e5e5;
  }
</style>
