<script>
import clueServices from '@/services/clueServices'
import BtnVoiceInput from '@/components/btn-voice-input'
export default {
  components: {
    BtnVoiceInput,
  },
  data() {
    return {
      remark:'',
      followResult: this.$route.query.followResult || 1011041
    }
  },
  methods:{
    // 625fb2
    goBack() {
      this.$router.go(-1)
    },
    onSubmit() {
      const id = this.$route.query.id
      const remark = this.remark
      if (!remark){
        this.$toast(this.$t('请输入'))
        return
      }
      if (id){
        clueServices.followClue({
          id,
          followResult: this.followResult,
          followRemark: remark
        }).then(res => {
          this.$toast(this.$t('留言成功'))
          setTimeout(()=>{
            this.goBack()
          },1000)
        })
      } else {
        this.$toast(this.$t('缺少线索id'))
      }
    }
  }
}
</script>
<template>
<div class="page">
  <!-- 跟进内容 -->
  <van-cell :title="$t('跟进内容')" required>
    <template #right-icon>
      <btn-voice-input @input="(value) => (remark = remark + value.slice(0, 200))" />
    </template>
  </van-cell>
  <van-field
    v-model="remark"
    rows="4"
    autosize
    label=""
    type="textarea"
    :placeholder="$t('请输入')"
    maxlength="200"
    show-word-limit
  />
  <div class="btns">
    <van-button class="btn" round @click.stop="goBack">{{$t('取消')}}</van-button>
    <van-button class="btn" round  type="primary" @click="onSubmit">{{$t('提交')}}</van-button>
  </div>
</div>
</template>
<style lang="less" scoped>
.page{
  padding:16px;
  .btns{
    margin-top:20px;
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    .btn{
      width: 168px;
    }
  }
}
</style>
