<template>
  <div ref="orderEdit" :class="['orderEdit', 'orderEdit-pab', showSubmit ? 'orderEdit-pab-n' : '']">
    <div class="common-block top-block">
      <p>{{ $t('订单修改审批流程节点（未修改交付门店时，只需店长审批）：') }}</p>
      <p>{{ $t('申请发起 - 店长审批 - 城市经理审批 - 城市经理审批（跨区）') }}</p>
      <p v-if="showOrderUpdateInfo" style="color: #E4002C; margin:4px 0">{{ `${$t('审批状态')}：` }}{{ orderUpdateStatus | codeFormat }}</p>
      <p v-if="!toExamine" style="color: #B9921A;">{{ $t('注：修改成功后，系统会自动向客户推送新合同；请务必在发起修改订单前完成相关折扣补贴的申请') }}</p>
      <p v-if="!toExamine" style="color: #B9921A;">{{ $t('若修改了配置或交付门店，申请提交后，OTD会在一定时间内为您预留新车辆资源；为确保预留资源有效，请及时提醒审批人完成审批') }}</p>
    </div>
    <van-form ref="orderEditForm" class="orderEditForm" input-align="right" error-message-align="right"
      @submit.native.prevent>
      <van-collapse v-model="activeCollapse" class="common-block">
        <van-collapse-item  title-class="collapse-title" :lazy-render="false" disabled :title="$t('订单基本信息')" name="1">
          <van-field
            v-model.trim="orderDetail.appOrderId"
            :label="$t('订单编号')"
            readonly>
          </van-field>
          <van-field
            :label="$t('订单类型细分')"
            readonly>
            <template #input>
              <div>
                <p>{{ orderDetail.coVehicleSource  === 2 ? '特殊车（非一车一价）' : '普通商品车'}}</p>
              </div>
            </template>
          </van-field>
          <!-- <p v-if="orderDetail.coVehicleSource  === 2" class="specialTips">{{ $t('注：特殊车订单暂不支持修改配置和交付门店') }}</p> -->
        </van-collapse-item>
        <van-collapse-item title-class="collapse-title" :lazy-render="false" name="2">
          <template #title>
            <div style="display: flex;">
              <div style="width: max-content;" >{{ $t('配置信息') }}</div>
              <div v-if="orderDetail.coVehicleSource  === 2" class="specialTips">{{ $t('注：特殊车订单暂不支持修改配置信息') }}</div>
            </div>
          </template>
          <van-field
            v-if="toExamine"
            v-model="orderRes.salesDealerName"
            readonly
            :label="$t('销售门店')">
          </van-field>
          <van-field id="carInfo" ref="carInfo" name="carInfo" :label="$t('车系车型')" :placeholder="$t('请选择车系车型')" :value="formData.carInfo | formatCarInfo"
            :right-icon="toExamine || isSpecialCar ? '' : 'arrow'" type="textarea" rows="1" autosize readonly required
            :rules="[{ required: true }]" class="modelCodeTips" @click="selectCarModel">
            <template v-if="toExamine" #input>
              <div class="differenceTips">
                <div>{{ formData.carInfo | formatCarInfo }}</div>
                <div class="tips">{{ showDifference('carInfo') }}</div>
              </div>
            </template>
          </van-field>
          <van-field id="outterColor" v-model="formData.outterColor.dictName" name="outterColor" :label="$t('外饰')" :placeholder="$t('请选择外饰颜色')" required
            :right-icon="toExamine || isSpecialCar ? '' : 'arrow'" readonly :rules="[{ required: true }]"
            @click="selectCarColorInfo('out')">
            <template v-if="toExamine" #input>
              <div class="differenceTips">
                <div>{{ formData.outterColor.dictName }}</div>
                <div class="tips">{{ showDifference('out') }}</div>
              </div>
            </template>
          </van-field>
          <van-field id="innerColor" v-model="formData.innerColor.dictName" name="innerColor" :label="$t('内饰')" :placeholder="$t('请选择内饰颜色')" required
            :right-icon="toExamine || isSpecialCar? '' : 'arrow'" readonly :rules="[{ required: true }]"
            @click="selectCarColorInfo('in')">
            <template v-if="toExamine" #input>
              <div class="differenceTips">
                <div>{{ formData.innerColor.dictName }}</div>
                <div class="tips">{{ showDifference('in') }}</div>
              </div>
            </template>
          </van-field>
          <van-field v-model="displayOptions" :label="$t('选装')" :placeholder="$t('请选择选装')"
            :right-icon="toExamine || isSpecialCar? '' : 'arrow'" readonly input-align="right" @click="selectOptions">
            <template v-if="toExamine" #input>
              <div class="differenceTips">
                <div>{{ displayOptions }}</div>
                <div class="tips">{{ showDifference('options') }}</div>
              </div>
            </template>
          </van-field>
        </van-collapse-item>
        <van-collapse-item title-class="collapse-title" :lazy-render="false" disabled :title="$t('购车信息')" name="3">
          <div class="common-cell required">
            <span class="common-cell-label">{{$t('购车类型')}}</span>
            <div :class="['common-cell-value', !formData.customerType && customerTypeValidate ? 'customerType' : '']">
              <div v-for="(item, index) in customerTypeList" :key="index" class="btn-item"
                :class="{ active: item.dictCode === formData.customerType }"
                @click="changeCell('customerType', item.dictCode)">{{ item.dictName }}</div>
              <div v-if="toExamine" class="differenceTips">
                <div class="tips">{{ showDifference('customerType') }}</div>
              </div>
            </div>
          </div>
          <div class="common-cell required">
            <span class="common-cell-label">{{$t('购车方式')}}</span>
            <div
              :class="['common-cell-value', ![0, 1].includes(formData.installmentsFlag) && installmentsFlagValidate ? 'installmentsFlag' : '']">
              <div v-for="(item, index) in installmentsFlagList" :key="index" class="btn-item"
                :class="{ active: item.dictCode === formData.installmentsFlag }"
                @click="changeCell('installmentsFlag', item.dictCode)">{{ item.dictName }}</div>
              <div v-if="toExamine" class="differenceTips">
                <div class="tips">{{ showDifference('installmentsFlag') }}</div>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item title-class="collapse-title" :lazy-render="false" :title="$t('车辆所有人信息')" name="4">
          <template v-if="formData.customerType == 1">
            <van-field
              id="ownerName"
              v-model.trim="formData.ownerName"
              name="ownerName"
              :label="$t('姓名')"
              :placeholder="$t('请输入姓名')"
              required
              :readonly="toExamine"
              :rules="[{ validator: validateLength, message: $t('2-64位字符'), trigger: 'onBlur' }]">
              <template v-if="toExamine" #input>
                <div class="differenceTips">
                  <div>{{ formData.ownerName }}</div>
                  <div class="tips">{{ showDifference('ownerName') }}</div>
                </div>
              </template>
            </van-field>
            <van-field id="mobile" v-model.trim="formData.mobile" name="mobile" :label="$t('手机号码')" :placeholder="$t('请输入手机号码')" required type="number"
              :readonly="toExamine" :rules="phoneCheck" :formatter="(value)=> value.replace(/\s+/g, '')">
              <template v-if="toExamine" #input>
                <div class="differenceTips">
                  <div>{{ formData.mobile }}</div>
                  <div class="tips">{{ showDifference('mobile') }}</div>
                </div>
              </template>
            </van-field>
            <van-field id="idType" :value="formData.idType | documentFilter" name="idType" :label="$t('证件类型')" :placeholder="$t('请选择证件类型')" required readonly
              :right-icon="toExamine ? '' : 'arrow'" :rules="[{ required: true }]"
              @click="selectField('idType', 3002, false)">
              <template v-if="toExamine" #input>
                <div class="differenceTips">
                  <div>{{ formData.idType | documentFilter }}</div>
                  <div class="tips">{{ showDifference('idType') }}</div>
                </div>
              </template>
            </van-field>
            <van-field id="idNum" ref="idNum" v-model.trim="formData.idNum" name="idNum" :label="$t('证件号码')" :placeholder="$t('请输入证件号码')" required :readonly="toExamine"
              :rules="[{ validator: validateCredentialCode, message: $t('证件号码不正确') }]">
              <template v-if="toExamine" #input>
                <div class="differenceTips">
                  <div>{{ formData.idNum }}</div>
                  <div class="tips">{{ showDifference('idNum') }}</div>
                </div>
              </template>
              <template v-if="!toExamine" #button>
                <van-uploader :after-read="afterRead">
                  <van-icon name="photograph" size="20" color="#0D171A"/>
                </van-uploader>
              </template>
            </van-field>
          </template>
          <template v-else>
            <van-field
              id="ownerName"
              v-model.trim="formData.ownerName"
              name="ownerName"
              :label="$t('企业名称')"
              :placeholder="$t('请输入企业名称')"
              required
              :readonly="toExamine"
              :rules="[{ validator: validateLength, message: $t('2-64位字符'), trigger: 'onBlur' }]">
              <template v-if="toExamine" #input>
                <div class="differenceTips">
                  <div>{{ formData.ownerName }}</div>
                  <div class="tips">{{ showDifference('ownerName') }}</div>
                </div>
              </template>
            </van-field>
            <van-field
              id="contactPerson"
              v-model.trim="formData.contactPerson"
              name="contactPerson"
              :label="$t('联系人')"
              :placeholder="$t('请输入联系人姓名')"
              required
              :readonly="toExamine"
              :rules="[{ validator: validateLength, message: $t('2-64位字符'), trigger: 'onBlur' }]">
              <template v-if="toExamine" #input>
                <div class="differenceTips">
                  <div>{{ formData.contactPerson }}</div>
                  <div class="tips">{{ showDifference('contactPerson') }}</div>
                </div>
              </template>
            </van-field>
            <van-field id="mobile" v-model.trim="formData.mobile" name="mobile" :label="$t('手机号')" :placeholder="$t('请输入手机号')" required type="number"
              :readonly="toExamine" :rules="phoneCheck" :formatter="(value)=> value.replace(/\s+/g, '')">
              <template v-if="toExamine" #input>
                <div class="differenceTips">
                  <div>{{ formData.mobile }}</div>
                  <div class="tips">{{ showDifference('mobile') }}</div>
                </div>
              </template>
            </van-field>
            <van-field id="idNum" v-model.trim="formData.idNum" name="idNum" :label="$t('统一社会信用代码')" :placeholder="$t('请输入统一社会信用代码')" :required="showExamine ? false : true"
              :readonly="toExamine"
              :rules="showExamine ? [] : [{ validator: validateCredentialCode1, message: $t('最多18个字符仅可为字母和数字') }]">
              <template v-if="toExamine" #input>
                <div class="differenceTips">
                  <div>{{ formData.idNum }}</div>
                  <div class="tips">{{ showDifference('idNum') }}</div>
                </div>
              </template>
              <template v-if="!toExamine" #button>
                <van-uploader :after-read="afterRead">
                  <van-icon name="photograph" size="20" color="#0D171A" />
                </van-uploader>
              </template>
            </van-field>
          </template>
        </van-collapse-item>
        <van-collapse-item title-class="collapse-title" :lazy-render="false" disabled :title="$t('交付门店')" name="5">
          <template #title>
            <div style="display: flex;">
              <div style="width: max-content;" >{{ $t('交付门店') }}</div>
              <div v-if="orderDetail.coVehicleSource  === 2" class="specialTips">{{ $t('注：特殊车订单暂不支持修改交付门店') }}</div>
            </div>
          </template>
          <van-field id="deliveryDealerName" :value="formData.deliveryDealerName" name="deliveryDealerName" :label="$t('交付门店')" :placeholder="$t('请选择交付门店')" required
            :right-icon="toExamine || isSpecialCar ? '' : 'arrow'" readonly :rules="[{ required: true }]" @click="selectDeliverShop">
            <template v-if="toExamine" #input>
              <div class="differenceTips">
                <div>{{ formData.deliveryDealerName }}</div>
                <div class="tips">{{ showDifference('deliveryDealerName') }}</div>
              </div>
            </template>
          </van-field>
        </van-collapse-item>
        <van-collapse-item title-class="collapse-title" :lazy-render="false" disabled :title="$t('修改前金额')" name="6">
          <van-cell :title="$t('车辆总价')">
            <span content="¥"></span>{{ formatRMB(orderRes.totalAmount || originTotalAmount) }}
          </van-cell>
          <van-cell :title="$t('购车总价')">
            <span content="¥"></span>{{ formatRMB(orderRes.actualAmount || originActualAmount) }}
          </van-cell>
        </van-collapse-item>
        <van-collapse-item v-if="discountList.length > 0" title-class="collapse-title" :lazy-render="false" 
          disabled :title="$t('优惠券折扣明细')" name="7">
          <div class="discount">
            <div v-for="(item, index) in discountList" :key="index" class="discount-item">
              <div class="discount-item-t">
                <span>{{ item.discountReason }}</span>
                <span>-<span content="¥"></span>{{ `${formatRMB(item.discountAmount)}` }}</span>
              </div>
              <!-- <div class="discount-item-b">购车权益专享，购车可享受最高20000元购车优惠</div> -->
            </div>
          </div>
        </van-collapse-item>
        <van-collapse-item title-class="collapse-title" :lazy-render="false" disabled name="8" :title="$t('申请原因')">
          <van-field
            id="applyReason"
            v-model="formData.applyReason"
            name="applyReason"
            required 
            :readonly="toExamine"
            style="display: block;" 
            type="textarea"
            :label="$t('申请原因')"
            :placeholder="$t('请输入申请原因')" 
            :rules="[{ required: true }]" 
            maxlength="200" show-word-limit />
          <van-field id="fileList" name="fileList" :label="$t('申请材料')" readonly required :rules="[{required: true, message: $t('请上传申请材料')}]">
            <template #input>
              <div :class="['fileUploadBox', toExamine ? 'fileUploadBox_hide' : '']">
                <van-uploader
                  :fileList="formData.fileList"
                  accept="image/*"
                  :preview-options="{closeable: true}"
                  :before-read="beforeRead"
                  :deletable="!toExamine"
                  :after-read="(file) => afterReadFile(file)"
                  :before-delete="(file) => beforeDelete(file)"
                  :max-count="20"/>
                  <p class="tip" style="color: #B9921A; font-size: 12px" >{{ $t('不超过20张图片，单张不大于20M') }}</p>
              </div>
            </template>
          </van-field>
        </van-collapse-item>
        </van-collapse>
      <div :class="['common-footer', showSubmit ? 'common-footer-n' : '']">
        <div class="footer-l">
          <p>{{ $t('购车总价') }}</p>
          <div class="amount"><span content="¥"></span>{{ `${formatRMB(formData.actualAmount) || '--'}` }}</div>
          <p>{{$t('车辆总价')}}</p>
          <div class="totalAmount"><span content="¥"></span>{{ `${formatRMB(formData.totalAmount) || '--'}` }}</div>
        </div>
        <div class="btns">
          <van-button v-if="showSave" class="btn submit save" type="submit" @click="onSubmit('save')">{{$t('保存')}}</van-button>
          <template v-if="showSubmit">
            <van-button class="btn submit examine" type="submit" size="small"
              @click="onSubmit('examine')">{{$t('提交审核')}}</van-button>
            <van-button class="btn cancel" size="small" @click="cancelEdit">{{$t('取消')}}</van-button>
            <van-button class="btn submit reEdit" size="small" type="submit" @click="reEdit">{{$t('编辑')}}</van-button>
          </template>
          <van-button v-if="showUrging" class="btn submit" size="large" type="submit" @click="urging">
            {{$t('一键催办')}}
            <p class="uringTips">{{ orderUpdateStatus | codeFormat }}</p>
          </van-button>
          <template v-if="showExamine">
            <van-button class="btn" @click="rejectAudit">{{$t('驳回')}}</van-button>
            <van-button class="btn submit" type="submit" @click="passAudit">{{$t('通过')}}</van-button>
          </template>
        </div>
      </div>
    </van-form>
    <van-dialog
      v-model="showDialog"
      class="authDialog"
      :title="$t('确认驳回')"
      show-cancel-button
      confirmButtonColor="#EED484"
      :confirm-button-text="$t('确定')"
      :before-close="beforeClose">
      <van-form ref="dialogForm" label-width='65px' validate-trigger="onSubmit">
        <van-field v-model="rejectParams.refuseReason" :label="$t('驳回原因')" :placeholder="$t('请填写驳回原因')" maxlength="50" required
          :rules="[{ required: true }]" />
      </van-form>
    </van-dialog>
    <SelectDeliverShop
      v-if="showSelectShop"
      :lastSelect="formData.deliveryDealerCode"
      :dealerList="dealerList"
      :cellList="dealerListAll"
      @cancel="cancelSelectShop"
      @confirm="confirmSelectShop">
    </SelectDeliverShop>
  </div>
</template>
<script>
import { validate } from '@/utils/validate'
import selectCar from '@/components/select-car'
import selectCarColor from '@/components/select-common'
import selectCarPackage from '@/components/select-car-package'
import commonSelector from '@/components/common-selector'
import selectShop from '@/components/select-shop'
import orderServices from '@/services/orderServices'
import loading from '@/utils/loading'
import baseDataServices from '@/services/baseDataServices'
import { mapGetters } from 'vuex'
import store from '@/store'
import { ROLES } from '@/constants/constants'
import { uploadFile, cosDeleteFile } from '@/utils/cos'
import SelectDeliverShop from './component/selectDeliverShop.vue'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'orderEdit',
  filters: {
    formatCarInfo(info = {}) {
      const { seriesName, modelName } = info
      let display = ''
      seriesName && (display += ` ${seriesName}`)
      modelName && (display += ` ${modelName}`)
      return Object.keys(info).length === 0
        ? null
        : display
    },
    documentFilter(val) {
      const { dictHash } = store.getters
      if (dictHash && dictHash[3002] && Array.isArray(dictHash[3002])) {
        const obj = dictHash[3002].find(({ code }) => code === val)
        if (obj) {
          return obj.name
        }
      } else {
        return ''
      }
      return ''
    }
  },
  components: {
    SelectDeliverShop
  },
  data() {
    return {
      activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8'],
      customerTypeList: [
        { dictName: this.$t('个人'), dictCode: 1 },
        { dictName: this.$t('企业'), dictCode: 2 },
      ],
      installmentsFlagList: [
        { dictName: this.$t('分期'), dictCode: 1 },
        { dictName: this.$t('全款'), dictCode: 0 },
      ],
      customerTypeValidate: false,
      installmentsFlagValidate: false,
      formData: {
        carInfo: {}, // 意向信息
        outterColor: {}, // 外饰颜色
        innerColor: {}, // 内饰颜色
        options: [], // 选装
        material: {}, // 物料信息
        customerType: '', // 购车类型 1 6a0e04 2 企业
        installmentsFlag: '', // 购车方式 1bed9e5 0ee84a2
        ownerName: '', // 姓名|公司名称
        mobile: '', // 电话
        idType: '', // 证件类型
        idTypeName: '', // 证件类型名称
        idNum: '', // 证件号码|统一社会信用代码
        contactPerson: '', // 联系人
        totalAmount: '', // 订单总金额
        actualAmount: '', // 实际金额
        deliveryDealerCode: '', // 交付店code
        deliveryDealerName: '', // 交付店名称
        applyReason: '', // 申请原因
        applyUrl: '', // 申请材料
        fileList: [], // 申请材料数组
      },
      discountList: [], // 折扣明细
      outterColorList: [], // 外饰颜色数据源
      innerColorList: [], // 内饰颜色数据源
      materialList: [], // 物料数据源
      optionsList: [], // 选装数据源
      orderRes: {},
      orderDetail: {}, // 订单详情
      toExamine: false,
      modelListInfo: [], // 车型车型处理后数据
      modelListInfoOld: [], // 车系车型数据源
      dealerList: [], // 关联交付门店数据
      dealerListAll: [], // 全国交付门店数据
      diccountedPrice: '', // 修改前实际金额
      originTotalAmount: '', // 修改前订单总金额
      originActualAmount: '', // 修改前订单总金额
      discountAmountAll: 0, // 折扣金额
      // orderUpdateStatus: '',
      btnLoading: false,
      showDialog: false,
      rejectParams: {
        refuseReason: '',
      },
      showSelectShop: false,
      noExamineBtn: '',
      orderId: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    displayOptions() {
      return this.formData.options.map(item => { return item.dictName }).join(',')
    },
    phoneCheck() {
      // 跟进方式-展厅接待可不填手机号
      const { mobile } = this.formData
      const rules = [{ required: true, trigger: 'onBlur' }]
      // 如果有手机号则验证
      if (mobile) {
        rules.push(validate.mustPhone)
      }
      return rules
    },
    getDealersId() {
      const { dealers } = this.userInfo || {}
      return dealers && dealers[0]?.id || ''
    },
    orderUpdateStatus() {
      let status = this.orderRes.orderUpdateStatus || this.orderDetail.orderUpdateStatus
      return status
    },
    // 判断展示保存按钮
    showSave() {
      return !this.toExamine
    },
    // 判断展示提交审核 取消 修改
    showSubmit() {
      return this.toExamine && this.orderUpdateStatus == '3001001'
    },
    // 判断展示催办按钮(顾问展示催办，有店长或经理优先展示审核、驳回)
    showUrging() {
      const { roleLogos = [] } = this.userInfo || {}
      const auth = [
        ['3001003', '3001005', '3001010'].includes(this.orderUpdateStatus), // 状态满足
        roleLogos?.includes(ROLES.SALES), // 需要是顾问
        '3001003' === this.orderUpdateStatus ? !roleLogos?.includes(ROLES.SHOWOWNER) : true, // 店长待审的时候 角色不能为店长
        ['3001005', '3001010'].includes(this.orderUpdateStatus) ? !roleLogos?.includes(ROLES.REGIONMANAGER) : true // 城市经理待审时 角色不能为城市经理
      ]
      return !auth.some((item) => item === false)
    },
    // 展示通过 驳回
    showExamine() {
      const { roleLogos = [] } = this.userInfo || {}
      return !this.noExamineBtn && (('3001003' == this.orderUpdateStatus && roleLogos?.includes(ROLES.SHOWOWNER)) || (['3001005', '3001010'].includes(this.orderUpdateStatus) && roleLogos?.includes(ROLES.CITYMANAGER)))
    },
    // 展示订单修改/退单信息
    showOrderUpdateInfo() {
      return (
        this.orderUpdateStatus && !['3001000', '3001035'].includes(this.orderUpdateStatus)
      )
    },
    // 判断是否是特殊车辆
    isSpecialCar() {
      return this.orderDetail.coVehicleSource === 2
    }
  },
  watch: {
    orderDetail: {
      handler(val) {
        const { carOptionsList = [], carOrderDiscountList = [], carOrderMaterial = {}, customerType, installmentsFlag, carRegistration = {}, deliveryDealerName, deliveryDealerCode, totalAmount, actualAmount, applyReason, applyUrl } = val
        const { seriesCode, seriesName, modelCode, modelName, colourCode, colourName, interiorCode, interiorName } = carOrderMaterial
        const { materialCode, materialPrice } = carOrderMaterial
        const { idType, idTypeName, idNum, mobile, ownerName, contactPerson } = carRegistration
        const modelCar = this.modelListInfoOld.find((item) => { return item.modelCode === modelCode }) || {} // 当前订单的车型
        /** 组装车型数据 */
        const colourList = modelCar?.colourList || [] // 当前订单的外饰数据源
        this.formData.carInfo = { seriesCode, seriesName, modelCode, modelName, colourList }
        this.outterColorList = colourList.map((item) => {
          return {
            id: item.colourCode,
            dictCode: item.colourCode,
            dictName: item.colourName,
            children: item.interiorList || [],
            colourPrice: item.colourPrice,
          }
        })
        /** 组装外饰数据 */
        const interiorList = colourList.find((item) => { return item.colourCode === colourCode })?.interiorList || [] // 当前订单的内饰数据源
        this.formData.outterColor = { id: colourCode, dictCode: colourCode, dictName: colourName, children: interiorList }
        this.innerColorList = interiorList.map((item) => {
          return {
            id: item.interiorCode,
            dictCode: item.interiorCode,
            dictName: item.interiorName,
            materialList: item.materialList,
            optionList: item.optionList,
            interiorPrice: item.interiorPrice
          }
        })
        /** 组装内饰数据 */
        const findIndex = interiorList.findIndex((item) => { return item.interiorCode === interiorCode })
        const materialList = findIndex != -1 ? interiorList[findIndex].materialList || [] : [] // 当前订单物料数据
        const optionList = findIndex != -1 ? interiorList[findIndex].optionList || [] : [] // 当前订单选装数据
        this.formData.innerColor = { id: interiorCode, dictCode: interiorCode, dictName: interiorName, materialList, optionList }
        this.optionsList = optionList.map((item) => {
          return {
            id: item.optionCode,
            dictCode: item.optionCode,
            dictName: item.optionName,
            optionPrice: item.optionPrice
          }
        })
        this.materialList = materialList
        /** 组装选装数据 */
        this.formData.options = carOptionsList.map((item) => { return { dictCode: item.optionsCode, dictName: item.optionsName, id: item.optionsCode } })
        /** 组装物料数据 */
        this.formData.material = { materialCode, totalPrice: materialPrice }
        /** 组装折扣数据 */
        this.discountList = carOrderDiscountList.map((item) => {
          return {
            ...item,
            id: item.id || '',
            planId: item.planId || '',
          }
        })
        this.discountAmountAll = 0
        for (let i = 0; i < this.discountList.length; i++) {
          this.discountAmountAll += isNaN(Number(this.discountList[i].discountAmount)) ? 0 : Number(this.discountList[i].discountAmount)
        }
        this.formData.actualAmount = this.formData.material.totalPrice - this.discountAmountAll
        this.formData.customerType = customerType
        this.formData.installmentsFlag = installmentsFlag
        this.formData.applyReason = applyReason
        this.formData.fileList = applyUrl ? applyUrl.split(',').map((item) => {
          return {
            url: item,
            cosUrl: item,
            id: uuidv4()
          }
        }) : []
        /** 组装上牌人信息*/
        this.formData.ownerName = ownerName
        this.formData.mobile = mobile
        this.formData.idType = idType
        this.formData.idTypeName = idTypeName
        this.formData.idNum = idNum
        this.formData.contactPerson = contactPerson
        /** */
        this.formData.deliveryDealerCode = deliveryDealerCode
        this.formData.deliveryDealerName = deliveryDealerName
        this.formData.totalAmount = totalAmount
        this.formData.actualAmount = actualAmount
        this.originTotalAmount = totalAmount
        this.originActualAmount = actualAmount
      },
      deep: true,
    },
    getDealersId: {
      handler(val) {
        if (!val) return
        this.getDealerDetail(val)
      },
      immediate: true
    }
  },
  async mounted() {
    const { toExamine = false, reEdit = false, noExamineBtn, id } = this.$route.query
    this.orderId = id
    this.noExamineBtn = noExamineBtn
    this.toExamine = Boolean(toExamine || false)
    loading.showLoading()
    const result = await baseDataServices.seriesModelsForEditOrder()
    this.modelListInfoOld = Array.isArray(result) ? result : []
    let arr = []
    this.modelListInfo = (Array.isArray(result) ? result : []).forEach((item) => {
      const findIndex = arr.findIndex((item1) => { return item1.seriesCode === item.seriorCode })
      const { seriorCode: seriesCode, seriorName: seriesName, ...query } = item
      let children = [{ text: query.modelName, ...query }]
      const obj = { text: seriesName, seriesCode, seriesName, children }
      if (findIndex !== -1) {
        arr[findIndex].children.push({ text: query.modelName, ...query })
      } else {
        arr.push(obj)
      }
    })
    this.modelListInfo = arr
    loading.hideLoading()
    if (this.toExamine || Boolean(reEdit || false)) {
      this.getOrderUpdateDetail()
    } else {
      this.getOrderDetail()
    }
    // 获取所有交付门店的数据
    this.getDeliveryShopList()
  },
  methods: {
    afterRead(file) {
      loading.showLoading(this.$t('识别中...'))
      uploadFile(file.file).then(async ({ url, Key }) => {
        orderServices[this.formData.customerType == 1 ? 'idCardOCR' : 'bizLicenseOCR']({ imgUrl: url }).then((data)=>{
          loading.hideLoading()
          const { idNum, name, regNum } = data
          this.formData.idNum = this.formData.customerType == 1 ? idNum : regNum
          this.formData.ownerName = name
        }).finally(()=>{
          cosDeleteFile(Key)
        })
      }).catch(() => {
        loading.hideLoading()
      })
    },
    // 监听数据更改统一方法
    watchInfo(type) {
      this.formData.actualAmount = ''
      this.formData.totalAmount = ''
      const getPriceFun = () => {
        this.formData.totalAmount = this.formData.material.totalPrice
        this.discountAmountAll = 0
        for (let i = 0; i < this.discountList.length; i++) {
          this.discountAmountAll += isNaN(Number(this.discountList[i].discountAmount)) ? 0 : Number(this.discountList[i].discountAmount)
        }
        this.formData.actualAmount = this.formData.material.totalPrice - this.discountAmountAll
      }
      switch (type) {
        case 'carInfo':
          const { colourList = [] } = this.formData.carInfo // eslint-disable-line
          this.formData.outterColor = {}
          this.formData.innerColor = {}
          this.formData.options = []
          this.formData.material = {}
          this.outterColorList = []
          this.outterColorList = colourList.map((item) => {
            return {
              id: item.colourCode,
              dictCode: item.colourCode,
              dictName: item.colourName,
              children: item.interiorList || [],
              colourPrice: item.colourPrice,
            }
          })
          this.innerColorList = []
          this.materialList = []
          this.optionsList = []
          break
        case 'out':
          const { dictCode: outDictCode, children = [] } = this.formData.outterColor // eslint-disable-line
          if (!outDictCode) return false
          this.formData.innerColor = {}
          this.formData.options = []
          this.formData.material = {}
          this.innerColorList = []
          this.innerColorList = children.map((item) => {
            return {
              id: item.interiorCode,
              dictCode: item.interiorCode,
              dictName: item.interiorName,
              materialList: item.materialList,
              optionList: item.optionList,
              interiorPrice: item.interiorPrice
            }
          })
          this.materialList = []
          this.optionsList = []
          break
        case 'in':
          const { dictCode: innerDictCode, optionList, materialList } = this.formData.innerColor // eslint-disable-line
          if (!innerDictCode) return false
          this.formData.options = []
          // this.formData.material = {}
          this.optionsList = []
          this.optionsList = optionList.map((item) => {
            return {
              id: item.optionCode,
              dictCode: item.optionCode,
              dictName: item.optionName,
              optionPrice: item.optionPrice
            }
          })
          this.materialList = materialList
          // 因为选择不用必填 但又需要 选取选装来算最终价格
          // 所以 选完内饰 默认无选装所对应的物料信息
          this.formData.material = this.materialList.find((item) => {
            return item.optionList.length === 0 && (item.optionList || []).every((m, n) => m === [][n])
          })
          // 计算购车总价 和 车辆总价
          getPriceFun()
          break
        case 'option':
          this.formData.material = {}
          if (Array.isArray(this.formData.options)) {
            const optionList = this.formData.options.map(({ id }) => { return id })
            this.formData.material = this.materialList.find((item) => {
              return item.optionList.length === optionList.length && (item.optionList || []).every((m, n) => m === optionList[n])
            })
            if (!this.formData.material) {
              this.$toast(this.$t('该选装无物料信息！'))
              return false
            }
            // 计算购车总价 和 车辆总价
            getPriceFun()
            // this.formData.totalAmount = this.formData.material.totalPrice
            // this.discountAmountAll = 0
            // for (let i = 0; i < this.discountList.length; i++) {
            //   this.discountAmountAll += isNaN(Number(this.discountList[i].discountAmount)) ? 0 : Number(this.discountList[i].discountAmount)
            // }
            // this.formData.actualAmount = this.formData.material.totalPrice - this.discountAmountAll
          }
          break
        default:
          return
      }
    },
    // 证件校验
    validateCredentialCode() {
      if (this.formData.idTypeName === this.$t('身份证') || this.formData.idType === '1') {
        return /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(this.formData.idNum) || /^[1-9]\d{7}(?:0\d|10|11|12)(?:0[1-9]|[1-2][\d]|30|31)\d{3}$/.test(this.formData.idNum)
      }
      return true
    },
    // 统一社会信用代码校验
    validateCredentialCode1() {
      return /^[a-zA-Z0-9]{18}/.test(this.formData.idNum)
    },
    validateLength(value) {
      if (value.length < 2 || value.length > 64) {
        return false
      }
      // if (!/^[\u4E00-\u9FA5\u3400-\u4DB5A-Za-z]*$/.test(value)) {
      //   return false
      // }
      return true
    },
    // 获取订单详情
    async getOrderDetail() {
      loading.showLoading()
      const res = await orderServices.getOrderDetail({ id: this.$route.query.id })
      loading.hideLoading()
      this.orderDetail = res
    },
    // 获取订单修改详情
    async getOrderUpdateDetail() {
      loading.showLoading()
      const res = await orderServices.orderUpdateDetail({ orderId: this.$route.query.id })
      const { orderRes = {}, updateVo = {} } = res
      const { couponItems=[], policyConfigList=[] } = orderRes
      this.orderDetail = { ...updateVo, couponItems, policyConfigList }
      this.orderRes = orderRes
      loading.hideLoading()
    },
    // 获取交付门店
    getDealerDetail(val) {
      baseDataServices.getDealerDetail({ id: val }).then(res => {
        const list = res?.relations || []
        this.dealerList = list.map(({ dealerName, dealerCode, ...querys }) => { return {
          dictCode: dealerCode,
          dictName: dealerName,
          ...querys
        } })
      })
    },
    // 获取所有的交付门店数据
    async getDeliveryShopList() {
      const params = {
        pageSize: 9999,
        pageNum: 1,
        dealerType: 2
      }
      const result = await baseDataServices.getDeliveryShopList(params)
      this.dealerListAll = result.map(({ code, name, ...querys }) => {
        return {
          dealerCode: code,
          dealerName: name,
          ...querys
        }
      })
    },
    changeCell(field, dictCode) {
      if (this.toExamine) return false
      if (field === 'customerType') {
        const idTypeTem = this.formData.idType
        if (dictCode === 2) {
          this.formData.idType = 11
        } else {
          this.formData.idType = ''
        }
      }
      this.formData[field] = dictCode
    },
    // 选择意向车型
    selectCarModel() {
      if (this.toExamine || this.isSpecialCar) return false
      const { carInfo: initCarInfo, } = this.formData
      selectCar({ initCarInfo, requestList: this.modelListInfo })
        .then(carInfo => {
          if (typeof carInfo.modelCode === 'undefined') {
            this.$toast(this.$t('请选择正确的车系车型'))
            return
          }
          const { discount = [] } = carInfo
          if (discount.length > 0) {
            const { discountCoList = [] } = discount[0]
            this.discountList = discountCoList.map((item) => {
              return {
                discountAmount: item.policyValue,
                discountReason: item.policyName,
                discountRemark: '',
                planId: item.planId,
                policyConfigId: item.id
              }
            })
          }
          this.$set(this.formData, 'carInfo', carInfo)
          this.watchInfo('carInfo')
        })
        .catch(() => { })
    },
    // 选择内外饰颜色
    selectCarColorInfo(type) {
      if (this.toExamine || this.isSpecialCar) return false
      const { modelCode } = this.formData.carInfo
      if (typeof modelCode === 'undefined') {
        this.$toast({ message: this.$t('请选择车系车型') })
        return false
      }
      if (type === 'in' && !this.formData.outterColor.dictCode) {
        this.$toast({ message: this.$t('请选择外饰颜色') })
        return false
      }
      let params = {
        requestList: type === 'out' ? this.outterColorList : this.innerColorList,
      }
      selectCarColor({ type, params })
        .then(colorInfo => {
          if (type === 'out') {
            this.formData.outterColor = colorInfo
          }
          if (type === 'in') {
            this.formData.innerColor = colorInfo
          }
          this.watchInfo(type)
        })
        .catch(() => { })
    },
    // 选装
    selectOptions() {
      if (this.toExamine || this.isSpecialCar) return false
      const { carInfo: { modelCode }, outterColor: { dictCode: colourCode }, innerColor: { dictCode: interiorCode } } = this.formData
      if (!modelCode) {
        this.$toast({ message: this.$t('请选择车系车型') })
        return false
      }
      if (!colourCode) {
        this.$toast({ message: this.$t('请选择外饰颜色') })
        return false
      }
      if (!interiorCode) {
        this.$toast({ message: this.$t('请选择内饰颜色') })
        return false
      }
      const initOptions = this.formData.options.map(({ dictCode }) => { return dictCode })
      selectCarPackage({ initOptions, requestList: this.optionsList })
        .then(options => {
          this.formData.options = options || []
          this.watchInfo('option')
        })
        .catch(() => { })
    },
    selectField(field, dictType, multiple = false) {
      if (this.toExamine) return false
      const _obj = {
        field,
        dictType,
        multiple,
      }
      commonSelector(_obj).then(res => {
        this.formData[field] = res.dictCode
        if (field == 'idType') {
          this.formData.idTypeName = res.dictName
        }
      })
    },
    // 选择交付门店
    selectDeliverShop() {
      if (this.toExamine || this.isSpecialCar) return false
      this.showSelectShop = true
    },
    // 取消交付门店选择
    cancelSelectShop() {
      this.showSelectShop = false
    },
    // 确认交付门店选择
    confirmSelectShop(res) {
      this.showSelectShop = false
      this.formData.deliveryDealerCode = res.dictCode
      this.formData.deliveryDealerName = res.dictName
    },
    // 提交审核
    async orderApply() {
      const {
        carInfo: {
          modelCode,
          modelName,
          modelPrice,
          seriesCode,
          seriesName
        }, // 意向信息
        outterColor: {
          dictCode: colourCode,
          dictName: colourName,
          colourPrice,
        }, // 外饰颜色
        innerColor: {
          dictCode: interiorCode,
          dictName: interiorName,
          interiorPrice,
        }, // 内饰颜色
        options, // 选装
        material = {},
        customerType, // 购车类型 1 个人 2 企业
        installmentsFlag, // 购车方式 1分期 0全款
        ownerName, // 姓名|公司名称
        mobile, // 电话
        idType, // 证件类型
        idTypeName, // 证件类型名称
        idNum, // 证件号码|统一社会信用代码
        contactPerson, // 联系人
        totalAmount, // 订单总金额
        actualAmount, // 实际金额
        deliveryDealerCode, // 交付店code
        deliveryDealerName, // 交付店名称
        fileList,
        applyReason,
      } = this.formData
      const {
        materialCode = '',
        materialPrice = ''
      } = material // 物料信息
      let params = {
        actualAmount,
        carOptionsList: options.map((item) => {
          return {
            optionsCode: item.dictCode,
            optionsName: item.dictName,
            optionsPrice: item.optionPrice,
          }
        }),
        carOrderDiscountList: this.discountList,
        carOrderMaterial: {
          colourCode,
          colourName,
          colourPrice,
          interiorCode,
          interiorName,
          interiorPrice,
          materialCode,
          materialPrice,
          modelCode,
          modelName,
          modelPrice,
          quantity: 1,
          seriesCode,
          seriesName
        },
        carRegistration: {
          contactPerson,
          idNum,
          idType,
          idTypeName,
          mobile,
          ownerName
        },
        customerType,
        deliveryDealerCode,
        deliveryDealerName,
        discountAmount: this.discountAmountAll,
        installmentsFlag,
        orderId: this.$route.query.id,
        totalAmount,
        applyReason,
      }
      let fileListInfo = JSON.parse(JSON.stringify(fileList))
      fileListInfo = fileListInfo.map(({ cosUrl }) => {
        return cosUrl?.indexOf('?q-sign-algorithm') == -1 ? cosUrl : cosUrl.slice(0, cosUrl.indexOf('?q-sign-algorithm'))
      })
      params.applyUrl = fileListInfo.join(',')
      if (this.btnLoading) return false
      this.btnLoading = true
      loading.showLoading()
      const res = await orderServices.orderUpdateApply(params)
      loading.hideLoading()
      this.btnLoading = false
      const { code, msg, message } = res
      if (code == 0) {
        this.getOrderUpdateDetail()
        this.toExamine = true
      } else {
        this.$toast(msg || message)
      }
    },
    // 审核
    async examineRequest() {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确认提交审核 ？'),
        confirmButtonColor: '#B9921A',
      }).then(async () => {
        if (this.btnLoading) return false
        this.btnLoading = true
        loading.showLoading()
        const res = await orderServices.orderUpdateCommit({ orderId: this.$route.query.id })
        const { code, msg, message } = res
        if (code === 0) {
          setTimeout(() => {
            this.btnLoading = false
            loading.hideLoading()
            if (window.history.length>1){
              this.$router.go(-1)
            } else {
              window.location.reload()
            }
          }, 1500)
        } else if (code === 40047) {
          loading.hideLoading()
          this.$dialog.confirm({
            title: '',
            message: this.$t('该订单的新配置无资源，请联系客户修改配置'),
            confirmButtonColor: '#B9921A',
            showCancelButton: false,
            confirmButtonText: this.$t('我知道了'),
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          this.btnLoading = false
          loading.hideLoading()
          this.$toast(msg || message)
        }
      }).finally(() => {
      })
    },
    // 提交
    onSubmit(type) {
      if (!this.formData.customerType) {
        this.customerTypeValidate = true
        return false
      }
      this.customerTypeValidate = false
      if (![0, 1].includes(this.formData.installmentsFlag)) {
        this.installmentsFlagValidate = true
        return false
      }
      this.installmentsFlagValidate = false
      this.$refs.orderEditForm.validate().then(async () => {
        if (type == 'save') {
          this.orderApply()
        } else if (type == 'examine') {
          this.examineRequest()
        }
      }).catch(err => {
        const idName = err[0]?.name
        const target = document.getElementById(`${idName}`)
        target.scrollIntoView({ behavior: 'smooth' })
      })
    },
    // 取消修改 删除保存的配置信息 并且回到订单详情
    async cancelEdit() {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('取消修改后，系统会删除修改的内容，保持原订单不变。确认取消 ？'),
        confirmButtonColor: '#B9921A',
      }).then(async () => {
        if (this.btnLoading) return false
        this.btnLoading = true
        loading.showLoading()
        const res = await orderServices.orderUpdateCancel({ orderId: this.$route.query.id })
        const { code, msg, message } = res
        if (code === 0) {
          this.$toast.success(this.$t('操作成功！'))
          setTimeout(() => {
            this.btnLoading = false
            loading.hideLoading()
            if (window.history.length>1){
              this.$router.go(-1)
            } else {
              window.location.reload()
            }
          }, 1500)
        } else {
          this.btnLoading = false
          loading.hideLoading()
          this.$toast(msg || message)
        }
      }).finally(() => {
      })
    },
    // 重新编辑
    reEdit() {
      this.toExamine = false
      const { colourList = [] } = this.formData.carInfo
      // 组装外饰数据源
      this.outterColorList = colourList.map((item) => {
        return {
          id: item.colourCode,
          dictCode: item.colourCode,
          dictName: item.colourName,
          children: item.interiorList || [],
          colourPrice: item.colourPrice,
        }
      })
      // 组装内饰数据源
      const { dictCode: outDictCode, children = [] } = this.formData.outterColor
      if (outDictCode) {
        this.innerColorList = children.map((item) => {
          return {
            id: item.interiorCode,
            dictCode: item.interiorCode,
            dictName: item.interiorName,
            materialList: item.materialList,
            optionList: item.optionList,
            interiorPrice: item.interiorPrice
          }
        })
      }
      // 组装选装数据源
      const { dictCode: innerDictCode, optionList, materialList } = this.formData.innerColor
      if (innerDictCode) {
        this.optionsList = optionList.map((item) => {
          return {
            id: item.optionCode,
            dictCode: item.optionCode,
            dictName: item.optionName,
            optionPrice: item.optionPrice
          }
        })
        this.materialList = materialList
      }
    },
    // 一键催办
    async urging() {
      const res = orderServices.orderUpdateRemind({ orderId: this.$route.query.id })
      this.$toast(this.$t('催办成功！'))
    },
    // 发起审核
    async audit(params) {
      if (this.btnLoading) return false
      this.btnLoading = true
      loading.showLoading()
      const res = await orderServices.orderUpdateAudit(params)
      const { code, msg, message } = res
      if (code === 0) {
        this.$toast.success(this.$t('操作成功！'))
        setTimeout(() => {
          this.btnLoading = false
          loading.hideLoading()
          if (window.history.length>1){
            this.$router.go(-1)
          } else {
            window.location.reload()
          }
        }, 1500)
      } else if (code === 40019) {
        /**
         * 若当前审批人是流程的最后一个节点： 通过时，需要调用DCOS寻车接口 若寻车无资源，则提示如下。 
        选择立即驳回，则在驳回原因中自动填充 ：订单配置无资源
         */
        loading.hideLoading()
        this.btnLoading = false
        this.$dialog.confirm({
          title: '',
          message: msg || message,
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('立即驳回'),
          cancelButtonText: this.$t('取消'),
        }).then(async () => {
          const params = {
            orderIds: [this.$route.query.id],
            result: 0,
            refuseReason: this.$t('订单配置无资源')
          }
          this.audit(params)
        }).finally(() => {
        })
      } else {
        this.btnLoading = false
        loading.hideLoading()
        this.$toast(msg || message)
      }
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        this.$refs.dialogForm.validate().then(async () => {
          const params = {
            orderIds: [this.$route.query.id],
            result: 0,
            refuseReason: this.rejectParams.refuseReason
          }
          if (this.btnLoading) return false
          this.btnLoading = true
          loading.showLoading()
          const res = await orderServices.orderUpdateAudit(params)
          const { code, msg, message } = res
          if (code === 0) {
            this.$toast.success(this.$t('操作成功！'))
            setTimeout(() => {
              this.btnLoading = false
              loading.hideLoading()
              done()
              if (window.history.length>1){
                this.$router.go(-1)
              } else {
                window.location.reload()
              }
            }, 1500)
          } else {
            this.btnLoading = false
            loading.hideLoading()
            this.$toast(msg || message)
          }
        }, done.bind(null, false))
      } else {
        this.rejectParams = {
          refuseReason: '',
        }
        done()
      }
    },
    // 驳回
    rejectAudit() {
      this.showDialog = true
    },
    // 通过
    async passAudit() {
      // 通过的时候不再做物料和权益的校验
      // if (this.btnLoading) return false
      // this.btnLoading = true
      // loading.showLoading()
      // const res = await orderServices.orderUpdateAuditCheck({ orderIds: [this.$route.query.id], result: 1 })
      // loading.hideLoading()
      // this.btnLoading = false
      // const { code, msg, message } = res
      // if ([0, 40012, 40013].includes(code)) {
      //   this.$dialog.confirm({
      //     title: this.$t('提示'),
      //     message: code == 0 ? this.$t('确认审批通过 ？') : msg || message,
      //     confirmButtonColor: '#B9921A',
      //     confirmButtonText: code == 0 ? this.$t('确认') : this.$t('立即驳回')
      //   }).then(async () => {
      //     if (code == 0) {
      //       const params = {
      //         orderIds: [this.$route.query.id],
      //         result: 1
      //       }
      //       this.audit(params)
      //     } else {
      //       const params = {
      //         orderIds: [this.$route.query.id],
      //         result: 0,
      //         refuseReason: msg || message
      //       }
      //       this.audit(params)
      //     }
      //   }).finally(() => {
      //   })
      // } else {
      //   this.$toast.fail(msg || message)
      // }
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确认审批通过 ？'),
        confirmButtonColor: '#B9921A',
        confirmButtonText: this.$t('确认'),
        cancelButtonText: this.$t('取消'),
      }).then(async () => {
        const params = {
          orderIds: [this.$route.query.id],
          result: 1
        }
        this.audit(params)
      }).finally(() => {
      })
    },
    // 判断数组是否相等
    isEqualFun(one = [], two = []) {
      const arr1 = one.sort()
      const arr2 = two.sort()
      if (arr1.length != arr2.length) {
        return false
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false
        }
      }
      return true
    },
    // 展示差异
    showDifference(field) {
      if (Object.keys(this.orderRes).length == 0 || Object.keys(this.orderDetail).length == 0) return
      const {
        carOrderMaterial: {
          colourCode: colourCodeOld,
          colourName: colourNameOld,
          colourNameEn: colourNameEnOld,
          colourPrice: colourPriceOld,
          entryPrice: entryPriceOld,
          interiorCode: interiorCodeOld,
          interiorName: interiorNameOld,
          interiorNameEn: interiorNameEnOld,
          interiorPrice: interiorPriceOld,
          materialCode: materialCodeOld,
          materialPrice: materialPriceOld,
          modelCode: modelCodeOld,
          modelName: modelNameOld,
          modelNameEn: modelNameEnOld,
          modelPrice: modelPriceOld,
          quantity: quantityOld,
          seriesCode: seriesCodeOld,
          seriesName: seriesNameOld,
        },
        customerType: customerTypeOld,
        installmentsFlag: installmentsFlagOld,
        carRegistration: {
          cityName: cityNameOld,
          idNum: idNumOld,
          idType: idTypeOld,
          idTypeName: idTypeNameOld,
          mobile: mobileOld,
          ownerName: ownerNameOld,
          contactPerson: contactPersonOld,
        },
        deliveryDealerCode: deliveryDealerCodeOld,
        deliveryDealerName: deliveryDealerNameOld,
        carOptionsList: carOptionsListOld = []
      } = this.orderRes
      const {
        carOrderMaterial: {
          colourCode,
          interiorCode,
          modelCode,
          seriesCode,
        },
        customerType,
        installmentsFlag,
        carRegistration: {
          cityName,
          idNum,
          idType,
          idTypeName,
          mobile,
          ownerName,
          contactPerson
        },
        deliveryDealerCode,
        carOptionsList = []
      } = this.orderDetail
      switch (field) {
        case 'carInfo':
          return (seriesCode != seriesCodeOld || modelCode != modelCodeOld) ? `${this.$t('修改前：')}${seriesNameOld + modelNameOld}` : ''
        case 'out':
          return colourCode != colourCodeOld ? `${this.$t('修改前：')}${colourNameOld}` : ''
        case 'in':
          return interiorCode != interiorCodeOld ? `${this.$t('修改前：')}${interiorNameOld}` : ''
        case 'options':
          const optionsCompar = this.isEqualFun(carOptionsListOld.map((item => { return item.optionsCode })), carOptionsList.map((item => { return item.optionsCode }))) // eslint-disable-line
          return !optionsCompar ? `${this.$t('修改前：')}${carOptionsListOld.map((item) => { return item.optionsName }).join('、')}` : ''
        case 'customerType':
          return customerType != customerTypeOld ? `${this.$t('修改前：')}${customerTypeOld == 1 ? this.$t('个人') : this.$t('企业')}` : ''
        case 'installmentsFlag':
          return installmentsFlag != installmentsFlagOld ? `${this.$t('修改前：')}${installmentsFlagOld == 1 ? this.$t('分期') : this.$t('全款')}` : ''
        case 'ownerName':
          return ownerName != ownerNameOld ? `${this.$t('修改前：')}${ownerNameOld}` : ''
        case 'mobile':
          return mobile != mobileOld ? `${this.$t('修改前：')}${mobileOld}` : ''
        case 'idType':
          return idType != idTypeOld ? `${this.$t('修改前：')}${idTypeNameOld}` : ''
        case 'idNum':
          return idNum != idNumOld ? `${this.$t('修改前：')}${idNumOld}` : ''
        case 'contactPerson':
          return contactPerson != contactPersonOld ? `${this.$t('修改前：')}${contactPersonOld}` : ''
        case 'deliveryDealerName':
          return deliveryDealerCode != deliveryDealerCodeOld ? `${this.$t('修改前：')}${deliveryDealerNameOld}` : ''
        default:
          return ''
      }
    },
    // 图片校验
    beforeRead(file) {
      const isLt20M = file.size / 1024 / 1024 > 20
      if (!file.type.includes('image') || isLt20M) {
        this.$toast.fail(this.$t('不超过20张图片，单张不大于20M'))
        return false
      }
      return true
    },
    afterReadFile(file) {
      const uuid = uuidv4()
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file)
        .then(({ url, previewUrl }) => {
          if (file) {
            file.status = 'done'
            file.message = ''
            file.url = previewUrl
            file.cosUrl = url
            this.formData.fileList.push(file)
          }
        })
        .catch(() => {
          file.status = 'failed'
          file.message = this.$t('上传失败')
        })
    },
    beforeDelete(file) {
      this.formData.fileList = this.formData.fileList.filter((item) => item.uuid !== file.uuid)
    },
  }
}
</script>
<style lang="less" scoped>
.orderEdit {
  padding: 16px;
  display: flex;
  flex-direction: column;
  color: #0D171A;
  font-size: 13px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  &.orderEdit-pab {
    padding-bottom: 106px;
  }

  &.orderEdit-pab-n {
    padding-bottom: calc(106px + env(safe-area-inset-bottom));
  }
  .top-block{
    padding: 12px 16px;
    margin-bottom: 10px;
  }
  .orderEditForm {
    /deep/.specialTips{
      color: @yellow-text;
      text-align: left;
      font-size: 12px;
      padding-left: 6px;
      flex: 1;
    }
    /deep/ .fileUploadBox_hide{
      .van-uploader__upload{
        display: none;
      }
    }
    /deep/ .common-cell {
      position: relative;
      display: flex;
      font-size: 14px;
      padding: 10px 16px;

      &.required::before {
        position: absolute;
        top: 16px;
        left: 8px;
        color: #EED484;
        font-size: 15px;
        content: '*'
      }

      &:not(:last-of-type) {
        &::after {
          content: '';
          position: absolute;
          box-sizing: border-box;
          pointer-events: none;
          right: 16px;
          bottom: 0;
          left: 16px;
          border-bottom: 1px solid #ebedf0;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
        }
      }

      .common-cell-label {
        color: @black;
        width: 6.2em;
        margin-right: 12px;
        line-height: 32px;
      }

      .common-cell-value {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;

        &.customerType {
          &::before {
            content: '请选择购车类型';
            font-size: 12px;
            margin-right: 6px;
            color: #ee0a24;
          }
        }

        &.installmentsFlag {
          &::before {
            content: '请选择购车方式';
            font-size: 12px;
            margin-right: 6px;
            color: #ee0a24;
          }
        }

        .btn-item {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 8px;
          height: 24px;
          color: @black;
          background-color: #F5F5F5;
          border-radius: 4px;
          font-size: 12px;

          &.active {
            color: #B9921A;
            background: rgba(238, 212, 132, 0.45);
          }

          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
    }

    /deep/ .van-collapse {
      overflow: hidden;

      .van-collapse-item {
        .van-collapse-item__content {
          padding: 0;
          .van-cell__title {
            color: @black;
          }
        }
        .van-collapse-item__title--disabled {
          .van-icon-arrow {
            display: none;
          }
        }
        .collapse-title {
          padding-left: 8px;
          font-size: 15px;
          position: relative;
          color: #0D171A !important;
          font-weight: bold;
          &::before {
            content: ' ';
            left: 0;
            position: absolute;
            // top: calc(50% - 7px);
            top: 5px;
            width: 2px;
            height: 14px;
            // background: #0040C5;
            background: @yellow-text;
            border-radius: 0px 0px 0px 0px;
          }
        }

        .discount {
          display: flex;
          flex-direction: column;
          padding: 16px;

          .discount-item {
            display: flex;
            flex-direction: column;

            &:not(:last-of-type) {
              margin-bottom: 8px;
            }

            .discount-item-t {
              display: flex;
              justify-content: space-between;
              color: @black;
              // margin-bottom: 6px;
            }

            .discount-item-b {
              display: flex;
            }
          }
        }
      }
    }

    .common-block {
      margin-bottom: 16px;
    }

    /deep/ .differenceTips {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;

      .tips {
        color: #ee0a24;
        font-size: 13px;
        word-break: break-all
      }
    }

    .common-footer {
      height: 106px;
      padding-top: 10px;
      padding-bottom: 10px;
      &.common-footer-n {
        height: calc(106px + env(safe-area-inset-bottom));
      }
      .footer-l {
        width: auto;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        color: @black;
        font-size: 14px;
        .amount {
          font-size: 16px;
          font-weight: bold;
          color: @yellow-text;
        }
      }

      .btns {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .examine {
          flex: auto;
          width: 100% !important;
        }
        .uringTips {
          width: fit-content;
          left: 0;
          margin: 0 auto;
          font-size: 12px;
          color: #ee0a24;
          margin-bottom: -8px;
        }
      }
    }
  }
}
</style>
