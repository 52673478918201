<!--
* @Author: tianzl
* @Date: 2023-11-08 11:09:52
* @LastEditors: tianzl
* @LastEditTime: 2023-11-08 11:09:52
* @Description: 试驾车管理
 -->
 <template>
  <div class="car-manage">
    <van-tabs v-model="type" class="tabs1" @change="onRefresh">
      <van-tab
        v-for="item in tabs"
        :key="item.value"
        :title="item.name"
        :name="item.value"
      >
      </van-tab>
    </van-tabs>
    <div class="totalCount">
      {{ $t('共') }} {{ totalCount}} {{ $t('条数据') }}
    </div>
    <img v-if="userInfo.type === 0" class="img" :src="Object.values(filterParams).length > 0 ? filterActiveIcon : filterIcon" @click="filterPanelVisible = true"/>
    <FilterPanel
      ref="filterPanel"
      v-model="filterPanelVisible"
      :options="filterOptions"
      @submit="onFilter"
    />
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <CarItem :item-data="item" :type="type" @go="(type)=>handleGo(type,item)" @upDate="onRefresh"/>
      </template>
    </List>
  </div>
 </template>
<script>
import List from '@/components/baseList/list.vue'
import CarItem from './components/car-item.vue'
import loading from '@/utils/loading'
import CarManageServices from '@/services/carManageService.js'
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'
import FilterPanel from '@/components/filter-panel'
import { mapGetters } from 'vuex'
import salesAssistantService from '@/services/salesAssistant'
import formatParams from '@/utils/formatParams'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'CarManage',
  components:{ CarItem,List,FilterPanel },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'CarManage')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/salesAssistant', '/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.filterParams = {}
      this.$refs.filterPanel.reset()
      this.$store.commit('app/deleteKeepAlive', 'CarManage')
    }
    next()
  },
  data(){
    return {
      filterIcon,
      filterActiveIcon,
      filterParams: {},
      type:1,
      tabs:[
        {
          name: this.$t('可用车辆'),
          value: 1,
        },
        {
          name: this.$t('不可用车辆'),
          value: 0,
        }
      ],
      totalCount: 0,
      filterPanelVisible: false,
      filterOptions: [],
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
  },
  watch: {
    dictHash: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.createClueFilterOptions()
        }
      },
      deep: true
    },
  },
  activated() {
    this.onRefresh()
  },
  methods:{
    async createClueFilterOptions() {
      let ret = []
      const parentList = await salesAssistantService.getRegionTreeApi()
      // 大区
      ret.push({
        id: uuidv4(),
        type: 'selectOptions',
        label: this.$t('大区'),
        field: 'parentCode',
        options: parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
      })
      // 城市区域
      ret.push({
        id: uuidv4(),
        type: 'selectOptions',
        label: this.$t('城市区域'),
        multiple: true,
        field: 'childCode',
        options: [],
      })
      const provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
      // 省份
      ret.push({
        id: uuidv4(),
        type: 'selectOptions',
        label: this.$t('省份'),
        field: 'dealerProvinceCode',
        options: provinceList.map(({ code, name }) => ({ id: code, code, name })),
      })
      // 城市
      ret.push({
        id: uuidv4(),
        type: 'selectOptions',
        label: this.$t('城市'),
        field: 'dealerCityCode',
        options: [],
      })
      // 销售门店
      const dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '', dealerType: 1 })
      ret.push({
        id: uuidv4(),
        type: 'selectOptions',
        label: this.$t('销售门店'),
        multiple: true,
        field: 'dealerIds',
        dealerType: 1,
        options: dealerResult.dataList.map((item) => { return { code: item.id, name: item.name } })
      })
      this.filterOptions = ret
    },
    fetchApi(obj={}){
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        query:{
          isRetire: '11041002',
          status: this.type,
          ...this.filterParams,
        }
      }
      loading.showLoading()
      return CarManageServices.getList(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        return res
      })
    },
    // 刷新线索数据
    onRefresh() {
      try {
        this.$refs.list.onRefresh()
      } catch (error) {

      }
    },
    handleGo(type,item){
      const { vinCode,carNumber,id }=item
      const typeMap = {
        operate:'carOperate',
        schedule:'carScheduling',
        detail:'carManageDetail',
      }
      this.$router.push({
        name: typeMap[type],
        query:{
          id,
          vinCode,
          carNumber
        }
      })
    },
    onFilter(params) {
      let filterParams = formatParams(params, false)
      this.filterParams = filterParams
      this.filterPanelVisible = false
      this.onRefresh()
    },
  }
}
</script>
<style lang="less" scoped>
img{
  position: fixed;
  right: 10px;
  top: 10px;
  width: 24px;
  margin-right: 14px;
  &:last-of-type{
    margin-right: 0;
  }
}
.totalCount {
  color: @black;
  padding: 10px 16px 0 16px;
  font-size: 12px;
}
</style>