<template>
  <div class="specialStock"> 
    <div class="special-stock-top">
      <div class="search-l">{{ $t('列表为已入门店库的可用特殊车辆，若在库车辆未在列表展示，可在配置单明细查看该车是否被已有配置单占用；配置单过期失效或手动取消，可释放车辆资源') }}</div>
      <div class="search-r">
        <div class="filter-panel">
          <img class="img" :src="Object.values(filterParams).length > 0 ? filterActiveIcon : filterIcon" @click="filterActive = true"/>
        </div>
      </div>
    </div>
    <FilterPanel
      ref="filterPanel"
      v-model="filterActive"
      :options="filterOptions"
      @submit="onFilter"
      @reset="controlModelSales"
      @beforeReset="beforeReset"
    />
    
    <!-- List -->
    <div class="totality">
      <div>{{$t('共')}} {{ countNum }} {{$t('条数据')}}</div>
      <div class="btn" @click="handleConfigList">{{$t('查看配置单明细')}}</div>
      <div class="btn" @click="handleOperate">{{$t('查询中心仓库存')}}</div>
    </div>
    <List v-if="userInfo.id" ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <Item :data="item" @go="(type)=>handleDetail(item)"></Item>
      </template>
    </List>

  </div>
</template>

<script>
// Tools
import { mapGetters } from 'vuex'
import formatParams from '@/utils/formatParams'
import { arrayFormat, debounce } from '@/utils/index.js'
import { v4 as uuidv4 } from 'uuid'
import loading from '@/utils/loading'

// Components
import List from '@/components/baseList/list.vue'
import Item from './components/Item.vue'
import FilterPanel from '@/components/filter-panel'

// Images
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'

// APIs
import { getWarehouseList, isExistSpecialOrder } from '@/services/specialCar.js'
import SearchBarNew from '@/components/search-bar-new'
import salesAssistantService from '@/services/salesAssistant'
import mixin from './mixin'
export default {
  name: 'specialStock',
  components: { List, Item, FilterPanel, SearchBarNew },
  mixins: [mixin],
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/salesAssistant', '/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.filterParams = {}
      this.$refs.filterPanel.reset()
    }
    next()
  },
  data() {
    return {
      filterIcon,
      filterActiveIcon,
      filterActive: false,
      list: [],
      filterParams: {
        // specialVehicleTypes:[],
        // deliveryStoreCodes:[], // 交付门店
        // seriesCodes:[], // 车系
        // modelCodes:[], // 车型
        // colourCodes:[], // 外饰
        // interiorCodes:[], // 内饰
        // optionsCodes:[], // 选装
        // parentCode: '',
        // childCode: [],
        // dealerProvinceCode: '',
        // dealerCityCode: '',
        // dealerIds: []
      },
      defaultValue: {
        parentCode: '',
        childCode: [],
        dealerProvinceCode: '',
        dealerCityCode: '',
        dealerIds: []
      },
      vin: '',
      countNum: 0,
      parentList: [],
      provinceList: [],
      dealerResult: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    // 交付门店
    deliveryStoreList() {
      let arr = []
      try {
        arr = arrayFormat(this.userInfo.dealers[0].relations,{
          value: 'dealerCode',
          name:'dealerName',
          cb:(item)=>({ id:item.dealerCode })
        })
      } catch (error) {
            
      }
      return arr
    },
    filterOptions() {
      let arr = [{
        id: uuidv4(),
        type: 'options',
        label:this.$t('车辆类型'),
        field: 'specialVehicleTypes',
        multiple: true,
        isCache: true,
        options: this.$store.getters.getDictHash(4055)
      },
      {
        id: uuidv4(),
        type: 'options',
        label: this.$t('车系'),
        multiple: true,
        field: 'seriesCodes',
        options: this.seriesList,
        isCache: true,        
        onChange:(arr, params={})=>{
          Object.assign(params,{
            modelCodes:[],
            colourCodes:[],
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(2,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('车型'),
        multiple: true,
        field: 'modelCodes',
        options: this.modelList,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            colourCodes:[],
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(3,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('外饰'),
        multiple: true,
        field: 'colourCodes',
        options:this.outsideColours,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            interiorCodes:[],
            optionsCodes:[]
          })
          this.controlModelSales(4,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('内饰'),
        multiple: true,
        field: 'interiorCodes',
        options:this.innerColours,
        isCache: true,
        onChange:(arr, params={})=>{
          Object.assign(params,{
            optionsCodes:[]
          })
          this.controlModelSales(5,formatParams(params, false))
        }
      },{
        id: uuidv4(),
        type: 'options',
        label: this.$t('选装'),
        multiple: true,
        field: 'optionsCodes',
        options:this.saleOptions,
        isCache: true,
      }]
      if (this.userInfo.type === 1) {
        arr.unshift(
          {
            id: uuidv4(),
            type: 'options',
            label: this.$t('交付门店'),
            multiple: true,
            field: 'deliveryStoreCodes',
            options: this.deliveryStoreList,
            defaultValue: this.filterParams.deliveryStoreCodes && this.deliveryStoreList.filter(({ value })=>this.filterParams.deliveryStoreCodes.includes(value)) || [] ,
            isCache: true,
          }
        )
      } else {
        const { parentCode= '', childCode=[], dealerProvinceCode= '', dealerCityCode='', dealerIds=[] } = this.defaultValue
        arr.unshift(
          // 大区
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('大区'),
            field: 'parentCode',
            defaultValue: parentCode,
            options: this.parentList.map(({ code, name, children=[] }) => ({ id: code, code, name, children })),
          },
          // 城市区域
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('城市区域'),
            multiple: true,
            field: 'childCode',
            defaultValue: childCode.join(','),
            options: [],
          },
          // 省份
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('省份'),
            field: 'dealerProvinceCode',
            defaultValue: dealerProvinceCode,

            options: this.provinceList.map(({ code, name }) => ({ id: code, code, name })),
          },
          // 城市
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.$t('城市'),
            field: 'dealerCityCode',
            defaultValue: dealerCityCode,
            options: [],
          },
          // 门店
          {
            id: uuidv4(),
            type: 'selectOptions',
            label: this.active === '2' ? this.$t('销售门店') : this.$t('交付门店'),
            multiple: true,
            field: 'dealerIds',
            defaultValue: dealerIds.join(','),
            dealerType: this.active === '2' ? 1 : 2,
            options: this.dealerResult.dataList?.map((item) => { return { code: item.id, name: item.name } })
          }
        )
      }
      return arr
    }
  },
  watch: {
    deliveryStoreList:{
      handler(val){
        this.setDeliveryStoreCodes(val)
      },
      immediate: true
    },
    dictHash: {
      handler(){
        if (this.userInfo.type === 0) {
          this.getRegionAndAddress()
        }
      },
      immediate: true
    }
  },
  created() {
    this.controlModelSales()
  },
  methods: {
    beforeReset(options) {
      for (let i in options) {
        const item = options[i]
        if (item.field === 'deliveryStoreCodes' && this.deliveryStoreList && this.deliveryStoreList.length){
          item.defaultValue = [this.deliveryStoreList[0]]
        }
      } 
    },
    setDeliveryStoreCodes(val) {
      if (val && val.length){
        this.filterParams.deliveryStoreCodes=[val[0].value]
      }
    },
    onRefresh:debounce(function() {            
      this.$refs.list.onRefresh()
    },500),
    fetchApi(obj = {}) {
      let vinStr = ''
      if ([8, 17].includes(this.vin.length)) vinStr = this.vin
      const page = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
      }
      const { deliveryStoreCodes=[],...query } = this.filterParams
      return getWarehouseList({
        ...page,
        ...query,
        queryType: 0, // 查询类型 0门店库存 1中心库库存
        vin: vinStr,
        deliveryStoreCodes : deliveryStoreCodes.length ? deliveryStoreCodes: this.deliveryStoreList.map(({ value })=>value),
        type: this.active
      }).then(res=>{
        console.log('res-----------', res)
        this.countNum = res?.page?.totalCount || 0
        return res
      })
    },
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      const { parentCode='', childCode=[], dealerProvinceCode='', dealerCityCode='', dealerIds=[] } = this.filterParams
      this.defaultValue = { parentCode,childCode,dealerProvinceCode,dealerCityCode,dealerIds }
      this.onRefresh()
      this.filterActive= false
    },
    handleConfigList(){
      this.$router.push({
        path: '/special-car-order-config',
        query:{ }
      })
    },
    handleOperate(){
      this.$router.push({
        path: '/special-car-stock/operate',
        query:{ 
          deliveryStoreCode: this.filterParams.deliveryStoreCodes,
          deliveryStoreName: this.userInfo.type === 1 ? this.deliveryStoreList.filter(({ value })=>this.filterParams.deliveryStoreCodes.includes(value))[0].dealerName : '',
        }
      })
    },
    async handleDetail(data) {
      loading.showLoading()
      const params = {
        coVehicleSource: 2,
        configType: 2,
        deliveryDealerCode: data.deliveryStoreCode,
        deliveryDealerName: data.deliveryStoreName,
        materialCode: data.materialCode,
        specialVehicleType: data.specialVehicleType,
        vin: data.vin,
      }
      const res = await isExistSpecialOrder(params)
      loading.hideLoading()
      if (res.code === 0) {
        if (!res.data) { 
          setTimeout(() => {
            this.goDetail(data)
          }, 500)
        } else { 
          this.$dialog.confirm({
            title: '',
            message: `${res.data.salesName}已申请创建该车辆的配置单，店长审核中，不可重复申请`,
            confirmButtonText: '我知道了',
            showCancelButton: false
          }).then(() => {})
        }
      } else { 
        this.$toast.fail(res.msg)
      }
    },
    goDetail(item){
      this.$dialog.confirm({
        title: '',
        message: this.$t('配置单审批通过后，会在一定时间内为您预留该车辆资源'),
        confirmButtonColor: '#B9921A',
        confirmButtonText: this.$t('继续创建配置单'),
        beforeClose: async (action, done) => {
          done()
          if (action === 'confirm') {
            this.$router.push({
              path: '/special-car-stock/detail',
              query: {
                entryType: 'list',
                configType: 2,
                params: JSON.stringify(item)
              }
            })
          }
        },
      })
    },
    // 获取大小区 省份城市 交付门店
    async getRegionAndAddress() {
      this.parentList = await salesAssistantService.getRegionTreeApi()
      this.provinceList = await salesAssistantService.getPronceApi({ parentId: 0 })
      this.dealerResult = await salesAssistantService.getRegionPageApi({ childCode: [], cityCode: '', pageNum: 1, pageSize: 100000, parentCode: '', provinceCode: '', dealerType: 2 })
    },
  }
}
</script>

<style lang="less" scoped>
.specialStock {
  .special-stock-top{
    position: sticky;
    top: 0;
    z-index: 20;
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    .search-l{
      font-size: 14px;
      color: #7e7979;
      margin-right: 8px;
    }
  }
  .filter-icon {
    width: 20px;
    height: 20px;
    // position: fixed;
    // right: 12px;
    // top: 12px;
  }
  .totality{
    padding: 10px 16px 0;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    .btn {
      color: #B9921A;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
