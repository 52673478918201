<!--
* @Author: tianzl
* @Date: 2023-10-12 16:06:35
* @LastEditors: tianzl
* @LastEditTime: 2023-10-12 16:06:35
* @Description: 待分配线索
 -->
 <template>
  <div class="clue-item">
    <div class="left" @click.stop="$emit('goDetail',clue)">
      <div class="row">
        <!-- 总部下发的线索需要脱敏 店端自有线索不脱敏 -->
        <shortConsumerInfo :data="clue" :isDesensitization="clue.sourceType==='1007001'"/>
        <span v-if="clue.isAppointToConfirm===1" class="appoint-confirm">{{$t('预约待确认')}}</span>
      </div>
      <CardOptions :data="clue"/>
        <div class="info">
            <span class="label">{{ $t('用户来源') }}：</span>
            <span v-if="clue.channelOne || clue.channelTwo" class="channel-name"> {{ clue | sourceText }}</span>
        </div>
        <!-- <div class="info">
            <span class="label">{{ $t('意向车型') }}：</span>
            <span class="val"> {{ clue.seriesName }}  {{ clue.modelName  }}{{ !clue.seriesName&&!clue.modelName?'--':'' }}</span>
        </div> -->
        <!-- 线索归属 -->
        <div class="info">
            <span class="label">{{ $t('线索归属') }}：</span>
            <span class="val"> {{ getSourceName  }} </span>
        </div>
        <!-- 下发时间 -->
        <div class="info">
            <span class="label">{{ $t('下发时间') }}：</span>
            <span class="val"> {{ $t('于') }} {{ clue.distributeTime | timeText }} {{ $t('下发到店') }}</span>
        </div>
    </div>
    <div v-if="userInfo.type === 1" class="right">
        <van-checkbox :value="clue.checked" shape="square" @click.stop="onClick"></van-checkbox>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import CardOptions from '@/components/card-view/cardOptions.vue'
import { dateFormat } from '@/utils'
let vm
export default {
  name:'ClueItem',
  components:{
    shortConsumerInfo,
    CardOptions
  },
  filters: {
    // sourceText(data) {
    //   let str = ''
    //   const { channelOne, channelTwo } = data
    //   const splitStr = channelOne&&channelTwo?'/':''
    //   str+= (channelOne || '')
    //   str+= `${splitStr}${channelTwo || ''}`
    //   return str
    // },
    sourceText(data) {
      let arr = []
      const { channelOne, channelTwo, channelThree } = data
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      channelThree && arr.push(channelThree)
      return arr.length > 0 ? arr.join('/') : vm.$t('暂无')
    },
    timeText(data){
      const year = new Date(data).getFullYear()
      const theYear = new Date().getFullYear()
      if (year!=theYear){
        return dateFormat(data, 'YYYY-MM-DD HH:mm')
      } else {
        return dateFormat(data, 'MM-DD HH:mm')
      }
    }
  },
  props:{
    clue:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {
          
    }
  },
  computed:{
    ...mapGetters(['userInfo']),
    getSourceName() {
      const options = this.$store.getters.getDictHash(1007) || []
      const { sourceType } = this.clue
      const theOne = options.find(item=>item.code === sourceType)
      return theOne?.name || ''
    }
  },
  created() {
    vm = this
  },
  methods:{
    onClick(){
      this.$emit('change',!this.clue.checked,{ ...this.clue })
    }
  }
}
</script>
<style lang="less" scoped>
@checkbox-color:#0D171A;

.clue-item{
  display: flex;
  // height: 140px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 16px 12px 8px 12px;
  .left{
      flex:1;
      color: @checkbox-color;
      font-size: 13px;
      height: 16px;
      line-height: 16px;
      height: auto;
      .info{
          margin-bottom: 8px;
          .label{
              color: rgba(13,23,26,0.45);
          }
          .channel-name{
              position: relative;
              // padding-left: 8px;
          }
          // .channel-name::before{
          //     position: absolute;
          //     top:50%;
          //     transform: translateY(-50%);
          //     left: 0;
          //     width: 1px;
          //     height: 14px;
          //     content: '';
          //     background: #D9D9D9;
          // }
      }
      .row{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(13,23,26,0.05);
        .appoint-confirm{
          color: #FA5410;
        }
      }
  }
  .right{
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/.van-checkbox__icon{
          font-size: 16px;
          .van-icon{
              border: 2px solid rgba(13,23,26,0.45);
          }
      }

      /deep/.van-checkbox__icon--checked .van-icon{
          background-color: @checkbox-color;
          border-color: @checkbox-color;
          line-height: 1;
      }
  }
}
</style>
